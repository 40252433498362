import { Component, OnInit } from '@angular/core';
import {BreadcrumbService} from "xng-breadcrumb";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {filter, Observable} from "rxjs";
import {View} from "../../../../shared/store/view/types";
import {ViewService} from "../../../../shared/store/view/views.service";

@UntilDestroy()
@Component({
  selector: 'app-country-list',
  templateUrl: './country-list.component.html',
  styleUrls: ['./country-list.component.scss'],
})
export class CountryListComponent implements OnInit {
  view$: Observable<View>;

  constructor(private readonly breadcrumbService:BreadcrumbService,
              private readonly viewService:ViewService
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {

    this.view$.pipe(
      untilDestroyed(this),
      filter((x) => !!x)
    ).subscribe((x) => {
      this.breadcrumbService.set('@countryList', x.title)
    });
  }
}
