import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { SidebarModule } from './types';

export const sidebarActions = {
  getSidebarModule: createApiCallActions<SidebarModule[]>(
    'sidebar',
    'getSidebarModule'
  ),
};
export type SidebarAction = typeof sidebarActions;
