import { createFeatureSelector } from "@ngrx/store";
import { ICountryState } from "./country.reducer";
import { countryFeatureKey } from "./types"
import { createApiCallSelectors } from "src/app/common/store/generic.selectors";

const countryFeatureState = 
    createFeatureSelector<ICountryState>(countryFeatureKey);

export const countrySelectors = {
    getById: createApiCallSelectors<number, ICountryState>(
        countryFeatureState,
        'getById'
    ),
    postCountry: createApiCallSelectors<any, ICountryState>(
        countryFeatureState,
        'postCountry'
    ),
    updateCountry: createApiCallSelectors<any, ICountryState>(
        countryFeatureState,
        'updateCountry'
    )
};