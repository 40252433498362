<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  formGroupName="healthFormGroup"
>
  <div class="d-flex flex-column h-100">
    <div class="mt-2">
      <div class="form-group-title my-1" translate>
        CONTRACT_FIELDSET_FAMILY_COMPOSITION
      </div>
      <div class="row my-2 family-row">
        <div class="col-md-6 col-12">
          <div class="card">
            <div class="card-header">
              <h5 class="form-group-title my-1" translate>
                CONTRACT_FIELDSET_INSURED_SUBSCRIBER
              </h5>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <div class="col-12 col-xl-6">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >CONTRACT_LBL_INSURED_PERSONS_LASTNAME</label
                    >
                    <div class="col">
                      <input type="hidden" formControlName="subscriberId"/>
                      <input
                        class="form-control form-control-sm"
                        id="subscriberLastName"
                        name="subscriberLastName"
                        type="text"
                        value=""
                        formControlName="subscriberLastName"
                        oninput="this.value = this.value.toUpperCase()"
                        checkFormFieldValidity
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >CONTRACT_LBL_INSURED_PERSONS_FIRSTNAME</label
                    >
                    <div class="col">
                      <input
                        class="form-control form-control-sm"
                        id="subscriberFirstName"
                        name="subscriberFirstName"
                        type="text"
                        value=""
                        formControlName="subscriberFirstName"
                        checkFormFieldValidity
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>
                      CONTRACT_LBL_INSURED_PERSONS_REGIME
                    </label>
                    <div class="col">
                      <app-typeahead
                        [limitToList]="true"
                        [dataType]="DataType.Enum"
                        [referenceEndpoint]="'Views/enum/values'"
                        [referenceField]="'Comparanoo.Core.Enums.RegimeEnum'"
                        [dropdownStyle]="dropdownStyle"
                        [controlForm]="
                          this.form.controls['healthFormGroup'].get(
                            'subscriberRegime'
                          )
                        "
                      ></app-typeahead>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-6">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>
                      CONTRACT_LBL_INSURED_PERSONS_BIRTHDATE
                    </label>
                    <div class="col">
                      <input
                        class="form-control form-control-sm"
                        id="subscriberBirthDate"
                        name="subscriberBirthDate"
                        type="date"
                        value=""
                        formControlName="subscriberBirthDate"
                        checkFormFieldValidity
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>
                      CONTRACT_LBL_INSURED_PERSONS_GENDER
                    </label>
                    <div class="col">
                      <app-typeahead
                        [limitToList]="true"
                        [dataType]="DataType.Enum"
                        [referenceEndpoint]="'Views/enum/values'"
                        [referenceField]="'Comparanoo.Core.Enums.Gender'"
                        [dropdownStyle]="dropdownStyle"
                        [controlForm]="
                          this.form.controls['healthFormGroup'].get(
                            'subscriberGender'
                          )
                        "
                      ></app-typeahead>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>
                      CONTRACT_LBL_INSURED_PERSONS_SOCIAL_SECURITY_NUMBER
                    </label>
                    <div class="col">
                      <input
                        class="form-control form-control-sm"
                        id="subscriberSocialSecurityNumber"
                        name="subscriberSocialSecurityNumber"
                        type="text"
                        [placeholder]="'CONTRACT_PLACEHOLDER_SOCIAL_SECURITY_NUMBER' | translate"
                        formControlName="subscriberSocialSecurityNumber"
                        mask="0 00 00 00 000 000 00"
                        checkFormFieldValidity
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>
                      CONTRACT_LBL_INSURED_PERSONS_CPAM
                    </label>
                    <div class="col">
                      <input
                        class="form-control form-control-sm"
                        id="subcriberCodeCpam"
                        name="subcriberCodeCpam"
                        type="text"
                        [placeholder]="'CONTRACT_PLACEHOLDER_CODE_CPAM' | translate"
                        formControlName="subcriberCodeCpam"
                        mask="00 000 0000"
                        checkFormFieldValidity
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <button
            class="btn-sm btn btn-outline-primary btn-add"
            (click)="addPartner()"
            *ngIf="!this.hasPartner"
            translate
          >
            CONTRACT_LBL_ADD_PARTNER
          </button>
          <ng-container *ngIf="this.hasPartner" formArrayName="partners">
            <div
              class="card"
              *ngFor="let partner of partners.controls; index as i"
              [formGroupName]="i"
            >
              <div class="card-header">
                <h5 class="form-group-title my-1" translate>
                  CONTRACT_FIELDSET_INSURED_PARTNER
                </h5>
                <button
                  class="btn btn-outline-danger btn-sm"
                  (click)="removePartner()"
                >
                  <i class="mdi mdi-close"></i>
                </button>
              </div>
              <div class="card-body">
                <div class="row mt-2">
                  <div class="col-12 col-xl-6">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_INSURED_PERSONS_LASTNAME</label
                      >
                      <div class="col">
                        <input type="hidden" formControlName="partnerId"/>
                        <input
                          class="form-control form-control-sm"
                          id="partnerLastName"
                          name="partnerLastName"
                          type="text"
                          value=""
                          formControlName="partnerLastName"
                          oninput="this.value = this.value.toUpperCase()"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_INSURED_PERSONS_FIRSTNAME</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="partnerFirstName"
                          name="partnerFirstName"
                          type="text"
                          value=""
                          formControlName="partnerFirstName"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_INSURED_PERSONS_REGIME</label
                      >
                      <div class="col">
                        <app-typeahead
                          [limitToList]="true"
                          [dataType]="DataType.Enum"
                          [referenceEndpoint]="'Views/enum/values'"
                          [referenceField]="'Comparanoo.Core.Enums.RegimeEnum'"
                          [dropdownStyle]="dropdownStyle"
                          [controlForm]="partner.get('partnerRegime')"
                        ></app-typeahead>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-6">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_INSURED_PERSONS_BIRTHDATE</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="partnerBirthDate"
                          name="partnerBirthDate"
                          type="date"
                          value=""
                          formControlName="partnerBirthDate"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate>
                        CONTRACT_LBL_INSURED_PERSONS_GENDER
                      </label>
                      <div class="col">
                        <app-typeahead
                          [limitToList]="true"
                          [dataType]="DataType.Enum"
                          [referenceEndpoint]="'Views/enum/values'"
                          [referenceField]="'Comparanoo.Core.Enums.Gender'"
                          [dropdownStyle]="dropdownStyle"
                          [controlForm]="partner.get('partnerGender')"
                        ></app-typeahead>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate>
                        CONTRACT_LBL_INSURED_PERSONS_SOCIAL_SECURITY_NUMBER
                      </label>
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="partnerSocialSecurityNumber"
                          name="partnerSocialSecurityNumber"
                          type="text"
                          [placeholder]="'CONTRACT_PLACEHOLDER_SOCIAL_SECURITY_NUMBER' | translate"
                          formControlName="partnerSocialSecurityNumber"
                          mask="0 00 00 00 000 000 00"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate>
                        CONTRACT_LBL_INSURED_PERSONS_CPAM
                      </label>
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="partnerCodeCpam"
                          name="partnerCodeCpam"
                          type="text"
                          [placeholder]="'CONTRACT_PLACEHOLDER_CODE_CPAM' | translate"
                          formControlName="partnerCodeCpam"
                          mask="00 000 0000"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <hr/>
      <div class="row my-2 family-row">
        <ng-container formArrayName="children">
          <div
            class="col-md-6 col-12"
            *ngFor="let childForm of children.controls; index as i"
            [formGroupName]="i"
          >
            <div class="card">
              <div class="card-header">
                <h5 class="form-group-title my-1" translate>
                  CONTRACT_FIELDSET_INSURED_CHILD
                </h5>
                <button
                  class="btn btn-outline-danger btn-sm"
                  (click)="removeChild(i)"
                  data-bs-toggle="tooltip"
                >
                  <i class="mdi mdi-close"></i>
                </button>
              </div>
              <div class="card-body">
                <div class="row mt-2">
                  <div class="col-12 col-xl-6">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_INSURED_PERSONS_LASTNAME</label
                      >
                      <div class="col">
                        <input type="hidden" formControlName="childId"/>
                        <input
                          class="form-control form-control-sm"
                          id="childLastName"
                          name="childLastName"
                          type="text"
                          value=""
                          formControlName="childLastName"
                          oninput="this.value = this.value.toUpperCase()"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_INSURED_PERSONS_FIRSTNAME</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="childFirstName"
                          name="childFirstName"
                          type="text"
                          value=""
                          formControlName="childFirstName"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_INSURED_PERSONS_REGIME</label
                      >
                      <div class="col">
                        <app-typeahead
                          [limitToList]="true"
                          [dataType]="DataType.Enum"
                          [referenceEndpoint]="'Views/enum/values'"
                          [referenceField]="'Comparanoo.Core.Enums.RegimeEnum'"
                          [dropdownStyle]="dropdownStyle"
                          [controlForm]="childForm.get('childRegime')"
                        ></app-typeahead>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-6">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_INSURED_PERSONS_BIRTHDATE</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="childBirthDate"
                          name="childBirthDate"
                          type="date"
                          value=""
                          formControlName="childBirthDate"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate>
                        CONTRACT_LBL_INSURED_PERSONS_GENDER
                      </label>
                      <div class="col">
                        <app-typeahead
                          [limitToList]="true"
                          [dataType]="DataType.Enum"
                          [referenceEndpoint]="'Views/enum/values'"
                          [referenceField]="'Comparanoo.Core.Enums.Gender'"
                          [dropdownStyle]="dropdownStyle"
                          [controlForm]="childForm.get('childGender')"
                        ></app-typeahead>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_INSURED_PERSONS_SUBSCRIBER_AFFILIATION</label
                      >
                      <div class="col">
                        <span class="form-control-plaintext form-control-sm">
                          <input
                            #isAffiliatedToSubscriber
                            type="checkbox"
                            name="active"
                            formControlName="childAffiliatedToSubscriber"
                          />
                        </span>
                      </div>
                    </div>
                    <div class="form-group row" *ngIf="!isAffiliatedToSubscriber.checked">
                      <label class="col-4 col-form-label-sm" translate>
                        CONTRACT_LBL_INSURED_PERSONS_SOCIAL_SECURITY_NUMBER
                      </label>
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="childSocialSecurityNumber"
                          name="childSocialSecurityNumber"
                          type="text"
                          [placeholder]="'CONTRACT_PLACEHOLDER_SOCIAL_SECURITY_NUMBER' | translate"
                          formControlName="childSocialSecurityNumber"
                          mask="0 00 00 00 000 000 00"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                    <div class="form-group row" *ngIf="!isAffiliatedToSubscriber.checked">
                      <label class="col-4 col-form-label-sm" translate>
                        CONTRACT_LBL_INSURED_PERSONS_CODE_CPAM
                      </label>
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="childCodeCpam"
                          name="childCodeCpam"
                          type="text"
                          [placeholder]="'CONTRACT_PLACEHOLDER_CODE_CPAM' | translate"
                          formControlName="childCodeCpam"
                          mask="00 000 0000"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="col-md-6 col-12">
          <button
            class="btn-sm btn btn-outline-primary btn-add"
            (click)="addChild()"
            translate
          >
            CONTRACT_LBL_ADD_CHILD
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
