import { Pipe, type PipeTransform } from '@angular/core';
import { Domain } from '../../store/search/types';

@Pipe({
  name: 'formattedDomain',
})
export class FormattedDomainPipe implements PipeTransform {
  transform(domains: Domain[], ...args: any[]): Domain[] {
    let filteredDomains = JSON.parse(JSON.stringify(domains));
    filteredDomains = filteredDomains.filter((domain) => domain.isActive);
    let formattedDomains: Domain[] = [];
    filteredDomains.forEach((domain: Domain) => {
      let existingDomain = formattedDomains.filter(
        (formattedDomain) => formattedDomain.field == domain.field
      );
      if (existingDomain.length) {
        let existingIndex = formattedDomains.indexOf(existingDomain[0]);
        formattedDomains[existingIndex].text =
          formattedDomains[existingIndex].text + ' <i>ou</i> ' + domain.text;
      } else {
        formattedDomains.push(domain);
      }
    });
    return formattedDomains;
  }
}
