import {Injectable} from "@angular/core";
import {BaseServiceFacade, GenericApiCall} from "src/app/common/store/types";
import {Store} from "@ngrx/store";
import {AppState} from "../types";
import {Comment, CommentPost, InputComment} from "./types";
import {commentActions} from "./comment.action";
import {commentSelectors} from "./comment.selector";

@Injectable()
export class CommentService
    extends BaseServiceFacade
    //implements IGridDataService
{
    constructor(store: Store<AppState>) {
        super(store);
    }

    getByEntityId: GenericApiCall<Comment[], InputComment> = this.genericApiCall(
        commentActions.getByEntityId,
        commentSelectors.getByEntityId,
        true
    );

    postComment: GenericApiCall<any, CommentPost> = this.genericApiCall(
        commentActions.postComment,
        commentSelectors.postComment,
        true
    );
}
