import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { View } from '../../../../shared/store/view/types';
import { Campaign } from '../../../../shared/store/campaign/types';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { FormService } from '../../../../shared/store/form/form.service';
import { CampaignService } from '../../../../shared/store/campaign/campaign.service';
import { formatDate } from '../../../../shared/helper/datehelper';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-campaign-edit',
  templateUrl: './campaign-edit.component.html',
  styleUrls: ['./campaign-edit.component.scss'],
})
export class CampaignEditComponent implements OnInit, OnDestroy {
  campaign$: Observable<Campaign>;
  campaignForm: FormGroup;
  view$: Observable<View>;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly campaignService: CampaignService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.campaign$ = this.campaignService.getById.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'campaignUpdate') this.onSubmit();
        if (x == 'campaignDetails') {
          this.router.navigate([
            'Sales/Campaigns/Details/',
            this.route.snapshot.params['id'],
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.campaignService.getById.call(this.route.snapshot.params['id']);

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@campaignList', x.title);
      });

    this.campaignForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      startDate: [''],
      endDate: [''],
    });

    this.campaign$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@campaignEdit', x.name);
        this.campaignForm.patchValue({
          name: x.name,
          startDate: formatDate(x.startDate, 'ToInverseSqlDate'),
          endDate: formatDate(x.endDate, 'ToInverseSqlDate'),
        });
      });
  }

  onSubmit() {
    if (this.campaignForm.invalid) {
      this.campaignForm.markAllAsTouched();
      this.formService.countErrors(this.campaignForm, true);
      return;
    }
    console.log(this.campaignForm);

    this.formService.clear();

    let result$ = this.campaignService.updateCampaign.call({
      id: this.route.snapshot.params['id'],
      name: this.campaignForm.value.name!,
      startDate:
        this.campaignForm.value.startDate === ''
          ? null
          : this.campaignForm.value.startDate!,
      endDate:
        this.campaignForm.value.endDate === ''
          ? null
          : this.campaignForm.value.endDate!,
    });
    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.router.navigate(['/Sales/Campaigns/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.campaignService.updateCampaign.reset();
    this.breadcrumbService.set('@campaignEdit', ' ');
  }
}
