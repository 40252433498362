<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  *ngIf="this.carDetail$ | async as carLead"
>
  <div class="d-flex flex-column h-100">
    <ng-container>
      <div class="form-group-title my-1" translate>
        LEAD_LBL_VEHICLE_REGISTRATION_HOLDER
      </div>
      <div class="row mt-2">
        <div class="col-12 col-xl-6">
          <div class="form-group row">
            <label class="col-4 col-form-label-sm" translate
              >LEAD_LBL_VEHICLE_REGISTRATION_HOLDER</label
            >
            <div class="col-auto">
              <span class="form-control-plaintext form-control-sm">
                {{ carLead.vehiculeRegistrationHolder }}
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label-sm" translate
              >LEAD_LBL_VEHICLE_REGISTRATION_HOLDER_COMPANY_NAME</label
            >
            <div class="col-auto">
              <span class="form-control-plaintext form-control-sm">
                {{ carLead.vehiculeRegistrationHolderCompanyName }}
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label-sm" translate
              >LEAD_LBL_VEHICLE_REGISTRATION_HOLDER_FIRSTNAME</label
            >
            <div class="col-auto">
              <span class="form-control-plaintext form-control-sm">
                {{ carLead.vehiculeRegistrationHolderNameFirstName }}
              </span>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6">
          <div class="form-group row">
            <label class="col-4 col-form-label-sm" translate
              >LEAD_LBL_VEHICLE_REGISTRATION_HOLDER_COMPANY_NAME</label
            >
            <div class="col-auto">
              <span class="form-control-plaintext form-control-sm">
                {{ carLead.vehiculeRegistrationHolderCompanyName }}
              </span>
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label-sm" translate
              >LEAD_LBL_VEHICLE_REGISTRATION_HOLDER_BIRTHDATE</label
            >
            <div class="col-auto">
              <span class="form-control-plaintext form-control-sm">
                {{
                  carLead.vehiculeRegistrationHolderBirthdate
                    | date : "dd/MM/yyyy"
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="form-group-title my-1" translate>LEAD_LBL_VEHICLE_VEHICLE_DESCRIPTION</div>
    <div class="row mt-2">
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_LICENSE_PLATE_NUMBER</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carLead.licensePlateNumber }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_BRAND</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carLead.brand }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_MODEL</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carLead.model }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_EXACT_MODEL</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carLead.exactModel }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_CAR_BODY</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carLead.carBody }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_CAR_ENERGY</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carLead.carEnergie }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_CAR_POWER</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carLead.carPower }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_VEHICLE_USAGE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carLead.vehiculeUsage }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_VEHICLE_USAGE_PRO</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carLead.vehiculeUsageProSupp }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_PARKING_TYPE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carLead.parkingType }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_DATE_OF_CIRCULATION</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carLead.dateOfCirculation | date : "dd/MM/yyyy" }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
