import { DeviceInfo } from 'ngx-device-detector';

export type Environment = 'debug' | 'dev' | 'test' | 'preprod' | 'prod';

export class Config {
  production: boolean;
  deviceInfo!: DeviceInfo;
  env: { name: Environment; version: string };
  api:{ authenticateUrl: string}
  features: {};

  constructor() {
    this.production = false;
    this.env = { name: 'debug', version: '' };
    this.api = { authenticateUrl : ''};
    this.features = {};
  }
}