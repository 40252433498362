<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.employeeBase$ | async as employee">

        <div class="card card-light w-100">

          <div class="card-body">
            <div class="container-fluid">

              <div class="row mb-2">
                <div class="col">
                  <div class="form-group">
                    <label class="form-title-label" translate>EMPLOYEE_LBL_NAME</label>
                    <div class="form-title">{{ employee.userContactName }}</div>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="avatar-upload">
                    <div class="avatar-preview rounded-circle shadow-sm position-relative">
                      <div id="imagePreview" class="rounded-circle">
                        <img
                          src="{{this.avatarUrl}}?name={{employee.userContactName}}&bgColor={{this.bgColorDefaultCss}}&color=FFFFFF"
                          alt="{{employee.userContactName}}"
                          class="avatar-preview"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-4">
                <div class="col-12 col-md">

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>EMPLOYEE_LBL_MAIL</label>
                    <div class="col">
                    <span class="form-control-plaintext form-control-sm">
                      <a href="mailto:{{ employee.workEmail }}">{{ employee.workEmail }}</a>
                    </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>EMPLOYEE_LBL_PHONE_NUMBER</label>
                    <div class="col">
                    <span class="form-control-plaintext form-control-sm">
                      <app-phone-number
                        [phoneNumber]="employee.workPhone"
                        [isLinkEnabled]="true"
                      ></app-phone-number>
                    </span>
                    </div>
                  </div>
                </div>
                <div class="col">

                </div>
              </div>
              <app-employee-tabs-details />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.employeeBase$ | async as employee" [hidden]="false">
      <app-comment-panel [entityType]="'Employee'" [entityId]="employee.id.toString()"></app-comment-panel>
    </div>
  </div>
</div>
