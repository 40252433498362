<app-document-subheader></app-document-subheader>
<app-custom-actions-flow-article></app-custom-actions-flow-article>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.article$ | async as article">
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <div class="form-title">{{ article.name }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-lg-6">

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>ARTICLE_LBL_URL</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ article.url }}
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>ARTICLE_LBL_DESCRIPTION</label>
                    <div class="col">
                       <span [innerHTML]="article.description" class="form-control-plaintext form-control-sm"></span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>ARTICLE_LBL_CREATION_DATE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ article.creationDate | date : "dd/MM/yyyy HH:mm" }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>ARTICLE_LBL_PUBLICATION_DATE</label>
                    <div class="col">
                      <span
                        class="form-control-plaintext form-control-sm"
                        *ngIf="article.publicationDate != null; else elseBlock"
                      >
                        {{ article.publicationDate | date : "dd/MM/yyyy HH:mm" }}
                      </span>
                    </div>
                  </div>
                </div>

              </div>

              <div class="row mt-2">
                <div class="col-12 col-lg-12">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>ARTICLE_LBL_PAGE_CONTENT</label>
                    <div
                      [innerHTML]="article.pageContent | fullPathImage : 'ToAbsolute'"
                      class="card card-light card-body"
                    ></div>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.article$ | async as article">
      <app-comment-panel [entityType]="'Article'" [entityId]="article.id.toString()"></app-comment-panel>
    </div>
  </div>
</div>


<ng-template #elseBlock>
  <span class="form-control-plaintext form-control-sm" translate
  >ARTICLE_LBL_NOT_PUBLISHED</span
  ></ng-template
>
