import {Action, ActionReducer, createReducer, on} from '@ngrx/store';
import {authenticationActions} from './authentication.actions';
import {UserConnected} from './types';
import {GenericState, IState} from "../../../common/store/types";
import {onApiCall} from "../../../common/store/generic.reducer";


export interface IAuthenticationState extends IState<any> {
  userConnected: UserConnected;
  forgotPassword: GenericState<any>;
  resetPassword: GenericState<any>;
  changePassword: GenericState<any>;
}

export const authenticationInitialState: IAuthenticationState = {
  userConnected: {username: '', email: ''},
  forgotPassword: new GenericState<any>(),
  resetPassword: new GenericState<any>(),
  changePassword: new GenericState<any>(),
};

const authenticationReducers = [
  on(
    authenticationActions.setUserConnected,
    (state: IAuthenticationState, {userConnected}) => ({
      ...state,
      userConnected: userConnected,
    })
  ),
  ...onApiCall<any>(authenticationActions.forgotPassword, 'forgotPassword'),
  ...onApiCall<any>(authenticationActions.resetPassword, 'resetPassword'),
  ...onApiCall<any>(authenticationActions.changePassword, 'changePassword'),
];

export const authenticationReducer = createReducer(
  authenticationInitialState,
  ...authenticationReducers
) as ActionReducer<IAuthenticationState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return authenticationReducer(state, action);
}
