import { createFeatureSelector } from '@ngrx/store';
import { IBankAccountState } from './bank-account.reducer';
import { bankAccountFeatureKey } from './types';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';

const bankAccountFeatureState = createFeatureSelector<IBankAccountState>(
  bankAccountFeatureKey
);

export const bankAccountSelectors = {
  getById: createApiCallSelectors<string, IBankAccountState>(
    bankAccountFeatureState,
    'getById'
  ),
};
