export function isNumeric(str) {
  if (typeof str != 'string') return false; // we only process strings!
  return (
    !isNaN(str as any) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
    !isNaN(parseFloat(str))
  ); // ...and ensure strings of whitespace fail
}

export function decodeHtml(html: string) {
  let txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

export function toFirstLetterLower(str: string) {
  return str[0].toLowerCase() + str.substring(1);
}
