import { Directive, HostListener, Self } from '@angular/core';
import { NgForm } from '@angular/forms';

@Directive({
  selector: 'form[formAccessible]',
})
export class FormAccessibleDirective {
  constructor() {}

  // Listen for the input event to also handle copy and paste.
  @HostListener('submit', ['$event.target'])
  onSubmit(target: any) {
    console.log(target);

    let x: HTMLElement = target;

    //Rechercher comment trouver les élements (angular et pas jquery)
    // // find the first invalid element (visible and not readonly inputs/textarea)
    // var firstInvalid =
    //   (x).find('input:visible:not([readonly]).ng-invalid')[0] ||
    //   (x.get).find('textarea:visible:not([readonly]).ng-invalid')[0] ||
    //   (x).find('input.validate.ng-invalid')[0];

    // if we find one, set focus
    // if (firstInvalid) {
    //   firstInvalid.focus();
    // }
  }
}
