import { Pipe, type PipeTransform } from '@angular/core';
import { Filter, Domain } from '../../store/search/types';

@Pipe({
  name: 'isDomainActive',
})
export class IsDomainActivePipe implements PipeTransform {
  transform(
    activeFilters: Filter[],
    customFilter: Filter,
    domain: Domain
  ): boolean {
    let isDomainActive: boolean = false;
    let filter = activeFilters.find((f) => f.name == customFilter.name);
    if (filter == undefined) {
      return isDomainActive;
    }
    let activeDomain = filter.domains.find(
      (d) => d.field == domain.field && d.value == domain.value
    );
    if (activeDomain != undefined) {
      if (activeDomain.isActive) {
        isDomainActive = true;
      }
    }
    return isDomainActive;
  }
}
