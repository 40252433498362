import { SalesTeamMember } from '../sales-team-member/types';

export const salesTeamFeatureKey = 'salesTeam';

export type SalesTeam = {
  id: number;
  name: string;
  code: string;
  notes: string;
  active: boolean;
  teamLeaderId: number;
  teamLeaderContactName: string;
  parentId: number;
  parentName: string;
  salesTeamMembers: SalesTeamMember[];
};

export type SalesTeamBase = {
  id: number;
  name: string;
};

export type SalesTeamPost = {
  name: string;
  code: string;
  active: boolean;
  teamLeaderId: number;
};

export type SalesTeamUpdate = {
  id: number;
  name: string;
  code: string;
  notes: string;
  active: boolean;
  teamLeaderId: number;
};

export type CreateSalesTeamFormModel = {
  name: string;
  code: string;
  active: boolean;
  teamLeaderId: number;
};
