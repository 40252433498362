import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { ZipCodeRoutingModule } from "./zipcode-routing.module";
import { ZipCodeListComponent } from "./zipcode-list/zipcode-list.component";
import { ZipCodeDetailsComponent } from "./zipcode-details/zipcode-details.component";

@NgModule({
    declarations:[
        ZipCodeListComponent,
        ZipCodeDetailsComponent
    ],
    imports:[
        CommonModule,
        SharedModule,
        ZipCodeRoutingModule,
    ]
})
export class ZipCodeModule{}