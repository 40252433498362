import {StoreModule} from "@ngrx/store";
import {commentFeatureKey} from "./types";
import {EffectsModule} from "@ngrx/effects";
import {commentInitialState, commentReducer, ICommentState} from "./comment.reducer";
import {NgModule} from "@angular/core";
import {CommentEffects} from "./comment.effects";
import {CommentApiService} from "./comment.api.service";
import {CommentService} from "./comment.service";

@NgModule({
    imports: [
        StoreModule.forFeature<ICommentState>(commentFeatureKey, commentReducer, {
            initialState: commentInitialState,
        }),
        EffectsModule.forFeature([CommentEffects])
    ],
    providers: [CommentApiService, CommentService]
})
export class StoreCommentModule {
}
