import { IContactState } from './contact.reducer';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { createFeatureSelector } from '@ngrx/store';
import {
  contactFeatureKey,
  ContactPost,
  ContactUpdate,
  CreateContactFormModel,
} from './types';

const contactFeatureState =
  createFeatureSelector<IContactState>(contactFeatureKey);

export const contactSelectors = {
  getById: createApiCallSelectors<number, IContactState>(
    contactFeatureState,
    'getById',
  ),
  postContact: createApiCallSelectors<ContactPost, IContactState>(
    contactFeatureState,
    'postContact',
  ),
  updateContact: createApiCallSelectors<ContactUpdate, IContactState>(
    contactFeatureState,
    'updateContact',
  ),
  getContactFormModel: createApiCallSelectors<void, IContactState>(
    contactFeatureState,
    'getContactFormModel',
  ),
};
