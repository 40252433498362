import { Action, ActionReducer, createReducer } from "@ngrx/store";
import { onApiCall } from "src/app/common/store/generic.reducer";
import { GenericState, IState } from "src/app/common/store/types";
import { currencyActions } from "./currency.actions";
import { Currency } from "./types";

export interface ICurrencyState extends IState<Currency> {
  getById: GenericState<Currency>;
  getList: GenericState<Currency[]>;
  postCurrency: GenericState<any>;
  updateCurrency: GenericState<any>;
}

export const currencyInitialState: ICurrencyState = {
  getById: new GenericState<Currency>(),
  getList: new GenericState<Currency[]>(),
  postCurrency: new GenericState<any>(),
  updateCurrency: new GenericState<any>(),
};

const currencyReducers = [
  ...onApiCall<Currency>(currencyActions.getById, 'getById'),
  ...onApiCall<Currency[]>(currencyActions.getList, 'getList'),
  ...onApiCall<any>(currencyActions.postCurrency, 'postCurrency'),
  ...onApiCall<any>(currencyActions.updateCurrency, 'updateCurrency'),
];

export const currencyReducer = createReducer(
  currencyInitialState,
  ...currencyReducers
) as ActionReducer<ICurrencyState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
 return currencyReducer(state, action);
}