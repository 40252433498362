import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { notificationActions } from './notification.action';
import { Notification } from './types';

export interface INotificationState {
  notifications: Notification[];
}

export const notificationInitialState: INotificationState = {
  notifications: [],
};

const notificationReducers = [
  on(
    notificationActions.add,
    (state: INotificationState, { notification }) => ({
      ...state,
      notifications: [...state.notifications, notification],
    }),
  ),
  on(
    notificationActions.remove,
    (state: INotificationState, { notification }) => ({
      ...state,
      notifications: state.notifications.filter(
        (notif) => notif.timestamp != notification.timestamp,
      ),
    }),
  ),
  on(notificationActions.clear, (state: INotificationState) => ({
    ...state,
    notifications: [] as Notification[],
  })),
  on(
    notificationActions.clearByType,
    (state: INotificationState, { notificationType }) => ({
      ...state,
      notifications: state.notifications.filter(
        (notif) => notif.type != notificationType,
      ),
    }),
  ),
];

export const notificationReducer = createReducer(
  notificationInitialState,
  ...notificationReducers,
) as ActionReducer<INotificationState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return notificationReducer(state, action);
}
