import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { filter, Observable } from 'rxjs';
import { CarInsuranceLeadModel } from '../../../../../../shared/store/lead/types';
import { LeadService } from '../../../../../../shared/store/lead/lead.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-lead-form-car-insurance',
  templateUrl: './lead-form-car-insurance.component.html',
  styleUrls: ['./lead-form-car-insurance.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class LeadFormCarInsuranceComponent implements OnInit {
  carDetail$: Observable<CarInsuranceLeadModel>;
  leadForm: FormGroup<any>;
  constructor(
    private parent: FormGroupDirective,
    private readonly leadService: LeadService,
    private formBuilder: FormBuilder,
  ) {
    this.carDetail$ = this.leadService.getCarDetail.value$;
  }

  ngOnInit(): void {
    this.leadForm = this.parent.form;

    this.leadForm.addControl(
      'carInsuranceFormGroup',
      this.formBuilder.group({
        carInsuranceCurrentInsuranceCompany: [undefined],
        carInsuranceInsuranceCoverage: [undefined],
        carInsuranceDriverBodyCoverage: [false],
        carInsuranceReplacementVehicleCoverage: [false],
        carInsuranceDriverPropertyCoverage: [false],
        carInsuranceBrandNewCoverage: [false],
        carInsuranceMoneyLossCoverage: [false],
      }),
    );

    this.carDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.leadForm.patchValue({
          carInsuranceFormGroup: {
            carInsuranceCurrentInsuranceCompany: x.currentInsuranceCompany,
            carInsuranceInsuranceCoverage: x.insuranceCoverage,
            carInsuranceDriverBodyCoverage: x.driverBodyCoverage,
            carInsuranceReplacementVehicleCoverage:
              x.replacementVehicleCoverage,
            carInsuranceDriverPropertyCoverage: x.driverPropertyCoverage,
            carInsuranceBrandNewCoverage: x.brandNewCoverage,
            carInsuranceMoneyLossCoverage: x.moneyLossCoverage,
          },
        });
      });
  }
}
