import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PageResult, PaginationParameters } from '../../types';
import { ZipCode } from './types';
import { ErrorService } from '../../error';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ZipCodeApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService
  ) {}

  get = (): Observable<ZipCode[]> => {
    let url = this.formatUrl([]);
    return this.httpClient.get<ZipCode[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getById = (id: string): Observable<ZipCode> => {
    let parameters: string[];
    parameters = [id];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ZipCode>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getPaged = (
    paginationParam: PaginationParameters
  ): Observable<PageResult<ZipCode>> => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);

    let queryParams = new HttpParams();
    queryParams = queryParams.append('PageIndex', paginationParam.pageIndex);
    queryParams = queryParams.append('PageSize', paginationParam.pageSize);
    queryParams = queryParams.append(
      'Filter',
      paginationParam.filter.join(',')
    );

    return this.httpClient
      .get<PageResult<ZipCode>>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.zipcodes.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
