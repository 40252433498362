import { Component } from '@angular/core';

@Component({
  selector: 'app-contract-details-commission',
  templateUrl: './contract-details-commission.component.html',
  styleUrls: ['./contract-details-commission.component.scss']
})
export class ContractDetailsCommissionComponent {

}
