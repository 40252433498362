import { Component } from '@angular/core';

@Component({
  selector: 'app-comment-none',
  templateUrl: './comment-none.component.html',
  styleUrls: ['./comment-none.component.scss']
})
export class CommentNoneComponent {

}
