import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { ICoreState } from './core.reducer';
import { coreFeatureKey } from './types';
import {
  Field,
  SearchView,
  FavoriteSearchView,
  Operation,
} from '../search/types';

const coreFeatureState = createFeatureSelector<ICoreState>(coreFeatureKey);

export const coreSelectors = {
  getFieldsByEntity: createApiCallSelectors<Field[], ICoreState>(
    coreFeatureState,
    'getFieldsByEntity'
  ),
  getSearchViewByEntity: createApiCallSelectors<SearchView, ICoreState>(
    coreFeatureState,
    'getSearchViewByEntity'
  ),
  getFavoriteByEntity: createApiCallSelectors<FavoriteSearchView[], ICoreState>(
    coreFeatureState,
    'getFavoriteByEntity'
  ),
  getOperatorsByType: createApiCallSelectors<Operation[], ICoreState>(
    coreFeatureState,
    'getOperatorsByType'
  ),
  getStringOperators: createApiCallSelectors<Operation[], ICoreState>(
    coreFeatureState,
    'getStringOperators'
  ),
  getIntOperators: createApiCallSelectors<Operation[], ICoreState>(
    coreFeatureState,
    'getIntOperators'
  ),
};
