import { Pipe, PipeTransform } from '@angular/core';
import {
  SubHeaderButton,
  ButtonType,
} from 'src/app/shared/store/subheader/type';

@Pipe({
  name: 'buttonsubheaderpipe',
})
export class ButtonSubHeaderPipe implements PipeTransform {
  transform(value: SubHeaderButton, ...args: any[]): unknown {
    // console.log(value.type)
    return {
      'btn-outline-primary': value.type == ButtonType.Light,
      'btn-primary': value.type == ButtonType.Primary,
      'mr-2': value.type == ButtonType.Primary,
    };
  }
}

@Pipe({
  name: 'buttontypepipe',
})
export class ButtonTypePipe implements PipeTransform {
  transform(value: ButtonType, ...args: any[]): unknown {
    // console.log(value)
    return {
      'btn-outline-primary': value == ButtonType.Light,
      'btn-primary': value == ButtonType.Primary,
      'mr-2': value == ButtonType.Primary,
    };
  }
}
