<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <form [formGroup]="userForm" (ngSubmit)="onSubmit()" autocomplete="off" [hasUnsavedData]="userForm">
      <button type="submit" hidden="hidden"></button>
      <div class="card-body">
        <div class="container-fluid">

          <div class="row">
            <div class="col-lg-10">
              <div class="form-group">
                <label class="form-title-label" for="username" translate>USER_LBL_USERNAME</label>
                <input
                  class="form-control form-title"
                  id="username"
                  name="username"
                  [placeholder]="'USER_PLACEHOLDER_USERNAME' | translate"
                  type="text"
                  value=""
                  formControlName="username"
                  autocomplete="new-username"
                  checkFormFieldValidity
                />
              </div>
            </div>
          </div>

          <div class="row">

            <div class="col-6 col-lg-6">

              <div class="form-group row">
                <label class="col-3 col-form-label-sm" for="email" translate>USER_LBL_EMAIL</label>
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    id="email"
                    name="email"
                    type="text"
                    value=""
                    formControlName="email"
                    checkFormFieldValidity
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-3 col-form-label-sm" for="password" translate>USER_LBL_PASSWORD</label>
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    name="password"
                    id="password"
                    type="password"
                    value=""
                    formControlName="password"
                    autocomplete="new-password"
                    checkFormFieldValidity
                  />
                </div>
              </div>


            </div>

          </div>
        </div>
      </div>
    </form>
  </div>
</div>
