<div
  class="tab-pane fade flex-even active show"
  id="employees"
  role="tabpanel"
  aria-labelledby="employees-tab"
  *ngIf="this.employeeDetail$ | async as employeeDetail"
>
  <div class="row mt-2">
    <div class="col-12 col-md">

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate>EMPLOYEE_LBL_COMPANY_VEHICLE</label>
        <div class="col">
          <span
            class="form-control-plaintext form-control-sm">
              {{ employeeDetail.vehicle }}
          </span>
        </div>
      </div>

      <div class="form-group row">
        <label class="col-4 col-form-label-sm" translate>EMPLOYEE_LBL_HOME_DISTANCE</label>
        <div class="col">
          <span
            class="form-control-plaintext form-control-sm">
            {{ employeeDetail.homeWorkDistance }}
          </span>
        </div>
      </div>
    </div>

    <div class="col">

    </div>
  </div>
</div>
