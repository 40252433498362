import { createApiCallActions } from '../../../common/store/generic.actions';
import { Contact, CreateContactFormModel } from './types';

export const contactActions = {
  getById: createApiCallActions<Contact>('contact', 'getById'),
  postContact: createApiCallActions<Contact>('contact', 'postContact'),
  updateContact: createApiCallActions<Contact>('contact', 'updateContact'),
  getContactFormModel: createApiCallActions<CreateContactFormModel>(
    'contact',
    'getContactFormModel',
  ),
};

export type ContactAction = typeof contactActions;
