import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from '../types';
import { Employee } from './types';
import { employeeActions } from './employee.action';
import { employeeSelectors } from './employee.selector';

@Injectable()
export class EmployeeService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<Employee, string> = this.genericApiCall(
    employeeActions.getById,
    employeeSelectors.getById,
    true
  );
}
