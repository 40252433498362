import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { PageResult } from '../../types';
import { ZipCode, zipCodeFeatureKey } from './types';
import { IZipCodeState } from './zipcode.reducer';

const zipCodeFeatureState =
  createFeatureSelector<IZipCodeState>(zipCodeFeatureKey);

export const zipCodeSelectors = {
  get: createApiCallSelectors<ZipCode[], IZipCodeState>(
    zipCodeFeatureState,
    'get'
  ),
  getById: createApiCallSelectors<ZipCode, IZipCodeState>(
    zipCodeFeatureState,
    'getById'
  ),
  getPaged: createApiCallSelectors<PageResult<ZipCode>, IZipCodeState>(
    zipCodeFeatureState,
    'getPaged'
  ),
};
