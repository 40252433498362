import {Injectable} from "@angular/core";
import {CommentApiService} from "./comment.api.service";
import {CommentAction, commentActions} from "./comment.action";
import {Actions} from "@ngrx/effects";
import {createApiCallEffects} from "../../../common/store/generic.effects";

@Injectable()
export class CommentEffects {
    constructor(
        private readonly commentApiService: CommentApiService,
        private readonly action$: Actions<CommentAction>
    ) { }

    getByEntityId = createApiCallEffects(
        this,
        this.action$,
        commentActions.getByEntityId,
        this.commentApiService.getByEntityId
    );

    postComment = createApiCallEffects(
        this,
        this.action$,
        commentActions.postComment,
        this.commentApiService.postComment
    );
}
