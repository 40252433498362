import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { CurrencyService } from 'src/app/shared/store/currency/currency.service';
import { Currency, SymbolPosition } from 'src/app/shared/store/currency/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-currency-edit',
  templateUrl: './currency-edit.component.html',
  styleUrls: ['./currency-edit.component.scss'],
})
export class CurrencyEditComponent implements OnInit, OnDestroy {
  currency$: Observable<Currency>;
  currencyForm: FormGroup;
  symbolPositionsArray: any[];
  view$: Observable<View>;

  constructor(
    private readonly currencyService: CurrencyService,
    private readonly subHeaderService: SubHeaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
  ) {
    this.currency$ = this.currencyService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'currencyUpdate') this.onSubmit();
        if (x == 'currencyDetails') {
          this.router.navigate([
            'Configuration/Currencies/Details/',
            this.route.snapshot.params['id'],
          ]);
          this.subHeaderService.setTriggerAction('');
        }
      });

    this.currencyService.getById.call(this.route.snapshot.params['id']);

    this.currency$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@currencyEdit', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@currencyList', x.title);
      });

    this.currencyForm = new FormGroup({
      isoCode: new FormControl('', [
        Validators.required,
        Validators.maxLength(5),
      ]),
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(80),
      ]),
      accuracy: new FormControl('', [Validators.required]),
      currentRate: new FormControl(''),
      rounding: new FormControl('', [Validators.required]),
      symbol: new FormControl('', [Validators.maxLength(5)]),
      symbolPosition: new FormControl('', [Validators.required]),
    });

    this.currency$.pipe().subscribe((x) => {
      this.currencyForm.patchValue({
        isoCode: x.isoCode,
        name: x.name,
        accuracy: x.accuracy,
        currentRate: x.currentRate,
        rounding: x.rounding,
        symbol: x.symbol,
        symbolPosition: SymbolPosition[x.symbolPosition],
      });
    });

    this.symbolPositionsArray = [
      { label: 'Après le montant', value: SymbolPosition.AfterAmount },
      { label: 'Avant le montant', value: SymbolPosition.BeforeAmount },
    ];
  }

  onSubmit(): void {
    if (this.currencyForm.invalid) {
      this.currencyForm.markAllAsTouched();
      this.formService.countErrors(this.currencyForm, true);
      return;
    }

    this.formService.clear();

    let isCurrentRateWrong: boolean =
      this.currencyForm.value.currentRate! === '' ||
      this.currencyForm.value.currentRate! === null;

    let result$ = this.currencyService.updateCurrency.call({
      id: +this.route.snapshot.params['id'],
      isoCode: this.currencyForm.value.isoCode!,
      name: this.currencyForm.value.name!,
      accuracy: +this.currencyForm.value.accuracy!,
      currentRate: isCurrentRateWrong
        ? null
        : +this.currencyForm.value.currentRate!,
      rounding: +this.currencyForm.value.rounding!,
      symbol: this.currencyForm.value.symbol!,
      symbolPosition: this.currencyForm.value.symbolPosition!,
    });

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.router.navigate(['/Configuration/Currencies/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.currencyService.updateCurrency.reset();
    this.breadcrumbService.set('@currencyDetails', ' ');
  }
}
