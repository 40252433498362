import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CoreApiService } from './core.api.services';
import { CoreEffects } from './core.effects';
import { ICoreState, coreReducer, coreInitialState } from './core.reducer';
import { CoreService } from './core.service';
import { coreFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<ICoreState>(coreFeatureKey, coreReducer, {
      initialState: coreInitialState,
    }),
    EffectsModule.forFeature([CoreEffects]),
  ],
  providers: [CoreApiService, CoreService],
})
export class StoreCoreModule {}
