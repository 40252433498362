import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { RouterService } from '../router/router.service';
import { AppState } from '../types';
import { subHeaderActions } from './subheader.actions';
import { subHeaderSelectors } from './subheader.selectors';
import { SubHeaderOption, SubHeaderType } from './type';

@Injectable()
export class SubHeaderService extends BaseServiceFacade {
  constructor(
    store: Store<AppState>,
    private readonly routerService: RouterService
  ) {
    super(store);
  }

  getSubHeaderOption: GenericApiCall<SubHeaderOption, SubHeaderType> =
    this.genericApiCall(
      subHeaderActions.getSubHeaderOption,
      subHeaderSelectors.getSubHeaderOption,
      true
    );

  setTriggerAction = (actionName: string) => {
    this.store.dispatch(
      subHeaderActions.triggerAction({ actionName: actionName })
    );
  };

  getTriggerAction = () =>
    this.store.select(subHeaderSelectors.getTriggerAction);
}
