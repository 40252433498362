import { Component } from '@angular/core';

@Component({
  selector: 'app-currency-create',
  templateUrl: './currency-create.component.html',
  styleUrls: ['./currency-create.component.scss'],
})
export class CurrencyCreateComponent {

}
