import { Injectable, Type } from '@angular/core';
import { Subject } from 'rxjs';
import {
  Component,
  ComponentDef,
  ComponentType,
} from '../store/typeahead/types';
import { JobCreateComponent } from '../../features/human-resources/job/job-create/job-create.component';
import { JobDetailsComponent } from '../../features/human-resources/job/job-details/job-details.component';
import { CurrencyCreateContentComponent } from '../../features/configuration/currency/currency-create/currency-create-content/currency-create-content.component';
import { Entity } from '../store/view/types';
import { CurrencyDetailsContentComponent } from '../../features/configuration/currency/currency-details/currency-details-content/currency-details-content.component';
import { ContactCreateContentComponent } from '../../features/contacts/contact/contact-create/contact-create-content/contact-create-content.component';
import { ContactDetailsContentComponent } from '../../features/contacts/contact/contact-details/contact-details-content/contact-details-content.component';

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private output = new Subject<string>();
  private components: ComponentType[] = [
    {
      modelType: Entity.Job,
      create: JobCreateComponent,
      details: JobDetailsComponent,
    },
    {
      modelType: Entity.Currency,
      create: CurrencyCreateContentComponent,
      details: CurrencyDetailsContentComponent,
    },
  ];
  private componentsRef: ComponentDef[] = [
    {
      name: 'currencycreatecontentcomponent',
      type: CurrencyCreateContentComponent,
    },
    {
      name: 'currencydetailscontentcomponent',
      type: CurrencyDetailsContentComponent,
    },
    {
      name: 'contactcreatecontentcomponent',
      type: ContactCreateContentComponent,
    },
    {
      name: 'contactdetailscontentcomponent',
      type: ContactDetailsContentComponent,
    },
  ];

  constructor() {}

  getObservable() {
    return this.output.asObservable();
  }

  outputFromDynamicComponent(data: string) {
    this.output.next(data);
  }

  getComponent(entity: Entity, type: 'create' | 'details') {
    let idx = this.components.findIndex(
      (component) => component.modelType === entity,
    );
    if (idx < 0) return undefined;
    return this.components[idx][type];
  }

  getComponentClass(name: string) {
    let idx = this.componentsRef.findIndex(
      (component) => component.name === name.toLowerCase(),
    );
    if (idx < 0) return undefined;
    return this.componentsRef[idx].type;
  }

  declareComponent(name: string, type: Type<Component>) {
    this.componentsRef.push({ name: name.toLowerCase(), type: type });
  }
}
