import { Component, Input, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { User } from '../../../store/user/type';

@Component({
  selector: 'app-badge-list',
  templateUrl: './badge-list.component.html',
  styleUrls: ['./badge-list.component.scss'],
})
export class BadgeListComponent implements ICellRendererAngularComp {
  @Input('InputRoles') inputRoles: string[];
  roles: string[];

  constructor() {}

  agInit(params: ICellRendererParams): void {
    if (params) {
      this.inputRoles = params.value;
    }
  }
  refresh(params: ICellRendererParams): boolean {
    return false;
  }

  ngOnInit() {
    if (this.inputRoles) {
      this.roles = this.inputRoles;
    }
  }
}
