<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  formGroupName="driverFormGroup"
>
  <div class="d-flex flex-column h-100">
    <div class="form-group-title my-1" translate>LEAD_FIELDSET_DRIVER</div>
    <div class="row mt-2">
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_LASTNAME</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverLastname"
              name="driverLastname"
              type="text"
              formControlName="driverLastname"
              checkFormFieldValidity
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_FIRSTNAME</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverFirstname"
              name="driverFirstname"
              type="text"
              formControlName="driverFirstname"
              checkFormFieldValidity
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_BIRTHDATE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverBirthdate"
              name="driverBirthdate"
              type="date"
              formControlName="driverBirthdate"
              checkFormFieldValidity
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_ADDRESS</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="address"
              name="address"
              type="text"
              formControlName="driverAddress"
              checkFormFieldValidity
            />
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm"> </label>
          <div class="col-2">
            <input
              class="form-control form-control-sm"
              id="zipCode"
              name="zipCode"
              type="text"
              mask="00000"
              maxlength="5"
              formControlName="driverZipCode"
              checkFormFieldValidity
              [placeholder]="'LEAD_PLACEHOLDER_DRIVER_ZIPCODE' | translate"
            />
          </div>
          <div class="col">
            <app-typeahead
              [entity]="Entity.ZipCode"
              [referenceEndpoint]="'ZipCodes/Cities/'"
              [dropdownStyle]="DropdownStyle.DropDown"
              [controlForm]="
                this.leadForm.controls['driverFormGroup'].get('driverCity')
              "
              [limitToList]="true"
              [referenceFilterParentName]="'Code'"
              [referenceFilterParentValue]="
                this.leadForm.controls['driverFormGroup'].get('driverZipCode')
                  ?.value
              "
              [valueMember]="'name'"
              [displayMember]="'name'"
              [dbNameOfId]="'City'"
              [dbNameOfName]="'City'"
              [referenceField]="'City'"
              [showFirstElement]="true"
            ></app-typeahead>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_LICENSE_DATE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverLicenseDate"
              name="driverLicenseDate"
              type="date"
              formControlName="driverLicenseDate"
              checkFormFieldValidity
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form-group-title my-1" translate>
      LEAD_FIELDSET_INSURANCE_HISTORY
    </div>
    <div class="row mt-2">
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_YEARS_INSURED</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverYearsInsured"
              name="driverYearsInsured"
              type="text"
              formControlName="driverYearsInsured"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_BONUS_MALUS</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverBonusMalus"
              name="driverBonusMalus"
              type="text"
              formControlName="driverBonusMalus"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_MONTH_INSURANCE_INTERRUPTION</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverMonthInsuranceInterruption"
              name="driverMonthInsuranceInterruption"
              type="text"
              formControlName="driverMonthInsuranceInterruption"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_INSURANCE_TERMINATION</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverInsuranceTermination"
              name="driverInsuranceTermination"
              type="text"
              formControlName="driverInsuranceTermination"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form-group-title my-1" translate>LEAD_FIELDSET_LICENSE_HISTORY</div>
    <div class="row mt-2">
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_LICENSE_ANNULATION</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                formControlName="driverLicenseAnnulation"
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_NO_LICENSE_CONVICTION</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverNoLicenseConviction"
              name="driverNoLicenseConviction"
              type="text"
              formControlName="driverNoLicenseConviction"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_LICENSE_CANCEL</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverLicenseCancel"
              name="driverLicenseCancel"
              type="text"
              formControlName="driverLicenseCancel"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_LICENSE_CANCEL_MOTIVE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverLicenseCancelMotive"
              name="driverLicenseCancelMotive"
              type="text"
              formControlName="driverLicenseCancelMotive"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_LICENSE_CANCEL_DATE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverLicenseCancelDate"
              name="driverLicenseCancelDate"
              type="date"
              formControlName="driverLicenseCancelDate"
            />
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_LICENSE_SUSPENSION</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverLicenseSuspension"
              name="driverLicenseSuspension"
              type="text"
              formControlName="driverLicenseSuspension"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_LICENSE_SUSPENSION_MOTIVE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverLicenseSuspensionMotive"
              name="driverLicenseSuspensionMotive"
              type="text"
              formControlName="driverLicenseSuspensionMotive"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_LICENSE_SUSPENSION_DATE</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverLicenseSuspensionDate"
              name="driverLicenseSuspensionDate"
              type="date"
              formControlName="driverLicenseSuspensionDate"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_LONGEST_SUSPENSION</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverLongestSuspension"
              name="driverLongestSuspension"
              type="text"
              formControlName="driverLongestSuspension"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_DRIVER_ALCOHOL_DRUG_CONTROL</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="driverAlcoholDrugControl"
              name="driverAlcoholDrugControl"
              type="text"
              formControlName="driverAlcoholDrugControl"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="form-group-title my-1" translate>LEAD_FIELDSET_DISASTERS</div>
    <div class="row my-2 disaster-row">
      <ng-container formArrayName="driverDisasters">
        <div
          class="col-md-6 col-12"
          *ngFor="let disasterForm of driverDisasters.controls; index as i"
          [formGroupName]="i"
        >
          <div class="card">
            <div class="card-header">
              <h5 class="form-group-title my-1" translate>
                LEAD_FIELDSET_DISASTER
              </h5>
              <button
                class="btn btn-outline-danger btn-sm"
                (click)="removeDisaster(i)"
                data-bs-toggle="tooltip"
              >
                <i class="mdi mdi-close"></i>
              </button>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <div class="col-12 col-xl-6">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >LEAD_LBL_DISASTER_DATE</label
                    >
                    <div class="col">
                      <input
                        class="form-control form-control-sm"
                        id="carDisasterDate"
                        name="carDisasterDate"
                        type="date"
                        formControlName="carDisasterDate"
                      />
                      <span
                        class="field-validation-valid text-danger"
                        id="effectiveDate_validationMessage"
                      >
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >LEAD_FIELDSET_REASON</label
                    >
                    <div class="col">
                      <input
                        class="form-control form-control-sm"
                        id="carDisasterReason"
                        name="carDisasterReason"
                        type="text"
                        formControlName="carDisasterReason"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-6">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >LEAD_FIELDSET_RESPONSIBLE</label
                    >
                    <div class="col">
                      <input
                        class="form-control form-control-sm"
                        id="carDisasterResponsiblePerson"
                        name="carDisasterResponsiblePerson"
                        type="text"
                        formControlName="carDisasterResponsiblePerson"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="col-md-6 col-12">
        <a
          class="btn-sm btn btn-outline-primary btn-add"
          (click)="addDisaster()"
          translate
        >LEAD_LBL_DRIVER_ADD_SINISTER</a
        >
      </div>
    </div>
  </div>
</div>
