<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <form [formGroup]="currencyForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="currencyForm">
      <button type="submit" hidden="hidden"></button>
      <div class="card-body">
        <div class="container-fluid">

          <div class="row">
            <div class="col-lg-10">
              <div class="form-group">
                <label class="form-title-label" for="Name" translate>CURRENCY_LBL_NAME</label>
                <input
                  class="form-control form-title"
                  id="name"
                  name="name"
                  [placeholder]="'CURRENCY_PLACEHOLDER_NAME' | translate"
                  type="text"
                  value=""
                  formControlName="name"
                  checkFormFieldValidity
                />
              </div>
            </div>
          </div>

          <div class="row">

            <div class="col-6 col-lg-6">

              <div class="form-group row">
                <label class="col-4 col-form-label-sm" for="Accuracy" translate>CURRENCY_LBL_ACCURACY</label>
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    id="accuracy"
                    name="accuracy"
                    type="number"
                    value=""
                    formControlName="accuracy"
                    checkFormFieldValidity
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-4 col-form-label-sm" for="SymbolPosition" translate>CURRENCY_LBL_SYMBOL_POSITION</label>
                <div class="col">
                  <select
                    class="form-control form-control-sm"
                    id="symbolPosition"
                    name="symbolPosition"
                    formControlName="symbolPosition"
                    checkFormFieldValidity
                  >
                    <!-- <option [value]=null></option> -->
                    <option *ngFor="let symbolPosition of symbolPositionsArray"
                            [value]="symbolPosition.value"
                    >{{ symbolPosition.label }}
                    </option>
                  </select>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-4 col-form-label-sm" for="CurrentRate" translate>CURRENCY_LBL_CURRENT_RATE</label>
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    id="currentRate"
                    name="currentRate"
                    type="number"
                    value=""
                    formControlName="currentRate"
                    checkFormFieldValidity
                  />
                </div>
              </div>

            </div>

            <div class="col">

              <div class="form-group row">
                <label class="col-4 col-form-label-sm" for="Symbol" translate>CURRENCY_LBL_SYMBOL</label>
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    id="symbol"
                    name="symbol"
                    type="text"
                    value=""
                    formControlName="symbol"
                    checkFormFieldValidity
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-4 col-form-label-sm" for="Rounding" translate>CURRENCY_LBL_ROUNDING</label>
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    id="rounding"
                    name="rounding"
                    type="number"
                    value=""
                    formControlName="rounding"
                    checkFormFieldValidity
                  />
                </div>
              </div>

              <div class="form-group row">
                <label class="col-4 col-form-label-sm" for="ISOCode" translate>CURRENCY_LBL_ISOCODE</label>
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    id="isoCode"
                    name="isoCode"
                    type="text"
                    value=""
                    formControlName="isoCode"
                    checkFormFieldValidity
                  />
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
