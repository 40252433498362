import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { TabsContentContact } from '../../../../../shared/models/tabs-content-contact';
import { ContactDetailsNotesComponent } from '../contact-details-tab/contact-details-notes/contact-details-notes.component';

@Component({
  selector: 'app-contact-tabs-details',
  templateUrl: './contact-tabs-details.component.html',
  styleUrls: ['./contact-tabs-details.component.scss'],
})
export class ContactTabsDetailsComponent implements OnInit, OnChanges {
  active = 1;

  tabsContent$: Observable<TabsContentContact[]> = of([]);
  tabsInit: TabsContentContact[] = [
    {
      title: 'CONTACT_TABS_NOTES',
      index: 1,
      name: 'notes',
      component: ContactDetailsNotesComponent,
    },
  ];

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.tabsContent$ = of(this.tabsInit);
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tabsContent$ = of(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentContact) {
    return item.index;
  }
}
