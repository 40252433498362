import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { DataType, DropdownStyle } from 'src/app/shared/store/typeahead/types';
import { Entity, View } from 'src/app/shared/store/view/types';
import { ViewService } from 'src/app/shared/store/view/views.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ContractService } from '../../../../shared/store/contract/contract.service';
import {
  BankAccountAddModel,
  CarInsuranceContractDisasterDetailFormModel,
  CarInsuranceContractFormModel,
  ContractCarFormModel,
  ContractFormModel,
  ContractHealthFormModel,
  ContractHouseFormModel,
  ContractStatus,
  CreateContractFormModel,
  HealthInsuranceContractDetailFormModel,
  HouseInsuranceContractFormModel,
} from '../../../../shared/store/contract/types';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { formatDate } from '../../../../shared/helper/datehelper';
import {
  InsuranceCategory,
  InsuredType,
} from '../../../../shared/store/lead/types';
import { take } from 'rxjs/operators';
import { ContactService } from 'src/app/shared/store/contact/contact.service';
import { Contact } from 'src/app/shared/store/contact/types';
import { FormService } from '../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-contract-create',
  templateUrl: './contract-create.component.html',
  styleUrls: ['./contract-create.component.scss'],
})
export class ContractCreateComponent implements OnInit {
  contractForm: FormGroup;
  view$: Observable<View>;
  createContractFormModel$: Observable<CreateContractFormModel>;
  category: string;

  protected readonly Entity = Entity;
  protected readonly DropdownStyle = DropdownStyle;
  protected readonly DataType = DataType;

  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
    private readonly contractService: ContractService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly contactService: ContactService,
  ) {
    this.createContractFormModel$ =
      this.contractService.getContractModel.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit() {
    this.category = this.route.snapshot.params['category'];

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@contractList', x.title);
        this.breadcrumbService.set('@contractCreate', x.breadcrumbCreate);
      });

    this.contractService.getContractModel.call(
      InsuranceCategory[this.category],
    );
    this.createContractFormModel$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.contractForm?.patchValue({
          name: x.contractFormModel.name,
          category: this.category,
          categoryLoc: x.contractFormModel.categoryLoc,
          categoryColor: x.contractFormModel.categoryColor,
          salesTeam: x.contractFormModel.salesTeamId,
          salesTeamMember: x.contractFormModel.salesPersonId,
          effectiveDate: formatDate(
            x.contractFormModel.startDate,
            'ToInverseSqlDate',
          ),
          subscriptionDate: formatDate(
            x.contractFormModel.startDate,
            'ToInverseSqlDate',
          ),
          contractManagerId: x.contractFormModel.contractManagerId,
        });
      });

    this.contractForm = this.formBuilder.group({
      clientId: [undefined, [Validators.required]],
      salesTeam: [undefined],
      salesTeamMember: [undefined],
      category: [''],
      categoryLoc: [''],
      categoryColor: [''],
      companyKey: [undefined, [Validators.required]],
      productLineKey: [undefined],
      productKey: [undefined, [Validators.required]],
      effectiveDate: ['', [Validators.required]],
      subscriptionDate: ['', [Validators.required]],
      contractManagerId: [undefined],
    });

    this.contractForm.controls['clientId']?.valueChanges.subscribe((x) => {
      let contact$: Observable<Contact>;
      contact$ = this.contactService.getById.value$;
      this.contactService.getById.call(x);

      contact$
        .pipe(
          filter((x) => !!x),
          untilDestroyed(this),
        )
        .subscribe((x: Contact) => {
          this.contractForm.controls['healthFormGroup']?.patchValue({
            subscriberFirstName: x.firstName,
            subscriberLastName: x.lastName,
          });
          this.contractForm.controls['paymentSplittingFormGroup']?.patchValue({
            paymentHolderName: x.name,
          });
        });
    });

    this.subHeaderService
      .getTriggerAction()
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        if (x == 'contractCreatePush') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });
  }

  onSubmit(): void {
    console.log(this.contractForm);

    if (this.contractForm.invalid) {
      this.contractForm.markAllAsTouched();
      this.formService.countErrors(this.contractForm, true);

      return;
    }

    let contractFormModel: ContractFormModel = {
      id: undefined,
      categoryLoc: null,
      category: InsuranceCategory.Health,
      salesTeamId: this.contractForm.value.salesTeam,
      salesPersonId: this.contractForm.value.salesTeamMember,
      campaignId:
        this.contractForm.value.marketingFormGroup.marketingCampaignId,
      medium: this.contractForm.value.marketingFormGroup.marketingMedium,
      source: this.contractForm.value.marketingFormGroup.marketingSource,
      monthlyFee:
        this.contractForm.value.generalInformationFormGroup.monthlyFee,
      creationDate: new Date(),
      companyKey: this.contractForm.value.companyKey,
      productLineKey: this.contractForm.value.productLineKey,
      productKey: this.contractForm.value.productKey,
      statusId: ContractStatus.Draft,
      productLineId: -1,
      productId: -1,
      paymentSplittingId:
        this.contractForm.value.paymentSplittingFormGroup.paymentSplittingId,
      startDate: this.contractForm.value.effectiveDate,
      endDate: this.contractForm.value.effectiveDate,
      name: this.contractForm.value.name,
      subscriptionDate: this.contractForm.value.subscriptionDate,
      documentsReceivedDate:
        this.contractForm.value.generalInformationFormGroup
          .documentsReceivedDate,
      regularizationDate:
        this.contractForm.value.generalInformationFormGroup.regularizationDate,
      documentsSentDate:
        this.contractForm.value.generalInformationFormGroup.documentsSentDate,
      // expiryMonth:
      //   this.contractForm.value.generalInformationFormGroup.expiryMonth,
      // expiryDay: this.contractForm.value.generalInformationFormGroup.expiryDay,
      expiryMonth: 1,
      expiryDay: 1,
      terminationDate:
        this.contractForm.value.generalInformationFormGroup.terminationDate,
      terminationReasonId: 1,
      // terminationReasonId:
      //   this.contractForm.value.generalInformationFormGroup.terminationId,
      applicationFees:
        this.contractForm.value.generalInformationFormGroup.applicationFees,
      monthlyCost:
        this.contractForm.value.generalInformationFormGroup.monthlyCost,
      commissionRate:
        this.contractForm.value.generalInformationFormGroup.commissionRate,
      taxRate: this.contractForm.value.generalInformationFormGroup.taxRate,
      validationDate:
        this.contractForm.value.generalInformationFormGroup.validationDate,
      approverId:
        this.contractForm.value.generalInformationFormGroup.approverId,
      contractManagerId: this.contractForm.value.contractManagerId,
      contactId: this.contractForm.value.clientId,
    };

    let paymentBankAccount: BankAccountAddModel = {
      holderName:
        this.contractForm.value.paymentSplittingFormGroup.paymentHolderName,
      iban: this.contractForm.value.paymentSplittingFormGroup.paymentIBAN,
      bic: this.contractForm.value.paymentSplittingFormGroup.paymentBIC,
      active: true,
    };
    let refundingBankAccount: BankAccountAddModel;
    if (this.contractForm.value.paymentSplittingFormGroup.sameBankAccount) {
      refundingBankAccount = {
        holderName:
          this.contractForm.value.paymentSplittingFormGroup.paymentHolderName,
        iban: this.contractForm.value.paymentSplittingFormGroup.paymentIBAN,
        bic: this.contractForm.value.paymentSplittingFormGroup.paymentBIC,
        active: true,
      };
    } else {
      refundingBankAccount = {
        holderName:
          this.contractForm.value.paymentSplittingFormGroup.refundingHolderName,
        iban: this.contractForm.value.paymentSplittingFormGroup.refundingIBAN,
        bic: this.contractForm.value.paymentSplittingFormGroup.refundingBIC,
        active: true,
      };
    }
    switch (this.category) {
      case 'Health': {
        contractFormModel.category = InsuranceCategory.Health;
        const subscriber: HealthInsuranceContractDetailFormModel = {
          socialSecurityKey:
            this.contractForm.value.healthFormGroup.subscriberRegime,
          birthDate:
            this.contractForm.value.healthFormGroup.subscriberBirthDate,
          insuredType: InsuredType.Holder,
          firstName:
            this.contractForm.value.healthFormGroup.subscriberFirstName,
          lastName: this.contractForm.value.healthFormGroup.subscriberLastName,
          birthName: '',
          parentId: undefined,
          socialSecurityNumber:
            this.contractForm.value.healthFormGroup
              .subscriberSocialSecurityNumber,
          codeCpam: this.contractForm.value.healthFormGroup.subcriberCodeCpam,
          gender: this.contractForm.value.healthFormGroup.subscriberGender,
          // maritalStatus: this.contractForm.value.maritalStatus,
          maritalStatus: 1,
        };

        let partners: HealthInsuranceContractDetailFormModel[] = [];

        this.contractForm.value.healthFormGroup.partners.forEach((x) => {
          const partner: HealthInsuranceContractDetailFormModel = {
            socialSecurityKey: x.partnerRegime,
            birthDate: x.partnerBirthDate,
            insuredType: InsuredType.Partner,
            firstName: x.partnerFirstName,
            lastName: x.partnerLastName,
            maritalStatus: this.contractForm.value.maritalStatus,
            birthName: '',
            gender: x.partnerGender,
            socialSecurityNumber: x.partnerSocialSecurityNumber,
            codeCpam: x.partnerCodeCpam,
            parentId: null,
          };
          partners.push(partner);
        });

        let children: HealthInsuranceContractDetailFormModel[] = [];

        this.contractForm.value.healthFormGroup.children.forEach((x) => {
          const child: HealthInsuranceContractDetailFormModel = {
            socialSecurityKey: x.childRegime,
            birthDate: x.childBirthDate,
            insuredType: InsuredType.Child,
            firstName: x.childFirstName,
            lastName: x.childLastName,
            birthName: '',
            gender: x.childGender,
            maritalStatus: 0,
            socialSecurityNumber: x.childAffiliatedToSubscriber
              ? this.contractForm.value.healthFormGroup
                  .subscriberSocialSecurityNumber
              : x.childSocialSecurityNumber,
            codeCpam: x.childAffiliatedToSubscriber
              ? this.contractForm.value.healthFormGroup.subcriberCodeCpam
              : x.childCodeCpam,
            parentId: undefined,
          };
          children.push(child);
        });

        let healthInsuranceContractDetailFormModel: HealthInsuranceContractDetailFormModel[] =
          [subscriber, ...partners, ...children];

        let contractHealthFormModel: ContractHealthFormModel = {
          contractFormModel: contractFormModel,
          healthInsuranceContractDetailFormModels:
            healthInsuranceContractDetailFormModel,
          paymentBankAccount: paymentBankAccount,
          refundingBankAccount: refundingBankAccount,
        };
        console.log(contractHealthFormModel);

        let result$ = this.contractService.createHealthContract.call(
          contractHealthFormModel,
        );
        result$
          .pipe(
            filter((x) => !!x),
            take(1),
          )
          .subscribe((x) => {
            this.formService.clear();

            this.router.navigate([
              'Sales/ContractRequests/Details',
              x.uniqueId.toString(),
            ]);
          });
        break;
      }
      case 'Car': {
        contractFormModel.category = InsuranceCategory.Car;

        let carInsuranceContractFormModel: CarInsuranceContractFormModel = {
          driverFirstName:
            this.contractForm.value.driverFormGroup.driverFirstname,
          driverLastName:
            this.contractForm.value.driverFormGroup.driverLastname,
          driverBirthdate:
            this.contractForm.value.driverFormGroup.driverBirthdate,
          driverZipCode: this.contractForm.value.driverFormGroup.driverZipCode,
          driverCity: this.contractForm.value.driverFormGroup.driverCity,
          driverAddress: this.contractForm.value.driverFormGroup.driverAddress,
          licenseDate:
            this.contractForm.value.driverFormGroup.driverLicenseDate,
          isDriverVehiculeRegistrationHolder:
            this.contractForm.value.driverFormGroup
              .driverVehicleRegistrationHolder,
          vehiculeRegistrationHolder:
            this.contractForm.value.vehicleFormGroup.vehicleRegistrationHolder,
          vehiculeRegistrationHolderNameLastName:
            this.contractForm.value.vehicleFormGroup
              .vehicleRegistrationHolderCompanyName,
          vehiculeRegistrationHolderNameFirstName:
            this.contractForm.value.vehicleFormGroup
              .vehicleRegistrationHolderNameLastName,
          vehiculeRegistrationHolderCompanyName:
            this.contractForm.value.vehicleFormGroup
              .vehicleRegistrationHolderNameFirstName,
          vehiculeRegistrationHolderBirthdate:
            this.contractForm.value.vehicleFormGroup
              .vehicleRegistrationHolderBirthdate,
          yearsInsured:
            this.contractForm.value.driverFormGroup.driverYearsInsured,
          bonusMalus: this.contractForm.value.driverFormGroup.driverBonusMalus,
          monthInsuranceInterruption:
            this.contractForm.value.driverFormGroup
              .driverMonthInsuranceInterruption,
          insuranceTermination:
            this.contractForm.value.driverFormGroup.driverInsuranceTermination,
          licenseAnnulation:
            this.contractForm.value.driverFormGroup.driverLicenseAnnulation,
          noLicenseConviction:
            this.contractForm.value.driverFormGroup.driverNoLicenseConviction,
          licenseCancel:
            this.contractForm.value.driverFormGroup.driverLicenseCancel,
          licenseCancelMotive:
            this.contractForm.value.driverFormGroup.driverLicenseCancelMotive,
          licenseCancelDate:
            this.contractForm.value.driverFormGroup.driverLicenseCancelDate,
          licenseSuspension:
            this.contractForm.value.driverFormGroup.driverLicenseSuspension,
          licenseSuspensionMotive:
            this.contractForm.value.driverFormGroup
              .driverLicenseSuspensionMotive,
          licenseSuspensionDate:
            this.contractForm.value.driverFormGroup.driverLicenseSuspensionDate,
          longestSuspension:
            this.contractForm.value.driverFormGroup.driverLongestSuspension,
          alcoholDrugControl:
            this.contractForm.value.driverFormGroup.driverAlcoholDrugControl,
          nbCarDisaster: '',
          licensePlateNumber:
            this.contractForm.value.vehicleFormGroup.vehicleLicensePlateNumber,
          brand: this.contractForm.value.vehicleFormGroup.vehicleBrand,
          model: this.contractForm.value.vehicleFormGroup.vehicleModel,
          exactModel:
            this.contractForm.value.vehicleFormGroup.vehicleExactModel,
          carBody: this.contractForm.value.vehicleFormGroup.vehicleCarBody,
          carEnergie: this.contractForm.value.vehicleFormGroup.vehicleCarEnergy,
          carPower: this.contractForm.value.vehicleFormGroup.vehicleCarPower,
          vehiculeUsage: this.contractForm.value.vehicleFormGroup.vehicleUsage,
          vehiculeUsageProSupp:
            this.contractForm.value.vehicleFormGroup.vehicleUsageProSupp,
          parkingType:
            this.contractForm.value.vehicleFormGroup.vehicleParkingType,
          dateOfCirculation:
            this.contractForm.value.vehicleFormGroup.vehicleDateOfCirculation,
          contractStartDate: this.contractForm.value.effectiveDate,
          insuranceCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceInsuranceCoverage,
          currentInsuranceCompany:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceCurrentInsuranceCompany,
          driverBodyCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceDriverBodyCoverage,
          replacementVehicleCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceReplacementVehicleCoverage,
          driverPropertyCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceDriverPropertyCoverage,
          brandNewCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceBrandNewCoverage,
          moneyLossCoverage:
            this.contractForm.value.carInsuranceFormGroup
              .carInsuranceMoneyLossCoverage,
        };

        let disasters: CarInsuranceContractDisasterDetailFormModel[] = [];

        this.contractForm.value.driverFormGroup.driverDisasters.forEach((x) => {
          const disaster: CarInsuranceContractDisasterDetailFormModel = {
            carDisasterDate: x.carDisasterDate,
            carDisasterReason: x.carDisasterReason,
            carDisasterResponsiblePerson: x.carDisasterResponsiblePerson,
          };
          disasters.push(disaster);
        });

        const contractCarFormModel: ContractCarFormModel = {
          contractFormModel: contractFormModel,
          paymentBankAccount: paymentBankAccount,
          refundingBankAccount: refundingBankAccount,
          carInsuranceContractFormModel: carInsuranceContractFormModel,
          carInsuranceContractDisasterDetailFormModels: disasters,
        };

        let result$ =
          this.contractService.createCarContract.call(contractCarFormModel);
        result$
          .pipe(
            filter((x) => !!x),
            take(1),
          )
          .subscribe((x) => {
            this.formService.clear();

            this.router.navigate([
              'Sales/ContractRequests/Details',
              x.uniqueId.toString(),
            ]);
          });

        break;
      }
      case 'House': {
        contractFormModel.category = InsuranceCategory.House;
        const houseInsuranceContractModel: HouseInsuranceContractFormModel = {
          address: this.contractForm.value.houseFormGroup.houseAddress,
          zipCode: this.contractForm.value.houseFormGroup.houseZipCode,
          city: this.contractForm.value.houseFormGroup.houseCity,
          maritalStatus: this.contractForm.value.houseFormGroup.houseAddress,
          profession: this.contractForm.value.houseFormGroup.houseAddress,
          housingType: this.contractForm.value.houseFormGroup.houseHousingType,
          occupancyRole:
            this.contractForm.value.houseFormGroup.houseOccupancyRole,
          nbOfRooms: this.contractForm.value.houseFormGroup.houseNbOfRooms,
          area: this.contractForm.value.houseFormGroup.houseArea,
          constructionType:
            this.contractForm.value.houseFormGroup.houseConstructionType,
          roofType: this.contractForm.value.houseFormGroup.houseRoofType,
          amountToInsure:
            this.contractForm.value.houseFormGroup.houseAmountToInsure,
          flatLevel: this.contractForm.value.houseFormGroup.houseFlatLevel,
          residencyType:
            this.contractForm.value.houseFormGroup.houseResidencyType,
          fireCoverage:
            this.contractForm.value.houseFormGroup.houseFireCoverage,
          waterCoverage:
            this.contractForm.value.houseFormGroup.houseWaterCoverage,
          theftCoverage:
            this.contractForm.value.houseFormGroup.houseTheftCoverage,
          glassBreakCoverage:
            this.contractForm.value.houseFormGroup.houseGlassBreakCoverage,
          publicLiabilityCoverage:
            this.contractForm.value.houseFormGroup.housePublicLiabilityCoverage,
          feeCancellationCoverage:
            this.contractForm.value.houseFormGroup.houseFeeCancellationCoverage,
          electricalDamageCoverage:
            this.contractForm.value.houseFormGroup
              .houseElectricalDamageCoverage,
          poolCoverage:
            this.contractForm.value.houseFormGroup.housePoolCoverage,
          dogCoverage: this.contractForm.value.houseFormGroup.houseDogCoverage,
          currentInsuranceCompany:
            this.contractForm.value.houseFormGroup.houseCurrentInsuranceCompany,
        };

        const contractHouseFormModel: ContractHouseFormModel = {
          contractFormModel: contractFormModel,
          paymentBankAccount: paymentBankAccount,
          refundingBankAccount: refundingBankAccount,
          houseInsuranceContractFormModel: houseInsuranceContractModel,
        };

        let result$ = this.contractService.createHouseContract.call(
          contractHouseFormModel,
        );
        result$
          .pipe(
            filter((x) => !!x),
            take(1),
          )
          .subscribe((x) => {
            this.formService.clear();

            this.router.navigate([
              'Sales/ContractRequests/Details',
              x.uniqueId.toString(),
            ]);
          });
        break;
      }
      default: {
        break;
      }
    }
  }
}
