<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  *ngIf="this.healthDetail$ | async as healthDetail"
>
  <div class="d-flex flex-column h-100">
    <div class="form-group-title my-1" translate>
      LEAD_FIELDSET_HEALTH_COVERAGE
    </div>
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HEALTH_MEDICAL_CARE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              <ngb-rating [rate]="healthDetail.routineCareCoverageLevel">
                <ng-template let-fill="fill" let-index="index">
                  <i
                    class="fs-4 mdi mdi-star{{
                      fill !== 100 ? '-outline' : ''
                    }}"
                    [class.filled]="fill === 100"
                  ></i>
                </ng-template>
              </ngb-rating>
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HEALTH_HOSPITALIZATION</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              <ngb-rating [rate]="healthDetail.hospitalizationCoverageLevel">
                <ng-template let-fill="fill" let-index="index">
                  <i
                    class="fs-4 mdi mdi-star{{
                      fill !== 100 ? '-outline' : ''
                    }}"
                    [class.filled]="fill === 100"
                  ></i>
                </ng-template>
              </ngb-rating>
            </span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HEALTH_DENTAL</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              <ngb-rating [rate]="healthDetail.dentalCoverageLevel">
                <ng-template let-fill="fill" let-index="index">
                  <i
                    class="fs-4 mdi mdi-star{{
                      fill !== 100 ? '-outline' : ''
                    }}"
                    [class.filled]="fill === 100"
                  ></i>
                </ng-template>
              </ngb-rating>
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_HEALTH_OPTICAL</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              <ngb-rating [rate]="healthDetail.opticalCoverageLevel">
                <ng-template let-fill="fill" let-index="index">
                  <i
                    class="fs-4 mdi mdi-star{{
                      fill !== 100 ? '-outline' : ''
                    }}"
                    [class.filled]="fill === 100"
                  ></i>
                </ng-template>
              </ngb-rating>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <div class="form-group-title my-1" translate>
        LEAD_FIELDSET_FAMILY_COMPOSITION
      </div>
      <div class="d-flex flex-column h-100">
        <table class="table table-striped border-bottom">
          <thead>
            <tr>
              <th>
                <a translate>LEAD_LBL_INSURED_PERSONS_TYPE</a>
              </th>
              <th>
                <a translate>LEAD_LBL_INSURED_PERSONS_FIRSTNAME</a>
              </th>
              <th>
                <a translate>LEAD_LBL_INSURED_PERSONS_LASTNAME</a>
              </th>
              <th>
                <a translate>LEAD_LBL_INSURED_PERSONS_REGIME</a>
              </th>
              <th>
                <a translate>LEAD_LBL_INSURED_PERSONS_BIRTHDATE</a>
              </th>
              <th>
                <a translate>LEAD_LBL_INSURED_PERSONS_AGE</a>
              </th>
            </tr>
          </thead>

          <tbody>
            <tr
              *ngFor="let person of healthDetail.insuredPersons"
              class="row-action"
            >
              <td>{{ person.insuredTypeLoc }}</td>
              <td>{{ person.firstName }}</td>
              <td>
                {{ person.lastName }}
              </td>
              <td>
                {{ person.socialSecurityKeyLoc }}
              </td>
              <td>
                {{ person.birthDate | date : "dd/MM/yyyy" }}
              </td>
              <td>
                {{ person.age }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
