<div class="topbar navbar navbar-expand-sm navbar-light">
  <a class="navbar-brand" routerLink="Sales/Home">
    <img
      class="img-fluid logo"
      src="assets/images/logo.png"
      alt="{{ 'LOGO_COMPANY_ALT' | translate }}"
    />
  </a>
  <div class="container-fluid ps-0">
    <app-menu-dropdown></app-menu-dropdown>
    <!-- <ul
    *ngIf="this.user$ |async as currentUser"
    id="navbar-tools"
    class="navbar-nav navbar-right"
  >
    <li class="nav-item" ngbDropdown>
      <a class="nav-link nav-link-user" ngbDropdownToggle>
        <img src="../../../assets/images/user.svg" class="userbox" />
        <span class="name">{{ currentUser.username }}</span>
      </a>
      <div
        ngbDropdownMenu
        aria-labelledby="navbarDropdown2"
        class="dropdown-menu"
      >
        <a ngbDropdownItem (click)="logout()"
          ><i class="mdi mdi-power mr-1"></i
          >{{ "HEADER_USER_LOG_OFF" | translate }}</a
        >
      </div>
    </li>
  </ul> -->
    <div ngbDropdown *ngIf="this.user$ |async as currentUser" id="navbar-tools" class="navbar-nav navbar-right">
      <a class="nav-link nav-link-user" ngbDropdownToggle>
        <ng-container *ngIf="this.currentAvatar$ | async as avatar">
          <img src="{{this.avatar.url}}?name={{this.avatar.contactName}}&bgColor={{this.avatar.bgColor}}&color=FFFFFF"
               class="userbox"/>
        </ng-container>
        <span class="name">{{ currentUser.username }}</span>
      </a>
      <div ngbDropdownMenu aria-labelledby="dropdownBasic2"
           class="dropdown-menu dropdown-menu-lg-end dropdown-menu-md-end">
        <a ngbDropdownItem (click)="changePassword()">
          <i class="mdi mdi-lock me-1"></i>
          {{ "HEADER_LBL_CHANGE_PASSWORD" | translate }}
        </a>
        <a ngbDropdownItem (click)="logout()">
          <i class="mdi mdi-power me-1"></i>
          {{ "HEADER_LBL_LOG_OFF" | translate }}
        </a>
      </div>
    </div>
  </div>
  <!-- <div class="container-fluid ps-0"> -->

  <!-- </div> -->
</div>
<app-toast-validation />

