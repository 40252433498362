import {createFeatureSelector} from "@ngrx/store";
import {ICommentState} from "./comment.reducer";
import {InputComment, commentFeatureKey} from "./types";
import {createApiCallSelectors} from "../../../common/store/generic.selectors";

const commentFeatureState =
    createFeatureSelector<ICommentState>(commentFeatureKey);

export const commentSelectors = {
    getByEntityId: createApiCallSelectors<InputComment, ICommentState>(
        commentFeatureState,
        'getByEntityId'
    ),
    postComment: createApiCallSelectors<any, ICommentState>(
        commentFeatureState,
        'postComment'
    ),
};
