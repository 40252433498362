import { createReducer, ActionReducer, Action } from "@ngrx/store";
import { onApiCall } from "src/app/common/store/generic.reducer";
import { IState, GenericState } from "src/app/common/store/types";
import { jobActions } from "./job.action";
import { Job } from "./types";

export interface IJobState extends IState<Job> {
    getById: GenericState<Job>;
    postJob: GenericState<any>;
    updateJob: GenericState<any>;
}

export const jobInitialState: IJobState = {
    getById: new GenericState<Job>(),
    postJob: new GenericState<any>(),
    updateJob: new GenericState<any>(),
};

const jobReducers = [
    ...onApiCall<Job>(jobActions.getById, 'getById'),
    ...onApiCall<any>(jobActions.postJob, 'postJob'),
    ...onApiCall<any>(jobActions.updateJob, 'updateJob'),
];

export const jobReducer = createReducer(
    jobInitialState,
    ...jobReducers
) as ActionReducer<IJobState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
 return jobReducer(state, action);
}