import { createAction, props } from '@ngrx/store';
import { Notification, NotificationType } from './types';

export const notificationActions = {
  add: createAction(
    '[Notification] Show',
    props<{ notification: Notification }>(),
  ),
  remove: createAction(
    '[Notification] Remove',
    props<{ notification: Notification }>(),
  ),
  clear: createAction('[Notification] Clear'),
  clearByType: createAction(
    '[Notification] Clear By Type',
    props<{ notificationType: NotificationType }>(),
  ),
};

export type NotificationAction = typeof notificationActions;
