<div class="d-flex align-items-center justify-content-center h-100">
  <div class="error-message text-center">
    <div class="i-circle text-info"><i class="mdi mdi-vlc"></i></div>
    <h2 class="text-info" translate>ERROR_WORKINPROGRESS_MESSAGE</h2>
    <div>
      <button class="btn btn-outline-dark" onClick="history.go(-1)">
        <i class="mdi mdi-arrow-left mr-1"></i>
        <span translate>ERROR_WORKINPROGRESS_RELOAD</span>
      </button>
    </div>
  </div>
</div>