import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { createApiCallEffects } from "src/app/common/store/generic.effects";
import { HeaderAction, headerActions } from "./header.actions";
import { HeaderApiService } from "./header.api.services";
import { IHeaderState } from "./header.reducer";

@Injectable()
export class HeaderEffects {
    constructor(
        private readonly store: Store<IHeaderState>,
        private readonly headerApiService: HeaderApiService,
        private readonly actions$: Actions<HeaderAction>
    ) { }

    getHeaderMenu = createApiCallEffects(
        this,
        this.actions$,
        headerActions.getHeaderMenu,
        this.headerApiService.getHeaderMenu
    );
}