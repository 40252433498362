import { Pipe, type PipeTransform } from '@angular/core';
import { PaginationConfig } from '../../types';

@Pipe({
  name: 'paginationLabel',
})
export class PaginationLabelPipe implements PipeTransform {
  transform(paginationConfig: PaginationConfig, ...args: unknown[]): unknown {
    let paginationLabel: string = '';
    if (
      paginationConfig.pageIndex * paginationConfig.pageSize <
      paginationConfig.totalCount
    ) {
      paginationLabel +=
        paginationConfig.pageIndex * paginationConfig.pageSize -
        paginationConfig.pageSize +
        1;
      paginationLabel += '-';
      paginationLabel += paginationConfig.pageIndex * paginationConfig.pageSize;
    } else {
      paginationLabel +=
        paginationConfig.pageIndex * paginationConfig.pageSize -
        paginationConfig.pageSize +
        1;
      paginationLabel += '-';
      paginationLabel += paginationConfig.totalCount;
    }
    paginationLabel += '/';
    paginationLabel += paginationConfig.totalCount;
    return paginationLabel;
  }
}
