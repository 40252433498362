import {NgModule} from "@angular/core";
import {StoreModule} from "@ngrx/store";
import {ITypeaheadState, typeaheadInitialState, typeaheadReducer} from "./typeahead.reducer";
import {typeaheadFeatureKey} from "./types";
import {EffectsModule} from "@ngrx/effects";
import {TypeaheadEffects} from "./typeahead.effects";
import {TypeaheadService} from "./typeahead.service";
import {TypeaheadApiService} from "./typeahead.api.service";

@NgModule({
  imports:[
    StoreModule.forFeature<ITypeaheadState>(typeaheadFeatureKey, typeaheadReducer, {
      initialState: typeaheadInitialState,
    }),
    EffectsModule.forFeature([TypeaheadEffects])
  ],
  providers:[TypeaheadService, TypeaheadApiService]
})
export class StoreTypeaheadModule{}
