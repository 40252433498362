import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { createApiCallEffects } from "src/app/common/store/generic.effects";
import { CurrencyAction, currencyActions } from "./currency.actions";
import { CurrencyApiService } from "./currency.api.service";
import { ICurrencyState } from "./currency.reducer";

@Injectable()
export class CurrencyEffects {
  constructor(
    private readonly store: Store<ICurrencyState>,
    private readonly currencyApiService: CurrencyApiService,
    private readonly action$: Actions<CurrencyAction>    
  ) {}
  
  getById = createApiCallEffects(
    this,
    this.action$,
    currencyActions.getById,
    this.currencyApiService.getById
  );

  getList = createApiCallEffects(
    this,
    this.action$,
    currencyActions.getList,
    this.currencyApiService.getList
  );

  postCurrency = createApiCallEffects(
    this,
    this.action$,
    currencyActions.postCurrency,
    this.currencyApiService.postCurrency
  );

  updateCurrency = createApiCallEffects(
    this,
    this.action$,
    currencyActions.updateCurrency,
    this.currencyApiService.updateCurrency
  );
}