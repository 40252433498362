import { createApiCallActions } from '../../../common/store/generic.actions';
import { SalesTeamMember } from '../sales-team-member/types';
import { CreateSalesTeamFormModel, SalesTeam, SalesTeamBase } from './types';

export const salesTeamActions = {
  getById: createApiCallActions<SalesTeam>('salesTeam', 'getById'),
  getList: createApiCallActions<SalesTeamBase[]>('salesTeam', 'getList'),
  getSalesTeamMemberList: createApiCallActions<SalesTeamMember[]>(
    'salesTeam',
    'getSalesTeamMemberList',
  ),
  getSalesTeamTypeAheadList: createApiCallActions<SalesTeamBase[]>(
    'salesTeam',
    'getSalesTeamTypeAheadList',
  ),
  getSalesTeamMemberTypeAheadList: createApiCallActions<SalesTeamMember[]>(
    'salesTeam',
    'getSalesTeamMemberTypeAheadList',
  ),
  getSalesTeamModel: createApiCallActions<CreateSalesTeamFormModel>(
    'salesTeam',
    'getSalesTeamModel',
  ),
  postSalesTeam: createApiCallActions<SalesTeam>('salesTeam', 'postSalesTeam'),
  updateSalesTeam: createApiCallActions<SalesTeam>(
    'salesTeam',
    'updateSalesTeam',
  ),
};

export type SalesTeamAction = typeof salesTeamActions;
