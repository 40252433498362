import { AbstractControl } from '@angular/forms';

export const formFeatureKey = 'form';

export type ErrorsNestedFormGroup = {
  root: ChildFormGroup;
  children: ChildFormGroup[];
};
export type ChildFormGroup = {
  count: number;
  countTotal: number;
  groupName: string;
  fieldName: string[];
};

export type AbstractControlExtended = {
  [p: string]: AbstractControl<any, any>;
};

export type FormState = {
  name: string;
  isDirty: boolean;
};
