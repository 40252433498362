<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.countryBase$ | async as country">
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form-title-label" translate
                      >COUNTRY_LBL_NAME</label
                    >
                    <div class="form-title">{{ country.name }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-lg-6">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >COUNTRY_LBL_ENGLISH_NAME</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ country.englishName }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >COUNTRY_LBL_CURRENCY</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ country.currencyName }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >COUNTRY_LBL_NATIONALITY</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ country.nationality }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >COUNTRY_LBL_ALPHA2CODE</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ country.alpha2Code }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >COUNTRY_LBL_ALPHA3CODE</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ country.alpha3Code }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >COUNTRY_LBL_ACTIVE</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        <input
                          type="checkbox"
                          name="active"
                          [(ngModel)]="country.active"
                          onclick="return false;"
                          disabled
                        />
                        <!--   onkeydown="e = e || window.event; if(e.keyCode !== 9) return false;"  -->
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.countryBase$ | async as country">
      <app-comment-panel
        [entityType]="'Country'"
        [entityId]="country.id.toString()"
      ></app-comment-panel>
    </div>
  </div>
</div>
