import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Contact,
  ContactPost,
  ContactUpdate,
  CreateContactFormModel,
} from './types';
import { ErrorService } from '../../error';
import { MessageService } from '../../service/message/message.service';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { CrudType } from '../../service/message/types';
import { CreateContractFormModel } from '../contract/types';

@Injectable({
  providedIn: 'root',
})
export class ContactApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  getById = (id: number): Observable<Contact> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<Contact>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  postContact = (contactPost: ContactPost): any => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<Contact>(url, contactPost, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  updateContact = (contactUpdate: ContactUpdate): any => {
    let parameters: string[];
    parameters = [contactUpdate.id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<Contact>(url, contactUpdate, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  getContactFormModel = (): Observable<CreateContactFormModel> => {
    let parameters: string[];
    parameters = ['ContactModel'];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CreateContactFormModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  formatUrl = (parameters: string[]): string => {
    let url = environment.comparanooapiurl;
    url += environment.features.contact.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
