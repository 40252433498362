import { Component, OnInit } from '@angular/core';
import { ContractService } from '../../store/contract/contract.service';
import { Observable } from 'rxjs';
import { Action, View } from '../../store/view/types';
import { ViewService } from '../../store/view/views.service';
import {SubHeaderService} from "../../store/subheader/subheader.service";
import {environment} from "../../../../environments/environment";
import {Glossary} from "../../store/glossary/types";
import {GlossaryService} from "../../store/glossary/glossary.service";

@Component({
  selector: 'app-custom-actions-flow-glossary',
  templateUrl: './custom-actions-flow-glossary.component.html',
  styleUrls: ['./custom-actions-flow-glossary.component.scss'],
})
export class CustomActionsFlowGlossaryComponent implements OnInit {
  view$: Observable<View>;
  customActions$: Observable<Action[]>;
  glossary$: Observable<Glossary>;
  comparanooUrl:string;


  constructor(
    private readonly viewService: ViewService,
    private readonly subHeaderService:SubHeaderService,
    private readonly glossaryService: GlossaryService,
  ) {}

  ngOnInit(): void {
    this.view$ = this.viewService.getViews.value$;
    this.glossary$ = this.glossaryService.getById.value$;

    this.comparanooUrl = environment.comparanoourl;
  }
  triggerAction(actionName) {
    this.subHeaderService.setTriggerAction(actionName);
  }
}
