import {Injectable} from "@angular/core";
import {HttpClient, HttpParams, HttpResponse} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {Comment, CommentPost, InputComment} from "./types";
import {ErrorService} from "../../error";
import {catchError} from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class CommentApiService {
    constructor(
        private readonly httpClient:HttpClient,
        private readonly errorService:ErrorService
    ) { }

    getByEntityId = (inputComment: InputComment): Observable<Comment[]> => {
        let parameters: string[];
        parameters = [inputComment.entityType, inputComment.entityId.toString()];

        let params = new HttpParams()
            .append('orderBy', 'CreationDate desc');

        let url = this.formatUrl(parameters);
        return this.httpClient.get<Comment[]>(url, {params: params})
          .pipe(
            catchError((error) => {
              throw this.errorService.serverErrorRedirect(error);
            })
          );

        // Comments/Contract/1726?orderBy=CreationDate%20asc
    }

    postComment = (commentPost:CommentPost): any =>  {
        let parameters: string[];
        parameters = [];
        let url= this.formatUrl(parameters);

        console.log(commentPost)

        return this.httpClient.post<Comment>(url, commentPost)
          .pipe(
            catchError((error) => {
              throw this.errorService.serverErrorRedirect(error);
            })
          );
    }

    formatUrl = (parameters: string[]): string => {
        let url = environment.comparanooapiurl;
        url += environment.features.comment.url;
        parameters.forEach((parameters) => {
            url += '/';
            url += parameters;
        });
        return url;
    };
}
