import {Injectable} from "@angular/core";
import {EmployeeApiService} from "./employee.api.service";
import {Actions} from "@ngrx/effects";
import {EmployeeAction, employeeActions} from "./employee.action";
import {createApiCallEffects} from "../../../common/store/generic.effects";

@Injectable()
export class EmployeeEffects {
  constructor(
    private readonly employeeApiService: EmployeeApiService,
    private readonly action$: Actions<EmployeeAction>
  ) {
  }

  getById = createApiCallEffects(
    this,
    this.action$,
    employeeActions.getById,
    this.employeeApiService.getById
  );
}
