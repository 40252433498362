import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Country, CountryPost, CountryUpdate} from './types';
import {catchError} from "rxjs/operators";
import {ErrorService} from "../../error";
import {MessageService} from "../../service/message/message.service";
import {CrudType} from "../../service/message/types";

@Injectable({
  providedIn: 'root'
})
export class CountryApiService {
  constructor(private readonly httpClient: HttpClient,
              private readonly errorService: ErrorService,
              private readonly messageService:MessageService
  ) {
  }

  getById = (id: number): Observable<Country> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<Country>(url,)
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  postCountry = (countryPost: CountryPost): any => {

    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);
    return this.httpClient.post<Country>(url, countryPost, {observe: 'response'})
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  updateCountry = (countryUpdate: CountryUpdate): any => {
    let parameters: string[];
    parameters = [countryUpdate.id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.put<Country>(url, countryUpdate, {observe: 'response'})
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  }

  formatUrl = (parameters: string[]): string => {
    let url = environment.comparanooapiurl;
    url += environment.features.countries.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}


