import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesTeamRoutingModule } from './sales-team-routing.module';
import {SalesTeamListComponent} from "./sales-team-list/sales-team-list.component";
import {SharedModule} from "../../../shared/shared.module";
import { SalesTeamDetailsComponent } from './sales-team-details/sales-team-details.component';
import { SalesTeamCreateComponent } from './sales-team-create/sales-team-create.component';
import { SalesTeamEditComponent } from './sales-team-edit/sales-team-edit.component';


@NgModule({
  declarations: [
    SalesTeamListComponent,
    SalesTeamDetailsComponent,
    SalesTeamCreateComponent,
    SalesTeamEditComponent
  ],
  imports: [
    CommonModule,
    SalesTeamRoutingModule,
    SharedModule
  ]
})
export class SalesTeamModule { }
