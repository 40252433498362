<app-document-subheader></app-document-subheader>
<div class="page-container" *ngIf="this.user$ | async as user">
  <div class="card card-light w-100">

    <div class="card-body">
      <div class="container-fluid">

        <div class="row">
          <div class="col">
            <div class="form-group">
              <label class="form-title-label" translate>USER_LBL_NAME</label>
              <div class="form-title">{{ user.contactName }}</div>
            </div>
          </div>
        </div>

        <div class="row">

          <div class="col-6 col-lg-6">

            <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate>USER_LBL_USERNAME</label>
              <div class="col">
                          <span class="form-control-plaintext form-control-sm">
                              {{ user.aspNetUserUserName }}
                          </span>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate>USER_LBL_ROLES</label>
              <div class="col">
                          <span class="form-control-plaintext form-control-sm badge-list">
                              <!-- <app-badge *ngFor="let role of user.roles" [message]="role" color="primary"></app-badge> -->
                            <!-- <app-badge [InputRoles]="user.roles" ></app-badge> -->
                              <ng-multiselect-dropdown
                                [placeholder]="'Rôles'"
                                [settings]="dropdownSettings"
                                [data]="dropdownList"
                                [(ngModel)]="selectedItems"
                                (onSelect)="onItemSelect($event)"
                                (onSelectAll)="onSelectAll($event)"
                              >
                                </ng-multiselect-dropdown>
                          </span>
              </div>
            </div>

          </div>

          <div class="col">

            <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate>USER_LBL_EMAIL</label>
              <div class="col">
                          <span class="form-control-plaintext form-control-sm">
                              {{ user.aspNetUserEmail }}
                          </span>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate>USER_LBL_ACTIVE</label>
              <div class="col">
                          <span class="form-control-plaintext form-control-sm">
                           <input type="checkbox" name="active" [(ngModel)]="user.active"
                                  onclick="return false;" disabled/>
                            <!--   onkeydown="e = e || window.event; if(e.keyCode !== 9) return false;"  -->
                          </span>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
</div>
