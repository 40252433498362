import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, filter } from 'rxjs';
import { BankAccountService } from 'src/app/shared/store/bank-account/bank-account.service';
import { BankAccount } from 'src/app/shared/store/bank-account/types';
import { ContractService } from 'src/app/shared/store/contract/contract.service';
import {
  ContractModel,
  HealthInsuranceContractDetailModel,
} from 'src/app/shared/store/contract/types';
import { PaymentSplittingService } from 'src/app/shared/store/payment-splitting/payment-splitting.service';
import { PaymentSplitting } from 'src/app/shared/store/payment-splitting/type';
import { BreadcrumbService } from 'xng-breadcrumb';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { CategoryChoiceComponent } from '../../../../shared/components/category-choice/category-choice.component';
import { SalesType } from '../../../../shared/enum/sales-type';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@UntilDestroy()
@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss'],
})
export class ContractDetailsComponent implements OnInit, OnDestroy {
  contractId: string;
  contractBase$: Observable<ContractModel>;
  contractHealthDetails$: Observable<HealthInsuranceContractDetailModel[]>;
  bankAccount$: Observable<BankAccount>;
  bankAccountRefund$: Observable<BankAccount>;
  paymentSplitting$: Observable<PaymentSplitting>;
  active = 1;
  view$: Observable<View>;

  constructor(
    private route: ActivatedRoute,
    private readonly contractService: ContractService,
    private readonly bankAccountService: BankAccountService,
    private readonly paymentSplittingService: PaymentSplittingService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly subHeaderService: SubHeaderService,
    private readonly modalService: NgbModal,
    private readonly router: Router,
  ) {
    this.contractBase$ = this.contractService.getByUniqueId.value$;
    this.contractHealthDetails$ = this.contractService.getHealthDetail.value$;
    this.bankAccount$ = this.bankAccountService.getById.value$;
    this.paymentSplitting$ = this.paymentSplittingService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.contractId = this.route.snapshot.params['id'];
    this.contractService.getByUniqueId.call(this.contractId);

    this.contractBase$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@contractDetails', x.contactName);
        switch (x.category.toString()) {
          case 'Health':
            this.contractService.getHealthDetail.call(x.id);
            break;
          case 'Car':
            this.contractService.getCarDetail.call(x.id);
            break;
          case 'House':
            this.contractService.getHouseDetail.call(x.id);
            break;
          default:
            break;
        }
        this.bankAccountService.getById.call(x.paymentBankAccountId.toString());
        this.paymentSplittingService.getById.call(
          x.paymentSplittingId.toString(),
        );
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@contractList', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'contractCreate') {
          this.createContract();
        }
        if (x == 'contractEdit') {
          this.router.navigate([
            '/Sales/ContractRequests/Edit',
            this.contractId,
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  createContract() {
    const modalRef = this.modalService.open(CategoryChoiceComponent);
    modalRef.componentInstance.salesType = SalesType.CONTRACT;
  }

  ngOnDestroy(): void {
    this.contractService.getByUniqueId.reset();
    this.contractService.getHealthDetail.reset();
    this.contractId = this.route.snapshot.params['id'];
    this.contractBase$ = this.contractService.getByUniqueId.value$;
    this.contractService.getByUniqueId.call(this.contractId);
    this.breadcrumbService.set('@contractDetails', ' ');
  }
}
