import { DateTime } from 'luxon';

export function CurrentTime (){ 
    DateTime.now()
};

export function CurrentTimeToString (){
    return DateTime.now().toString();
}

export function FormatDateToString(date : DateTime){
    return date.toLocaleString(DateTime.DATETIME_SHORT)
}

