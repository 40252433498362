
<ng-container *ngIf="this.currencyBase$ | async as currency">
        <div class="row">
          <form class="col">
            <div class="form-group">
              <label class="form-title-label" translate>CURRENCY_LBL_NAME</label>
              <div class="form-title">{{ currency.name }}</div>
            </div>
          </form>
        </div>

        <div class="row">

          <div class="col-6 col-lg-6">

            <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate>CURRENCY_LBL_ACCURACY</label>
              <div class="col">
                <span class="form-control-plaintext form-control-sm">
                    {{currency.accuracy}}
                </span>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate>CURRENCY_LBL_SYMBOL_POSITION</label>
              <div class="col">
                <span class="form-control-plaintext form-control-sm">
                    {{currency.symbolPositionLoc}}
                </span>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate>CURRENCY_LBL_CURRENT_RATE</label>
              <div class="col">
                <span class="form-control-plaintext form-control-sm">
                    {{currency.currentRate}}
                </span>
              </div>
            </div>

          </div>

          <div class="col">

            <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate>CURRENCY_LBL_SYMBOL</label>
              <div class="col">
                <span class="form-control-plaintext form-control-sm">
                    {{currency.symbol}}
                </span>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate>CURRENCY_LBL_ROUNDING</label>
              <div class="col">
                <span class="form-control-plaintext form-control-sm">
                    {{currency.rounding}}
                </span>
              </div>
            </div>

            <div class="form-group row">
              <label class="col-4 col-form-label-sm" translate>CURRENCY_LBL_ISOCODE</label>
              <div class="col">
                <span class="form-control-plaintext form-control-sm">
                    {{currency.isoCode}}
                </span>
              </div>
            </div>

          </div>
        </div>

  </ng-container>
