import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onArrayApiCall } from 'src/app/common/store/generic.reducer';
import { GenericState, IState } from 'src/app/common/store/types';
import { headerActions } from './header.actions';
import { HeaderMenu } from './type';

export interface IHeaderState extends IState<HeaderMenu> {
  getHeaderMenu: GenericState<HeaderMenu[]>;
}

export const headerInitialState: IHeaderState = {
  getHeaderMenu: new GenericState<HeaderMenu[]>(),
};

const headerReducers = [
  ...onArrayApiCall<HeaderMenu>(headerActions.getHeaderMenu, 'getHeaderMenu'),
];

export const headerReducer = createReducer(
  headerInitialState,
  ...headerReducers
) as ActionReducer<IHeaderState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return headerReducer(state, action);
}
