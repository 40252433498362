import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  Type,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { ContactFormNotesComponent } from '../contact-form-tabs/contact-form-notes/contact-form-notes.component';
import { ErrorsNestedFormGroup } from '../../../../../shared/store/form/types';
import { TabsContentContact } from '../../../../../shared/models/tabs-content-contact';
import { FormService } from '../../../../../shared/store/form/form.service';

@Component({
  selector: 'app-contact-tabs-form',
  templateUrl: './contact-tabs-form.component.html',
  styleUrls: ['./contact-tabs-form.component.scss'],
})
export class ContactTabsFormComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  errorsDescription$: Observable<ErrorsNestedFormGroup>;

  active = 1;

  tabsContent$: Observable<TabsContentContact[]> = of([]);

  tabsInit: TabsContentContact[] = [
    {
      title: 'CONTACT_TABS_NOTES',
      index: 1,
      name: 'notes',
      component: ContactFormNotesComponent,
      formGroupName: 'notesFormGroup',
    },
  ];
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private readonly formService: FormService,
  ) {}

  ngOnInit(): void {
    this.errorsDescription$ = this.formService.getErrors();
    this.tabsContent$ = of(this.tabsInit);
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tabsContent$ = of(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentContact) {
    return item.index;
  }
}
