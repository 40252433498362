import { GenericState, IState } from '../../../common/store/types';
import { onApiCall } from '../../../common/store/generic.reducer';
import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { SalesTeamMember } from './types';
import { salesTeamMemberActions } from './sales-team-member.action';

export interface ISalesTeamMemberState extends IState<SalesTeamMember> {
  getById: GenericState<SalesTeamMember>;
}

export const salesTeamMemberInitialState: ISalesTeamMemberState = {
  getById: new GenericState<SalesTeamMember>(),
};

const salesTeamMemberReducers = [
  ...onApiCall<SalesTeamMember>(salesTeamMemberActions.getById, 'getById'),
];

export const salesTeamMemberReducer = createReducer(
  salesTeamMemberInitialState,
  ...salesTeamMemberReducers
) as ActionReducer<ISalesTeamMemberState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return salesTeamMemberReducer(state, action);
}
