import { createFeatureSelector, createSelector } from "@ngrx/store";
import { INotificationState } from "./notification.reducer";
import { notificationFeatureKey } from "./types";

const notificationFeatureState = 
    createFeatureSelector<INotificationState>(notificationFeatureKey);

export const notificationSelectors = {
    getNotifications: createSelector(
        notificationFeatureState,
        (state:INotificationState) => state.notifications
    )
};