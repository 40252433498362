import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { InputReporting, LabelWithValue, Period } from './types';
import { ErrorService } from '../../error';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ReportingApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService
  ) {}

  getReportByEntity = (inputReporting: InputReporting): Observable<any[]> => {
    let parameters: string[];
    parameters = inputReporting.parameters;

    let params = new HttpParams();
    inputReporting.query.forEach((value, key) => {
      params = params.append(key, value);
    });

    let url = this.formatUrl(parameters);

    // console.log(url)

    return this.httpClient.get<LabelWithValue[]>(url, { params: params }).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getPeriods = (fromDate: Date): Observable<Period[]> => {
    let parameters: string[];
    parameters = ['periods'];
    let url = this.formatUrl(parameters);

    let queryParams = new HttpParams();
    queryParams = queryParams.append('fromDate', fromDate.toDateString());

    return this.httpClient.get<Period[]>(url, { params: queryParams }).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getReportKpi = (inputReporting: InputReporting): Observable<number> => {

    let parameters: string[];
    parameters = inputReporting.parameters;

    let params = new HttpParams();
    inputReporting.query.forEach((value, key) => {
      params = params.append(key, value)
    });

    let url = this.formatUrl(parameters);

    // console.log(url)

    return this.httpClient
      .get<number>(url, {params: params})
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  formatUrl = (parameters: string[]): string => {
    let url = environment.comparanooapiurl;
    url += environment.features.reporting.url;

    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
