import { ContractModel } from '../contract/types';
import { LeadModel } from '../lead/types';

export const contactFeatureKey = 'contact';

export type Contact = {
  id: number;
  name: string;
  title: string;
  lastName: string;
  firstName: string;
  profession: string;
  address: string;
  address2: string;
  zipCode: string;
  city: string;
  phoneNumber: string;
  email: string;
  isCompany: boolean;
  salesPersonId?: number;
  salesPersonContactName: string;
  salesTeamId?: number;
  salesTeamName: string;
  campaignId?: number;
  campaignName: string;
  medium: string;
  source: string;
  addressType: number;
  addressTypeLoc: string;
  addressTypeInt: number;
  countryId?: number;
  countryName: string;
  gender?: number;
  genderLoc: string;
  notes: string;
  parentId?: number;
  creationTime: Date;
  contracts: ContractModel[];
  leads: LeadModel[];
};

export type ContactPost = {
  name: string;
  title: string;
  lastName: string;
  firstName: string;
  profession: string;
  address: string;
  address2: string;
  zipCode: string;
  city: string;
  phoneNumber: string;
  email: string;
  isCompany: boolean;
  salesPersonId?: number;
  salesTeamId?: number;
  campaignId?: number;
  medium: string;
  source: string;
  addressType: number;
  countryId?: number;
  gender?: number;
  notes: string;
  parentId?: number;
};

export type ContactUpdate = {
  id: number;
  lastName: string;
  firstName: string;
  profession: string;
  address: string;
  address2: string;
  zipCode: string;
  city: string;
  phoneNumber: string;
  email: string;
  isCompany: boolean;
  salesPersonId?: number;
  salesTeamId?: number;
  campaignId?: number;
  medium: string;
  source: string;
  addressType: number;
  countryId?: number;
  notes: string;
};

export type CreateContactFormModel = {
  name: string;
  title: string;
  lastName: string;
  firstName: string;
  profession: string;
  address: string;
  address2: string;
  zipCode: string;
  city: string;
  phoneNumber: string;
  email: string;
  isCompany: boolean;
  salesPersonId?: number;
  salesTeamId?: number;
  campaignId?: number;
  medium: string;
  source: string;
  addressType: number;
  countryId?: number;
  gender?: number;
  notes: string;
  parentId?: number;
};
