import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { LeadApiService } from "./lead.api.services";
import { LeadEffects } from "./lead.effects";
import { ILeadState, leadInitialState, leadReducer } from "./lead.reducer";
import { LeadService } from "./lead.service";
import { leadFeatureKey } from "./types";

@NgModule({
    imports:[
        StoreModule.forFeature<ILeadState>(leadFeatureKey, leadReducer,{
            initialState: leadInitialState,
        }),
        EffectsModule.forFeature([LeadEffects])
    ],
    providers:[LeadApiService, LeadService]
})
export class StoreLeadModule{}