import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { JobApiService } from "./job.api.service";
import { JobEffects } from "./job.effects";
import { IJobState, jobReducer, jobInitialState } from "./job.reducer";
import { JobService } from "./job.service";
import { jobFeatureKey } from "./types";

@NgModule({
    imports:[
        StoreModule.forFeature<IJobState>(jobFeatureKey, jobReducer,{
            initialState: jobInitialState,
        }),
        EffectsModule.forFeature([JobEffects])
    ],
    providers:[JobApiService, JobService]
})
export class StoreJobModule{}