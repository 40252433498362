import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Chart, registerables } from 'chart.js';
import { color } from 'chart.js/helpers';
import { ReportingService } from '../../../store/reporting/reporting.service';
import { dateDiff, formatDate } from '../../../helper/datehelper';
import { filter } from 'rxjs/operators';
import {
  InputReporting,
  LabelByDayAndHoursRange,
  LabelWithValue,
  MatrixDataPointExtended,
} from '../../../store/reporting/types';
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix';
import { debounceTime, Observable } from 'rxjs';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy, OnChanges {
  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() campaignId: number;
  @Input() insuranceType: number;
  @Input() salesTeamId: number;
  @Input() salesTeamMemberId: number;

  //Observable KPI
  leadsSum$: Observable<number>;
  contractsSum$: Observable<number>;
  annualFeesSum$: Observable<number>;
  monthlyFeesAverage$: Observable<number>;

  //Observable Charts
  leadsByType$: Observable<LabelWithValue[]>;
  leadsByCity$: Observable<LabelWithValue[]>;
  leadsByRegime$: Observable<LabelWithValue[]>;
  leadsByRange$: Observable<LabelWithValue[]>;
  leadsByDayAndHours$: Observable<LabelByDayAndHoursRange[]>;
  peopleByAgeM$: Observable<LabelWithValue[]>;
  peopleByAgeF$: Observable<LabelWithValue[]>;

  //Chart
  private leadsPerTypeChart: any;
  private leadsPerCityChart: any;
  private peopleByAges: any;
  private leadByHoursByRange: any;
  private leadByDayAndHoursByRange: any;
  private peopleByRegime: any;

  private filterPeriod: string;
  private numberOfDays: any;

  constructor(private readonly reportingService: ReportingService) {}

  ngOnInit(): void {
    //Bar Chart
    //Matrix (Heat) Chart
    Chart.register(...registerables);
    Chart.register(MatrixController, MatrixElement);

    this.numberOfDays = dateDiff(this.startDate, this.endDate);

    this.filterPeriod = `CreationDate>=${formatDate(
      this.startDate,
      'ToSqlDate',
    )},CreationDate<=${formatDate(this.endDate, 'ToSqlDate')}`;

    let filterString: string = this.filterPeriod;
    if (this.campaignId && this.campaignId !== -1) {
      filterString = filterString + `,CampaignId=${this.campaignId}`;
    }
    if (this.insuranceType && this.insuranceType !== -1) {
      filterString = filterString + `,Category=${this.insuranceType}`;
    }
    if (this.salesTeamId && this.salesTeamId !== -1) {
      filterString = filterString + `,SalesTeamId=${this.salesTeamId}`;
    }
    if (this.salesTeamMemberId && this.salesTeamMemberId !== -1) {
      filterString = filterString + `,SalesPersonId=${this.salesTeamMemberId}`;
    }
    if (this.salesTeamMemberId && this.salesTeamMemberId != -2) {
      //filterString = filterString + `,SalesPersonId=NULL`;
    }

    this.contractsSum$ = this.reportingService.getReportContractsSum.value$;
    this.leadsSum$ = this.reportingService.getReportLeadsSum.value$;
    this.annualFeesSum$ = this.reportingService.getReportAnnualFeesSum.value$;
    this.monthlyFeesAverage$ =
      this.reportingService.getReportMonthlyFeesAverage.value$;

    //init Observable
    this.peopleByAgeM$ = this.reportingService.getReportMalePeopleByAge.value$;
    this.peopleByAgeF$ =
      this.reportingService.getReportFemalePeopleByAge.value$;
    this.leadsByDayAndHours$ =
      this.reportingService.getLeadsByDayAndHoursRange.value$;
    this.leadsByRange$ =
      this.reportingService.getReportLeadsMonthlyRange.value$;
    this.leadsByRegime$ = this.reportingService.getReportPeopleByRegime.value$;
    this.leadsByCity$ = this.reportingService.getReportLeadsByCity.value$;
    this.leadsByType$ = this.reportingService.getReportLeadsByType.value$;

    //color Chart
    const colorChart: string = '#03787c';
    const fontFamily: string = 'Roboto';
    //primary : '#03787c'
    //sidebar: '#3c5067'
    this.chartsCallApi(filterString);

    //Chart 1
    this.matrixChart(colorChart, fontFamily);

    //Chart 2
    this.matrixChart2(colorChart, fontFamily);

    //Chart 3
    this.lineChart(colorChart, fontFamily);

    //Chart 4
    this.doughnut(colorChart, fontFamily);

    //chart leadsByTypeChart
    this.pie(colorChart, fontFamily);

    //chart leadsByCityChart top 5
    this.bar(colorChart, fontFamily);

    //////KPI
    this.kpiCallApi(filterString);
  }

  private chartsCallApi(filterString: string) {
    ////LeadPerType
    //input
    let inputForLeadsByType: InputReporting = {
      parameters: ['leadsByType'],
      query: new Map<string, string>([['filter', filterString]]),
    };

    //call
    this.reportingService.getReportLeadsByType.call(inputForLeadsByType);

    ////LeadPerCity
    const nbCity: number = 5;
    //input
    let inputForLeadsByCity: InputReporting = {
      parameters: ['leadsByCity'],
      query: new Map<string, string>([
        ['nbCity', nbCity.toString()],
        ['filter', filterString],
      ]),
    };

    //call
    this.reportingService.getReportLeadsByCity.call(inputForLeadsByCity);

    ////LeadsPerRegime
    //input
    let inputForLeadsByRegime: InputReporting = {
      parameters: ['leadsByRegime'],
      query: new Map<string, string>([
        ['filter', 'InsuredType=1'],
        // ['filter', filterPeriod + ',InsuredType=1']
      ]),
    };

    //call
    this.reportingService.getReportPeopleByRegime.call(inputForLeadsByRegime);

    ////LeadsByRange
    //input
    if (this.numberOfDays.day <= 31) {
      let queryStrings: Map<string, string> = new Map<string, string>();
      queryStrings.set('startDate', new Date(this.startDate).toDateString());
      queryStrings.set('endDate', new Date(this.endDate).toDateString());
      queryStrings.set('filter', filterString);
      // queryStrings.set('filter', filterPeriod);
      let inputForLeadsByRange: InputReporting = {
        parameters: ['leadsByDay'],
        query: queryStrings,
      };
      //call
      this.reportingService.getReportLeadsMonthlyRange.call(
        inputForLeadsByRange,
      );
    } else {
      let rangeValue:
        | 'Monthly'
        | 'Yearly'
        | 'AllTime'
        | 'Daily'
        | 'Weekly'
        | 'MonthlyByYearAndMonthRange' = 'MonthlyByYearAndMonthRange';
      if (this.numberOfDays.day <= 91) {
        rangeValue = 'Weekly';
      }
      let queryStrings: Map<string, string> = new Map<string, string>();
      queryStrings.set('entity', 'Lead');
      queryStrings.set('range', rangeValue);
      queryStrings.set('filter', filterString);
      let inputForLeadsByRange: InputReporting = {
        parameters: ['range'],
        query: queryStrings,
      };

      //call
      this.reportingService.getReportLeadsMonthlyRange.call(
        inputForLeadsByRange,
      );
    }

    //
    //input
    let inputForLeadsByDayAndHours: InputReporting = {
      parameters: ['leadsByDayAndHoursRange'],
      query: new Map<string, string>([['filter', filterString]]),
    };

    //call
    this.reportingService.getLeadsByDayAndHoursRange.call(
      inputForLeadsByDayAndHours,
    );

    ////PeopleByAge
    //////Male
    //input
    let inputForPeopleMale: InputReporting = {
      parameters: ['peopleByAges'],
      query: new Map<string, string>([['filter', `Gender=1`]]),
    };

    //call
    this.reportingService.getReportMalePeopleByAge.call(inputForPeopleMale);

    //////Female
    //input
    let inputForPeopleFemale: InputReporting = {
      parameters: ['peopleByAges'],
      query: new Map<string, string>([['filter', `Gender=2`]]),
    };

    //call
    this.reportingService.getReportFemalePeopleByAge.call(inputForPeopleFemale);
  }

  private kpiCallApi(filterString: string) {
    let inputContractsSum: InputReporting = {
      parameters: ['count'],
      query: new Map([
        ['filter', filterString],
        ['entity', 'Contract'],
      ]),
    };
    this.reportingService.getReportContractsSum.call(inputContractsSum);

    let inputLeadsSum: InputReporting = {
      parameters: ['count'],
      query: new Map([
        ['filter', filterString],
        ['entity', 'Lead'],
      ]),
    };
    this.reportingService.getReportLeadsSum.call(inputLeadsSum);

    let inputAnnualyFeesSum: InputReporting = {
      parameters: ['annualfees', 'sum'],
      query: new Map<string, string>([['filter', filterString]]),
    };
    this.reportingService.getReportAnnualFeesSum.call(inputAnnualyFeesSum);

    let inputMonthlyFeesAverage: InputReporting = {
      parameters: ['monthlyfees', 'average'],
      query: new Map<string, string>([['filter', filterString]]),
    };
    this.reportingService.getReportMonthlyFeesAverage.call(
      inputMonthlyFeesAverage,
    );
  }

  ngOnDestroy(): void {
    this.reportingService.getReportLeadsMonthlyRange.reset();
    this.reportingService.getReportPeopleByRegime.reset();
    this.reportingService.getReportMalePeopleByAge.reset();
    this.reportingService.getReportFemalePeopleByAge.reset();
    this.reportingService.getLeadsByDayAndHoursRange.reset();
    this.reportingService.getReportContractsSum.reset();
    this.reportingService.getReportMonthlyFeesAverage.reset();
    this.reportingService.getReportLeadsSum.reset();
    this.reportingService.getReportAnnualFeesSum.reset();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.numberOfDays = dateDiff(this.startDate, this.endDate);

    this.filterPeriod = `CreationDate>=${formatDate(
      this.startDate,
      'ToSqlDate',
    )},CreationDate<=${formatDate(this.endDate, 'ToSqlDate')}`;

    //filter input
    let filterString: string = this.filterPeriod;

    if (this.campaignId && this.campaignId != -1) {
      filterString = filterString + `,CampaignId=${this.campaignId}`;
    }
    if (this.insuranceType && this.insuranceType != -1) {
      filterString = filterString + `,Category=${this.insuranceType}`;
    }
    if (this.salesTeamId && this.salesTeamId != -1) {
      filterString = filterString + `,SalesTeamId=${this.salesTeamId}`;
    }
    if (this.salesTeamMemberId && this.salesTeamMemberId != -1) {
      filterString = filterString + `,SalesPersonId=${this.salesTeamMemberId}`;
    }
    if (this.salesTeamMemberId && this.salesTeamMemberId != -2) {
      //filterString = filterString + `,SalesPersonId=NULL`;
    }

    this.kpiCallApi(filterString);
    this.chartsCallApi(filterString);
  }

  // sexe
  private matrixChart(colorChart: string, fontFamily: string) {
    this.peopleByAgeM$
      .pipe(
        filter((x) => !!x),
        debounceTime(100),
      )
      .subscribe((resultM) => {
        const peopleByAgeMLabels = resultM.map((v) => v.label);
        const peopleByAgeMValues = resultM.map((v) => v.value);

        let peopleByAgeData: any[] = [];
        for (let i = 0; i < peopleByAgeMLabels.length; i++) {
          peopleByAgeData = [
            ...peopleByAgeData,
            {
              x: peopleByAgeMLabels[i],
              y: 'Homme',
              v: peopleByAgeMValues[i],
            },
          ];
        }

        this.peopleByAgeF$.pipe(filter((x) => !!x)).subscribe((resultF) => {
          const peopleByAgeFLabels = resultF.map((v) => v.label);
          const peopleByAgeFValues = resultF.map((v) => v.value);

          for (let i = 0; i < peopleByAgeFLabels.length; i++) {
            peopleByAgeData = [
              ...peopleByAgeData,
              {
                x: peopleByAgeFLabels[i],
                y: 'Femme',
                v: peopleByAgeFValues[i],
              },
            ];
          }

          let min: number = Math.min(...peopleByAgeData.map((x) => x.v));
          let max: number = Math.max(...peopleByAgeData.map((x) => x.v));

          if (this.peopleByAges) {
            this.peopleByAges.clear();
            //remove
            this.peopleByAges.data.labels?.splice(0); // remove the label first

            this.peopleByAges.data.datasets.forEach((dataset) => {
              dataset.data.splice(0);
            });

            //add
            const data = this.peopleByAges.data;
            if (data.datasets.length > 0) {
              for (let index = 0; index < data.datasets.length; ++index) {
                data.datasets[index].data = [...peopleByAgeData];
              }
            }
            this.peopleByAges.update();
          }

          if (!this.peopleByAges)
            this.peopleByAges = new Chart('peopleByAges', {
              type: 'matrix',
              data: {
                datasets: [
                  {
                    data: peopleByAgeData,
                    label: 'Sexe & âge',
                    backgroundColor(context) {
                      const value = context.dataset.data[
                        context.dataIndex
                      ] as MatrixDataPointExtended;
                      const alpha = (value.v - 5) / max; // /40;
                      return color(colorChart).alpha(alpha).rgbString();
                      // return color('#03787c').alpha(alpha).rgbString();
                    },
                    borderColor(context) {
                      const value = context.dataset.data[
                        context.dataIndex
                      ] as MatrixDataPointExtended;
                      const alpha = (value.v - 5) / max; // /40;
                      // return color('#03787c').alpha(alpha).rgbString();
                      return color(colorChart).alpha(alpha).rgbString();
                    },
                    borderWidth: 1,
                    width: ({ chart }) => (chart.chartArea || {}).width / 7 - 1,
                    height: ({ chart }) =>
                      (chart.chartArea || {}).height / 2 - 1,
                  },
                ],
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  legend: {
                    display: false,
                  },
                  title: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      title() {
                        return '';
                      },
                      label(context) {
                        const v = context.dataset.data[
                          context.dataIndex
                        ] as MatrixDataPointExtended;
                        return ['Value: ' + v.v];
                      },
                    },
                  },
                },
                scales: {
                  x: {
                    type: 'category',
                    labels: [],
                    ticks: {
                      display: true,
                    },
                    grid: {
                      display: false,
                    },
                  },
                  y: {
                    type: 'category',
                    labels: ['Femme', 'Homme'],
                    offset: true,
                    ticks: {
                      display: true,
                    },
                    grid: {
                      display: false,
                    },
                  },
                },
                font: {
                  family: fontFamily, //Voir comment mettre ça en dynamique
                },
              },
            });
        });
      });
  } //Ok

  // lead jour et heure
  private matrixChart2(colorChart: string, fontFamily: string) {
    this.leadsByDayAndHours$.pipe(filter((x) => !!x)).subscribe((result) => {
      const leadsByDayAndHoursLabels: any[] = result.map(
        (labelByDayAndHoursRange: any) => labelByDayAndHoursRange.dayLabel,
      );
      const leadsByDayAndHoursValues: any[] = result.map(
        (labelByDayAndHoursRange: any) => labelByDayAndHoursRange.value,
      );
      const leadsByDayAndHoursRanges: any[] = result.map(
        (labelByDayAndHoursRange: any) => labelByDayAndHoursRange.hourRange,
      );

      let leadsByDayAndHoursData: any[] = [];
      for (let i = 0; i < leadsByDayAndHoursLabels.length; i++) {
        leadsByDayAndHoursData = [
          ...leadsByDayAndHoursData,
          {
            x: leadsByDayAndHoursRanges[i],
            y: leadsByDayAndHoursLabels[i],
            v: leadsByDayAndHoursValues[i],
          },
        ];
      }
      let arrayLength = leadsByDayAndHoursData.length;
      let max: number = Math.max(...leadsByDayAndHoursData.map((x) => x.v));

      if (this.leadByHoursByRange) {
        this.leadByHoursByRange.clear();
        //remove
        this.leadByHoursByRange.data.labels?.splice(0); // remove the label first

        this.leadByHoursByRange.data.datasets.forEach((dataset) => {
          dataset.data.splice(0);
        });

        //add
        const data = this.leadByHoursByRange.data;
        if (data.datasets.length > 0) {
          // data.labels = Utils.months({count: data.labels.length + 1});

          for (let index = 0; index < data.datasets.length; ++index) {
            data.datasets[index].data = [...leadsByDayAndHoursData];
          }
        }
        this.leadByHoursByRange.update();
      }

      if (!this.leadByHoursByRange)
        this.leadByHoursByRange = new Chart('leadByHoursByRange', {
          type: 'matrix',
          data: {
            datasets: [
              {
                label: 'Leads par jour et par heure',
                data: leadsByDayAndHoursData,
                backgroundColor(context) {
                  const value = context.dataset.data[
                    context.dataIndex
                  ] as MatrixDataPointExtended;
                  // const alpha = (value.v - 5) / max; // /40;
                  const alpha = value.v / max; // /40;
                  return color(colorChart).alpha(alpha).rgbString();
                },
                borderColor(context) {
                  const value = context.dataset.data[
                    context.dataIndex
                  ] as MatrixDataPointExtended;
                  const alpha = value.v / max; // /40;
                  // const alpha = (value.v - 5) /   max; // /40;
                  return color(colorChart).alpha(alpha).darken(0.3).rgbString();
                },
                borderWidth: 1,
                // hoverBackgroundColor: 'yellow',
                // width: ({chart}) => (chart.chartArea || {}).width / chart.scales['x'].ticks.length - 3,
                // height: ({chart}) => (chart.chartArea || {}).height / chart.scales['y'].ticks.length - 3
                // width: ({chart}) => (chart.chartArea || {}).width / arrayLength - 1,
                // height: ({chart}) => (chart.chartArea || {}).height / 7 - 1
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: false,
              },
              //legend: false,
              tooltip: {
                callbacks: {
                  title() {
                    return '';
                  },
                  label(context) {
                    const v = context.dataset.data[
                      context.dataIndex
                    ] as MatrixDataPointExtended;
                    return ['Horaire: ' + v.x, 'Leads: ' + v.v];
                  },
                },
              },
            },
            scales: {
              x: {
                type: 'category',
                labels: [],
                reverse: false, //reverse le array des labels
                ticks: {
                  display: true,
                  // For a category axis, the val is the index so the lookup via getLabelForValue is needed
                  callback: function (val, index) {
                    //24h dans une journée en array = 23
                    let boo = index % 4 === 0;
                    let booHour = 23 === index;
                    if (booHour) {
                      //return '00 h'
                    }
                    return boo
                      ? this.getLabelForValue(index).slice(0, 2) + ' h'
                      : '';
                  },
                  align: 'end',
                  maxRotation: 0,
                  padding: 0,
                },
                grid: {
                  drawOnChartArea: true,
                  display: false,
                  tickLength: 0,
                },
              },
              y: {
                type: 'category',
                labels: [
                  'Dimanche',
                  'Samedi',
                  'Vendredi',
                  'Jeudi',
                  'Mercredi',
                  'Mardi',
                  'Lundi',
                ],
                // labels: [],
                offset: true,
                ticks: {
                  maxRotation: 0,
                  display: true,
                  autoSkip: true,
                  padding: 1,
                },
                grid: {
                  display: false,
                },
              },
            },
            font: {
              family: fontFamily, //Voir comment mettre ça en dynamique
            },
          },
        });
    });
  } //Ok

  //lead par mois par an
  private lineChart(colorChart: string, fontFamily: string) {
    this.leadsByRange$.pipe(filter((x) => !!x)).subscribe((result) => {
      let leadByRangeLabels: any[] = result.map(
        (labelsWithValue: any) => labelsWithValue.label,
      );
      let leadByRangeValues: any[] = result.map(
        (labelsWithValue: any) => labelsWithValue.value,
      );
      //No need anymore since new call with MonthlyByYearAndMonthRange
      // let diffMonth = new Date(this.endDate).getMonth() - new Date(this.startDate).getMonth();
      // if (diffMonth !== 0 && this.numberOfDays.day > 91) {
      //   //Month view
      //   leadByRangeLabels = [...leadByRangeLabels.slice(new Date(this.startDate).getMonth(), new Date(this.endDate).getMonth() + 1)];
      //   leadByRangeValues = [...leadByRangeValues.slice(new Date(this.startDate).getMonth(), new Date(this.endDate).getMonth() + 1)];
      // }
      // if (this.numberOfDays.day > 91) {
      //   leadByRangeLabels = [
      //     ...leadByRangeLabels.slice(new Date(this.endDate).getMonth() + 1),
      //     ...leadByRangeLabels.slice(0, new Date(this.endDate).getMonth() + 1),
      //   ];
      //   leadByRangeValues = [
      //     ...leadByRangeValues.slice(new Date(this.endDate).getMonth() + 1),
      //     ...leadByRangeValues.slice(0, new Date(this.endDate).getMonth() + 1),
      //   ];
      // }
      if (this.leadByDayAndHoursByRange) {
        this.leadByDayAndHoursByRange.clear();
        //remove
        this.leadByDayAndHoursByRange.data.labels?.splice(0); // remove the label first
        // this.leadByDayAndHoursByRange.data.datasets?.data?.splice(0); // remove the label first
        // this.leadByDayAndHoursByRange.data.labels?.splice(-1, 1); // remove the label first
        //
        this.leadByDayAndHoursByRange.data.datasets?.forEach((dataset) => {
          dataset.data?.splice(0);
        });

        //add
        const data = this.leadByDayAndHoursByRange.data;
        if (data.datasets.length > 0) {
          data.labels = leadByRangeLabels;

          for (let index = 0; index < data.datasets.length; ++index) {
            data.datasets[index].data = leadByRangeValues;
          }
        }
        this.leadByDayAndHoursByRange.update();
      }

      if (!this.leadByDayAndHoursByRange)
        this.leadByDayAndHoursByRange = new Chart('leadByDayAndHoursByRange', {
          type: 'line',
          data: {
            labels: leadByRangeLabels,
            datasets: [
              {
                label: 'Leads',
                data: leadByRangeValues,
                backgroundColor: [colorChart],
                borderColor: [colorChart],
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: false,
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                // ticks: {
                //   callback: function(label, index, labels) {
                //     // when the floored value is the same as the value we have a whole number
                //     return parseInt(label.toString());
                //   },
                // }
              },
            },
            font: {
              family: fontFamily, //Voir comment mettre ça en dynamique
            },
          },
        });
    });
  } //Ok par an

  //regime obligatoire
  private doughnut(colorChart: string, fontFamily: string) {
    this.leadsByRegime$.pipe(filter((x) => !!x)).subscribe((result) => {
      const leadsByRegimeLabels = result.map(
        (labelsWithValue: any) => labelsWithValue.label,
      );
      const leadsByRegimeValues = result.map(
        (labelsWithValue: any) => labelsWithValue.value,
      );

      if (this.peopleByRegime) {
        this.peopleByRegime.clear();
        //remove
        this.peopleByRegime.data.labels?.splice(0); // remove the label first

        this.peopleByRegime.data.datasets.forEach((dataset) => {
          dataset.data.splice(0);
        });

        //add
        const data = this.peopleByRegime.data;
        if (data.datasets.length > 0) {
          data.labels = leadsByRegimeLabels;

          for (let index = 0; index < data.datasets.length; ++index) {
            data.datasets[index].data = [...leadsByRegimeValues];
          }
        }
        this.peopleByRegime.update();
      }

      if (!this.peopleByRegime)
        this.peopleByRegime = new Chart('peopleByRegime', {
          type: 'doughnut',
          data: {
            labels: leadsByRegimeLabels,
            datasets: [
              {
                label: 'Régime obligatoire',
                data: leadsByRegimeValues,
                backgroundColor: [
                  color(colorChart).alpha(1).rgbString(),
                  color(colorChart).alpha(0.8).rgbString(),
                  color(colorChart).alpha(0.5).rgbString(),
                  color(colorChart).alpha(0.4).rgbString(),
                  color(colorChart).alpha(0.3).rgbString(),
                  color(colorChart).alpha(0.2).rgbString(),
                ],
                borderColor: [
                  color(colorChart).alpha(1).rgbString(),
                  color(colorChart).alpha(0.8).rgbString(),
                  color(colorChart).alpha(0.5).rgbString(),
                  color(colorChart).alpha(0.4).rgbString(),
                  color(colorChart).alpha(0.3).rgbString(),
                  color(colorChart).alpha(0.2).rgbString(),
                ],
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: true,
                position: 'right',
              },
              title: {
                display: false,
              },
            },
            scales: {
              y: {
                beginAtZero: false,
                display: false,
              },
            },
            font: {
              family: fontFamily, //Voir comment mettre ça en dynamique
            },
          },
        });
    });
  } //Ok

  //répartition des leads par type
  private pie(colorChart: string, fontFamily: string) {
    this.leadsByType$.pipe(filter((x) => !!x)).subscribe((result) => {
      // let leadPerType = result;

      const leadPerTypeLabels = result.map(
        (labelsWithValue: any) => labelsWithValue.label,
      );
      const leadPerTypeValues = result.map(
        (labelsWithValue: any) => labelsWithValue.value,
      );

      if (this.leadsPerTypeChart) {
        this.leadsPerTypeChart.clear();
        //remove
        this.leadsPerTypeChart.data.labels?.splice(0); // remove the label first
        // this.leadsPerTypeChart.data.labels?.splice(-1, 1); // remove the label first
        // this.leadsPerTypeChart.data.datasets?.data?.splice(0)
        this.leadsPerTypeChart.data.datasets?.forEach((dataset) => {
          dataset.data?.splice(0);
        });

        //add
        const data = this.leadsPerTypeChart.data;
        if (data.datasets.length > 0) {
          data.labels = leadPerTypeLabels;

          for (let index = 0; index < data.datasets.length; ++index) {
            data.datasets[index].data = [...leadPerTypeValues];
            // data.datasets[index].data.push(leadPerTypeValues);
          }
        }
        this.leadsPerTypeChart.update();
      }

      if (!this.leadsPerTypeChart)
        this.leadsPerTypeChart = new Chart('leadsPerTypeChart', {
          type: 'pie',
          data: {
            labels: leadPerTypeLabels,
            datasets: [
              {
                label: 'Leads',
                data: leadPerTypeValues,
                backgroundColor: [
                  color(colorChart).alpha(1).rgbString(),
                  color(colorChart).alpha(0.7).rgbString(),
                  color(colorChart).alpha(0.3).rgbString(),
                ],
                borderColor: [
                  color(colorChart).alpha(1).rgbString(),
                  color(colorChart).alpha(0.7).rgbString(),
                  color(colorChart).alpha(0.3).rgbString(),
                ],
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            // parsing: {
            //   xAxisKey: 'label',
            //   yAxisKey: 'value',
            //   key: 'value'
            // },
            plugins: {
              legend: {
                display: true,
                position: 'right',
              },
              title: {
                display: false,
              },
            },
            scales: {
              y: {
                beginAtZero: false,
                display: false,
              },
            },
            font: {
              family: fontFamily, //Voir comment mettre ça en dynamique
            },
          },
        });
    });
  } //Ok

  private bar(colorChart: string, fontFamily: string) {
    this.leadsByCity$.pipe(filter((x) => !!x)).subscribe((result) => {
      const leadPerCityLabels = result.map(
        (labelsWithValue: any) => labelsWithValue.label,
      );
      const leadPerCityValues = result.map(
        (labelsWithValue: any) => labelsWithValue.value,
      );

      if (this.leadsPerCityChart) {
        this.leadsPerCityChart.clear();
        //remove
        this.leadsPerCityChart.data.labels?.splice(0); // remove the label first
        // this.leadsPerCityChart.data.labels?.splice(-1, 1); // remove the label first

        this.leadsPerCityChart.data.datasets.forEach((dataset) => {
          dataset.data.splice(0);
        });

        //add
        const data = this.leadsPerCityChart.data;
        if (data.datasets.length > 0) {
          data.labels = leadPerCityLabels;

          for (let index = 0; index < data.datasets.length; ++index) {
            data.datasets[index].data = [...leadPerCityValues];
          }
        }
        this.leadsPerCityChart.update();
      }

      if (!this.leadsPerCityChart)
        this.leadsPerCityChart = new Chart('leadsPerCityChart', {
          type: 'bar',
          data: {
            labels: leadPerCityLabels,
            datasets: [
              {
                label: 'Leads',
                data: leadPerCityValues,
                backgroundColor: [colorChart],
                borderColor: [colorChart],
                borderWidth: 1,
              },
            ],
          },
          options: {
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              title: {
                display: false,
              },
            },
            scales: {
              y: {
                beginAtZero: true,
              },
            },
            font: {
              family: fontFamily, //Voir comment mettre ça en dynamique
            },
          },
        });
    });
  } //Ok
}
