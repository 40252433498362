<div class="me-3">
  <ng-container *ngIf="this.currentAvatar$ | async as avatar">
    <img src="{{this.avatar.url}}?name={{this.avatar.contactName}}&bgColor={{this.avatar.bgColor}}&color=FFFFFF" alt="{{this.avatar.contactName}}"
         class="userbox-img"/>
  </ng-container>
</div>
<div class="d-flex flex-column">
    <div>
        <span class="font-weight-bolder pe-1">{{ comment.userContactName }}</span>
        <small>{{ {localeDate:comment.localeCreationDate, utcDate:comment.creationDate} | friendlyDate}}</small>
<!--        <small>{{ comment.creationDate | friendlyDate}}</small>-->
    </div>
    <div>{{ comment.message }}</div>
</div>
