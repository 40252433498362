import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageNotFoundComponent } from './shared/error';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo:'/Sales/Home'
  },
  {
    path: '**',
    pathMatch: 'full',
    component: PageNotFoundComponent,
    canActivate: [],
    canActivateChild: [],
    data: {
      title: 'APP_TITLE',
      header: 'HEADER_SUBTITLE',
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
