import { Injectable } from '@angular/core';
import { ReportingApiService } from './reporting.api.services';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ReportingAction, reportingActions } from './reporting.actions';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { IReportingState } from './reporting.reducer';

@Injectable()
export class ReportingEffects {
  constructor(
    private readonly store: Store<IReportingState>,
    private readonly reportingApiService: ReportingApiService,
    private readonly actions$: Actions<ReportingAction>
  ) {}

  getReportByFilter = createApiCallEffects(
    this,
    this.actions$,
    reportingActions.getReportByFilter,
    this.reportingApiService.getReportByEntity
  );
  getReportLeadsMonthlyRange = createApiCallEffects(
    this,
    this.actions$,
    reportingActions.getReportLeadsMonthlyRange,
    this.reportingApiService.getReportByEntity
  );
  getReportPeopleByRegime = createApiCallEffects(
    this,
    this.actions$,
    reportingActions.getReportPeopleByRegime,
    this.reportingApiService.getReportByEntity
  );
  getReportFemalePeopleByAge = createApiCallEffects(
    this,
    this.actions$,
    reportingActions.getReportFemalePeopleByAge,
    this.reportingApiService.getReportByEntity
  );
  getReportMalePeopleByAge = createApiCallEffects(
    this,
    this.actions$,
    reportingActions.getReportMalePeopleByAge,
    this.reportingApiService.getReportByEntity
  );
  getLeadsByDayAndHoursRange = createApiCallEffects(
    this,
    this.actions$,
    reportingActions.getLeadsByDayAndHoursRange,
    this.reportingApiService.getReportByEntity
  );
  getPeriods = createApiCallEffects(
    this,
    this.actions$,
    reportingActions.getPeriods,
    this.reportingApiService.getPeriods
  );
  getReportLeadsByType = createApiCallEffects(
    this,
    this.actions$,
    reportingActions.getReportLeadsByType,
    this.reportingApiService.getReportByEntity
  );
  getReportLeadsByCity = createApiCallEffects(
    this,
    this.actions$,
    reportingActions.getReportLeadsByCity,
    this.reportingApiService.getReportByEntity
  );
  getReportLeadsSum = createApiCallEffects(
      this,
      this.actions$,
      reportingActions.getReportLeadsSum,
      this.reportingApiService.getReportKpi
  );
  getReportContractsSum = createApiCallEffects(
      this,
      this.actions$,
      reportingActions.getReportContractsSum,
      this.reportingApiService.getReportKpi
  );
  getReportAnnualFeesSum = createApiCallEffects(
      this,
      this.actions$,
      reportingActions.getReportAnnualFeesSum,
      this.reportingApiService.getReportKpi
  );
  getReportMonthlyFeesAverage = createApiCallEffects(
      this,
      this.actions$,
      reportingActions.getReportMonthlyFeesAverage,
      this.reportingApiService.getReportKpi
  );
}
