import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onArrayApiCall } from 'src/app/common/store/generic.reducer';
import { IState, GenericState } from 'src/app/common/store/types';
import { sidebarActions } from './sidebar.actions';
import { SidebarModule } from './types';

export interface ISidebarState extends IState<SidebarModule> {
  getSidebarModule: GenericState<SidebarModule[]>;
}

export const sidebarInitialState: ISidebarState = {
  getSidebarModule: new GenericState<SidebarModule[]>(),
};

const sidebarReducers = [
  ...onArrayApiCall<SidebarModule>(
    sidebarActions.getSidebarModule,
    'getSidebarModule'
  ),
];

export const sidebarReducer = createReducer(
  sidebarInitialState,
  ...sidebarReducers
) as ActionReducer<ISidebarState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return sidebarReducer(state, action);
}
