<div id="offline-error" class="d-flex align-items-center justify-content-center h-100">
  <div class="error-message ">
    <div class="p-3">
      <div class>
        <div class="display-3 font-weight-bolder" translate>ERROR_MSG_OOPS</div>
        <div class="h1 text-info my-4" translate>ERROR_MSG_OFFLINE</div>
        <button class="btn btn-outline-info btn-lg" [routerLink]="['/']">
        </button>
      </div>
    </div>
  </div>
</div>
