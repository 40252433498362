import {Component, OnInit} from '@angular/core';
import {Observable} from "rxjs";
import {BreadcrumbDefinition, BreadcrumbService} from "xng-breadcrumb";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit{
  breadcrumbBase$: Observable<BreadcrumbDefinition[]>

  constructor(private readonly breadcrumbService:BreadcrumbService) {
  }

  ngOnInit(): void {

    this.breadcrumbBase$ = this.breadcrumbService.breadcrumbs$;

  }

}
