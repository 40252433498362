<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <form [formGroup]="articleForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="articleForm">
      <button type="submit" hidden="hidden"></button>
      <div class="card-body">
        <div class="container-fluid">
          <div class="row">
            <div class="col">
              <div class="form-group">
                <input
                  class="form-control form-title"
                  placeholder="{{ 'ARTICLE_PLACEHOLDER_NAME' | translate}}"
                  id="name"
                  name="name"
                  type="name"
                  value=""
                  formControlName="name"
                  checkFormFieldValidity
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6 col-lg-6">
              <div class="form-group row">
                <label
                  class="col-4 col-form-label-sm"
                  for="url"
                  translate
                >ARTICLE_LBL_URL</label
                >
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    placeholder="{{ 'ARTICLE_PLACEHOLDER_URL' | translate}}"
                    id="url"
                    name="url"
                    type="text"
                    value=""
                    formControlName="url"
                    checkFormFieldValidity
                  />
                </div>
              </div>

              <div class="form-group row">
                <label
                  class="col-4 col-form-label-sm control-label"
                  for="description"
                  translate
                >ARTICLE_LBL_DESCRIPTION</label
                >
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    placeholder="{{ 'ARTICLE_PLACEHOLDER_DESCRIPTION' | translate }}"
                    id="description"
                    name="description"
                    type="text"
                    value=""
                    formControlName="description"
                    checkFormFieldValidity
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-lg-12">
              <div class="form-group row">
                <label class="col-4 col-form-label-sm" translate
                >ARTICLE_LBL_PAGECONTENT</label>
                <div class="NgxEditor__Wrapper card card-light card-body">
                  <ngx-editor-menu [editor]="editor"></ngx-editor-menu>
                  <ngx-editor
                    [editor]="editor"
                    formControlName="pageContent"
                    [disabled]="false"
                    [placeholder]="'ARTICLE_PLACEHOLDER_CONTENT' | translate"
                  ></ngx-editor>
                </div>
              </div>
            </div>

            <!--                        <div class="form-group">-->
            <!--                            <label class="form-title-label" for="thumbnail" translate-->
            <!--                            >ARTICLE_LBL_THUMBNAIL</label-->
            <!--                            >-->
            <!--                            <app-file-upload-->
            <!--                                    [name]="'thumbnail'"-->
            <!--                                    formControlName="thumbnail"-->
            <!--                            ></app-file-upload>-->
            <!--                        </div>-->

            <!--                        <div class="form-group">-->
            <!--                            <label class="form-title-label" for="background" translate-->
            <!--                            >ARTICLE_LBL_BACKGROUND</label-->
            <!--                            >-->
            <!--                            <app-file-upload-->
            <!--                                    [name]="'background'"-->
            <!--                                    formControlName="background"-->
            <!--                            ></app-file-upload>-->
            <!--                        </div>-->
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
