import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { combineLatest, filter, map, Observable } from 'rxjs';
import { PaginationService } from '../../store/pagination/pagination.service';
import { PaginationConfig } from '../../types';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
})
export class PaginationComponent implements OnInit {
  paginationConfig$: Observable<PaginationConfig>;

  constructor(private readonly paginationService: PaginationService) {}

  ngOnInit(): void {
    this.paginationConfig$ = combineLatest([
      this.paginationService.getTotalCount(),
      this.paginationService.getPageSize(),
      this.paginationService.getTotalPages(),
      this.paginationService.getPageIndex(),
    ]).pipe(
      filter(
        ([totalCount, pageSize, totalPages, pageIndex]) =>
          !!totalCount && !!pageSize && !!totalPages && !!pageIndex
      ),
      map(([totalCount, pageSize, totalPages, pageIndex]) => ({
        totalCount,
        pageSize,
        totalPages,
        pageIndex,
      }))
    );
  }
  onPageChange(input: number) {
    this.paginationService.setPageIndex(input || 1);
  }
}
