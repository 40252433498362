import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthentificationGuard } from 'src/app/shared/guard/authentification-guard';
import { HeaderMenuType } from 'src/app/shared/store/header/type';
import { SubHeaderType } from 'src/app/shared/store/subheader/type';
import { LeadListComponent } from './lead-list/lead-list.component';
import { LeadDetailsComponent } from './lead-details/lead-details.component';
import { Entity } from 'src/app/shared/store/view/types';
import { LeadEditComponent } from './lead-edit/lead-edit.component';
import { LeadCreateComponent } from './lead-create/lead-create.component';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';

const routes: Routes = [
  {
    path: 'Sales/Leads',
    data: {
      breadcrumb: { label: ' ', alias: 'leadList' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: LeadListComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.Lead,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: LeadDetailsComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.Lead,
          breadcrumb: { label: ' ', alias: 'leadDetails' },
        },
      },
      {
        path: 'Edit/:id',
        component: LeadEditComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.Lead,
          breadcrumb: { label: ' ', alias: 'leadEdit' },
        },
      },
      {
        path: 'Create/:category',
        component: LeadCreateComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.Lead,
          breadcrumb: { label: ' ', alias: 'leadCreate' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class LeadRoutingModule {}
