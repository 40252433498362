import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { CountyApiService } from "./county.api.services";
import { CountyEffects } from "./county.effects";
import { ICountyState, countyInitialState, countyReducer } from "./county.reducer";
import { CountyService } from "./county.service";
import { countyFeatureKey } from "./types";

@NgModule({
    imports:[
        StoreModule.forFeature<ICountyState>(countyFeatureKey, countyReducer,{
            initialState: countyInitialState,
        }),
        EffectsModule.forFeature([CountyEffects])
    ],
    providers:[CountyApiService, CountyService]
})
export class StoreCountyModule{}