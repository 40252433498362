import { Campaign, CampaignSimplified, CreateCampaignFormModel } from './types';
import { GenericState, IState } from '../../../common/store/types';
import { onApiCall } from '../../../common/store/generic.reducer';
import { campaignActions } from './campaign.action';
import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { CreateSalesTeamFormModel, SalesTeam } from '../sales-team/types';
import { salesTeamActions } from '../sales-team/sales-team.action';

export interface ICampaignState extends IState<Campaign> {
  getById: GenericState<Campaign>;
  getList: GenericState<CampaignSimplified[]>;
  getCampaign: GenericState<CreateCampaignFormModel>;
  postCampaign: GenericState<Campaign>;
  updateCampaign: GenericState<Campaign>;
}

export const campaignInitialState: ICampaignState = {
  getById: new GenericState<Campaign>(),
  getList: new GenericState<CampaignSimplified[]>(),
  getCampaign: new GenericState<CreateCampaignFormModel>(),
  postCampaign: new GenericState<Campaign>(),
  updateCampaign: new GenericState<Campaign>(),
};

const campaignReducers = [
  ...onApiCall<Campaign>(campaignActions.getById, 'getById'),
  ...onApiCall<CampaignSimplified[]>(campaignActions.getList, 'getList'),
  ...onApiCall<CreateCampaignFormModel>(
    campaignActions.getCampaignModel,
    'getCampaignModel',
  ),
  ...onApiCall<Campaign>(campaignActions.postCampaign, 'postCampaign'),
  ...onApiCall<Campaign>(campaignActions.updateCampaign, 'updateCampaign'),
];

export const campaignReducer = createReducer(
  campaignInitialState,
  ...campaignReducers,
) as ActionReducer<ICampaignState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return campaignReducer(state, action);
}
