export const campaignFeatureKey = 'campaign';

export type Campaign = {
  id: number;
  name: string;
  endDate: Date;
  startDate: Date;
};

export type CampaignSimplified = {
  id: number;
  name: string;
};

export type CampaignPost = {
  name: string;
  endDate: Date | null;
  startDate: Date | null;
};

export type CampaignUpdate = {
  id: number;
  name: string;
  endDate: Date | null;
  startDate: Date | null;
};

export type CreateCampaignFormModel = {
  name: string;
  endDate: Date;
  startDate: Date;
};

/*
    {
      "id": 0,
      "name": "string",
      "endDate": "2023-11-28T16:48:08.036Z",
      "startDate": "2023-11-28T16:48:08.036Z"
    }
 */
