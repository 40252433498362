import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { FeaturesModule } from './features/features.module';
import { LayoutModule } from './layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { ContractDetailsDriverComponent } from './features/sale/contract/contract-details/contract-details-tab/contract-details-driver/contract-details-driver.component';
import { ContractDetailsVehicleComponent } from './features/sale/contract/contract-details/contract-details-tab/contract-details-vehicle/contract-details-vehicle.component';
import { ContractDetailsCarInsuranceComponent } from './features/sale/contract/contract-details/contract-details-tab/contract-details-car-insurance/contract-details-car-insurance.component';
import { ContractDetailsHouseComponent } from './features/sale/contract/contract-details/contract-details-tab/contract-details-house/contract-details-house.component';

@NgModule({
  declarations: [
    AppComponent,
    ContractDetailsDriverComponent,
    ContractDetailsVehicleComponent,
    ContractDetailsCarInsuranceComponent,
    ContractDetailsHouseComponent,
  ],
  imports: [
    BrowserModule,
    CoreModule,
    FeaturesModule,
    LayoutModule,
    SharedModule,
    AppRoutingModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
