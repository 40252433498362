import { createApiCallActions } from "src/app/common/store/generic.actions";
import { Currency } from "./types";

export const currencyActions = {
  getById: createApiCallActions<Currency>('currency', 'getById'),
  getList: createApiCallActions<Currency[]>('currency', 'getList'),
  postCurrency: createApiCallActions<any>('currency','postCurrency'),
  updateCurrency: createApiCallActions<any>('currency', 'updateCurrency')
};

export type CurrencyAction = typeof currencyActions;