<div class="sub-header" *ngIf="this.contractBase$ | async as contractBase">
  <div class="sub-header-row border-top align-items-center px-1">
    <div class="row">
      <div class="col" *ngIf="this.view$ | async as view">
        <a class="btn-sm btn btn-primary mr-2" translate>
          GLOBAL_BTN_VALIDATION
        </a>
        <a class="btn-sm btn btn-outline-primary" translate>
          GLOBAL_BTN_REGULARIZE
        </a>
        <a class="btn-sm btn btn-outline-primary" translate>
          GLOBAL_BTN_CANCEL
        </a>
      </div>

      <div
        class="col-auto state-breadcrumb-col"
        *ngIf="this.contractStatusFlows$ | async as contractStatus"
      >
        <div class="state-breadcrumb">
          <li
            *ngFor="let contractStatus of contractStatus"
            [ngClass]="
              contractBase.statusId == contractStatus.status ? 'active' : ''
            "
          >
            {{ contractStatus.label }}
          </li>
        </div>
      </div>
    </div>
  </div>
</div>
