import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {ConfigurationRoutingModule} from './configuration-routing.module';
import {CurrencyModule} from './currency/currency.module';
import {UserModule} from './user/user.module';
import {ZipCodeModule} from './zipcode/zipcode.module';
import {CountyModule} from './county/county.module';
import {CountryModule} from './country/country.module';
import {JobModule} from '../human-resources/job/job.module';
import {SalesTeamModule} from "../sale/sales-team/sales-team.module";
import {CampaignModule} from "../sale/campaign/campaign.module";


@NgModule({
    declarations: [],
    imports: [
        CommonModule,
        ConfigurationRoutingModule,
        CurrencyModule,
        UserModule,
        ZipCodeModule,
        CountyModule,
        CountryModule,
        JobModule,

    ]
})
export class ConfigurationModule {
}
