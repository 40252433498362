import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { appReadyAction } from "./store-app.actions";
import { AppState } from "./types";

@Injectable()
export class AppService {
  constructor(private readonly store: Store<AppState>) {}

  /**
   * @function
   * @description Dispatch an AppReady action.
   */
  public appReady = (): void => {
    this.store.dispatch(appReadyAction());
  };

  /**
   * @function
   * @description A global current date observable refreshed every seconds.
   */
  // public time$: Observable<CommonDate> = timer(0, 1000).pipe(
  //   map((_) => DateTz())
  // );
}
