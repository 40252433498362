import { CommonModule } from '@angular/common';
import { ConfigService } from './core.config.service';
import { CoreService } from './core.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { CommonLoaderModule } from '../common/loader';
import { CommonTranslateModule } from '../common/translate/translate.module';
import { AuthConfigModule } from '../common/authentification/auth-config.module';
import { NgBootstrapModule } from '../common/ngbootstrap/ngbootstrap.module';
import { GridModule } from '../common/ag-grid/ag-grid.module';
import { HtmlEditorModule } from '../common/html-editor/html-editor.module';
import { ChartJsModule } from '../common/chartsjs/chartjs.module';
import { MultiselectDropdownModule } from '../common/multiselect-dropdown/multiselect-dropdown.module';
import { XngBreadcrumbModule } from '../common/xng-breadcrumb/xng-breadcrumb.module';
import { TypeaheadModule } from '../common/ng-select/ng-select.module';
import { MaskModule } from '../common/ngx-mask/ngx-mask.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonLoaderModule,
    CommonTranslateModule.forRoot({ locales: ['fr', 'en'] }),
    AuthConfigModule,
    NgBootstrapModule,
    GridModule,
    HtmlEditorModule,
    ChartJsModule,
    MultiselectDropdownModule,
    XngBreadcrumbModule,
    TypeaheadModule,
    MaskModule,
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CommonLoaderModule,
    CommonTranslateModule,
    AuthConfigModule,
    NgBootstrapModule,
    GridModule,
    HtmlEditorModule,
    ChartJsModule,
    MultiselectDropdownModule,
    XngBreadcrumbModule,
    TypeaheadModule,
    MaskModule,
  ],

  providers: [CoreService, ConfigService],
})
export class CoreModule {}
