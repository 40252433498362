import { GenericState, IState } from 'src/app/common/store/types';
import { BankAccount } from './types';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { bankAccountActions } from './bank-account.actions';
import { Action, ActionReducer, createReducer } from '@ngrx/store';

export interface IBankAccountState extends IState<BankAccount> {
  getById: GenericState<BankAccount>;
}

export const bankAccountInitialState: IBankAccountState = {
  getById: new GenericState<BankAccount>(),
};

const bankAccountReducers = [
  ...onApiCall<BankAccount>(bankAccountActions.getById, 'getById'),
];

export const bankAccountReducer = createReducer(
  bankAccountInitialState,
  ...bankAccountReducers
) as ActionReducer<IBankAccountState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return bankAccountReducer(state, action);
}
