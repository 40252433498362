import {createApiCallActions} from "../../../common/store/generic.actions";
import {ListItem, ReferencesItem} from "./types";

export const typeaheadActions = {
  get: createApiCallActions<ListItem[]>('typeahead','get'),
  getReferencesItems: createApiCallActions<ReferencesItem[]>('typeahead','getReferencesItems'),
  quickAdd: createApiCallActions<ListItem>('typeahead','quickAdd')
};

export type TypeaheadAction = typeof typeaheadActions;
