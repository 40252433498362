import { GenericState, IState } from '../../../common/store/types';
import { CreateSalesTeamFormModel, SalesTeam, SalesTeamBase } from './types';
import { onApiCall } from '../../../common/store/generic.reducer';
import { salesTeamActions } from './sales-team.action';
import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { SalesTeamMember } from '../sales-team-member/types';
import { jobActions } from '../job/job.action';

export interface ISalesTeamState extends IState<SalesTeam> {
  getById: GenericState<SalesTeam>;
  getList: GenericState<SalesTeamBase[]>;
  getSalesTeamMemberList: GenericState<SalesTeamMember[]>;
  getSalesTeamTypeAheadList: GenericState<SalesTeamBase[]>;
  getSalesTeamMemberTypeAheadList: GenericState<SalesTeamMember[]>;
  getSalesTeamModel: GenericState<CreateSalesTeamFormModel>;
  postSalesTeam: GenericState<SalesTeam>;
  updateSalesTeam: GenericState<SalesTeam>;
}

export const salesTeamInitialState: ISalesTeamState = {
  getById: new GenericState<SalesTeam>(),
  getList: new GenericState<SalesTeamBase[]>(),
  getSalesTeamMemberList: new GenericState<SalesTeamMember[]>(),
  getSalesTeamTypeAheadList: new GenericState<SalesTeamBase[]>(),
  getSalesTeamMemberTypeAheadList: new GenericState<SalesTeamMember[]>(),
  getSalesTeamModel: new GenericState<CreateSalesTeamFormModel>(),
  postSalesTeam: new GenericState<SalesTeam>(),
  updateSalesTeam: new GenericState<SalesTeam>(),
};

const salesTeamReducers = [
  ...onApiCall<SalesTeam>(salesTeamActions.getById, 'getById'),
  ...onApiCall<SalesTeamBase[]>(salesTeamActions.getList, 'getList'),
  ...onApiCall<SalesTeamMember[]>(
    salesTeamActions.getSalesTeamMemberList,
    'getSalesTeamMemberList',
  ),
  ...onApiCall<SalesTeamBase[]>(
    salesTeamActions.getSalesTeamTypeAheadList,
    'getSalesTeamTypeAheadList',
  ),
  ...onApiCall<SalesTeamMember[]>(
    salesTeamActions.getSalesTeamMemberTypeAheadList,
    'getSalesTeamMemberTypeAheadList',
  ),
  ...onApiCall<CreateSalesTeamFormModel>(
    salesTeamActions.getSalesTeamModel,
    'getSalesTeamModel',
  ),
  ...onApiCall<SalesTeam>(salesTeamActions.postSalesTeam, 'postSalesTeam'),
  ...onApiCall<SalesTeam>(salesTeamActions.updateSalesTeam, 'updateSalesTeam'),
];

export const salesTeamReducer = createReducer(
  salesTeamInitialState,
  ...salesTeamReducers,
) as ActionReducer<ISalesTeamState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return salesTeamReducer(state, action);
}
