import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { CountyRoutingModule } from "./county-routing.module";
import { CountyComponent } from "./county-list/county-list.component";

@NgModule({
    declarations:[
        CountyComponent,
    ],
    imports:[
        CommonModule,
        SharedModule,
        CountyRoutingModule
    ]
})
export class CountyModule{}