import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { map, Observable } from 'rxjs';
import { AuthentificationService } from 'src/app/shared/authentification/authentification.service';
import { ErrorService } from '../error';

@Injectable({
  providedIn: 'root',
})
export class AuthentificationGuard {
  constructor(
    private readonly authService: AuthentificationService,
    private readonly router: Router,
    private readonly errorService: ErrorService
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (this.authService.isAuthenticated()) {
      return true;
    } else {
      // return this.errorService.unauthorized(state.url, true);
      return this.router.navigate(['/Login'], {
        queryParams: { returnUrl: state.url },
      });
    }
  }
}
