import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ReportingApiService } from './reporting.api.services';
import { ReportingEffects } from './reporting.effects';
import {
  IReportingState,
  reportingReducer,
  reportingInitialState,
} from './reporting.reducer';
import { ReportingService } from './reporting.service';
import { reportingFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IReportingState>(
      reportingFeatureKey,
      reportingReducer,
      {
        initialState: reportingInitialState,
      }
    ),
    EffectsModule.forFeature([ReportingEffects]),
  ],
  providers: [ReportingApiService, ReportingService],
})
export class StoreReportingModule {}
