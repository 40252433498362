import { Action, ActionReducer, createReducer } from '@ngrx/store';
import {
  onApiCall,
  onArrayApiCall,
} from 'src/app/common/store/generic.reducer';
import { GenericState, IState } from 'src/app/common/store/types';
import { PageResult } from '../../types';
import { countyActions } from './county.actions';
import { County } from './types';

export interface ICountyState extends IState<County> {
  get: GenericState<County[]>;
  getById: GenericState<County>;
  getPaged: GenericState<PageResult<County>>;
}

export const countyInitialState: ICountyState = {
  get: new GenericState<County[]>(),
  getById: new GenericState<County>(),
  getPaged: new GenericState<PageResult<County>>(),
};

const countyReducers = [
  ...onArrayApiCall<County>(countyActions.get, 'get'),
  ...onApiCall<County>(countyActions.getById, 'getById'),
  ...onApiCall<PageResult<County>>(countyActions.getPaged, 'getPaged'),
];

export const countyReducer = createReducer(
  countyInitialState,
  ...countyReducers
) as ActionReducer<ICountyState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return countyReducer(state, action);
}
