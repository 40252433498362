import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ViewAction, viewActions } from './view.actions';
import { ViewApiService } from './view.api.services';
import { IViewState } from './view.reducers';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';

@Injectable()
export class ViewEffects {
  constructor(
    private readonly store: Store<IViewState>,
    private readonly viewApiService: ViewApiService,
    private readonly action$: Actions<ViewAction>,
  ) {}

  getViews = createApiCallEffects(
    this,
    this.action$,
    viewActions.getViews,
    this.viewApiService.getViews,
  );

  getEnumValues = createApiCallEffects(
    this,
    this.action$,
    viewActions.getEnumValues,
    this.viewApiService.getEnumValues,
  );
}
