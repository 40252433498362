import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LeadService } from 'src/app/shared/store/lead/lead.service';
import { HouseInsuranceLeadModel } from 'src/app/shared/store/lead/types';

@Component({
  selector: 'app-lead-details-house',
  templateUrl: './lead-details-house.component.html',
  styleUrls: ['./lead-details-house.component.scss'],
})
export class LeadDetailsHouseComponent implements OnInit {
  constructor(private readonly leadService: LeadService) {}

  houseDetail$: Observable<HouseInsuranceLeadModel>;

  ngOnInit(): void {
    this.houseDetail$ = this.leadService.getHouseDetail.value$;
  }
}
