import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { matchPasswordValidator } from '../../../shared/validators/match-password.validator';
import { customValidator } from 'src/app/shared/validators/custom-validator.validators';
import { AuthenticationService } from '../../../shared/store/authentication/authentication.service';

@UntilDestroy()
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  host: { class: 'connexion-form p-4 col-12 col-sm-8' },
})
export class ResetPasswordComponent implements OnInit {
  showErrorLogin: any;

  paramsSubscription$: Subscription;
  email: string | undefined;
  token: string | undefined;
  msgError: string;
  css: string = 'alert-danger';

  constructor(
    private readonly route: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
  ) {}

  resetPasswordForm: FormGroup = new FormGroup({
    password: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(6),
      customValidator(/\d/),
      customValidator(/[A-Z]/),
      customValidator(/[a-z]/),
    ]),
    passwordConfirm: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(6),
      customValidator(/\d/),
      customValidator(/[A-Z]/),
      customValidator(/[a-z]/),
    ]),
  });

  ngOnInit(): void {
    this.resetPasswordForm.setValidators(matchPasswordValidator);
    this.resetPasswordForm.updateValueAndValidity();

    this.email = this.route.snapshot.queryParams['email'];
    this.token = this.route.snapshot.queryParams['token'];

    if (this.email == undefined || this.token == undefined) {
      this.msgError = "Le lien utilisé n'est pas valide.";
      this.showErrorLogin = true;
      this.css = 'alert-danger';
    }
  }

  onSubmit() {
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordForm.markAllAsTouched();
      return;
    }

    if (this.email == undefined) return;
    if (this.token == undefined) return;

    let result$ = this.authenticationService.resetPassword.call({
      email: this.email,
      token: this.token.split(' ').join('+'), //usefull because '+' are ' ' and throw 'invalid token'
      password: this.resetPasswordForm.value.password!,
    });

    result$
      .pipe(
        // catchError(e => { return e }),
        untilDestroyed(this),
      )
      .subscribe({
        next: (result) => {
          if (result !== undefined) {
            this.css = 'alert-success';
            this.msgError = 'Mot de passe changé.';
            this.showErrorLogin = true;
            this.resetPasswordForm.reset();
            this.router.navigate(['/']);
          }
        },
        // error: (error) => {
        //     this.css = 'alert-danger';
        //     this.msgError = error.error;
        //     this.showErrorLogin = true;
        // }
      });

    console.log('password reset');
  }
}
