import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { ButtonType, SubHeaderOption, SubHeaderType } from './type';
import { ErrorService } from '../../error';

@Injectable({
  providedIn: 'root',
})
export class SubHeaderApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService
  ) {}

  getSubHeaderOption = (key: SubHeaderType): Observable<SubHeaderOption> => {
    switch (key) {
      case SubHeaderType.Home:
        return of({
          id: 0,
          title: 'Accueil',
          buttons: [],
          searchView: false,
          breadcrumb: false,
        }).pipe();
      case SubHeaderType.Contract:
        return of({
          id: 0,
          title: "Demandes d'adhésion",
          buttons: [
            {
              title: 'Créer',
              route: '',
              actionName: '',
              type: ButtonType.Primary,
            },
          ],
          searchView: true,
          breadcrumb: false,
        }).pipe();
      case SubHeaderType.ContractDetail:
        return of({
          id: 0,
          title: "Demande d'adhésion",
          buttons: [
            {
              title: 'Modifier',
              route: '',
              actionName: '', //zipCodeEdit
              type: ButtonType.Primary,
            },
            {
              title: 'Créer',
              route: '/Configuration/ContractRequests/Create',
              actionName: '', //navigate
              type: ButtonType.Light,
            },
          ],
          searchView: false,
          breadcrumb: false,
        });
      case SubHeaderType.ZipCode:
        return of({
          id: 0,
          title: 'Codes postaux',
          buttons: [
            {
              title: 'Créer',
              route: '',
              actionName: '',
              type: ButtonType.Primary,
            },
          ],
          searchView: true,
          breadcrumb: false,
        }).pipe();
      case SubHeaderType.ZipCodeDetail:
        return of({
          id: 0,
          title: 'Code postal détail',
          buttons: [
            {
              title: 'Modifier',
              route: '',
              actionName: '', //zipCodeEdit
              type: ButtonType.Primary,
            },
            {
              title: 'Créer',
              route: '/Configuration/ZipCodes/Create',
              actionName: '', //navigate
              type: ButtonType.Light,
            },
          ],
          searchView: false,
          breadcrumb: false,
        }).pipe();
      case SubHeaderType.Employee:
        return of({
          id: 0,
          title: 'Employés',
          buttons: [
            {
              title: 'Créer',
              route: '/Configuration/Employees/Create',
              actionName: '', //navigate
              type: ButtonType.Primary,
            },
          ],
          searchView: true,
          breadcrumb: false,
        }).pipe();
      case SubHeaderType.EmployeeDetail:
        return of({
          id: 0,
          title: 'Employé détail',
          buttons: [
            {
              title: 'Modifier',
              route: '',
              actionName: 'employeeEdit',
              type: ButtonType.Primary,
            },
            {
              title: 'Créer',
              route: '/HumanResources/Employees/Create',
              actionName: '', //navigate
              type: ButtonType.Light,
            },
          ],
          searchView: false,
          breadcrumb: false,
        }).pipe();
      case SubHeaderType.PasswordManage:
        return of({
          id: 0,
          title: '',
          buttons: [
            {
              title: 'Sauvegarder',
              route: '/',
              actionName: 'changePassword',
              type: ButtonType.Primary,
            },
            {
              title: 'Annuler',
              route: '/',
              actionName: 'navigate',
              type: ButtonType.Light,
            },
          ],
          searchView: false,
          breadcrumb: false,
        }).pipe();
      default:
        return of().pipe();
    }
  };
}
