import { Component, OnInit } from '@angular/core';
import { NotificationService } from '../../store/notification/notification.service';
import { Observable } from 'rxjs';
import {
  Notification,
  NotificationStatus,
  NotificationType,
} from '../../store/notification/types';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
})
export class ToastComponent implements OnInit {
  notifications$: Observable<Notification[]>;

  constructor(private readonly notificationService: NotificationService) {}

  ngOnInit(): void {
    this.notifications$ = this.notificationService.getNotifications();
  }

  remove(notification: Notification) {
    this.notificationService.remove(notification);
  }

  getNotificationStyle(notificationStatus: NotificationStatus): string {
    switch (notificationStatus) {
      case NotificationStatus.Info:
        return 'info';
      case NotificationStatus.Warning:
        return 'warning';
      case NotificationStatus.Error:
        return 'danger';
      case NotificationStatus.Success:
      default:
        return 'success';
    }
  }

  protected readonly NotificationType = NotificationType;
}
