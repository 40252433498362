import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthentificationGuard } from 'src/app/shared/guard/authentification-guard';
import { HeaderMenuType } from 'src/app/shared/store/header/type';
import { SubHeaderType } from 'src/app/shared/store/subheader/type';
import { ArticleCreateComponent } from './article-create/article-create.component';
import { ArticleDetailComponent } from './article-detail/article-detail.component';
import { ArticleListComponent } from './article-list/article-list.component';
import { ArticleEditComponent } from './article-edit/article-edit.component';
import { Entity } from 'src/app/shared/store/view/types';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';

const routes: Routes = [
  {
    path: 'Content/Articles',
    data: {
      breadcrumb: { label: ' ', alias: 'articleList' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: ArticleListComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Content,
          entity: Entity.Article,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: ArticleDetailComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Content,
          entity: Entity.Article,
          breadcrumb: { label: ' ', alias: 'articleDetails' },
        },
      },
      {
        path: 'Create',
        component: ArticleCreateComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Content,
          entity: Entity.Article,
          breadcrumb: { label: ' ', alias: 'articleCreate' },
        },
      },
      {
        path: 'Edit/:id',
        component: ArticleEditComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Content,
          entity: Entity.Article,
          breadcrumb: { label: ' ', alias: 'articleEdit' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ArticleRoutingModule {}
