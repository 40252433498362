<!--https://paulund.co.uk/add-telephone-number-links-with-html5-->
<ng-container *ngIf="this.isLinkEnabled" >
  <span><a href="tel:{{this.phoneNumber}}"> {{ this.phoneNumber | phoneNumber }}</a></span>
</ng-container>
<ng-container *ngIf="!this.isLinkEnabled" >
  <span> {{ this.phoneNumber | phoneNumber }}</span>
</ng-container>



