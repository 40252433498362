import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { UserRoutingModule } from './user-routing.module';
import { UserListComponent } from './user/user-list/user-list.component';
import { UserCreateComponent } from './user/user-create/user-create.component';
import { UserDetailComponent } from './user/user-detail/user-detail.component';
import { UserEditComponent } from './user/user-edit/user-edit.component';

@NgModule({
  declarations: [
    UserListComponent, 
    UserCreateComponent,
    UserDetailComponent,
    UserEditComponent,
  ],
  imports: [
    CommonModule, 
    SharedModule, 
    UserRoutingModule
  ],
})
export class UserModule {}
