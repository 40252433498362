import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthentificationGuard } from '../../../shared/guard/authentification-guard';
import { HeaderMenuType } from '../../../shared/store/header/type';
import { Entity } from '../../../shared/store/view/types';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { CampaignDetailsComponent } from './campaign-details/campaign-details.component';
import { CampaignEditComponent } from './campaign-edit/campaign-edit.component';
import { CampaignCreateComponent } from './campaign-create/campaign-create.component';

const routes: Routes = [
  {
    path: 'Sales/Campaigns',
    data: {
      breadcrumb: { label: ' ', alias: 'campaignList' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: CampaignListComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.Campaign,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: CampaignDetailsComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.Campaign,
          breadcrumb: { label: ' ', alias: 'campaignDetails' },
        },
      },
      {
        path: 'Create',
        component: CampaignCreateComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.Campaign,
          breadcrumb: { label: ' ', alias: 'campaignCreate' },
        },
      },
      {
        path: 'Edit/:id',
        component: CampaignEditComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.Campaign,
          breadcrumb: { label: ' ', alias: 'campaignEdit' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CampaignRoutingModule {}
