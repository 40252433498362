import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import {filter, Observable, of} from 'rxjs';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { User } from 'src/app/shared/store/user/type';
import { UserService } from 'src/app/shared/store/user/user.service';
import {BreadcrumbService} from "xng-breadcrumb";
import {View} from "../../../../../shared/store/view/types";
import {ViewService} from "../../../../../shared/store/view/views.service";

@UntilDestroy()
@Component({
  selector: 'app-user-edit',
  templateUrl: './user-edit.component.html',
  styleUrls: ['./user-edit.component.scss']
})
export class UserEditComponent implements OnInit, OnDestroy{

  user$: Observable<User>;
  userForm: FormGroup;

  dropdownList: string[] = [];
  selectedItems: string[] = [];
  dropdownSettings:IDropdownSettings = {};

  rolesList$: Observable<string>;
  view$:Observable<View>;

  constructor(
    private readonly userService: UserService,
    private readonly subHeaderService:SubHeaderService,
    private readonly route:ActivatedRoute,
    private readonly router:Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService:ViewService
  ) {
    this.user$ = this.userService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
    .getTriggerAction()
    .pipe(untilDestroyed(this))
    .subscribe((x) => {
      if (x == 'userUpdate') this.onSubmit();
      if (x == 'userDetails') {
        this.router.navigate(['Configuration/Users/Details/', this.route.snapshot.params['id']]);
        this.subHeaderService.setTriggerAction('');
      }
    });

    this.userService.getById.call(this.route.snapshot.params['id']);

    this.user$.pipe(
      untilDestroyed(this),
      filter((x) => !!x)
    ).subscribe((x) => {
      this.breadcrumbService.set('@userEdit', x.contactName)
    });
    this.view$.pipe(
      untilDestroyed(this),
      filter((x) => !!x)
    ).subscribe((x) => {
      this.breadcrumbService.set('@userList', x.title);
    });

    this.userForm = new FormGroup({
      // name: new FormControl('',[validators.required, validators.maxLength(80)]),
    });

    this.user$.pipe().subscribe((x) => {
      this.userForm.patchValue({
        // name: x.name
      })
    })

    this.rolesList$ = of('Admin', 'Webmaster', 'Commercial');

    this.rolesList$.subscribe(result => {
      this.dropdownList.push(result);
      console.log(result)
    })
    this.user$.subscribe(user => {
      this.selectedItems.push(...user.rolesLoc);
    });
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_text',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 5,
      allowSearchFilter: false
    };

  }
  onSubmit() {
    throw new Error('Method not implemented.');
  }

  onItemSelect(item: any) {
    console.log(item);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  ngOnDestroy(): void {
    this.userService.getById.reset();
    this.breadcrumbService.set('@userDetails', ' ');
  }

}

