import {IEmployeeState} from "./employee.reducer";
import {createFeatureSelector} from "@ngrx/store";
import {employeeFeatureKey} from "./types";
import {createApiCallSelectors} from "../../../common/store/generic.selectors";

const employeeFeatureState =
  createFeatureSelector<IEmployeeState>(employeeFeatureKey);

export const employeeSelectors = {
  getById: createApiCallSelectors<number, IEmployeeState>(
    employeeFeatureState,
    'getById'
  )
}
