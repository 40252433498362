export const jobFeatureKey = 'job';
export type Job = {
    id: number;
    name: string;
}

export class JobPost {
    constructor(
        name: string
    ) {
       this.name = name;    
    }
    name: string;
}

export class JobUpdate {
    id: number;
    name: string;
}