<div
  class="sub-header"
>
  <div class="sub-header-row border-top align-items-center px-1">
    <div class="row">
      <div class="col" *ngIf="this.view$ |async as view">
        <ng-container *ngIf="this.article$ | async as article">
          <a
            *ngIf="!article.publicationDate"
            class="btn-sm btn btn-primary mr-2"
            (click)="triggerAction('articlePublish')"
            translate
          >
            ARTICLE_LBL_PUBLISH
          </a>
          <ng-container *ngIf="article.publicationDate">
            <a

              class="btn-sm btn btn-outline-primary"
              (click)="triggerAction('articleUnpublish')"
              translate
            >
              ARTICLE_LBL_UNPUBLISH
            </a>
            <a
              href="{{this.comparanooUrl}}/guide-mutuelle-sante/{{article.url}}"
              target="_blank"
              class="btn-sm btn btn-outline-primary"
              translate
            >
              ARTICLE_LBL_VIEW_ONLINE
            </a>
          </ng-container>
        </ng-container>
      </div>

      <div class="col-auto state-breadcrumb-col">
        <div class="state-breadcrumb">
          <li class="hiddn"> No</li>
        </div>
      </div>
    </div>
  </div>
</div>
