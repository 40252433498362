export const reportingFeatureKey = 'reporting';
export type LabelWithValue = {
  label: string;
  value: number;
};
export type LabelByDayAndHoursRange = {
  day: string;
  dayLabel: string;
  hourRange: string;
  value: number;
};
export type InputReporting = {
  parameters: string[];
  query: Map<string, string>;
};
export interface MatrixDataPointExtended {
  x: number;
  y: number;
  v: number;
}
export type Period = {
  value: string;
  label: string;
  startDate: Date;
  endDate: Date;
  group: string;
};
