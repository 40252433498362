<app-list-subheader></app-list-subheader>

<ng-container *ngIf="this.view$ | async as view">
  <ng-container *ngIf="this.viewType === 'TreeView'">
    <app-generic-grid [detail]="true" [isUniqueId]="true"></app-generic-grid>
  </ng-container>

  <ng-container *ngIf="this.viewType === 'KanbanView'">
    <app-kanban-view-lead></app-kanban-view-lead>
  </ng-container>
</ng-container>
