import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { ContractService } from '../../../../../../shared/store/contract/contract.service';
import { Observable } from 'rxjs';
import { ContractDetailsCarInsuranceComponent } from '../../../contract-details/contract-details-tab/contract-details-car-insurance/contract-details-car-insurance.component';
import { CarInsuranceContractModel } from '../../../../../../shared/store/contract/types';
import { filter, take } from 'rxjs/operators';

@Component({
  selector: 'app-contract-form-car-insurance',
  templateUrl: './contract-form-car-insurance.component.html',
  styleUrls: ['./contract-form-car-insurance.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ContractFormCarInsuranceComponent implements OnInit {
  carDetail$: Observable<CarInsuranceContractModel>;
  form: FormGroup;
  constructor(
    private parent: FormGroupDirective,
    private readonly contractService: ContractService,
    private formBuilder: FormBuilder,
  ) {
    this.carDetail$ = this.contractService.getCarDetail.value$;
  }

  ngOnInit(): void {
    this.form = this.parent.form;

    this.form.addControl(
      'carInsuranceFormGroup',
      this.formBuilder.group({
        carInsuranceCurrentInsuranceCompany: [undefined],
        carInsuranceInsuranceCoverage: [undefined],
        carInsuranceDriverBodyCoverage: [false],
        carInsuranceReplacementVehicleCoverage: [false],
        carInsuranceDriverPropertyCoverage: [false],
        carInsuranceBrandNewCoverage: [false],
        carInsuranceMoneyLossCoverage: [false],
      }),
    );

    this.carDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.form.patchValue({
          carInsuranceFormGroup: {
            carInsuranceCurrentInsuranceCompany: x.currentInsuranceCompany,
            carInsuranceInsuranceCoverage: x.insuranceCoverage,
            carInsuranceDriverBodyCoverage: x.driverBodyCoverage,
            carInsuranceReplacementVehicleCoverage:
              x.replacementVehicleCoverage,
            carInsuranceDriverPropertyCoverage: x.driverPropertyCoverage,
            carInsuranceBrandNewCoverage: x.brandNewCoverage,
            carInsuranceMoneyLossCoverage: x.moneyLossCoverage,
          },
        });
      });
  }
}
