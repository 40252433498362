<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <form [formGroup]="jobForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="jobForm">
      <button type="submit" hidden="hidden"></button>
      <div class="card-body">
        <div class="container-fluid">

          <div class="row">
            <div class="col-lg-10">
              <div class="form-group">
                <label class="form-title-label" for="name" translate>JOB_LBL_NAME</label>
                <input
                  class="form-control form-title"
                  id="name"
                  name="name"
                  [placeholder]="'JOB_PLACEHOLDER_NAME' | translate"
                  type="text"
                  value=""
                  formControlName="name"
                  checkFormFieldValidity
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
