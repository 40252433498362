import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Contact } from '../../../../../shared/store/contact/types';
import { ActivatedRoute, Router } from '@angular/router';
import { ContactService } from '../../../../../shared/store/contact/contact.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-contact-details-content',
  templateUrl: './contact-details-content.component.html',
  styleUrls: ['./contact-details-content.component.scss'],
})
export class ContactDetailsContentComponent implements OnInit, OnDestroy {
  contactId: number;
  contactDetail$: Observable<Contact>;

  @Input() modalId: number;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly contactService: ContactService,
    private readonly breadcrumbService: BreadcrumbService
  ) {
    this.contactDetail$ = this.contactService.getById.value$;
  }

  ngOnInit(): void {
    this.contactId = this.route.snapshot.params['id'];
    if (this.modalId) this.contactId = this.modalId;

    this.contactService.getById.call(this.contactId.toString());

    this.contactDetail$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@contactDetails', x.name);
      });
  }

  ngOnDestroy(): void {
    this.contactService.getById.reset();
    this.breadcrumbService.set('@contactDetails', ' ');
  }
}
