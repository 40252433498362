import { inject, Pipe, PipeTransform } from '@angular/core';
import { dateDiff, formatDate } from '../helper/datehelper';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'friendlyDate',
})
export class FriendlyDatePipe implements PipeTransform {
  constructor(private readonly translateService: TranslateService) {}

  transform(date: Date | DoubleDate | null): string {
    // console.log(date);

    if (date === null) return '';

    // Input
    let dateIn = new Date();
    let dateLocale = new Date();
    let dateNow = new Date();

    if (typeof date === 'object') {
      let dateDoubleDate = date as DoubleDate;

      if (!(dateDoubleDate.utcDate && dateDoubleDate.localeDate)) return '';

      dateIn = new Date(dateDoubleDate.utcDate);
      dateLocale = new Date(dateDoubleDate.localeDate);
    } else {
      dateIn = new Date(date);
      dateLocale = new Date(date);
    }

    let dateNowUtc = new Date(
      dateNow.getUTCFullYear(),
      dateNow.getUTCMonth(),
      dateNow.getUTCDate(),
      dateNow.getUTCHours(),
      dateNow.getUTCMinutes(),
      dateNow.getUTCSeconds(),
    );

    let result: string = '';

    // Process
    //let duration = new Date(dateNowUtc.getTime() - dateIn.getTime())

    let diff = dateDiff(dateIn, dateNowUtc);

    // console.log(diff);

    let durationDiffInSeconds = diff.diff / 1000;
    let durationDiffInMinutes = durationDiffInSeconds / 60;
    let durationDiffInHours = durationDiffInMinutes / 60;

    let durationInDays = diff.day;
    let durationInSeconds = diff.sec;
    let durationInMinutes = diff.minute;
    let durationInHours = diff.hour;

    // console.log(durationInDays);
    // console.log(durationDiffInSeconds);
    // console.log(durationDiffInMinutes);

    if (durationInDays < 1) {
      if (durationDiffInSeconds < 1)
        this.translateService
          .get('FRIENDLY_DATE_LBL_NOW')
          .subscribe((translation: string) => {
            result = translation;
          });
      //result = "A l'instant"; //     "LBL_AGED_DATE_NOW"
      else if (durationDiffInMinutes < 1)
        this.translateService
          .get('FRIENDLY_DATE_LBL_SECONDS')
          .subscribe((translation: string) => {
            result = translation;
          });
      //result = "Il y a moins d'une minute"; //     "LBL_AGED_DATE_SECONDS"
      else if (durationDiffInHours < 1) {
        this.translateService
          .get('FRIENDLY_DATE_LBL_MINUTES', { p0: durationInMinutes })
          .subscribe((translation: string) => {
            result = translation;
          });
        //result = `Il y a ${durationInMinutes}`; //     "LBL_AGED_DATE_MINUTES"
        result += durationInMinutes === 1 ? '' : 's';
      } else result = formatDate(dateLocale, 'ToShortTimeString');
    } else {
      if (durationInDays < 2)
        this.translateService
          .get('FRIENDLY_DATE_LBL_YESTERDAY', {
            p0: formatDate(dateLocale, 'ToShortTimeString'),
          })
          .subscribe((translation: string) => {
            result = translation;
          });
      //result = `Hier à ${formatDate(dateLocale, 'ToShortTimeString')}`; //     "LBL_AGED_DATE_YESTERDAY"
      else if (durationInDays < 7)
        this.translateService
          .get('FRIENDLY_DATE_LBL_DAY_AT_TIME', {
            p0: formatDate(dateIn, `ToLongDay`),
            p1: formatDate(dateLocale, 'ToShortTimeString'),
          })
          .subscribe((translation: string) => {
            result = translation;
          });
      //result = `${formatDate(dateIn, `ToLongDay`)} à ${formatDate(dateLocale, 'ToShortTimeString')}`; // "LBL_AGED_DATE_DAY_AT_TIME"
      else if (durationInDays < 14)
        this.translateService
          .get('FRIENDLY_DATE_LBL_DAYS', {
            p0: durationInDays,
          })
          .subscribe((translation: string) => {
            result = translation;
          });
      //result = `Il y a ${durationInDays} jours`; //     "LBL_AGED_DATE_DAYS"
      else if (dateNowUtc.getFullYear() - dateIn.getFullYear() < 1)
        this.translateService
          .get('FRIENDLY_DATE_LBL_WEEKS', {
            p0: Math.floor(durationInDays / 7),
          })
          .subscribe((translation: string) => {
            result = translation;
          });
      //result = `Il y a ${Math.floor(durationInDays / 7)} semaines`; //     "LBL_AGED_DATE_WEEKS"
      else if (
        dateNowUtc.getFullYear() - dateIn.getFullYear() < 2 &&
        Math.floor(durationInDays / (365.25 / 12)) < 12
      )
        this.translateService
          .get('FRIENDLY_DATE_LBL_MONTHS', {
            p0: Math.floor(durationInDays / (365.25 / 12)),
          })
          .subscribe((translation: string) => {
            result = translation;
          });
      //result = `Il y a ${Math.floor(durationInDays / (365.25 / 12))} mois`; //     "LBL_AGED_DATE_MONTHS"
      else
        this.translateService
          .get('FRIENDLY_DATE_LBL_YEARS', {
            p0: dateNowUtc.getFullYear() - dateIn.getFullYear(),
          })
          .subscribe((translation: string) => {
            result = translation;
          });
      //result = `Il y a ${dateNowUtc.getFullYear() - dateIn.getFullYear()} ans`; //     "LBL_AGED_DATE_YEARS"
    }

    return result;
  }
}

export class DoubleDate {
  localeDate: Date | null;
  utcDate: Date | null;
}
