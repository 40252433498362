import { Injectable } from '@angular/core';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { Store } from '@ngrx/store';
import { SalesTeamMember } from './types';
import { salesTeamMemberActions } from './sales-team-member.action';
import { salesTeamMemberSelectors } from './sales-team-member.selector';

@Injectable()
export class SalesTeamMemberService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<SalesTeamMember, string> = this.genericApiCall(
    salesTeamMemberActions.getById,
    salesTeamMemberSelectors.getById,
    true
  );
}
