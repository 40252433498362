import {
  Directive,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { FormService } from '../store/form/form.service';
import { debounceTime, Subscription } from 'rxjs';

@Directive({
  selector: 'form[hasUnsavedData]',
})
export class FormHasUnsavedDataDirective implements OnInit, OnDestroy {
  @Input() public hasUnsavedData!: AbstractControl;

  private subscription?: Subscription;
  constructor(
    private formGroup: FormGroupDirective,
    private readonly formService: FormService,
  ) {}

  ngOnInit(): void {
    // console.log(this.hasUnsavedData);

    this.subscription = this.hasUnsavedData.valueChanges
      .pipe(debounceTime(250))
      .subscribe((value) => {
        if (this.hasUnsavedData.dirty) {
          this.formService.setIsDirty('', true);
          return;
        }
        // If we get valueChanges while the form is not dirty it usually means we are programmatically setting
        // the control, so we need to reset its state in order to check for changes from the user
        // this.hasFormChangedService.setFormState(this.formId, value);
      });
  }

  ngOnDestroy(): void {
    this.formService.clear();
    this.subscription?.unsubscribe();
  }

  @HostListener('submit', ['$event.target'])
  onSubmit(target: any) {
    this.formService.clear();
    // console.log('isDirty ? : ' + this.formGroup.form.dirty);
  }

  @HostListener('window:beforeunload', ['$event'])
  public onBeforeUnload(event: BeforeUnloadEvent): void {
    if (this.formGroup.form.dirty) {
      event.preventDefault();
      event.returnValue = false;
    }
  }
}
