import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { PageResult } from '../../types';
import { Article } from './types';

export const articleActions = {
  getPaged: createApiCallActions<PageResult<Article>>('article', 'getPaged'),
  getById: createApiCallActions<Article>('article', 'getById'),
  postArticle: createApiCallActions<any>('article', 'postArticle'),
  publishArticle: createApiCallActions<any>('article', 'publishArticle'),
  unpublishArticle: createApiCallActions<any>('article', 'unpublishArticle'),
  updateArticle: createApiCallActions<any>('article', 'updateArticle'),
};

export type ArticleAction = typeof articleActions;
