import { Component, OnInit } from '@angular/core';
import { combineLatest, debounceTime, filter, Observable, of } from 'rxjs';
import { Avatar } from '../../store/user/type';
import { View } from '../../store/view/types';
import { PageResult } from '../../types';
import { ViewService } from '../../store/view/views.service';
import { PaginationService } from '../../store/pagination/pagination.service';
import { SearchService } from '../../store/search/search.service';
import { SubHeaderService } from '../../store/subheader/subheader.service';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { environment } from '../../../../environments/environment';
import { Domain, Filter } from '../../store/search/types';

@UntilDestroy()
@Component({
  selector: 'app-kanban-view-lead',
  templateUrl: './kanban-view-lead.component.html',
  styleUrls: ['./kanban-view-lead.component.scss'],
})
export class KanbanViewLeadComponent implements OnInit {
  currentAvatar$: Observable<Avatar>;

  view$: Observable<View>;

  data$: Observable<PageResult<any>>;
  viewType: string;

  constructor(
    private readonly viewService: ViewService,
    private readonly paginationService: PaginationService,
    private readonly searchService: SearchService,
    private readonly subHeaderService: SubHeaderService,
    private readonly router: Router,
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        if (x == 'TreeView') {
          this.viewType = x;
        }
        if (x == 'KanbanView') {
          this.viewType = x;
          this.callSearch();
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.data$ = this.searchService.search.value$;
    this.callSearch();

    this.data$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((pageModel) => {
        this.paginationService.setTotalCount(pageModel.totalCount);
        this.paginationService.setPageSize(pageModel.pageSize);
        this.paginationService.setTotalPages(pageModel.totalPages);
      });

    let avatar = new Avatar(
      '',
      [
        environment.comparanooapiurl,
        environment.features.thumbnail.url,
        environment.features.thumbnail.avatar,
      ].join(''),
      window
        .getComputedStyle(document.body)
        .getPropertyValue('--comment-avatar-bg')
        .replace('#', ''),
    );

    this.currentAvatar$ = of(avatar);
  }

  private callSearch() {
    combineLatest([
      this.viewService.getViews.value$,
      this.searchService.getActiveFilters(),
      this.paginationService.getPageIndex(),
      this.paginationService.getPageSize(),
      this.paginationService.getSortBy(),
      this.paginationService.getOrderBy(),
    ])
      .pipe(debounceTime(200), untilDestroyed(this))
      .subscribe(
        ([view, searchGroups, pageIndex, pageSize, sortBy, orderBy]) => {
          let filters = JSON.parse(JSON.stringify(searchGroups));

          //Filter the
          filters = filters.filter((filter: Filter) => {
            filter.domains = filter.domains.filter(
              (domain: Domain) => domain.isActive,
            );
            let result: boolean = false;
            filter.domains.forEach((domain: Domain) => {
              if (domain.isActive) {
                result = true;
              }
            });
            return result;
          });
          if (
            view != null &&
            view != undefined &&
            pageIndex != null &&
            pageIndex != undefined &&
            pageSize != null &&
            pageSize != undefined &&
            sortBy != null &&
            sortBy != undefined &&
            orderBy != null &&
            orderBy != undefined
          ) {
            this.searchService.search.call({
              filters: filters,
              url: view.searchView.url,
              pageIndex: pageIndex,
              pageSize: pageSize,
              sortBy: sortBy,
              orderBy: orderBy,
            });
          }
        },
      );
  }

  onCardClick(id: string): void {
    this.router.navigate([
      this.router.url.substring(0, this.router.url.lastIndexOf('/')),
      'Details',
      id,
    ]);
  }
}
