<!--<ng-container *ngIf="this.view$ | async as view">-->
  <ng-container *ngFor="let button of this.actions">
    <a
      *ngIf="button.mode == ActionMode.Navigate"
      routerLink="{{ button.route }}"
      [class]="button.buttonType | buttontypepipe"
      class="btn-sm btn"
      translate
    >
      {{ button.title }}
    </a>
    <a
      *ngIf="button.mode == ActionMode.Action"
      [class]="button.buttonType | buttontypepipe"
      class="btn-sm btn"
      (click)="triggerAction(button.actionName)"
      translate
    >
      {{ button.title }}
    </a>
  </ng-container>
<!--</ng-container>-->
