import { Actions } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ContactAction, contactActions } from './contact.actions';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { ContactApiService } from './contact.api.service';
import { IContactState } from './contact.reducer';

@Injectable()
export class ContactEffects {
  constructor(
    private readonly store: Store<IContactState>,
    private readonly contactApiService: ContactApiService,
    private readonly action$: Actions<ContactAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    contactActions.getById,
    this.contactApiService.getById,
  );

  postContact = createApiCallEffects(
    this,
    this.action$,
    contactActions.postContact,
    this.contactApiService.postContact,
  );

  updateContact = createApiCallEffects(
    this,
    this.action$,
    contactActions.updateContact,
    this.contactApiService.updateContact,
  );

  getContactFormModel = createApiCallEffects(
    this,
    this.action$,
    contactActions.getContactFormModel,
    this.contactApiService.getContactFormModel,
  );
}
