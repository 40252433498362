import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { SaleRoutingModule } from './sale-routing.module';
import { NgModule } from '@angular/core';
import { LeadModule } from './lead/lead.module';
import { ContractModule } from './contract/contract.module';
import { CampaignModule } from './campaign/campaign.module';
import { SalesDashboardModule } from './dashboard/sale-dashboard-module';
import {SalesTeamModule} from "./sales-team/sales-team.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    SaleRoutingModule,
    LeadModule,
    ContractModule,
    CampaignModule,
    SalesDashboardModule,
    SalesTeamModule,
  ],
  exports: [],
})
export class SaleModule {}
