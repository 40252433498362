import { AppStoreModule } from './store/store.module';
import { CoreModule } from '../core/core.module';
import { NgModule } from '@angular/core';
import { AuthentificationService } from './authentification/authentification.service';
import { FileUploadComponent } from './components/file-upload/file-upload.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { GenericGridComponent } from './components/generic-grid/generic-grid.component';
import { ToastComponent } from './components/toast/toast.component';
import { BadgeComponent } from './components/badge/badge/badge.component';
import { MenuDropdownComponent } from './components/menu-dropdown/menu-dropdown.component';
import { CustomActionsFlowComponent } from './components/custom-actions-flow/custom-actions-flow.component';
import { DocumentSubheaderComponent } from './components/document-subheader/document-subheader.component';
import { ListSubheaderComponent } from './components/list-subheader/list-subheader.component';
import { FavoriteSaveComponent } from './components/favorite-save/favorite-save.component';
import { SearchBarBadgeComponent } from './components/search-bar-badge/search-bar-badge.component';
import { SearchCustomFilterComponent } from './components/search-custom-filter/search-custom-filter.component';
import {
  ButtonSubHeaderPipe,
  ButtonTypePipe,
} from './pipe/button.subheader.pipe';
import { RouterModule } from '@angular/router';
import { CommentPanelComponent } from './components/comment/comment-panel/comment-panel.component';
import { CommentAddComponent } from './components/comment/comment-add/comment-add.component';
import { CommentComponent } from './components/comment/comment/comment.component';
import { CommentNoneComponent } from './components/comment/comment-none/comment-none.component';
import { FriendlyDatePipe } from './pipe/friendly-date.pipe';
import { BadgeListComponent } from './components/badge/badge-list/badge-list.component';
import { ErrorModule } from './error';
import { DashboardComponent } from './components/dashboard/dashboard/dashboard.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { KpiComponent } from './components/kpi/kpi.component';
import { DashboardSubheaderComponent } from './components/dashboard-subheader/dashboard-subheader.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { CustomActionsFlowContractComponent } from './components/custom-actions-flow-contract/custom-actions-flow-contract.component';
import { CustomActionsFlowLeadComponent } from './components/custom-actions-flow-lead/custom-actions-flow-lead.component';
import { CustomActionsFlowGlossaryComponent } from './components/custom-actions-flow-glossary/custom-actions-flow-glossary.component';
import { CustomActionsFlowArticleComponent } from './components/custom-actions-flow-article/custom-actions-flow-article.component';
import { AddressComponent } from './components/address/address.component';
import { IbanPipe } from './pipe/iban.pipe';
import { IbanComponent } from './components/iban/iban.component';
import { PhoneNumberComponent } from './components/phone-number/phone-number.component';
import { PhoneNumberPipe } from './pipe/phone-number.pipe';
import { FullPathImagePipe } from './pipe/full-path-image.pipe';
import { TypeaheadComponent } from './components/typeahead/typeahead.component';
import { ModalContentComponent } from './components/modal-content/modal-content.component';
import { ActionsComponent } from './components/actions/actions.component';
import { FormAccessibleDirective } from './directives/form-accessible.directive';
import { CategoryChoiceComponent } from './components/category-choice/category-choice.component';
import { FormValidationMessageComponent } from './components/form-validation-message/form-validation-message.component';
import { CheckFormFieldValidityDirective } from './directives/check-form-field-validity.directive';
import { FormHasUnsavedDataDirective } from './directives/form-has-unsaved-data.directive';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { GroupByPipe } from './pipe/group-by.pipe';
import { IbanValidatorDirective } from './directives/iban-validator.directive';
import { SearchFiltersDropdownComponent } from './components/search-filters-dropdown/search-filters-dropdown.component';
import { ToastValidationComponent } from './components/toast-validation/toast-validation.component';
import { ToastValidationContentComponent } from './components/toast-validation/toast-validation-content/toast-validation-content.component';
import { ToastCssPipe } from './pipe/toast-css.pipe';
import { PaginationLabelPipe } from './components/pagination/paginationLabel.pipe';
import { SmartActionsComponent } from './components/smart-actions/smart-actions.component';
import { KanbanViewComponent } from './components/kanban-view/kanban-view.component';
import { KanbanViewContactComponent } from './components/kanban-view-contact/kanban-view-contact.component';
import { KanbanViewLeadComponent } from './components/kanban-view-lead/kanban-view-lead.component';
import { KanbanViewContractComponent } from './components/kanban-view-contract/kanban-view-contract.component';
import { FormattedDomainPipe } from './components/search-bar-badge/formatted-domain.pipe';
import { IsDomainActivePipe } from './components/search-filters-dropdown/is-domain-active.pipe';
import { IsBuiltInActivePipe } from './components/search-filters-dropdown/is-built-in-active.pipe';
import { SmartActionsLeadComponent } from './components/smart-actions-lead/smart-actions-lead.component';

@NgModule({
  declarations: [
    FileUploadComponent,
    PaginationComponent,
    GenericGridComponent,
    ToastComponent,
    BadgeComponent,
    BadgeListComponent,
    MenuDropdownComponent,
    CustomActionsFlowComponent,
    CustomActionsFlowContractComponent,
    CustomActionsFlowLeadComponent,
    CustomActionsFlowGlossaryComponent,
    CustomActionsFlowArticleComponent,
    DocumentSubheaderComponent,
    ListSubheaderComponent,
    FavoriteSaveComponent,
    SearchBarBadgeComponent,
    SearchBarComponent,
    SearchCustomFilterComponent,
    SearchFiltersDropdownComponent,
    ButtonSubHeaderPipe,
    CommentPanelComponent,
    CommentAddComponent,
    CommentComponent,
    CommentNoneComponent,
    FriendlyDatePipe,
    DashboardComponent,
    KpiComponent,
    DashboardSubheaderComponent,
    ButtonTypePipe,
    BreadcrumbComponent,
    AddressComponent,
    IbanPipe,
    IbanComponent,
    PhoneNumberComponent,
    PhoneNumberPipe,
    FullPathImagePipe,
    TypeaheadComponent,
    ModalContentComponent,
    ActionsComponent,
    FormAccessibleDirective,
    CategoryChoiceComponent,
    FormValidationMessageComponent,
    CheckFormFieldValidityDirective,
    FormHasUnsavedDataDirective,
    ConfirmationComponent,
    GroupByPipe,
    IbanValidatorDirective,
    ToastValidationComponent,
    ToastValidationContentComponent,
    ToastCssPipe,
    FormattedDomainPipe,
    PaginationLabelPipe,
    SmartActionsComponent,
    KanbanViewComponent,
    KanbanViewContactComponent,
    KanbanViewLeadComponent,
    KanbanViewContractComponent,
    IsDomainActivePipe,
    IsBuiltInActivePipe,
    SmartActionsLeadComponent,
  ],
  exports: [
    AppStoreModule,
    CoreModule,
    FileUploadComponent,
    GenericGridComponent,
    PaginationComponent,
    ToastComponent,
    BadgeComponent,
    BadgeListComponent,
    MenuDropdownComponent,
    CustomActionsFlowComponent,
    CustomActionsFlowContractComponent,
    CustomActionsFlowLeadComponent,
    CustomActionsFlowGlossaryComponent,
    CustomActionsFlowArticleComponent,
    DocumentSubheaderComponent,
    ListSubheaderComponent,
    FavoriteSaveComponent,
    SearchBarBadgeComponent,
    SearchBarComponent,
    SearchCustomFilterComponent,
    SearchFiltersDropdownComponent,
    ButtonSubHeaderPipe,
    CommentPanelComponent,
    CommentAddComponent,
    CommentComponent,
    CommentNoneComponent,
    FriendlyDatePipe,
    DashboardComponent,
    KpiComponent,
    DashboardSubheaderComponent,
    ButtonTypePipe,
    BreadcrumbComponent,
    AddressComponent,
    IbanPipe,
    IbanComponent,
    PhoneNumberComponent,
    PhoneNumberPipe,
    FullPathImagePipe,
    TypeaheadComponent,
    ModalContentComponent,
    ActionsComponent,
    FormAccessibleDirective,
    CategoryChoiceComponent,
    FormValidationMessageComponent,
    CheckFormFieldValidityDirective,
    FormHasUnsavedDataDirective,
    ConfirmationComponent,
    GroupByPipe,
    IbanValidatorDirective,
    ToastValidationComponent,
    SmartActionsComponent,
    KanbanViewComponent,
    KanbanViewContactComponent,
    KanbanViewContractComponent,
    KanbanViewLeadComponent,
    SmartActionsLeadComponent,
  ],
  providers: [AuthentificationService],
  imports: [CoreModule, AppStoreModule, RouterModule, ErrorModule],
})
export class SharedModule {}
