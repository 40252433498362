import { Component } from '@angular/core';

@Component({
  selector: 'work-in-progress',
  templateUrl: './work-in-progress.html',
  styleUrls: ['work-in-progress.scss']
})
export class WorkInProgressComponent {

  constructor() { };
};
