import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { AppState } from '../types';
import { coreActions } from './core.actions';
import { coreSelectors } from './core.selectors';
import {
  Field,
  SearchView,
  FavoriteSearchView,
  Operation,
} from '../search/types';

@Injectable()
export class CoreService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getFieldsByEntity: GenericApiCall<Field[], string> = this.genericApiCall(
    coreActions.getFieldsByEntity,
    coreSelectors.getFieldsByEntity,
    true
  );
  getSearchViewByEntity: GenericApiCall<SearchView, string> =
    this.genericApiCall(
      coreActions.getSearchViewByEntity,
      coreSelectors.getSearchViewByEntity,
      true
    );
  getFavoriteByEntity: GenericApiCall<FavoriteSearchView[], string> =
    this.genericApiCall(
      coreActions.getFavoriteByEntity,
      coreSelectors.getFavoriteByEntity,
      true
    );
  getOperatorsByType: GenericApiCall<Operation[], string> = this.genericApiCall(
    coreActions.getOperatorsByType,
    coreSelectors.getOperatorsByType,
    true
  );
  getStringOperators: GenericApiCall<Operation[], void> = this.genericApiCall(
    coreActions.getStringOperators,
    coreSelectors.getStringOperators,
    false
  );
  getIntOperators: GenericApiCall<Operation[], void> = this.genericApiCall(
    coreActions.getIntOperators,
    coreSelectors.getIntOperators,
    false
  );
}
