import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { userFeatureKey } from "./type";
import { UserApiService } from "./user.api.services";
import { UserEffects } from "./user.effects";
import { IUserState, userReducer, userInitialState } from "./user.reducer";
import { UserService } from "./user.service";

@NgModule({
    imports:[
        StoreModule.forFeature<IUserState>(userFeatureKey, userReducer,{
            initialState: userInitialState,
        }),
        EffectsModule.forFeature([UserEffects])
    ],
    providers:[UserApiService, UserService]
})
export class StoreUserModule{}