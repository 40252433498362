<ng-template *ngIf="controlForm.invalid &&
             (controlForm.dirty ||
             controlForm.touched) ">

  <span *ngIf="controlForm.errors?.['required']"
    class="field-validation-valid text-danger" translate>
    FORM_LBL_VALIDATION_REQUIRED
  </span>

</ng-template>

