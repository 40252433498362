import {Component, Input, OnInit, Type} from '@angular/core';
import {ButtonType} from "../../store/subheader/type";
import {SubHeaderService} from "../../store/subheader/subheader.service";
import {SourceEnum} from "../../store/typeahead/types";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ModalService} from "../../service/modal.service";
import {filter, take} from "rxjs/operators";

@Component({
  selector: 'app-modal-content',
  templateUrl: './modal-content.component.html',
  styleUrls: ['./modal-content.component.scss']
})
export class ModalContentComponent implements OnInit {

  protected readonly ButtonType = ButtonType;

  @Input() component: Type<any>
  @Input() modalParam: string | number;
  isCreate: boolean = false;
  sourceEnum: SourceEnum = SourceEnum.Modal;
  inputs: Record<string, unknown> | undefined = undefined;

  @Input() title: string;
  @Input() saveActionName: string;

  constructor(private readonly subHeaderService: SubHeaderService,
              protected readonly activeModalService: NgbActiveModal,
              private readonly dynamicService: ModalService) {
  }


  ngOnInit(): void {
    this.isCreate = this.component.name.toLowerCase().indexOf('create') >= 0

    this.inputs = { 'modalId' : +this.modalParam };
    if (this.isCreate)
    {
      this.inputs = {
        'modalName': this.modalParam.toString(),
        'source': this.sourceEnum
      };
    }


    this.dynamicService.getObservable().pipe(filter(x => !!x), take(1)).subscribe((data) => {
      if (Number(data) >= 0) this.activeModalService.close(+data);
    });
  }

  triggerAction(actionName:string) {
    this.subHeaderService.setTriggerAction(actionName);
  }
}
