import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthentificationGuard } from 'src/app/shared/guard/authentification-guard';
import { HeaderMenuType } from 'src/app/shared/store/header/type';
import { SubHeaderType } from 'src/app/shared/store/subheader/type';
import { GlossaryCreateComponent } from './glossary-create/glossary-create.component';
import { GlossaryDetailComponent } from './glossary-detail/glossary-detail.component';
import { GlossaryListComponent } from './glossary-list/glossary-list.component';
import { GlossaryEditComponent } from './glossary-edit/glossary-edit.component';
import { Entity } from 'src/app/shared/store/view/types';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';

const routes: Routes = [
  {
    path: 'Content/Glossaries',
    data: {
      breadcrumb: { label: ' ', alias: 'glossaryList' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: GlossaryListComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Content,
          entity: Entity.Glossary,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: GlossaryDetailComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          entity: Entity.Glossary,
          headerMenu: HeaderMenuType.Content,
          breadcrumb: { label: ' ', alias: 'glossaryDetails' },
        },
      },
      {
        path: 'Create',
        component: GlossaryCreateComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          entity: Entity.Glossary,
          headerMenu: HeaderMenuType.Content,
          breadcrumb: { label: ' ', alias: 'glossaryCreate' },
        },
      },
      {
        path: 'Edit/:id',
        component: GlossaryEditComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          entity: Entity.Glossary,
          headerMenu: HeaderMenuType.Content,
          breadcrumb: { label: ' ', alias: 'glossaryEdit' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GlossaryRoutingModule {}
