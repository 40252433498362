import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { EmployeeRoutingModule } from './employee-routing.module';
import { EmployeeListComponent } from './employee-list/employee-list.component';
import {SharedModule} from "../../../shared/shared.module";
import { EmployeeDetailsComponent } from './employee-details/employee-details.component';
import { EmployeeTabsDetailsComponent } from './employee-details/employee-tabs-details/employee-tabs-details.component';
import { EmployeeDetailsNotesComponent } from './employee-details/employee-details-tab/employee-details-notes/employee-details-notes.component';
import { EmployeeDetailsPublicInformationsComponent } from './employee-details/employee-details-tab/employee-details-public-informations/employee-details-public-informations.component';
import { EmployeeDetailsPrivateInformationsComponent } from './employee-details/employee-details-tab/employee-details-private-informations/employee-details-private-informations.component';
import { EmployeeDetailsParametersComponent } from './employee-details/employee-details-tab/employee-details-parameters/employee-details-parameters.component';


@NgModule({
  declarations: [
    EmployeeListComponent,
    EmployeeDetailsComponent,
    EmployeeTabsDetailsComponent,
    EmployeeDetailsNotesComponent,
    EmployeeDetailsPublicInformationsComponent,
    EmployeeDetailsPrivateInformationsComponent,
    EmployeeDetailsParametersComponent
  ],
    imports: [
        CommonModule,
        EmployeeRoutingModule,
        SharedModule
    ]
})
export class EmployeeModule { }
