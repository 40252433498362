import { Action, ActionReducer, createReducer } from '@ngrx/store';
import {
  onApiCall,
  onArrayApiCall,
} from 'src/app/common/store/generic.reducer';
import { GenericState, IState } from 'src/app/common/store/types';
import { PageResult } from '../../types';
import { ZipCode } from './types';
import { zipCodeActions } from './zipcode.actions';

export interface IZipCodeState extends IState<ZipCode> {
  get: GenericState<ZipCode[]>;
  getById: GenericState<ZipCode>;
  getPaged: GenericState<PageResult<ZipCode>>;
}

export const zipCodeInitialState: IZipCodeState = {
  get: new GenericState<ZipCode[]>(),
  getById: new GenericState<ZipCode>(),
  getPaged: new GenericState<PageResult<ZipCode>>(),
};

const zipCodeReducers = [
  ...onArrayApiCall<ZipCode>(zipCodeActions.get, 'get'),
  ...onApiCall<ZipCode>(zipCodeActions.getById, 'getById'),
  ...onApiCall<PageResult<ZipCode>>(zipCodeActions.getPaged, 'getPaged'),
];

export const zipCodeReducer = createReducer(
  zipCodeInitialState,
  ...zipCodeReducers
) as ActionReducer<IZipCodeState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return zipCodeReducer(state, action);
}
