import { Component } from "@angular/core";

@Component({
  selector: "unauthorized",
  templateUrl: "./unauthorized.html",
  styleUrls: ["unauthorized.scss"],
})
export class UnauthorizedComponent {
  constructor() {}

  ngOnInit(): void {}
}
