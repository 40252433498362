import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractService } from '../../../../../../shared/store/contract/contract.service';
import { ContractModel } from '../../../../../../shared/store/contract/types';

@Component({
  selector: 'app-contract-details-marketing',
  templateUrl: './contract-details-marketing.component.html',
  styleUrls: ['./contract-details-marketing.component.scss'],
})
export class ContractDetailsMarketingComponent implements OnInit {
  contractBase$: Observable<ContractModel>;

  constructor(private readonly contractService: ContractService) {}

  ngOnInit(): void {
    this.contractBase$ = this.contractService.getByUniqueId.value$;
  }
}
