import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {ActivatedRoute, Router} from "@angular/router";
import {EmployeeService} from "../../../../shared/store/employee/employee.service";
import {SubHeaderService} from "../../../../shared/store/subheader/subheader.service";
import {BreadcrumbService} from "xng-breadcrumb";
import {ViewService} from "../../../../shared/store/view/views.service";
import {Observable} from "rxjs";
import {Employee} from "../../../../shared/store/employee/types";
import {View} from "../../../../shared/store/view/types";
import {filter} from "rxjs/operators";
import {environment} from "../../../../../environments/environment";

@UntilDestroy()
@Component({
  selector: 'app-employee-details',
  templateUrl: './employee-details.component.html',
  styleUrls: ['./employee-details.component.scss']
})
export class EmployeeDetailsComponent implements OnInit, OnDestroy{
  employeeId:number;
  employeeBase$: Observable<Employee>;
  view$:Observable<View>;

  active:number = 1;

//avatar
  avatarUrl: string;
  bgColorDefaultCss: string = 'cccccc';

  constructor(private readonly route:ActivatedRoute,
              private readonly router:Router,
              private readonly employeeService:EmployeeService,
              private readonly subHeaderService: SubHeaderService,
              private readonly breadcrumbService: BreadcrumbService,
              private readonly viewService:ViewService
  ) {
    this.employeeBase$ = this.employeeService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.employeeId = this.route.snapshot.params['id'];
    this.employeeService.getById.call(this.employeeId.toString());

    this.employeeBase$.pipe(
      untilDestroyed(this),
      filter((x) => !!x)
    ).subscribe((x) => {
      this.breadcrumbService.set('@employeeDetails', x.userContactName)
    });
    this.view$.pipe(
      untilDestroyed(this),
      filter((x) => !!x)
    ).subscribe((x) => {
      this.breadcrumbService.set('@employeeList', x.title);
    });

    // this.subHeaderService.getTriggerAction().subscribe((x) => {
    //   if (x == 'employeeEdit') {
    //     this.router.navigate(['HumanResources/Employees/Edit/', this.employeeId]);
    //     this.subHeaderService.setTriggerAction('');
    //   }
    // });

    //avatar
    this.avatarUrl = environment.comparanooapiurl
                  + environment.features.thumbnail.url
                  + environment.features.thumbnail.avatar;
    this.bgColorDefaultCss = window.getComputedStyle(document.body)
      .getPropertyValue('--comment-avatar-bg')
      .replace('#','');

  }
  ngOnDestroy(): void {
    this.employeeService.getById.reset();
    this.breadcrumbService.set('@employeeDetails', ' ');
  }
}
