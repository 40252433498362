<div class="sub-header">
  <div class="sub-header-row border-top align-items-center px-1">
    <div class="row">
      <div class="col" *ngIf="this.view$ | async as view">
        <!--        href="https://www.comparanoo.re/offres-mutuelles-sante?leadId={{this.leadId}}" target="_blank"-->
        <a class="btn-sm btn btn-primary mr-2" target="_blank" translate>
          GLOBAL_BTN_QUALIFY
        </a>
        <ng-container *ngIf="this.lead$ | async as lead">
          <a
            *ngIf="
              lead.uniqueId &&
              lead.category.toString() ===
                InsuranceCategory[InsuranceCategory.Health]
            "
            class="btn-sm btn btn-outline-primary mr-2"
            href="{{ this.comparanooUrl }}/offres-mutuelles-sante?leadId={{
              lead.uniqueId
            }}"
            target="_blank"
            translate
          >
            GLOBAL_BTN_SEE_OFFERS
          </a>
        </ng-container>
      </div>

      <div class="col-auto state-breadcrumb-col">
        <div class="state-breadcrumb">
          <li
            *ngFor="let status of this.leadStatus"
            [ngClass]="status.status == 'New' ? 'active' : ''"
          >
            {{ status.label }}
          </li>
        </div>
      </div>
    </div>
  </div>
</div>
