import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { createApiCallEffects } from "src/app/common/store/generic.effects";
import { JobAction, jobActions } from "./job.action";
import { JobApiService } from "./job.api.service";
import { IJobState } from "./job.reducer";

@Injectable()
export class JobEffects {
  constructor(
    private readonly store: Store<IJobState>,
    private readonly jobApiService: JobApiService,
    private readonly action$: Actions<JobAction>    
  ) {}
  
  getById = createApiCallEffects(
    this,
    this.action$,
    jobActions.getById,
    this.jobApiService.getById
  );

  postJob = createApiCallEffects(
    this,
    this.action$,
    jobActions.postJob,
    this.jobApiService.postJob
  );

  updateJob = createApiCallEffects(
    this,
    this.action$,
    jobActions.updateJob,
    this.jobApiService.updateJob
  );
}