import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Action } from '@ngrx/store';
import { ErrorService } from '../../error';
import { catchError } from 'rxjs/operators';
import {
  Field,
  SearchView,
  FavoriteSearchView,
  Operation,
} from '../search/types';

@Injectable({
  providedIn: 'root',
})
export class CoreApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService
  ) {}

  getFieldsByEntity = (entity: string): Observable<Field[]> => {
    let parameters: string[];
    parameters = ['fields'];
    const params = new HttpParams().set('entity', entity);
    let url = this.formatUrl(parameters);
    return this.httpClient.get<Field[]>(url, { params: params }).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getSearchViewByEntity = (entity: string): Observable<SearchView> => {
    let parameters: string[];
    parameters = ['searchview'];
    const params = new HttpParams().set('entity', entity);
    const headers = new HttpHeaders();
    headers.set('Accept-Language', 'fr');
    let url = this.formatUrl(parameters);
    return this.httpClient
      .get<SearchView>(url, { params: params, headers: headers })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  getFavoriteByEntity = (entity: string): Observable<FavoriteSearchView[]> => {
    let parameters: string[];
    parameters = ['favorite', entity];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<FavoriteSearchView[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getOperatorsByType = (type: string): Observable<Operation[]> => {
    let parameters: string[];
    parameters = ['operator'];
    const params = new HttpParams().set('typeName', type);
    let url = this.formatUrl(parameters);
    return this.httpClient.get<Operation[]>(url, { params: params }).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getStringOperators = (): Observable<Operation[]> => {
    let parameters: string[];
    parameters = ['operator'];
    const params = new HttpParams().set('typeName', 'System.String');
    let url = this.formatUrl(parameters);
    return this.httpClient.get<Operation[]>(url, { params: params }).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getIntOperators = (): Observable<Operation[]> => {
    let parameters: string[];
    parameters = ['operator'];
    const params = new HttpParams().set('typeName', 'System.Int32');
    let url = this.formatUrl(parameters);
    return this.httpClient.get<Operation[]>(url, { params: params }).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getActionsByEntity = (entity: string): Observable<Action[]> => {
    let parameters: string[];
    parameters = ['actions'];
    const params = new HttpParams().set('entity', entity);
    let url = this.formatUrl(parameters);
    return this.httpClient.get<Action[]>(url, { params: params }).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += '/api/search';
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
