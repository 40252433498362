import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IAuthenticationState } from './authentication.reducer';
import { authenticationFeatureKey } from './types';
import {createApiCallSelectors} from "../../../common/store/generic.selectors";

const authenticationFeatureState =
  createFeatureSelector<IAuthenticationState>(authenticationFeatureKey);

export const authenticationSelectors = {
  getUserConnected: createSelector(
    authenticationFeatureState,
    (state: IAuthenticationState) => state.userConnected
  ),
  forgotPassword: createApiCallSelectors<any, IAuthenticationState>(
      authenticationFeatureState,
      'forgotPassword'
  ),
  resetPassword: createApiCallSelectors<any, IAuthenticationState>(
      authenticationFeatureState,
      'resetPassword'
  ),
  changePassword: createApiCallSelectors<any, IAuthenticationState>(
      authenticationFeatureState,
      'changePassword'
  ),
};
