<!-- AG Grid Angular Component -->
<ag-grid-angular
  style="width: 100%; height: 100%"
  class="ag-theme-alpine"
  [gridOptions]="config"
  [rowData]="(this.data$ | async)?.data"
  [animateRows]="true"
  (gridReady)="onGridReady($event)"
  (sortChanged)="onSortChanged($event)"
  (gridSizeChanged)="onGridSizeChanged($event)"
  [overlayNoRowsTemplate]="noRowsTemplate"
  [suppressMiddleClickScrolls]="true"
></ag-grid-angular>

