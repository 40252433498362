import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {SharedModule} from 'src/app/shared/shared.module';
import {AuthentificationRoutingModule} from './authentification-routing.module';
import {LoginComponent} from './login/login.component';
import {ResetPasswordComponent} from './reset-password/reset-password.component';
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";
import {LoginFormComponent} from "./login-form/login-form.component";

@NgModule({
    declarations: [LoginComponent, ResetPasswordComponent, ForgotPasswordComponent, LoginFormComponent],
    imports: [CommonModule, SharedModule, AuthentificationRoutingModule],
    exports: [],
})
export class AuthentificationModule {
}
