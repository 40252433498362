import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { AppState } from '../types';
import { currencyActions } from './currency.actions';
import { currencySelectors } from './currency.selectors';
import { Currency, CurrencyPost, CurrencyUpdate } from './types';

@Injectable()
export class CurrencyService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<Currency, string> = this.genericApiCall(
    currencyActions.getById,
    currencySelectors.getById,
    true
  );

  getList: GenericApiCall<Currency[], string> = this.genericApiCall(
    currencyActions.getList,
    currencySelectors.getList,
    true
  );

  postCurrency: GenericApiCall<any, CurrencyPost> = this.genericApiCall(
    currencyActions.postCurrency,
    currencySelectors.postCurrency,
    true
  );

  updateCurrency: GenericApiCall<any, CurrencyUpdate> = this.genericApiCall(
    currencyActions.updateCurrency,
    currencySelectors.updateCurrency,
    true
  );
}
