<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container">
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">
              <app-currency-details-content></app-currency-details-content>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.currencyId">
      <app-comment-panel [entityType]="'Currency'" [entityId]="this.currencyId.toString()"></app-comment-panel>
    </div>
  </div>
</div>
