import { ContactApiService } from './contact.api.service';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  contactInitialState,
  contactReducer,
  IContactState,
} from './contact.reducer';
import { ContactService } from './contact.service';
import { ContactEffects } from './contact.effects';
import { EffectsModule } from '@ngrx/effects';
import { contactFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IContactState>(contactFeatureKey, contactReducer, {
      initialState: contactInitialState,
    }),
    EffectsModule.forFeature([ContactEffects]),
  ],
  providers: [ContactApiService, ContactService],
})
export class StoreContactModule {}
