import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { SubHeaderAction, subHeaderActions } from './subheader.actions';
import { SubHeaderApiService } from './subheader.api.services';
import { ISubHeaderState } from './subheader.reducer';

@Injectable()
export class SubHeaderEffects {
  constructor(
    private readonly store: Store<ISubHeaderState>,
    private readonly subHeaderApiService: SubHeaderApiService,
    private readonly actions$: Actions<SubHeaderAction>
  ) {}

  getSubHeaderButtons = createApiCallEffects(
    this,
    this.actions$,
    subHeaderActions.getSubHeaderOption,
    this.subHeaderApiService.getSubHeaderOption
  );
}
