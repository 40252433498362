import { Pipe, PipeTransform } from '@angular/core';
import { NotificationStatus } from '../store/notification/types';

@Pipe({
  name: 'toastCss',
})
export class ToastCssPipe implements PipeTransform {
  transform(value: NotificationStatus, ...args: unknown[]): unknown {
    return {
      'bg-info': value === NotificationStatus.Info,
      'bg-warning': value === NotificationStatus.Warning,
      'bg-danger': value === NotificationStatus.Error,
      'bg-success': value === NotificationStatus.Success,
    };
  }
}
