<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  *ngIf="this.leadDetail$ | async as leadDetail"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_MARKETING_CAMPAIGN</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              {{ leadDetail.campaignName }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_MARKETING_MEDIUM</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              {{ leadDetail.medium }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_MARKETING_SOURCE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              {{ leadDetail.source }}
            </span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group row">
          <label
            class="col-4 col-form-label-sm"
            translate
            >LEAD_LBL_MARKETING_CREATION_DATE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ leadDetail.creationDate | date : "dd/MM/yyyy" }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
