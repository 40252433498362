<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  *ngIf="this.carDetail$ | async as carDetail"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_CAR_INSURANCE_COMPANY_NAME</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.currentInsuranceCompany }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_CAR_INSURANCE_COVERAGE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.insuranceCoverage }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_CAR_INSURANCE_DRIVER_BODY_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                [(ngModel)]="carDetail.driverBodyCoverage"
                onclick="return false;"
                disabled
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_CAR_INSURANCE_REPLACEMENT_VEHICLE_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                [(ngModel)]="carDetail.replacementVehicleCoverage"
                onclick="return false;"
                disabled
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_CAR_INSURANCE_DRIVER_PROPERTY_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                [(ngModel)]="carDetail.driverPropertyCoverage"
                onclick="return false;"
                disabled
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_CAR_INSURANCE_DRIVER_BRAND_NEW_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                [(ngModel)]="carDetail.brandNewCoverage"
                onclick="return false;"
                disabled
              />
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_CAR_INSURANCE_DRIVER_MONEY_LOSS_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
              <input
                type="checkbox"
                name="active"
                [(ngModel)]="carDetail.moneyLossCoverage"
                onclick="return false;"
                disabled
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
