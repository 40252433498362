import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "internal-server-error",
  templateUrl: "./internal-server-error.html",
  styleUrls: ["internal-server-error.scss"],
})
export class InternalServerErrorComponent implements OnInit {
  
  message: string = "ERROR_MESSAGE";
  title: string = "ERROR_TITLE";

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.message = this.route.snapshot.paramMap.get("message") || '';
    this.title = this.route.snapshot.paramMap.get("title") || '';
  }
}
