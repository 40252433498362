import {Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute, Data } from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { ZipCodeService } from 'src/app/shared/store/zipcode/zipcode.service';
import { RouterService } from 'src/app/shared/store/router/router.service';
import { ZipCode } from 'src/app/shared/store/zipcode/types';
import {BreadcrumbService} from "xng-breadcrumb";
import {filter} from "rxjs/operators";
import {View} from "../../../../shared/store/view/types";
import {ViewService} from "../../../../shared/store/view/views.service";

@UntilDestroy()
@Component({
  selector: 'app-zipcode-details',
  templateUrl: './zipcode-details.component.html',
  styleUrls: ['./zipcode-details.component.scss'],
})
export class ZipCodeDetailsComponent implements OnInit, OnDestroy {
  zipCodeId: string;
  zipCode$: Observable<ZipCode>;
  data$: Observable<Data>;
  view$:Observable<View>;

  constructor(
    private zipCodeService: ZipCodeService,
    private route: ActivatedRoute,
    private readonly routeur: RouterService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService:ViewService
  ) {
    this.zipCode$ = this.zipCodeService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.zipCodeId = this.route.snapshot.params['id'];
    this.zipCodeService.getById.call(this.zipCodeId);

    this.zipCode$.pipe(
      untilDestroyed(this),
      filter((x) => !!x)
    ).subscribe((x) => {
      this.breadcrumbService.set('@zipCodeDetails', x.city)
    });
    this.view$.pipe(
      untilDestroyed(this),
      filter((x) => !!x)
    ).subscribe((x) => {
      this.breadcrumbService.set('@zipCodeList', x.title);
    });
  }

  ngOnDestroy() {
    this.zipCodeService.getById.reset();
    this.breadcrumbService.set('@zipCodeDetails', ' ');
  }
}
