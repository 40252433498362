import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { IViewState, ViewInitialState, viewReducer } from './view.reducers';
import { viewsFeatureKey } from './types';
import { EffectsModule } from '@ngrx/effects';
import { ViewApiService } from './view.api.services';
import { ViewEffects } from './view.effects';
import { ViewService } from './views.service';

@NgModule({
  imports: [
    StoreModule.forFeature<IViewState>(viewsFeatureKey, viewReducer, {
      initialState: ViewInitialState,
    }),
    EffectsModule.forFeature([ViewEffects]),
  ],
  providers: [ViewService, ViewApiService],
})
export class StoreViewModule {}
