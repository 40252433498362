import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SubHeaderService } from '../../store/subheader/subheader.service';
import { SubHeaderOption, SubHeaderType } from '../../store/subheader/type';

@Component({
  selector: 'app-list-subheader',
  templateUrl: './list-subheader.component.html',
  styleUrls: ['./list-subheader.component.scss'],
})
export class ListSubheaderComponent {
  subHeaderOption$: Observable<SubHeaderOption>;

  constructor(private readonly subHeaderService: SubHeaderService) {}

  ngOnInit(): void {
    this.subHeaderOption$ = this.subHeaderService.getSubHeaderOption.value$;
  }

  triggerAction(actionName: string) {
    this.subHeaderService.setTriggerAction(actionName);
  }
}
