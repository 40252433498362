import {CampaignApiService} from "./campaign.api.service";
import {NgModule} from "@angular/core";
import {StoreModule} from "@ngrx/store";
import {campaignInitialState, campaignReducer, ICampaignState} from "./campaign.reducer";
import {campaignFeatureKey} from "./types";
import {EffectsModule} from "@ngrx/effects";
import {CampaignEffects} from "./campaign.effects";
import {CampaignService} from "./campaign.service";

@NgModule({
    imports:[
        StoreModule.forFeature<ICampaignState>(campaignFeatureKey, campaignReducer, {
            initialState: campaignInitialState,
        }),
        EffectsModule.forFeature([CampaignEffects])
    ],
    providers:[CampaignApiService, CampaignService]
})
export class StoreCampaignModule {}
