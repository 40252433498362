import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { Employee } from '../../../../../../shared/store/employee/types';
import { EmployeeService } from '../../../../../../shared/store/employee/employee.service';

@Component({
  selector: 'app-employee-details-notes',
  templateUrl: './employee-details-notes.component.html',
  styleUrls: ['./employee-details-notes.component.scss'],
})
export class EmployeeDetailsNotesComponent {
  employeeDetail$: Observable<Employee>;

  constructor(private readonly employeeService: EmployeeService) {
    this.employeeDetail$ = this.employeeService.getById.value$;
  }
}
