import { NgModule } from "@angular/core";
import { CoreModule } from "src/app/core/core.module";
import { ErrorRoutingModule } from "./error-routing.module";
import { ErrorService } from "./error.service";
import { InternalServerErrorComponent } from "./internal-server-error/internal-server-error.component";
import { OfflineErrorComponent } from "./offline-error/offline-error.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { WorkInProgressComponent } from "./work-in-progress/work-in-progress.component";

@NgModule({
  declarations: [
    InternalServerErrorComponent,
    OfflineErrorComponent,
    PageNotFoundComponent,
    UnauthorizedComponent,
    WorkInProgressComponent,
  ],
  exports: [],
  imports: [CoreModule, ErrorRoutingModule],
  providers: [ErrorService],
})
export class ErrorModule {}
