import { Component, Input, OnInit } from '@angular/core';
import { SearchService } from '../../store/search/search.service';
import { Router } from '@angular/router';
import { SmartAction } from '../../store/view/types';

@Component({
  selector: 'app-smart-actions',
  templateUrl: './smart-actions.component.html',
  styleUrls: ['./smart-actions.component.scss'],
})
export class SmartActionsComponent implements OnInit {
  @Input() smartActions: SmartAction[];
  constructor(
    private readonly searchService: SearchService,
    private readonly router: Router
  ) {}
  ngOnInit(): void {}

  onSmartLinkClick(index: number) {
    this.searchService.addActiveFilter(this.smartActions[index].filter, true);
    this.searchService.resetActiveFilter();
    this.router.navigate([this.smartActions[index].url], {
      state: { useTempFilter: true },
    });
  }
}
