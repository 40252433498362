<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  formGroupName="marketingFormGroup"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >CONTRACT_LBL_MARKETING_CAMPAIGN</label
          >
          <div class="col">
            <app-typeahead
              [entity]="Entity.Campaign"
              [routeEndpoint]="'Campaigns'"
              [limitToList]="true"
              [controlForm]="
                this.form.controls['marketingFormGroup'].get(
                  'marketingCampaignId'
                )
              "
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >CONTRACT_LBL_MARKETING_MEDIUM</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/ReferenceData'"
              [referenceField]="'Medium'"
              [controlForm]="
                this.form.controls['marketingFormGroup'].get('marketingMedium')
              "
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >CONTRACT_LBL_MARKETING_SOURCE</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/ReferenceData'"
              [referenceField]="'Source'"
              [controlForm]="
                this.form.controls['marketingFormGroup'].get('marketingSource')
              "
            ></app-typeahead>
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
  </div>
</div>
