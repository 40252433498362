import { createAction, props } from '@ngrx/store';
import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { SubHeaderOption } from './type';

export const subHeaderActions = {
  getSubHeaderOption: createApiCallActions<SubHeaderOption>(
    'subHeader',
    'getSubHeaderOption'
  ),
  triggerAction: createAction(
    '[SubHeader] Trigger action',
    props<{ actionName: string }>()
  ),
};
export type SubHeaderAction = typeof subHeaderActions;
