<div class="modal-content">
  <div class="modal-header">
    <h4 class="modal-title" translate>SEARCH_LBL_FAVORITE</h4>
  </div>
  <div class="modal-body">
    <div class="panel-body">
      <div class="clearfix">
        <div class="form-group">
          <label class="control-label" translate>SEARCH_LBL_FAVORITE_NAME</label>
          <input
            autofocus="true"
            class="form-control"
            id="favoriteName"
            name="favoriteName"
            type="text"
            value=""
          />
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="privateView"
              id="privateView"
              checked
            />
            <label class="form-check-label" for="privateView" translate> SEARCH_LBL_FAVORITE_PRIVATE_VIEW </label>
          </div>
          <div class="form-check">
            <input
              class="form-check-input"
              type="radio"
              name="publicView"
              id="publicView"
            />
            <label class="form-check-label" for="publicView" translate> SEARCH_LBL_FAVORITE_PUBLIC_VIEW </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      id="closeAddExpenseSheetModal"
      type="reset"
      class="btn btn-outline-light"
      translate
    >
      GLOBAL_BTN_CANCEL
    </button>
    <button type="submit" class="btn btn-primary" translate>GLOBAL_BTN_VALIDATION</button>
  </div>
</div>
