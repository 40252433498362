import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommentService } from '../../../store/comment/comment.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { UserService } from '../../../store/user/user.service';
import { Avatar, User } from '../../../store/user/type';
import { environment } from '../../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { filter, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-comment-add',
  templateUrl: './comment-add.component.html',
  styleUrls: ['./comment-add.component.scss'],
})
export class CommentAddComponent implements OnInit {
  @Input() entityId: string;
  @Input() entityType: string;
  @Output() newComment = new EventEmitter<boolean>();

  isVisible: boolean = false;
  user: User;
  currentAvatar$: Observable<Avatar>;

  constructor(
    private readonly commentService: CommentService,
    private readonly userService: UserService,
  ) {}

  commentForm = new FormGroup({
    message: new FormControl('', [Validators.required]),
  });

  ngOnInit() {
    this.userService.getCurrentUser
      .call()
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((user) => {
        if (user != undefined) {
          this.user = user;
          let avatar = new Avatar(
            user.contactName,
            [
              environment.comparanooapiurl,
              environment.features.thumbnail.url,
              environment.features.thumbnail.avatar,
            ].join(''),
            window
              .getComputedStyle(document.body)
              .getPropertyValue('--comment-avatar-bg')
              .replace('#', ''),
          );

          this.currentAvatar$ = of(avatar);
        }
      });
  }

  onSubmit() {
    if (!this.commentForm.valid) return;

    let result$ = this.commentService.postComment.call({
      message: this.commentForm.value.message!,
      parentId: null,
      entityType: this.entityType,
      entityId: +this.entityId,
      userId: +this.user.id,
    });

    result$.pipe(untilDestroyed(this)).subscribe((result) => {
      if (result != undefined) {
        this.newComment.emit(true);
        this.commentForm.reset();
        this.isVisible = false;
      }
    });
  }

  cancelNewComment(): void {
    this.invertIsVisible();
    this.commentForm.reset();
  }

  invertIsVisible(): void {
    this.isVisible = !this.isVisible;
  }
}
