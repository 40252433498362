import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import {
  concatMap,
  debounce,
  debounceTime,
  last,
  Observable,
  switchMap,
  takeUntil,
  takeWhile,
} from 'rxjs';
import { ArticleService } from 'src/app/shared/store/article/article.service';
import { Article } from 'src/app/shared/store/article/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbService } from 'xng-breadcrumb';
import { filter, take } from 'rxjs/operators';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';

@UntilDestroy()
@Component({
  selector: 'app-article-detail',
  templateUrl: './article-detail.component.html',
  styleUrls: ['./article-detail.component.scss'],
})
export class ArticleDetailComponent implements OnInit, OnDestroy {
  articleId: string;
  article$: Observable<Article>;
  view$: Observable<View>;

  constructor(
    private readonly articleService: ArticleService,
    private readonly subHeaderService: SubHeaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService
  ) {
    this.article$ = this.articleService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.articleId = this.route.snapshot.params['id'];
    this.articleService.getById.call(this.articleId);

    this.article$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@articleDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@articleList', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this)
      )
      .subscribe((x) => {
        if (x === 'articlePublish') this.publishContent();
        if (x === 'articleUnpublish') this.unpublishContent();
        if (x === 'articleEdit') {
          this.router.navigate(['Content/Articles/Edit/', this.articleId]);
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  publishContent() {
    let resultPublish$ = this.articleService.publishArticle.call(
      this.route.snapshot.params['id']
    ) as Observable<Article>;

    resultPublish$
      .pipe(
        debounceTime(200),
        filter((x) => !!x),
        take(1)
      )
      .subscribe((x) => {
        this.articleService.getById.call(x.id.toString());
        this.articleService.publishArticle.reset();
      });
  }

  unpublishContent() {
    let resultUnpublish$ = this.articleService.unpublishArticle.call(
      this.route.snapshot.params['id']
    ) as Observable<Article>;

    resultUnpublish$
      .pipe(
        debounceTime(200),
        filter((x) => !!x),
        take(1)
      )
      .subscribe((x) => {
        this.articleService.getById.call(x.id.toString());
        this.articleService.unpublishArticle.reset();
      });
  }

  ngOnDestroy(): void {
    this.breadcrumbService.set('@articleDetails', ' ');
    // this.articleService.getById.reset();
    this.articleService.unpublishArticle.reset();
    this.articleService.publishArticle.reset();
  }
}
