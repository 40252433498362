import { formActions } from './form.actions';
import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { GenericState } from '../../../common/store/types';
import { ErrorsNestedFormGroup } from './types';
import { onApiCall } from '../../../common/store/generic.reducer';

export interface IFormState {
  countErrors: ErrorsNestedFormGroup;
  name: string;
  isDirty: boolean;
}

export const formInitialState: IFormState = {
  countErrors: {
    root: { count: 0, countTotal: 0, fieldName: [], groupName: '' },
    children: [],
  },
  name: '',
  isDirty: false,
};

const formReducers = [
  on(formActions.setIsDirty, (state: IFormState, { formName, isDirty }) => ({
    ...state,
    isDirty: isDirty,
  })),
  on(formActions.clear, (state: IFormState) => ({
    ...state,
    name: '',
    isDirty: false,
  })),
  on(
    formActions.countErrors,
    (state: IFormState, countErrors: ErrorsNestedFormGroup) => ({
      ...state,
      countErrors: countErrors,
    }),
  ),
];

export const formReducer = createReducer(
  formInitialState,
  ...formReducers,
) as ActionReducer<IFormState>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return formReducer(state, action);
}
