export const employeeFeatureKey = 'employee';

export type Employee = {
  id:number;
  userId: number;
  resourceId: number;
  nationality:number;
  birthDate:Date;
  placeOfBirth:string;
  ssnNumber:string;
  gender:string;
  genderLoc:string;
  maritalStatus:string;
  maritalStatusLoc:string;
  workingAddressId:number;
  homeAddressId:number;
  bankAccountId:number;
  workPhone:string;
  mobilePhone:string;
  workEmail:string;
  managerId:number;
  isManager:boolean;
  children:number;
  vehicle:string;
  homeWorkDistance:number;
  color:number;
  notes:string;
  jobId:number;
  passportNumber:string;
  resourceName:string;
  resourceCode:string;
  resourceActive:boolean;
  countryNationality:string;
  jobName:string;
  managerResourceName:string;
  workingAddressZipCode:string;
  workingAddressCity:string;
  workingAddressAddress:string;
  workingAddressAddress2:string;
  homeAddressZipCode:string;
  homeAddressCity:string;
  homeAddressAddress:string;
  homeAddressAddress2:string;
  userContactName:string;
  userAspNetUserUserName:string;
  bankAccountIBAN:string;
  managerUserContactName:string;
}
