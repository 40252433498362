import { Component, OnInit } from '@angular/core';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {filter, Observable} from "rxjs";
import {View} from "../../../../../shared/store/view/types";
import {BreadcrumbService} from "xng-breadcrumb";
import {ViewService} from "../../../../../shared/store/view/views.service";

@UntilDestroy()
@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {
  view$: Observable<View>
  constructor(private readonly breadcrumbService:BreadcrumbService,
              private readonly viewService:ViewService
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.view$.pipe(
      untilDestroyed(this),
      filter((x) => !!x)
    ).subscribe((x) => {
      this.breadcrumbService.set('@userList', x.title)
    });

  }
}
