import { AbstractControl, ValidationErrors } from '@angular/forms';

export function setValidators(
  formControl: AbstractControl | null,
  validator: (control: AbstractControl) => ValidationErrors | null,
) {
  formControl?.setValidators(validator);
  formControl?.updateValueAndValidity();
}

export function removeValidators(
  formControl: AbstractControl | null,
  validator: (control: AbstractControl) => ValidationErrors | null,
) {
  formControl?.removeValidators(validator);
  formControl?.updateValueAndValidity();
}
