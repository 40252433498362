import { InjectionToken } from '@angular/core';
import { TranslateConfigService } from './translate.config.service';

export const TRANSLATE_CONFIG_TOKEN = new InjectionToken<TranslateConfig>('TRANSLATE_CONFIG_TOKEN');

export function translateInitializerFactory(
  translateConfigService: TranslateConfigService,
  translateConfig: TranslateConfig
): any {
  const promise = translateConfigService.init(translateConfig).then((value) => {
    console.debug('Translate config', translateConfigService.get());
  });
  return () => promise;
}

/**
 * The TranslateConfig type.
 */
export declare type TranslateConfig = {
  locales: string[];
};
