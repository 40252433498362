import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, View } from '../../store/view/types';
import { ViewService } from '../../store/view/views.service';
import { SubHeaderService } from '../../store/subheader/subheader.service';
import { environment } from '../../../../environments/environment';
import {
  InsuranceCategory,
  LeadDetailModel,
  LeadStatusFlow,
} from '../../store/lead/types';
import { LeadService } from '../../store/lead/lead.service';

@Component({
  selector: 'app-custom-actions-flow-lead',
  templateUrl: './custom-actions-flow-lead.component.html',
  styleUrls: ['./custom-actions-flow-lead.component.scss'],
})
export class CustomActionsFlowLeadComponent implements OnInit {
  comparanooUrl: string;

  protected readonly InsuranceCategory = InsuranceCategory;

  view$: Observable<View>;
  customActions$: Observable<Action[]>;
  lead$: Observable<LeadDetailModel>;

  leadStatus: LeadStatusFlow[];

  constructor(
    private readonly viewService: ViewService,
    private readonly subHeaderService: SubHeaderService,
    private readonly leadService: LeadService
  ) {}

  ngOnInit(): void {
    this.view$ = this.viewService.getViews.value$;
    this.lead$ = this.leadService.getByUniqueId.value$;

    this.comparanooUrl = environment.comparanoourl;

    this.leadStatus = [
      {
        label: 'Nouveau',
        status: 'New',
        statusId: 1,
      },
      {
        label: 'Qualifié',
        status: 'Open',
        statusId: 1,
      },
      // {
      //   label: 'Gagné',
      //   status: 'Won',
      //   statusId: 1,
      // },
      // {
      //   label: 'Perdu',
      //   status: 'Lost',
      //   statusId: 1,
      // },
    ];
  }

  triggerAction(actionName) {
    this.subHeaderService.setTriggerAction(actionName);
  }
}
