<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  formGroupName="vehicleFormGroup"
>
  <div class="d-flex flex-column h-100">
    <div class="form-group-title my-1" translate>
      LEAD_FIELDSET_VEHICLE_DESCRIPTION
    </div>
    <div class="row mt-2">
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_VEHICLE_LICENSE_PLATE_NUMBER</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="vehicleLicensePlateNumber"
              name="vehicleLicensePlateNumber"
              type="text"
              value=""
              formControlName="vehicleLicensePlateNumber"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_VEHICLE_BRAND</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/Car/ReferenceData'"
              [referenceField]="'Brand'"
              [controlForm]="
                this.leadForm.controls['vehicleFormGroup'].get('vehicleBrand')
              "
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_VEHICLE_MODEL</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/Car/ReferenceData'"
              [referenceField]="'Model'"
              [referenceFilterParentName]="'Brand'"
              [referenceFilterParentValue]="
                this.leadForm.controls['vehicleFormGroup'].get('vehicleBrand')
                  ?.value
              "
              [controlForm]="
                this.leadForm.controls['vehicleFormGroup'].get('vehicleModel')
              "
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_VEHICLE_EXACT_MODEL</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/Car/ReferenceData'"
              [referenceField]="'ExactModel'"
              [referenceFilterParentName]="'Model'"
              [referenceFilterParentValue]="
                this.leadForm.controls['vehicleFormGroup'].get('vehicleModel')
                  ?.value
              "
              [controlForm]="
                this.leadForm.controls['vehicleFormGroup'].get(
                  'vehicleExactModel'
                )
              "
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_VEHICLE_CAR_BODY</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/Car/ReferenceData'"
              [referenceField]="'CarBody'"
              [controlForm]="
                this.leadForm.controls['vehicleFormGroup'].get('vehicleCarBody')
              "
            ></app-typeahead>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_VEHICLE_CAR_ENERGY</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/Car/ReferenceData'"
              [referenceField]="'CarEnergie'"
              [controlForm]="
                this.leadForm.controls['vehicleFormGroup'].get(
                  'vehicleCarEnergy'
                )
              "
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_VEHICLE_CAR_POWER</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="vehicleCarPower"
              name="vehicleCarPower"
              type="text"
              value=""
              formControlName="vehicleCarPower"
            />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_VEHICLE_VEHICLE_USAGE</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/Car/ReferenceData'"
              [referenceField]="'VehiculeUsage'"
              [controlForm]="
                this.leadForm.controls['vehicleFormGroup'].get('vehicleUsage')
              "
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_VEHICLE_VEHICLE_USAGE_PRO</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/Car/ReferenceData'"
              [referenceField]="'VehiculeUsageProSupp'"
              [controlForm]="
                this.leadForm.controls['vehicleFormGroup'].get(
                  'vehicleUsageProSupp'
                )
              "
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_VEHICLE_PARKING_TYPE</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/Car/ReferenceData'"
              [referenceField]="'ParkingType'"
              [controlForm]="
                this.leadForm.controls['vehicleFormGroup'].get(
                  'vehicleParkingType'
                )
              "
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_VEHICLE_DATE_OF_CIRCULATION</label
          >
          <div class="col">
            <input
              class="form-control form-control-sm"
              id="vehicleDateOfCirculation"
              name="vehicleDateOfCirculation"
              type="date"
              value=""
              formControlName="vehicleDateOfCirculation"
              checkFormFieldValidity
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_VEHICLE_DRIVER_IS_VEHICLE_REGISTRATION_HOLDER</label
          >
          <div class="col">
            <input
              #isDriverVehicleRegistrationHolder
              class="form-control-sm"
              type="checkbox"
              formControlName="vehicleIsDriverVehiculeRegistrationHolder"
              (change)="
                onChangeSetVehicleRegistrationValidators(
                  isDriverVehicleRegistrationHolder.checked
                )
              "
            />
          </div>
        </div>
      </div>
      <div class="col"></div>
    </div>
    <ng-container *ngIf="!this.leadForm.controls['vehicleFormGroup'].get('vehicleIsDriverVehiculeRegistrationHolder')?.value">
      <div class="form-group-title my-1" translate>
        LEAD_FIELDSET_VEHICLE_REGISTRATION_HOLDER
      </div>
      <div class="row mt-2">
        <div class="col-12 col-xl-6">
          <div class="form-group row">
            <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_REGISTRATION_HOLDER</label
            >
            <div class="col">
              <app-typeahead
                [limitToList]="true"
                [dataType]="DataType.Enum"
                [referenceEndpoint]="'Views/enum/values'"
                [referenceField]="'Comparanoo.Core.Enums.CarHolder'"
                [dropdownStyle]="DropdownStyle.DropDown"
                [controlForm]="
                          this.leadForm.controls['vehicleFormGroup'].get(
                            'vehicleRegistrationHolder'
                          )
                        "
                [valueMember]="'name'"
              ></app-typeahead>
            </div>
          </div>
          <div class="form-group row" *ngIf="this.isRegistrationHolderCompanyVisible">
            <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_REGISTRATION_HOLDER_COMPANY_NAME</label
            >
            <div class="col">
              <input
                class="form-control form-control-sm"
                id="vehicleRegistrationHolderCompanyName"
                name="vehicleRegistrationHolderCompanyName"
                type="text"
                value=""
                formControlName="vehicleRegistrationHolderCompanyName"
                checkFormFieldValidity
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-6" *ngIf="this.isRegistrationHolderNameVisible">
          <div class="form-group row">
            <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_REGISTRATION_HOLDER_FIRSTNAME</label
            >
            <div class="col">
              <input
                class="form-control form-control-sm"
                id="vehicleRegistrationHolderNameFirstName"
                name="vehicleRegistrationHolderNameFirstName"
                type="text"
                value=""
                formControlName="vehicleRegistrationHolderNameFirstName"
                checkFormFieldValidity
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_REGISTRATION_HOLDER_LASTNAME</label
            >
            <div class="col">
              <input
                class="form-control form-control-sm"
                id="vehicleRegistrationHolderNameLastName"
                name="vehicleRegistrationHolderNameLastName"
                type="text"
                value=""
                formControlName="vehicleRegistrationHolderNameLastName"
                checkFormFieldValidity
              />
            </div>
          </div>
          <div class="form-group row">
            <label class="col-4 col-form-label-sm" translate
            >LEAD_LBL_VEHICLE_REGISTRATION_HOLDER_BIRTHDATE</label
            >
            <div class="col">
              <input
                class="form-control form-control-sm"
                id="vehicleRegistrationHolderBirthdate"
                name="vehicleRegistrationHolderBirthdate"
                type="date"
                value=""
                formControlName="vehicleRegistrationHolderBirthdate"
                checkFormFieldValidity
              />
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
