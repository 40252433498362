import { Component, Input } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';

@Component({
  selector: 'app-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
})
export class PhoneNumberComponent implements ICellRendererAngularComp {
  @Input() phoneNumber!: string;
  @Input() isLinkEnabled: boolean = false;

  agInit(params: ICellRendererParams<any, any>): void {
    if (params) {
      this.phoneNumber = params.value;
    }
  }

  refresh(params: ICellRendererParams<any, any>): boolean {
    return false;
  }
}
