<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container partner-details">
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">

              <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="contactForm">
                <button type="submit" hidden="hidden"></button>


                <div class="row mb-2">
                  <div class="col-12 mr-auto">

                    <div class="row collapse">
                      <div class="col">
                        <div class="form-group">
                          <input
                            type="checkbox"
                            formControlName="isCompany"
                          />
                          <label class="ps-1 col-form-label-sm" translate
                          >CONTACT_LBL_COMPANY</label
                          >
                        </div>
                      </div>
                    </div>


                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <label
                            class="form-title-label"
                            translate
                          >CONTACT_LBL_LASTNAME</label
                          >
                          <input
                            class="form-control form-title"
                            type="text"
                            formControlName="lastname"
                            oninput="this.value = this.value.toUpperCase()"
                            checkFormFieldValidity
                          />
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <label
                            class="form-title-label"
                            translate
                          >CONTACT_LBL_FIRSTNAME</label
                          >
                          <input
                            class="form-control form-title"
                            type="text"
                            formControlName="firstname"
                            checkFormFieldValidity
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mt-4">
                  <div class="col-12 col-md">
                    <div class="form-group row collapse"
                         [style.visibility]="this.contactForm.controls['isCompany'].value ? 'visible' : 'hidden'">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTACT_LBL_COMPANY</label
                      >
                      <div class="col">
                        <!--          <app-typeahead></app-typeahead> //Société : Contact typeahead qui ne liste que les sociétés (domaine) et qui sera bindé sur parentId.            -->
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="company"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate>
                        CONTACT_LBL_ADDRESS_TYPE
                      </label>
                      <div class="col">
                        <app-typeahead
                          [readonly]="true"
                          [limitToList]="true"
                          [dataType]="DataType.Enum"
                          [referenceEndpoint]="'Views/enum/values'"
                          [referenceField]="'Comparanoo.Core.Enums.AddressType'"
                          [dropdownStyle]="DropdownStyle.DropDown"
                          [controlForm]="this.contactForm.controls['addressType']"
                        ></app-typeahead>
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTACT_LBL_ADDRESS</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="address"
                          [placeholder]="'CONTACT_PLACEHOLDER_ADDRESS' | translate"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>

                    <ng-container>
                      <div class="form-group row">
                        <label class="col-4 col-form-label-sm" translate></label>
                        <div class="col">
                          <div class="row">
                            <div class="col-2">
                              <input
                                class="form-control form-control-sm"
                                type="text"
                                formControlName="zipCode"
                                [placeholder]="'CONTACT_PLACEHOLDER_ZIPCODE' | translate"
                                checkFormFieldValidity
                                mask="00000"
                                maxlength="5"
                              />
                            </div>
                            <div class="col">
                              <app-typeahead
                                [entity]="Entity.ZipCode"
                                [referenceEndpoint]="'ZipCodes/Cities/'"
                                [dropdownStyle]="DropdownStyle.DropDown"
                                [controlForm]="this.contactForm.controls['city']"
                                [limitToList]="true"
                                [referenceFilterParentName]="'Code'"
                                [referenceFilterParentValue]="
                                this.contactForm.controls['zipCode'].value
                              "
                                [valueMember]="'name'"
                                [displayMember]="'name'"
                                [dbNameOfId]="'City'"
                                [dbNameOfName]="'City'"
                                [referenceField]="'City'"
                                [showFirstElement]="true"
                              ></app-typeahead>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTACT_LBL_COUNTRY</label
                      >
                      <div class="col">
                        <app-typeahead
                          [entity]="Entity.Country"
                          [routeEndpoint]="'Countries'"
                          [dropdownStyle]="DropdownStyle.DropDown"
                          [controlForm]="this.contactForm.controls['countryId']"
                          [limitToList]="true"
                          [domain]="'active=true'"
                        ></app-typeahead>
                      </div>
                    </div>

                  </div>
                  <div class="col">
                    <!--      invisible-->
                    <div class="form-group row collapse">
                      <label class="col-4 col-form-label-sm" translate
                      >Empty</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="empty"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTACT_LBL_PHONE_NUMBER</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="phoneNumber"
                          [placeholder]="'CONTACT_PLACEHOLDER_PHONE_NUMBER' | translate"
                          mask="00 00 00 00 00"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTACT_LBL_EMAIL</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          [placeholder]="'CONTACT_PLACEHOLDER_EMAIL' | translate"
                          formControlName="email"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTACT_LBL_JOB</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          type="text"
                          formControlName="job"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTACT_LBL_SALESTEAM</label
                      >
                      <div class="col">
                        <app-typeahead
                          [entity]="Entity.SalesTeam"
                          [routeEndpoint]="'SalesTeams'"
                          [controlForm]="
                            this.contactForm.controls['salesTeamId']
                          "
                          [limitToList]="true"
                        ></app-typeahead>
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                      >CONTACT_LBL_SALESPERSON</label
                      >
                      <div class="col">
                        <app-typeahead
                          [entity]="Entity.SalesTeam"
                          [routeEndpoint]="'SalesTeams/SalesMembers'"
                          [controlForm]="
                            this.contactForm.controls['salesPersonId']
                          "
                          [limitToList]="true"
                          [referenceFilterParentName]="'SalesTeamId'"
                          [referenceFilterParentValue]="
                            this.contactForm.controls['salesTeamId'].value
                          "
                          [dbNameOfName]="'UserContactName'"
                          [dbNameOfId]="'UserId'"
                        ></app-typeahead>
                      </div>
                    </div>

                  </div>
                </div>
                <app-contact-tabs-form [form]="this.contactForm"/>
              </form>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
