import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';
import { ICellRendererParams } from 'ag-grid-community';
import { User } from '../../../store/user/type';
import { ContractModel } from '../../../store/contract/types';
import { BadgeModel } from '../../../models/badge-model';

@Component({
  selector: 'app-badge',
  templateUrl: './badge.component.html',
  styleUrls: ['./badge.component.scss'],
})
export class BadgeComponent
  implements OnInit, ICellRendererAngularComp, OnChanges
{
  @Input('message') message: string;
  @Input('color') color: string;
  badgeColor: string = 'primary';

  ngOnInit() {
    if (this.color) this.badgeColor = this.color;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.color) this.badgeColor = this.color;
  }

  agInit(params: any): void {
    if (params) {
      this.message = params.value;
      this.badgeColor = params.data[params.color] ?? 'primary';
    }
  }
  refresh(params: ICellRendererParams): boolean {
    return false;
  }
}
