import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Action, View } from '../../store/view/types';
import { ViewService } from '../../store/view/views.service';
import {SubHeaderService} from "../../store/subheader/subheader.service";
import {Article} from "../../store/article/types";
import {ArticleService} from "../../store/article/article.service";
import {environment} from "../../../../environments/environment";

@Component({
  selector: 'app-custom-actions-flow-article',
  templateUrl: './custom-actions-flow-article.component.html',
  styleUrls: ['./custom-actions-flow-article.component.scss'],
})
export class CustomActionsFlowArticleComponent implements OnInit {
  view$: Observable<View>;
  customActions$: Observable<Action[]>;
  article$: Observable<Article>;
  comparanooUrl:string;

  constructor(
    private readonly viewService: ViewService,
    private readonly subHeaderService:SubHeaderService,
    private readonly articleService: ArticleService,
  ) {}

  ngOnInit(): void {
    this.view$ = this.viewService.getViews.value$;
    this.article$ = this.articleService.getById.value$;

    this.comparanooUrl = environment.comparanoourl;
  }

  triggerAction(actionName) {
    this.subHeaderService.setTriggerAction(actionName);
  }
}
