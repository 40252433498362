import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { JobService } from 'src/app/shared/store/job/job.service';
import { Job } from 'src/app/shared/store/job/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbService } from 'xng-breadcrumb';
import { filter } from 'rxjs/operators';
import { View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';

@UntilDestroy()
@Component({
  selector: 'app-job-details',
  templateUrl: './job-details.component.html',
  styleUrls: ['./job-details.component.scss'],
})
export class JobDetailsComponent implements OnInit, OnDestroy {
  jobId: number;
  jobBase$: Observable<Job>;
  view$: Observable<View>;

  @Input() modalId: number;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly jobService: JobService,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
  ) {
    this.jobBase$ = this.jobService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.jobId = this.route.snapshot.params['id'];

    if (this.modalId) this.jobId = this.modalId;

    this.jobService.getById.call(this.jobId.toString());

    this.jobBase$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@jobDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@jobList', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'jobEdit') {
          this.router.navigate(['HumanResources/Jobs/Edit/', this.jobId]);
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  ngOnDestroy(): void {
    this.jobService.getById.reset();
    this.breadcrumbService.set('@jobDetails', ' ');
  }
}
