import { Injectable } from '@angular/core';
import { TranslateConfig } from './types';

@Injectable({ providedIn: 'root' })
export class TranslateConfigService {
    
  private config: TranslateConfig;

  constructor() {}

  init(config: TranslateConfig): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.config = config;
      resolve(true);
    });
  }

  get(): TranslateConfig {
    return this.config;
  }
}
