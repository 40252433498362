import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable, of } from 'rxjs';
import { Entity, View } from '../../../../shared/store/view/types';
import { ViewService } from '../../../../shared/store/view/views.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { LeadService } from '../../../../shared/store/lead/lead.service';
import {
  BaseLeadFormModel,
  CarInsuranceLeadDisasterDetailFormModel,
  CarInsuranceLeadFormModel,
  CarLeadFormModel,
  ContactFormModel,
  HealthInsuranceLeadDetailFormModel,
  HealthInsuranceLeadFormModel,
  HealthLeadFormModel,
  HouseInsuranceLeadFormModel,
  HouseLeadFormModel,
  InsuranceCategory,
  InsuranceLeadFormModel,
  InsuredType,
  LeadDetailModel,
  LeadFormModel,
} from '../../../../shared/store/lead/types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { formatDate } from '../../../../shared/helper/datehelper';
import { take } from 'rxjs/operators';
import { DropdownStyle } from '../../../../shared/store/typeahead/types';
import { FormService } from '../../../../shared/store/form/form.service';
import { ErrorsNestedFormGroup } from '../../../../shared/store/form/types';

@UntilDestroy()
@Component({
  selector: 'app-lead-edit',
  templateUrl: './lead-edit.component.html',
  styleUrls: ['./lead-edit.component.scss'],
})
export class LeadEditComponent implements OnInit, OnDestroy {
  leadForm: FormGroup;
  leadId: string;
  leadIdNumber: number;
  leadType: InsuranceCategory;
  view$: Observable<View>;
  lead$: Observable<LeadDetailModel>;
  protected readonly Entity = Entity;
  protected readonly DropdownStyle = DropdownStyle;
  active = 1;
  category: string;
  fragment: string;

  constructor(
    private readonly viewService: ViewService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly subHeaderService: SubHeaderService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly leadService: LeadService,
    private readonly formService: FormService,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.lead$ = this.leadService.getByUniqueId.value$;
  }

  ngOnInit(): void {
    this.leadId = this.route.snapshot.params['id'];
    this.leadService.getByUniqueId.call(this.leadId);
    this.fragment = this.route.snapshot.fragment!;

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'leadUpdate') this.onSubmit();
        if (x == 'leadDetails') {
          this.router.navigate(['/Sales/Leads/Details', this.leadId]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@leadList', x.title);
      });

    this.lead$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@leadEdit', x.contactName);
        this.leadIdNumber = x.id;
        this.leadType = x.category;
        switch (x.category.toString()) {
          case 'Health':
            this.leadService.getHealthDetail.call(x.id.toString());
            break;
          case 'Car':
            this.leadService.getCarDetail.call(x.id.toString());
            break;
          case 'House':
            this.leadService.getHouseDetail.call(x.id.toString());
            break;
          default:
            break;
        }
        this.category = x.category.toString();
      });

    this.leadForm = new FormGroup({
      firstname: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      lastname: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
      ]),
      address: new FormControl(''),
      address2: new FormControl(''),
      zipCode: new FormControl('', [
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(5),
      ]),
      city: new FormControl(undefined, [Validators.required]),
      phoneNumber: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required, Validators.email]),
      category: new FormControl(''),
      categoryLoc: new FormControl(''),
      categoryColor: new FormControl(''),
      salesTeam: new FormControl(''),
      salesTeamMember: new FormControl(''),
      effectiveDate: new FormControl('', [Validators.required]),
      creationDate: new FormControl('', [Validators.required]),
    });

    this.lead$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.leadForm.patchValue({
          firstname: x.contactFirstName.trim(),
          lastname: x.contactLastName.trim(),
          address: x.contactAddress,
          zipCode: x.contactZipCode,
          city: x.contactCity,
          phoneNumber: x.contactPhoneNumber,
          email: x.contactEmail,
          category: x.category,
          categoryLoc: x.categoryLoc,
          categoryColor: x.categoryColor,
          salesTeam: x.salesTeamId,
          salesTeamMember: x.salesPersonId,
          effectiveDate: formatDate(
            x.insuranceLeadContractStartDate,
            'ToInverseSqlDate',
          ),
          creationDate: formatDate(x.creationDate, 'ToInverseSqlDate'),
        });
      });
  }

  onSubmit(): void {
    // console.log(this.leadForm.value);

    if (this.leadForm.invalid) {
      this.leadForm.markAllAsTouched();
      this.formService.countErrors(this.leadForm, true);
      return;
    }

    const leadFormModel: LeadFormModel = {
      id: this.leadIdNumber,
      category: this.leadForm.value.category!,
      categoryLoc: '',
      firstName: this.leadForm.value.firstname!,
      lastName: this.leadForm.value.lastname!,
      address: this.leadForm.value.address!,
      address2: this.leadForm.value.address2!,
      zipCode: this.leadForm.value.zipCode!,
      city: this.leadForm.value.city!,
      email: this.leadForm.value.email!,
      phoneNumber: this.leadForm.value.phoneNumber!,
      salesTeamId: this.leadForm.value.salesTeam!,
      salesPersonId: this.leadForm.value.salesTeamMember!,
      title: this.leadForm.value.title!,
      medium: this.leadForm.value.marketingFormGroup.marketingMedium!,
      source: this.leadForm.value.marketingFormGroup.marketingSource!,
      campaignId: this.leadForm.value.marketingFormGroup.marketingCampaignId!,
      profession: this.leadForm.value.profession!,
      creationDate: this.leadForm.value.creationDate!,
    };

    const contactFormModel: ContactFormModel = {
      firstName: this.leadForm.value.firstname!,
      lastName: this.leadForm.value.lastname!,
      address: this.leadForm.value.address!,
      address2: this.leadForm.value.address2!,
      zipCode: this.leadForm.value.zipCode!,
      city: this.leadForm.value.city!,
      email: this.leadForm.value.email!,
      phoneNumber: this.leadForm.value.phoneNumber!,
    };

    const insuranceLeadFormModel: InsuranceLeadFormModel = {
      contractStartDate: this.leadForm.value.effectiveDate,
    };

    const baseLeadFormModel: BaseLeadFormModel = {
      leadFormModel: leadFormModel,
      contactFormModel: contactFormModel,
      insuranceLeadFormModel: insuranceLeadFormModel,
    };
    let result$: Observable<any> = of();
    switch (this.leadType.toString()) {
      case 'Health':
        if (this.leadForm.get('healthFormGroup')) {
          const subscriber: HealthInsuranceLeadDetailFormModel = {
            socialSecurityKey:
              this.leadForm.value.healthFormGroup.subscriberRegime,
            birthDate: this.leadForm.value.healthFormGroup.subscriberBirthDate,
            insuredType: InsuredType.Holder,
            firstName: this.leadForm.value.healthFormGroup.subscriberFirstName,
            lastName: this.leadForm.value.healthFormGroup.subscriberLastName,
          };

          let partners: HealthInsuranceLeadDetailFormModel[] = [];

          this.leadForm.value.healthFormGroup.partners.forEach((x) => {
            const partner: HealthInsuranceLeadDetailFormModel = {
              socialSecurityKey: x.partnerRegime,
              birthDate: x.partnerBirthDate,
              insuredType: InsuredType.Partner,
              firstName: x.partnerFirstName,
              lastName: x.partnerLastName,
            };
            partners.push(partner);
          });

          let children: HealthInsuranceLeadDetailFormModel[] = [];

          this.leadForm.value.healthFormGroup.children.forEach((x) => {
            const child: HealthInsuranceLeadDetailFormModel = {
              socialSecurityKey: x.childRegime,
              birthDate: x.childBirthDate,
              insuredType: InsuredType.Child,
              firstName: x.childFirstName,
              lastName: x.childLastName,
            };
            children.push(child);
          });

          let insureds: HealthInsuranceLeadDetailFormModel[];

          insureds = [subscriber, ...partners, ...children];

          let healthInsuranceLeadFormModel: HealthInsuranceLeadFormModel = {
            routineCareCoverageLevel:
              this.leadForm.value.healthFormGroup.routineCare,
            dentalCoverageLevel: this.leadForm.value.healthFormGroup.dental,
            hospitalizationCoverageLevel:
              this.leadForm.value.healthFormGroup.hospi,
            opticalCoverageLevel: this.leadForm.value.healthFormGroup.optical,
            county: '',
          };
          let healthLeadFormModel: HealthLeadFormModel = {
            baseLeadFormModel: baseLeadFormModel,
            healthInsuranceLeadFormModel: healthInsuranceLeadFormModel,
            insureds: insureds,
          };
          result$ =
            this.leadService.updateHealthDetail.call(healthLeadFormModel);
        }
        break;
      case 'Car':
        let carInsuranceLeadFormModel: CarInsuranceLeadFormModel = {
          driverFirstName: this.leadForm.value.driverFormGroup.driverFirstname,
          driverLastName: this.leadForm.value.driverFormGroup.driverLastname,
          driverBirthdate: this.leadForm.value.driverFormGroup.driverBirthdate,
          driverZipCode: this.leadForm.value.driverFormGroup.driverZipCode,
          driverCity: this.leadForm.value.driverFormGroup.driverCity,
          driverAddress: this.leadForm.value.driverFormGroup.driverAddress,
          licenseDate: this.leadForm.value.driverFormGroup.driverLicenseDate,
          isDriverVehiculeRegistrationHolder:
            this.leadForm.value.vehicleFormGroup
              .vehicleIsDriverVehiculeRegistrationHolder,
          vehiculeRegistrationHolder: '',
          // vehicleRegistrationHolder:
          //   this.leadForm.value.vehicleFormGroup.vehicleRegistrationHolder,
          vehiculeRegistrationHolderNameLastName:
            this.leadForm.value.vehicleFormGroup
              .vehicleRegistrationHolderCompanyName,
          vehiculeRegistrationHolderNameFirstName:
            this.leadForm.value.vehicleFormGroup
              .vehicleRegistrationHolderNameLastName,
          vehiculeRegistrationHolderCompanyName:
            this.leadForm.value.vehicleFormGroup
              .vehicleRegistrationHolderNameFirstName,
          vehiculeRegistrationHolderBirthdate:
            this.leadForm.value.driverFormGroup
              .vehicleRegistrationHolderBirthdate,
          yearsInsured: this.leadForm.value.driverFormGroup.driverYearsInsured,
          bonusMalus: this.leadForm.value.driverFormGroup.driverBonusMalus,
          monthInsuranceInterruption:
            this.leadForm.value.driverFormGroup
              .driverMonthInsuranceInterruption,
          insuranceTermination:
            this.leadForm.value.driverFormGroup.driverInsuranceTermination,
          licenseAnnulation:
            this.leadForm.value.driverFormGroup.driverLicenseAnnulation.toString(),
          noLicenseConviction:
            this.leadForm.value.driverFormGroup.driverNoLicenseConviction,
          licenseCancel:
            this.leadForm.value.driverFormGroup.driverLicenseCancel,
          licenseCancelMotive:
            this.leadForm.value.driverFormGroup.driverLicenseCancelMotive,
          licenseCancelDate:
            this.leadForm.value.driverFormGroup.driverLicenseCancelDate,
          licenseSuspension:
            this.leadForm.value.driverFormGroup.driverLicenseSuspension,
          licenseSuspensionMotive:
            this.leadForm.value.driverFormGroup.driverLicenseSuspensionMotive,
          licenseSuspensionDate:
            this.leadForm.value.driverFormGroup.driverLicenseSuspensionDate,
          longestSuspension:
            this.leadForm.value.driverFormGroup.driverLongestSuspension,
          alcoholDrugControl:
            this.leadForm.value.driverFormGroup.driverAlcoholDrugControl,
          nbCarDisaster: '',
          licensePlateNumber:
            this.leadForm.value.vehicleFormGroup.vehicleLicensePlateNumber,
          brand: this.leadForm.value.vehicleFormGroup.vehicleBrand,
          model: this.leadForm.value.vehicleFormGroup.vehicleModel,
          exactModel: this.leadForm.value.vehicleFormGroup.vehicleExactModel,
          carBody: this.leadForm.value.vehicleFormGroup.vehicleCarBody,
          carEnergie: this.leadForm.value.vehicleFormGroup.vehicleCarEnergy,
          carPower: this.leadForm.value.vehicleFormGroup.vehicleCarPower,
          vehiculeUsage: this.leadForm.value.vehicleFormGroup.vehicleUsage,
          vehiculeUsageProSupp:
            this.leadForm.value.vehicleFormGroup.vehicleUsageProSupp,
          parkingType: this.leadForm.value.vehicleFormGroup.vehicleParkingType,
          dateOfCirculation:
            this.leadForm.value.vehicleFormGroup.vehicleDateOfCirculation,
          contractStartDate: this.leadForm.value.effectiveDate,
          insuranceCoverage:
            this.leadForm.value.carInsuranceFormGroup
              .carInsuranceInsuranceCoverage,
          currentInsuranceCompany:
            this.leadForm.value.carInsuranceFormGroup
              .carInsuranceCurrentInsuranceCompany,
          driverBodyCoverage:
            this.leadForm.value.carInsuranceFormGroup
              .carInsuranceDriverBodyCoverage,
          replacementVehicleCoverage:
            this.leadForm.value.carInsuranceFormGroup
              .carInsuranceReplacementVehicleCoverage,
          driverPropertyCoverage:
            this.leadForm.value.carInsuranceFormGroup
              .carInsuranceDriverPropertyCoverage,
          brandNewCoverage:
            this.leadForm.value.carInsuranceFormGroup
              .carInsuranceBrandNewCoverage,
          moneyLossCoverage:
            this.leadForm.value.carInsuranceFormGroup
              .carInsuranceMoneyLossCoverage,
        };
        let disasters: CarInsuranceLeadDisasterDetailFormModel[] = [];

        this.leadForm.value.driverFormGroup.driverDisasters.forEach((x) => {
          const disaster: CarInsuranceLeadDisasterDetailFormModel = {
            carDisasterDate: x.carDisasterDate,
            carDisasterReason: x.carDisasterReason,
            carDisasterResponsiblePerson: x.carDisasterResponsiblePerson,
          };
          disasters.push(disaster);
        });
        const carLeadFormModel: CarLeadFormModel = {
          baseLeadFormModel: baseLeadFormModel,
          carInsuranceLeadFormModel: carInsuranceLeadFormModel,
          disasters: disasters,
        };
        result$ = this.leadService.updateCarDetail.call(carLeadFormModel);
        break;
      case 'House':
        const houseInsuranceLeadFormModel: HouseInsuranceLeadFormModel = {
          address: this.leadForm.value.houseFormGroup.houseAddress,
          zipCode: this.leadForm.value.houseFormGroup.houseZipCode,
          city: this.leadForm.value.houseFormGroup.houseCity,
          maritalStatus: this.leadForm.value.houseFormGroup.houseAddress,
          profession: this.leadForm.value.houseFormGroup.houseAddress,
          housingType: this.leadForm.value.houseFormGroup.houseHousingType,
          occupancyRole: this.leadForm.value.houseFormGroup.houseOccupancyRole,
          nbOfRooms: this.leadForm.value.houseFormGroup.houseNbOfRooms,
          area: this.leadForm.value.houseFormGroup.houseArea,
          constructionType:
            this.leadForm.value.houseFormGroup.houseConstructionType,
          roofType: this.leadForm.value.houseFormGroup.houseRoofType,
          amountToInsure:
            this.leadForm.value.houseFormGroup.houseAmountToInsure,
          flatLevel: this.leadForm.value.houseFormGroup.houseFlatLevel,
          residencyType: this.leadForm.value.houseFormGroup.houseResidencyType,
          fireCoverage: this.leadForm.value.houseFormGroup.houseFireCoverage,
          waterCoverage: this.leadForm.value.houseFormGroup.houseWaterCoverage,
          theftCoverage: this.leadForm.value.houseFormGroup.houseTheftCoverage,
          glassBreakCoverage:
            this.leadForm.value.houseFormGroup.houseGlassBreakCoverage,
          publicLiabilityCoverage:
            this.leadForm.value.houseFormGroup.housePublicLiabilityCoverage,
          feeCancellationCoverage:
            this.leadForm.value.houseFormGroup.houseFeeCancellationCoverage,
          electricalDamageCoverage:
            this.leadForm.value.houseFormGroup.houseElectricalDamageCoverage,
          poolCoverage: this.leadForm.value.houseFormGroup.housePoolCoverage,
          dogCoverage: this.leadForm.value.houseFormGroup.houseDogCoverage,
          currentInsuranceCompany:
            this.leadForm.value.houseFormGroup.houseCurrentInsuranceCompany,
        };
        const houseLeadFormModel: HouseLeadFormModel = {
          baseLeadFormModel: baseLeadFormModel,
          houseInsuranceLeadFormModel: houseInsuranceLeadFormModel,
        };
        result$ = this.leadService.updateHouseDetail.call(houseLeadFormModel);

        break;
      default:
        break;
    }

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.leadService.getByUniqueId.call(this.leadId);
        this.formService.clear();
        this.router.navigate(['/Sales/Leads/Details', this.leadId]);
      });
  }

  ngOnDestroy(): void {
    this.leadService.updateCarDetail.reset();
    this.leadService.updateHouseDetail.reset();
    this.leadService.updateHealthDetail.reset();
    this.breadcrumbService.set('@leadDetails', ' ');
  }

  onBlurLastName($event: FocusEvent) {
    if (
      !this.leadForm.controls['healthFormGroup'] ||
      !this.leadForm.controls['healthFormGroup'].get('subscriberLastName') ||
      this.leadForm.controls['healthFormGroup'].get('subscriberLastName') ===
        null
    )
      return;
    else {
      // if (
      //   this.leadForm.controls['healthFormGroup'].get('subscriberLastName')!
      //     .value === ''
      // ) {
      let formControlValue =
        this.leadForm.controls['healthFormGroup'].get('subscriberLastName');
      formControlValue?.setValue(this.leadForm.get('lastname')?.value);
      // }
    }
  }

  onBlurFirstName($event: FocusEvent) {
    if (
      !this.leadForm.controls['healthFormGroup'] ||
      !this.leadForm.controls['healthFormGroup'].get('subscriberFirstName') ||
      this.leadForm.controls['healthFormGroup'].get('subscriberFirstName') ===
        null
    )
      return;
    else {
      // if (
      //   this.leadForm.controls['healthFormGroup'].get(
      //     'subscriberFirstName',
      //   )!.value === ''
      // ) {
      let formControlValue = this.leadForm.controls['healthFormGroup'].get(
        'subscriberFirstName',
      );
      formControlValue?.setValue(this.leadForm.get('firstname')?.value);
      // }
    }
  }
}
