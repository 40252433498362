<div id="internal-server-error" class="d-flex align-items-center justify-content-center h-100">
  <div class="error-message text-center">
    <div class="i-circle text-danger"><i class="mdi mdi-close"></i></div>
    <h2 class="text-danger">{{ title }}</h2>
    <h4 class="my-4 text-muted">{{ message }} </h4>
    <div>
      <button class="btn btn-outline-primary" onClick="history.go(0)">
        <i class="mdi mdi-sync mr-1"></i>
        <span translate>ERROR_RELOAD</span>
      </button>
    </div>
  </div>
</div>