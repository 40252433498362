import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PageResult, PaginationParameters } from '../../types';
import { RegisterModel, User } from './type';
import { ErrorService } from '../../error';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService
  ) {}

  //think of a way to add start date, end date, lead type as a parameters to the get methods to get the correct list
  get = (): Observable<User[]> => {
    let parameters: string[];
    let url = this.formatUrl('');
    return this.httpClient.get<User[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getById = (id: string): Observable<User> => {
    let parameters: string[];
    parameters = [id];
    let url = this.formatUrl('details', parameters);
    return this.httpClient.get<User>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  add = (registerModel: RegisterModel): Observable<boolean> => {
    let parameters: string[];
    parameters = [environment.features.users.addurl];
    let url = environment.comparanooapiurl;
    url += '/api/accounts';
    if (typeof parameters !== 'undefined') {
      parameters.forEach((parameters) => {
        url += '/';
        url += parameters;
      });
    }
    return this.httpClient.post<boolean>(url, registerModel).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getPaged = (
    paginationParam: PaginationParameters
  ): Observable<PageResult<User>> => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl('', parameters);

    let queryParams = new HttpParams();
    queryParams = queryParams.append('PageIndex', paginationParam.pageIndex);
    queryParams = queryParams.append('PageSize', paginationParam.pageSize);

    return this.httpClient
      .get<PageResult<User>>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  getCurrentUser = (): Observable<User> => {
    let parameters: string[] = [];
    let url = this.formatUrl('currentUser', parameters);
    return this.httpClient.get<User>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  formatUrl = (urlType: string, parameters?: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.users.url;

    switch (urlType) {
      case 'details':
        url += environment.features.users.details;
        break;
      case 'currentUser':
        url += environment.features.users.currentUser;
        break;
      default:
        break;
    }

    if (typeof parameters !== 'undefined') {
      parameters.forEach((parameters) => {
        url += '/';
        url += parameters;
      });
    }
    return url;
  };
}
