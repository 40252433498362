<div class="container-fluid">
    <div class="kpi-list py-3">
        <div class="kpi-col">
            <app-kpi [title]="'DASHBOARD_LBL_KPI_LEADS_SUM' | translate" kpiData="{{(leadsSum$ | async)}}"></app-kpi>
        </div>
        <div class="kpi-col">
            <app-kpi [title]="'DASHBOARD_LBL_KPI_CONTRACTS_SUM' | translate" kpiData="{{(contractsSum$ | async)}}"></app-kpi>
        </div>
        <div class="kpi-col" *ngIf="(contractsSum$ | async) as contractsSum ">
            <ng-container *ngIf="(leadsSum$ | async) as leadsSum; ">
                <app-kpi [title]="'DASHBOARD_LBL_KPI_CONVERSION_RATE' | translate"
                    kpiData="{{((contractsSum/leadsSum)*100) | number:'1.2-2':'fr-FR'}}%"
                    *ngIf="contractsSum != 0 && leadsSum != 0"></app-kpi>
            </ng-container>
        </div>
        <div class="kpi-col" *ngIf="(contractsSum$ | async) == 0 || (leadsSum$ | async) == 0">
            <app-kpi [title]="'DASHBOARD_LBL_KPI_CONVERSION_RATE' | translate" kpiData="{{ 0 | number:'1.2-2':'fr-FR'}}%"></app-kpi>
        </div>
        <div class="kpi-col">
            <app-kpi [title]="'DASHBOARD_LBL_KPI_ANNUAL_FEES_SUM' | translate"
                kpiData="{{(annualFeesSum$ | async) | number:'1.0-0':'fr-FR'}}€"></app-kpi>
        </div>
        <div class="kpi-col">
            <app-kpi [title]="'DASHBOARD_LBL_KPI_MONTHLY_FEES_AVERAGE' | translate"
                kpiData="{{(monthlyFeesAverage$ | async) | number:'1.2-2':'fr-FR'}}€"></app-kpi>
        </div>
    </div>
    <div class="row">
        <div class="col-12 col-xl-6 pb-3">
            <div class="card data-card ">
                <div class="card-chart-body">
                    <div class="card-chart-content">
                        <h6 class="card-title" translate>DASHBOARD_LBL_CHART_LEADBYDAYANDHOURSBYRANGE</h6>
                        <div class="card-chart">
                            <canvas id="leadByDayAndHoursByRange"
                                style="display: block; box-sizing: border-box; height: 248px;">
                                <!--                                     style="display: block; box-sizing: border-box; height: 248px; width: 768px;">-->
                            </canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-xl-6 pb-3">
            <div class="card data-card ">
                <div class="card-chart-body">
                    <div class="card-chart-content">
                        <h6 class="card-title" translate>DASHBOARD_LBL_CHART_LEADBYHOURSBYRANGE</h6>
                        <div class="card-chart">
                            <canvas id="leadByHoursByRange"
                                style="display: block; box-sizing: border-box; height: 248px; width: 768px;"></canvas>
                            <!--              style="display: block; box-sizing: border-box; height: 248px; width: 768px;"-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-xxl-3 pb-3">
            <div class="card data-card ">
                <div class="card-chart-body">
                    <div class="card-chart-content">
                        <h6 class="card-title" translate>DASHBOARD_LBL_CHART_LEADSPERTYPE</h6>
                        <div class="card-chart">
                            <canvas id="leadsPerTypeChart"
                                style="display: block; box-sizing: border-box; height: 248px;">
                                <!--                                     style="display: block; box-sizing: border-box; height: 248px; width: 768px;">-->
                            </canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6 col-xxl-3 pb-3">
            <div class="card data-card">

                <div class="card-chart-body">

                    <div class="card-chart-content">
                        <h6 class="card-title" translate>DASHBOARD_LBL_CHART_PEOPLEBYREGIME</h6>
                        <div class="card-chart">
                            <canvas id="peopleByRegime"
                                style="display: block; box-sizing: border-box; height: 248px;"></canvas>
                            <!--              style="display: block; box-sizing: content-box; height: 250px; width: 400px;"-->
                            <!--              width="400px" height="250px"-->
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-xxl-3 pb-3">
            <div class="card data-card">

                <div class="card-chart-body">
                    <div class="card-chart-content">
                        <h6 class="card-title" translate>DASHBOARD_LBL_CHART_PEOPLEBYAGES</h6>
                        <div class="card-chart">
                            <canvas id="peopleByAges"
                                style="display: block; box-sizing: border-box; height: 248px;"></canvas>
                            <!--              style="display: block; box-sizing: border-box; height: 248px; width: 768px;"-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-lg-6 col-xxl-3 pb-3">

            <div class="card data-card ">
                <div class="card-chart-body">
                    <div class="card-chart-content">
                        <h6 class="card-title" translate>DASHBOARD_LBL_CHART_LEADSPERCITY</h6>
                        <div class="card-chart">
                            <canvas id="leadsPerCityChart"
                                style="display: block; box-sizing: border-box; height: 248px;">
                                <!--                                     style="display: block; box-sizing: border-box; height: 248px; width: 768px;">-->
                            </canvas>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
