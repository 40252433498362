import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HeaderMenu, HeaderMenuType } from './type';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../../error';

@Injectable({
  providedIn: 'root',
})
export class HeaderApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
  ) {}

  getHeaderMenu = (menuType: HeaderMenuType): Observable<HeaderMenu[]> => {
    let parameters: string[];
    parameters = [HeaderMenuType[menuType], 'menus'];
    let url = this.formatUrl(parameters);

    return this.httpClient.get<HeaderMenu[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.layout.navigation.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
