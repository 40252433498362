import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { SubHeaderService } from '../../store/subheader/subheader.service';
import { SubHeaderOption } from '../../store/subheader/type';

@Component({
  selector: 'app-document-subheader',
  templateUrl: './document-subheader.component.html',
  styleUrls: ['./document-subheader.component.scss'],
})
export class DocumentSubheaderComponent {
  subHeaderOption$: Observable<SubHeaderOption>;

  constructor(private readonly subHeaderService: SubHeaderService) {}

  ngOnInit(): void {
    this.subHeaderOption$ = this.subHeaderService.getSubHeaderOption.value$;
  }

  triggerAction(actionName) {
    this.subHeaderService.setTriggerAction(actionName);
  }
}
