import {ListItem, ReferencesItem} from "./types";
import {GenericState, IState} from "../../../common/store/types";
import {onApiCall, onArrayApiCall} from "../../../common/store/generic.reducer";
import {typeaheadActions} from "./typeahead.actions";
import {Action, ActionReducer, createReducer} from "@ngrx/store";

export interface ITypeaheadState extends IState<ListItem> {
  get: GenericState<ListItem[]>;
  getReferencesItems: GenericState<ReferencesItem[]>;
  quickAdd: GenericState<ListItem>;
}

export const typeaheadInitialState: ITypeaheadState = {
  get: new GenericState<ListItem[]>(),
  getReferencesItems: new GenericState<ReferencesItem[]>(),
  quickAdd: new GenericState<ListItem>(),
};

const typeaheadReducers = [
  ...onArrayApiCall<ListItem>(typeaheadActions.get, 'get'),
  ...onApiCall<ReferencesItem[]>(typeaheadActions.getReferencesItems, 'getReferencesItems'),
  ...onApiCall<ListItem>(typeaheadActions.quickAdd, 'quickAdd'),
];

export const typeaheadReducer = createReducer(
  typeaheadInitialState,
  ...typeaheadReducers
) as ActionReducer<ITypeaheadState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return typeaheadReducer(state, action);
}
