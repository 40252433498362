<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  formGroupName="healthFormGroup"
>
  <div class="d-flex flex-column h-100">
    <div class="form-group-title my-1" translate>
      LEAD_FIELDSET_HEALTH_COVERAGE
    </div>
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group row">
          <label
            class="col-4 col-form-label-sm"
            translate
          >LEAD_LBL_HEALTH_MEDICAL_CARE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              <ngb-rating [formControlName]="'routineCare'">
                <ng-template let-fill="fill" let-index="index">
                  <i
                    class="fs-4 mdi mdi-star{{
                      fill !== 100 ? '-outline' : ''
                    }}"
                    [class.filled]="fill === 100"
                  ></i>
                </ng-template>
              </ngb-rating>
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-4 col-form-label-sm"
            translate
          >LEAD_LBL_HEALTH_HOSPITALIZATION</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              <ngb-rating [formControlName]="'hospi'">
                <ng-template let-fill="fill" let-index="index">
                  <i
                    class="fs-4 mdi mdi-star{{
                      fill !== 100 ? '-outline' : ''
                    }}"
                    [class.filled]="fill === 100"
                  ></i>
                </ng-template>
              </ngb-rating>
            </span>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group row">
          <label
            class="col-4 col-form-label-sm"
            translate
          >LEAD_LBL_HEALTH_DENTAL</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              <ngb-rating [formControlName]="'dental'">
                <ng-template let-fill="fill" let-index="index">
                  <i
                    class="fs-4 mdi mdi-star{{
                      fill !== 100 ? '-outline' : ''
                    }}"
                    [class.filled]="fill === 100"
                  ></i>
                </ng-template>
              </ngb-rating>
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label
            class="col-4 col-form-label-sm"
            translate
          >LEAD_LBL_HEALTH_OPTICAL</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              <ngb-rating [formControlName]="'optical'">
                <ng-template let-fill="fill" let-index="index">
                  <i
                    class="fs-4 mdi mdi-star{{
                      fill !== 100 ? '-outline' : ''
                    }}"
                    [class.filled]="fill === 100"
                  ></i>
                </ng-template>
              </ngb-rating>
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="mt-2">
      <div class="form-group-title my-1" translate>
        LEAD_FIELDSET_FAMILY_COMPOSITION
      </div>
      <div class="row my-2 family-row">
        <div class="col-md-6 col-12">
          <div class="card">
            <div class="card-header">
              <h5 class="form-group-title my-1" translate>
                LEAD_FIELDSET_INSURED_SUBSCRIBER
              </h5>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <div class="col-12 col-xl-6">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >LEAD_LBL_LASTNAME</label
                    >
                    <div class="col">
                      <input type="hidden" formControlName="subscriberId" />
                      <input
                        class="form-control form-control-sm"
                        id="subscriberLastName"
                        name="subscriberLastName"
                        type="text"
                        value=""
                        formControlName="subscriberLastName"
                        oninput="this.value = this.value.toUpperCase()"
                        checkFormFieldValidity
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >LEAD_LBL_FIRSTNAME</label
                    >
                    <div class="col">
                      <input
                        class="form-control form-control-sm"
                        id="subscriberFirstName"
                        name="subscriberFirstName"
                        type="text"
                        value=""
                        formControlName="subscriberFirstName"
                        checkFormFieldValidity
                      />
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>
                      LEAD_LBL_REGIME
                    </label>
                    <div class="col">
                      <app-typeahead
                        [limitToList]="true"
                        [dataType]="DataType.Enum"
                        [referenceEndpoint]="'Views/enum/values'"
                        [referenceField]="'Comparanoo.Core.Enums.RegimeEnum'"
                        [dropdownStyle]="dropdownStyle"
                        [controlForm]="
                          this.leadForm.controls['healthFormGroup'].get(
                            'subscriberRegime'
                          )
                        "
                      ></app-typeahead>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-6">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>
                      LEAD_LBL_BIRTHDATE
                    </label>
                    <div class="col">
                      <input
                        class="form-control form-control-sm"
                        id="subscriberBirthDate"
                        name="subscriberBirthDate"
                        type="date"
                        value=""
                        formControlName="subscriberBirthDate"
                        checkFormFieldValidity
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-6 col-12">
          <button
            class="btn-sm btn btn-outline-primary btn-add"
            (click)="addPartner()"
            *ngIf="!this.hasPartner"
            translate
          >
            LEAD_LBL_ADD_PARTNER
          </button>
          <ng-container *ngIf="this.hasPartner" formArrayName="partners">
            <div
              class="card"
              *ngFor="let partner of partners.controls; index as i"
              [formGroupName]="i"
            >
              <div class="card-header">
                <h5 class="form-group-title my-1" translate>
                  LEAD_FIELDSET_INSURED_PARTNER
                </h5>
                <button
                  class="btn btn-outline-danger btn-sm"
                  (click)="removePartner()"
                >
                  <i class="mdi mdi-close"></i>
                </button>
              </div>
              <div class="card-body">
                <div class="row mt-2">
                  <div class="col-12 col-xl-6">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >LEAD_LBL_LASTNAME</label
                      >
                      <div class="col">
                        <input type="hidden" formControlName="partnerId" />
                        <input
                          class="form-control form-control-sm"
                          id="partnerLastName"
                          name="partnerLastName"
                          type="text"
                          value=""
                          formControlName="partnerLastName"
                          oninput="this.value = this.value.toUpperCase()"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >LEAD_LBL_FIRSTNAME</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="partnerFirstName"
                          name="partnerFirstName"
                          type="text"
                          value=""
                          formControlName="partnerFirstName"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >LEAD_LBL_REGIME</label
                      >
                      <div class="col">
                        <app-typeahead
                          [limitToList]="true"
                          [dataType]="DataType.Enum"
                          [referenceEndpoint]="'Views/enum/values'"
                          [referenceField]="'Comparanoo.Core.Enums.RegimeEnum'"
                          [dropdownStyle]="dropdownStyle"
                          [controlForm]="partner.get('partnerRegime')"
                        ></app-typeahead>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-6">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >LEAD_LBL_BIRTHDATE</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="partnerBirthDate"
                          name="partnerBirthDate"
                          type="date"
                          value=""
                          formControlName="partnerBirthDate"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
      <hr />
      <div class="row my-2 family-row">
        <ng-container formArrayName="children">
          <div
            class="col-md-6 col-12"
            *ngFor="let childForm of children.controls; index as i"
            [formGroupName]="i"
          >
            <div class="card">
              <div class="card-header">
                <h5 class="form-group-title my-1" translate>
                  LEAD_FIELDSET_INSURED_CHILD
                </h5>
                <button
                  class="btn btn-outline-danger btn-sm"
                  (click)="removeChild(i)"
                  data-bs-toggle="tooltip"
                >
                  <i class="mdi mdi-close"></i>
                </button>
              </div>
              <div class="card-body">
                <div class="row mt-2">
                  <div class="col-12 col-xl-6">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >LEAD_LBL_LASTNAME</label
                      >
                      <div class="col">
                        <input type="hidden" formControlName="childId" />
                        <input
                          class="form-control form-control-sm"
                          id="childLastName"
                          name="childLastName"
                          type="text"
                          value=""
                          formControlName="childLastName"
                          oninput="this.value = this.value.toUpperCase()"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >LEAD_LBL_FIRSTNAME</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="childFirstName"
                          name="childFirstName"
                          type="text"
                          value=""
                          formControlName="childFirstName"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>

                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >LEAD_LBL_REGIME</label
                      >
                      <div class="col">
                        <app-typeahead
                          [limitToList]="true"
                          [dataType]="DataType.Enum"
                          [referenceEndpoint]="'Views/enum/values'"
                          [referenceField]="'Comparanoo.Core.Enums.RegimeEnum'"
                          [dropdownStyle]="dropdownStyle"
                          [controlForm]="childForm.get('childRegime')"
                        ></app-typeahead>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-xl-6">
                    <div class="form-group row">
                      <label class="col-4 col-form-label-sm" translate
                        >LEAD_LBL_BIRTHDATE</label
                      >
                      <div class="col">
                        <input
                          class="form-control form-control-sm"
                          id="childBirthDate"
                          name="childBirthDate"
                          type="date"
                          value=""
                          formControlName="childBirthDate"
                          checkFormFieldValidity
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="col-md-6 col-12">
          <button
            class="btn-sm btn btn-outline-primary btn-add"
            (click)="addChild()"
            translate
          >
            LEAD_LBL_ADD_CHILD
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
