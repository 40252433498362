<ng-container *ngFor="let toast of this.notifications$ | async">
<ngb-toast
    *ngIf="toast.type === NotificationType.Toast"
    [class]="toast.notificationStatus | toastCss"
    class="text-light" id="{{toast.timestamp}}"
    [autohide]="true" [delay]="toast.timeout || 5000"
    (hidden)="remove(toast)"
  >{{ toast.content | translate }}
  </ngb-toast>
</ng-container>
