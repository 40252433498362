import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { GenericState } from 'src/app/common/store/types';
import { PageResult } from '../../types';
import { glossaryActions } from './glossary.actions';
import { Glossary } from './types';

export interface IGlossaryState {
  getPaged: GenericState<any>;
  getById: GenericState<Glossary>;
  postGlossary: GenericState<any>;
  publishGlossary: GenericState<any>;
  unpublishGlossary: GenericState<any>;
  updateGlossary: GenericState<any>;
}
export const glossaryInitialState: IGlossaryState = {
  getPaged: new GenericState<any>(),
  getById: new GenericState<Glossary>(),
  postGlossary: new GenericState<any>(),
  publishGlossary: new GenericState<any>(),
  unpublishGlossary: new GenericState<any>(),
  updateGlossary: new GenericState<any>(),
};

const glossaryReducers = [
  ...onApiCall<PageResult<Glossary>>(glossaryActions.getPaged, 'getPaged'),
  ...onApiCall<Glossary>(glossaryActions.getById, 'getById'),
  ...onApiCall<any>(glossaryActions.postGlossary, 'postGlossary'),
  ...onApiCall<any>(glossaryActions.publishGlossary, 'publishGlossary'),
  ...onApiCall<any>(glossaryActions.unpublishGlossary, 'unpublishGlossary'),
  ...onApiCall<any>(glossaryActions.updateGlossary, 'updateGlossary'),
];

export const glossaryReducer = createReducer(
  glossaryInitialState,
  ...glossaryReducers
) as ActionReducer<IGlossaryState, Action>;

export function userReducerFunc(state: any | undefined, action: Action) {
  return glossaryReducer(state, action);
}
