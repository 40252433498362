import { createFeatureSelector } from "@ngrx/store";
import { createApiCallSelectors } from "src/app/common/store/generic.selectors";
import { IJobState } from "./job.reducer";
import { jobFeatureKey } from "./types";

const jobFeatureState = 
    createFeatureSelector<IJobState>(jobFeatureKey);

export const jobSelectors = {
    getById: createApiCallSelectors<number, IJobState>(
        jobFeatureState,
        'getById'
    ),
    postJob: createApiCallSelectors<any, IJobState>(
        jobFeatureState,
        'postJob'
    ),
    updateJob: createApiCallSelectors<any, IJobState>(
        jobFeatureState,
        'updateJob'
    )
};