<div
  class="tab-pane fade flex-even active show"
  id="contacts"
  role="tabpanel"
  aria-labelledby="contacts-tab"
  *ngIf="this.contactDetail$ | async as contactDetail"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-4">
      <div class="col">
        <div class="form-group row">
          <div class="col-auto">
            {{ contactDetail.notes }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
