import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { IHeaderState } from './header.reducer';
import { headerFeatureKey, HeaderMenu } from './type';

const headerFeatureState =
  createFeatureSelector<IHeaderState>(headerFeatureKey);

export const headerSelectors = {
  getHeaderMenu: createApiCallSelectors<HeaderMenu[], IHeaderState>(
    headerFeatureState,
    'getHeaderMenu'
  ),
};
