import { RouterModule, Routes } from '@angular/router';
import { AuthentificationGuard } from 'src/app/shared/guard/authentification-guard';
import { HeaderMenuType } from 'src/app/shared/store/header/type';
import { SubHeaderType } from 'src/app/shared/store/subheader/type';
import { ContractListComponent } from './contract-list/contract-list.component';
import { ContractDetailsComponent } from './contract-details/contract-details.component';
import { NgModule } from '@angular/core';
import { Entity } from 'src/app/shared/store/view/types';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { ContractCreateComponent } from './contract-create/contract-create.component';
import { ContractEditComponent } from './contract-edit/contract-edit.component';

const routes: Routes = [
  {
    path: 'Sales/ContractRequests',
    data: {
      breadcrumb: { label: ' ', alias: 'contractList' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: ContractListComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.ContractRequest,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: ContractDetailsComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.ContractRequest,
          breadcrumb: { label: ' ', alias: 'contractDetails' },
        },
      },
      {
        path: 'Edit/:id',
        component: ContractEditComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.ContractRequest,
          breadcrumb: { label: ' ', alias: 'contractEdit' },
        },
      },
      {
        path: 'Create/:category',
        component: ContractCreateComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.ContractRequest,
          breadcrumb: { label: ' ', alias: 'contractCreate' },
        },
      },
    ],
  },
  {
    path: 'Sales/Contracts',
    data: {
      breadcrumb: { label: ' ', alias: 'contractList' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: ContractListComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.Contract,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: ContractDetailsComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.Contract,
          breadcrumb: { label: ' ', alias: 'contractDetails' },
        },
      },
      // {
      //   path: 'Edit/:id',
      //   component: ContractEditComponent,
      //   canActivate: [AuthentificationGuard],
      //   canDeactivate: [HasUnsavedChangesGuard],
      //   data: {
      //     headerMenu: HeaderMenuType.Sales,
      //     entity: Entity.Contract,
      //     breadcrumb: { label: ' ', alias: 'contractEdit' },
      //   },
      // },
      // {
      //   path: 'Create/:category',
      //   component: ContractCreateComponent,
      //   canActivate: [AuthentificationGuard],canDeactivate: [HasUnsavedChangesGuard],
      //   data: {
      //     headerMenu: HeaderMenuType.Sales,
      //     entity: Entity.Contract,
      //     subHeaderOption: SubHeaderType.ContractCreate,
      //     breadcrumb: { label: ' ', alias: 'contractCreate' },
      //   },
      // },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContractRoutingModule {}
