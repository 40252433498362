import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { LabelWithValue, Period } from './types';
import { createAction, props } from '@ngrx/store';

export const reportingActions = {
  getReportByFilter: createApiCallActions<any[]>(
    'reporting',
    'getReportByFilter'
  ),
  setBarGraphStartDate: createAction(
    '[Reporting] Set bar graph start date',
    props<{ barGraphStartDate: string }>()
  ),
  setBarGraphEndDate: createAction(
    '[Reporting] Set bar graph start date',
    props<{ barGraphEndDate: string }>()
  ),

  getReportMalePeopleByAge: createApiCallActions<any[]>(
    'reporting',
    'getReportMalePeopleByAge'
  ),
  getReportFemalePeopleByAge: createApiCallActions<any[]>(
    'reporting',
    'getReportFemalePeopleByAge'
  ),
  getReportLeadsMonthlyRange: createApiCallActions<any[]>(
    'reporting',
    'getReportLeadsMonthlyRange'
  ),
  getLeadsByDayAndHoursRange: createApiCallActions<any[]>(
    'reporting',
    'getLeadsByDayAndHoursRange'
  ),
  getReportPeopleByRegime: createApiCallActions<any[]>(
    'reporting',
    'getReportPeopleByRegime'
  ),
  getReportLeadsByType: createApiCallActions<any[]>(
    'reporting',
    'getReportLeadsByType'
  ),
  getReportLeadsByCity: createApiCallActions<any[]>(
    'reporting',
    'getReportLeadsByCity'
  ),
  getReportLeadsSum: createApiCallActions<number>(
    'reporting',
    'getReportLeadsSum'
  ),
  getReportContractsSum: createApiCallActions<number>(
    'reporting',
    'getReportContractsSum'
  ),
  getReportAnnualFeesSum: createApiCallActions<number>(
    'reporting',
    'getReportAnnualFeesSum'
  ),
  getReportMonthlyFeesAverage: createApiCallActions<number>(
    'reporting',
    'getReportMonthlyFeesAverage'
  ),
  getPeriods: createApiCallActions<Period[]>('reporting', 'getPeriods'),
};
export type ReportingAction = typeof reportingActions;
