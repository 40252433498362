import { IState, GenericState } from 'src/app/common/store/types';
import { PaymentSplitting } from './type';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { paymentSplittingActions } from './payment-splitting.actions';
import { Action, ActionReducer, createReducer } from '@ngrx/store';

export interface IPaymentSplittingState extends IState<PaymentSplitting> {
  getById: GenericState<PaymentSplitting>;
  getByProductLine: GenericState<PaymentSplitting[]>;
}

export const paymentSplittingInitialState: IPaymentSplittingState = {
  getById: new GenericState<PaymentSplitting>(),
  getByProductLine: new GenericState<PaymentSplitting[]>(),
};

const paymentSplittingReducers = [
  ...onApiCall<PaymentSplitting>(paymentSplittingActions.getById, 'getById'),
  ...onApiCall<PaymentSplitting[]>(
    paymentSplittingActions.getByProductLine,
    'getByProductLine',
  ),
];

export const paymentSplittingReducer = createReducer(
  paymentSplittingInitialState,
  ...paymentSplittingReducers,
) as ActionReducer<IPaymentSplittingState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return paymentSplittingReducer(state, action);
}
