import { Injectable } from '@angular/core';
import { BaseServiceFacade } from 'src/app/common/store/types';
import { AppState } from '../types';
import { Store } from '@ngrx/store';
import { Notification, NotificationType } from './types';
import { notificationActions } from './notification.action';
import { notificationSelectors } from './notification.selectors';

@Injectable()
export class NotificationService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  add = (notification: Notification) => {
    this.store.dispatch(notificationActions.add({ notification }));
  };

  remove = (notification: Notification) => {
    this.store.dispatch(notificationActions.remove({ notification }));
  };

  clear = () => {
    this.store.dispatch(notificationActions.clear());
  };

  clearByType = (notificationType: NotificationType) => {
    this.store.dispatch(notificationActions.clearByType({ notificationType }));
  };

  getNotifications = () =>
    this.store.select(notificationSelectors.getNotifications);
}
