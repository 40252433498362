import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactListComponent } from './contact-list/contact-list.component';
import { AuthentificationGuard } from '../../../shared/guard/authentification-guard';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';
import { HeaderMenuType } from '../../../shared/store/header/type';
import { Entity } from '../../../shared/store/view/types';
import { SubHeaderType } from '../../../shared/store/subheader/type';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { ContactCreateComponent } from './contact-create/contact-create.component';
import { ContactEditComponent } from './contact-edit/contact-edit.component';

const routes: Routes = [
  {
    path: 'Contacts/Contacts',
    data: {
      breadcrumb: { label: ' ', alias: 'contactList' },
    },
    children: [
      {
        path: '',
        redirectTo: 'List',
        pathMatch: 'full',
      },
      {
        path: 'List',
        component: ContactListComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Contacts,
          entity: Entity.Contact,
          breadcrumb: { skip: true },
        },
      },
      {
        path: 'Details/:id',
        component: ContactDetailsComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Contacts,
          entity: Entity.Contact,
          breadcrumb: { label: ' ', alias: 'contactDetails' },
        },
      },
      {
        path: 'Edit/:id',
        component: ContactEditComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Contacts,
          entity: Entity.Contact,
          breadcrumb: { label: ' ', alias: 'contactEdit' },
        },
      },
      {
        path: 'Create',
        component: ContactCreateComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Contacts,
          entity: Entity.Contact,
          breadcrumb: { label: ' ', alias: 'contactCreate' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContactRoutingModule {}
