import { NgModule } from '@angular/core';
import {
  NgbAlertModule,
  NgbCollapseModule,
  NgbDatepicker,
  NgbDropdownModule,
  NgbModalModule,
  NgbNavModule,
  NgbPaginationModule,
  NgbRating,
  NgbToastModule,
  NgbTypeaheadModule,
} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [],
  imports: [
    NgbDropdownModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgbModalModule,
    NgbAlertModule,
    NgbToastModule,
    NgbNavModule,
    NgbRating,
    NgbDatepicker,
    NgbCollapseModule,
  ],
  exports: [
    NgbDropdownModule,
    NgbPaginationModule,
    NgbTypeaheadModule,
    NgbModalModule,
    NgbAlertModule,
    NgbToastModule,
    NgbNavModule,
    NgbRating,
    NgbDatepicker,
    NgbCollapseModule,
  ],
})
export class NgBootstrapModule {}
