import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { ArticleRoutingModule } from "./article-routing.module";
import { ArticleListComponent } from './article-list/article-list.component';
import { ArticleCreateComponent } from './article-create/article-create.component';
import { ArticleDetailComponent } from './article-detail/article-detail.component';
import { ArticleEditComponent } from './article-edit/article-edit.component';

@NgModule({
    declarations:[
    
    ArticleListComponent,
         ArticleCreateComponent,
         ArticleDetailComponent,
         ArticleEditComponent
  ],
    imports:[
        CommonModule,
        SharedModule,
        ArticleRoutingModule,
    ]
})
export class ArticleModule{}