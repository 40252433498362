import { Injectable, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription, filter, Observable, combineLatest } from 'rxjs';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { RouterService } from '../router/router.service';
import { AppState } from '../types';
import { headerActions } from './header.actions';
import { headerSelectors } from './header.selectors';
import { HeaderMenu, HeaderMenuType } from './type';
import { AuthentificationService } from '../../authentification/authentification.service';
import { FormService } from '../form/form.service';
import { tap } from 'rxjs/operators';
import { NotificationType } from '../notification/types';
import { NotificationService } from '../notification/notification.service';

@Injectable()
export class HeaderService extends BaseServiceFacade {
  headerMenuData$: Observable<HeaderMenuType>;
  isFormDirty$: Observable<boolean>;

  constructor(
    store: Store<AppState>,
    private readonly routerService: RouterService,
    private readonly authService: AuthentificationService,
    private readonly formService: FormService,
    private readonly notificationService: NotificationService,
  ) {
    super(store);

    this.headerMenuData$ = this.routerService.routeHeadMenuData$;
    this.isFormDirty$ = this.formService.getIsDirty();

    combineLatest([this.headerMenuData$, this.isFormDirty$])
      .pipe(filter(([headerMenu, isDirty]) => !!headerMenu && !isDirty))
      .subscribe(([menuType, isDirty]) => {
        if (this.authService.isAuthenticated()) {
          this.getHeaderMenu.call(menuType);
          this.notificationService.clearByType(
            NotificationType.ToastValidation,
          );
        }
      });
  }

  getHeaderMenu: GenericApiCall<HeaderMenu[], HeaderMenuType> =
    this.genericApiCall(
      headerActions.getHeaderMenu,
      headerSelectors.getHeaderMenu,
      true,
    );
}
