import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { PageResult } from '../../types';
import { AppState } from '../types';
import { jobActions } from './job.action';
import { jobSelectors } from './job.selector';
import { Job, JobPost, JobUpdate } from './types';

@Injectable()
export class JobService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<Job, string> = this.genericApiCall(
    jobActions.getById,
    jobSelectors.getById,
    true
  );

  postJob: GenericApiCall<any, JobPost> = this.genericApiCall(
    jobActions.postJob,
    jobSelectors.postJob,
    true
  );

  updateJob: GenericApiCall<any, JobUpdate> = this.genericApiCall(
    jobActions.updateJob,
    jobSelectors.updateJob,
    true
  );
}
