import { Injectable } from '@angular/core';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { AppState } from '../types';
import { Store } from '@ngrx/store';
import { PageResult } from '../../types';
import {
  CreateSalesTeamFormModel,
  SalesTeam,
  SalesTeamBase,
  SalesTeamPost,
  SalesTeamUpdate,
} from './types';
import { salesTeamActions } from './sales-team.action';
import { salesTeamSelectors } from './sales-team.selector';
import { SalesTeamMember } from '../sales-team-member/types';
import { JobPost, JobUpdate } from '../job/types';
import { jobActions } from '../job/job.action';
import { jobSelectors } from '../job/job.selector';

@Injectable()
export class SalesTeamService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<SalesTeam, string> = this.genericApiCall(
    salesTeamActions.getById,
    salesTeamSelectors.getById,
    true,
  );
  getList: GenericApiCall<SalesTeamBase[], void> = this.genericApiCall(
    salesTeamActions.getList,
    salesTeamSelectors.getList,
    true,
  );
  getSalesTeamMemberList: GenericApiCall<SalesTeamMember[], number> =
    this.genericApiCall(
      salesTeamActions.getSalesTeamMemberList,
      salesTeamSelectors.getSalesTeamMemberList,
      true,
    );
  getSalesTeamTypeAheadList: GenericApiCall<SalesTeamBase[], void> =
    this.genericApiCall(
      salesTeamActions.getSalesTeamTypeAheadList,
      salesTeamSelectors.getSalesTeamTypeAheadList,
      true,
    );
  getSalesTeamMemberTypeAheadList: GenericApiCall<SalesTeamMember[], number> =
    this.genericApiCall(
      salesTeamActions.getSalesTeamMemberTypeAheadList,
      salesTeamSelectors.getSalesTeamMemberTypeAheadList,
      true,
    );
  getSalesTeamModel: GenericApiCall<CreateSalesTeamFormModel, void> =
    this.genericApiCall(
      salesTeamActions.getSalesTeamModel,
      salesTeamSelectors.getSalesTeamModel,
      true,
    );
  postSalesTeam: GenericApiCall<SalesTeam, SalesTeamPost> = this.genericApiCall(
    salesTeamActions.postSalesTeam,
    salesTeamSelectors.postSalesTeam,
    true,
  );

  updateSalesTeam: GenericApiCall<SalesTeam, SalesTeamUpdate> =
    this.genericApiCall(
      salesTeamActions.updateSalesTeam,
      salesTeamSelectors.updateSalesTeam,
      true,
    );
}
