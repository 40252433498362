<div aria-live="polite" aria-atomic="true" class="bg-dark position-relative">
  <ng-container *ngFor="let toast of this.notifications$ | async">
    <div *ngIf="toast.type === NotificationType.ToastValidation"
         class="toast-container position-absolute p-3 pe-5 top-0 end-0">

      <ngb-toast class="bg-light"
                 [header]="+toast.content === 1 ?
                 ('TOAST_VALIDATION_MSG_HEADER_ERROR' | translate)
                 :('TOAST_VALIDATION_MSG_HEADER_ERRORS' | translate :{ countTotal: toast.content})"
                 [delay]="toast.timeout ?? 5000" [autohide]="true" (hidden)="remove(toast)">
        <app-toast-validation-content/>
      </ngb-toast>

    </div>
  </ng-container>
</div>
