import { inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmationComponent } from '../components/confirmation/confirmation.component';
import { FormService } from '../store/form/form.service';
import { take } from 'rxjs/operators';
import { NgxUiLoaderService } from 'ngx-ui-loader';

export const HasUnsavedChangesGuard = async () => {
  const formService = inject(FormService);
  const loader = inject(NgxUiLoaderService);
  var shouldNavigate = true;

  try {
    let isDirty = false;
    formService
      .getIsDirty()
      .pipe(take(1))
      .subscribe((x) => {
        isDirty = x;
      });

    // Récupérer service du loader pour le cacher
    loader.stopAll(); // Cacher le loader
    shouldNavigate = true;
    if (isDirty) {
      const modalService = inject(NgbModal);
      const modalRef = modalService.open(ConfirmationComponent, {
        backdrop: 'static',
      });
      shouldNavigate = false;
      shouldNavigate = await modalRef.result;
    }
    return shouldNavigate;
  } catch (error) {
    console.log(error);
    return true;
  } finally {
    if (shouldNavigate) formService.clear();
  }
};
