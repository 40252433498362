import { GenericState, IState } from 'src/app/common/store/types';
import { viewActions } from './view.actions';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { EnumModel, View } from './types';

export interface IViewState extends IState<View> {
  getViews: GenericState<View>;
  getEnumValues: GenericState<EnumModel[]>;
}

export const ViewInitialState: IViewState = {
  getViews: new GenericState<View>(),
  getEnumValues: new GenericState<EnumModel[]>(),
};

const ViewReducers = [
  ...onApiCall<View>(viewActions.getViews, 'getViews'),
  ...onApiCall<EnumModel[]>(viewActions.getEnumValues, 'getEnumValues'),
];

export const viewReducer = createReducer(
  ViewInitialState,
  ...ViewReducers,
) as ActionReducer<IViewState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return viewReducer(state, action);
}
