<ul class="navbar-nav flex-fill" *ngIf="this.headerMenu$">
    <ng-container *ngFor="let headerMenu of this.headerMenu$ | async">
        <li class="nav-item" *ngIf="!this.headerMenu.items?.length else subMenu">
            <a class="nav-link" [routerLink]="this.headerMenu.route">
                {{ headerMenu.title }}
            </a>
        </li>
        <ng-template #subMenu>
            <li class="nav-item" ngbDropdown>
                <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown" role="button" translate>{{
                    headerMenu.title }}</a>
                <div ngbDropdownMenu aria-labelledby="navbarDropdown" class="dropdown-menu dropdown-menu-end">
                    <a ngbDropdownItem *ngFor="let subMenu of this.headerMenu.items"
                        [routerLink]="(this.headerMenu.route? this.headerMenu.route : '') + (subMenu.route ? subMenu.route : '')"
                        >
                        {{ subMenu.title }}
                    </a>
                </div>
            </li>
        </ng-template>
    </ng-container>
</ul>