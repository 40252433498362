import { NgModule } from "@angular/core";
import { routerReducer } from "@ngrx/router-store";
import { StoreModule } from "@ngrx/store";
import { RouterService } from "./router.service";

@NgModule({
  imports: [StoreModule.forFeature("router", routerReducer)],
  providers: [RouterService],
})
export class StoreRouterModule {}
