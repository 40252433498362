import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { ISalesTeamMemberState } from './sales-team-member.reducer';
import { salesTeamMemberFeatureKey } from './types';

const salesTeamMemberFeatureState =
  createFeatureSelector<ISalesTeamMemberState>(salesTeamMemberFeatureKey);

export const salesTeamMemberSelectors = {
  getById: createApiCallSelectors<number, ISalesTeamMemberState>(
    salesTeamMemberFeatureState,
    'getById'
  ),
};
