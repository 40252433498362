import { createFeatureSelector } from '@ngrx/store';
import { IPaymentSplittingState } from './payment-splitting.reducer';
import { paymentSplittingFeatureKey } from './type';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';

const paymentSplittingFeatureState =
  createFeatureSelector<IPaymentSplittingState>(paymentSplittingFeatureKey);

export const paymentSplittingSelectors = {
  getById: createApiCallSelectors<string, IPaymentSplittingState>(
    paymentSplittingFeatureState,
    'getById',
  ),
  getByProductLine: createApiCallSelectors<string, IPaymentSplittingState>(
    paymentSplittingFeatureState,
    'getByProductLine',
  ),
};
