import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { CountyAction, countyActions } from './county.actions';
import { CountyApiService } from './county.api.services';
import { ICountyState } from './county.reducer';

@Injectable()
export class CountyEffects {
  constructor(
    private readonly store: Store<ICountyState>,
    private readonly countyApiService: CountyApiService,
    private readonly actions$: Actions<CountyAction>
  ) {}

  get = createApiCallEffects(
    this,
    this.actions$,
    countyActions.get,
    this.countyApiService.get
  );

  getById = createApiCallEffects(
    this,
    this.actions$,
    countyActions.getById,
    this.countyApiService.getById
  );

  getPaged = createApiCallEffects(
    this,
    this.actions$,
    countyActions.getPaged,
    this.countyApiService.getPaged
  );
}
