<app-document-subheader></app-document-subheader>
<app-custom-actions-flow-glossary></app-custom-actions-flow-glossary>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.glossary$ | async as glossary">
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <div class="form-title">{{ glossary.name }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-lg-6">

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>GLOSSARY_LBL_URL</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ glossary.url }}
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>GLOSSARY_LBL_CREATION_DATE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ glossary.creationDate | date : "dd/MM/yyyy HH:mm" }}
                      </span>
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >GLOSSARY_LBL_PUBLICATION_DATE</label>
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm"
                            *ngIf="glossary.publicationDate != null; else elseBlock"
                      >
                        {{ glossary.publicationDate | date : "dd/MM/yyyy HH:mm" }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-12">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate>GLOSSARY_LBL_PAGE_CONTENT</label>
                    <div
                      [innerHTML]="glossary.pageContent"
                      class="card card-light card-body"
                    ></div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.glossary$ | async as glossary">
      <app-comment-panel [entityType]="'Glossary'" [entityId]="glossary.id.toString()"></app-comment-panel>
    </div>
  </div>
</div>

<ng-template #elseBlock>
  <span class="form-control-plaintext form-control-sm" translate
  >GLOSSARY_LBL_NOT_PUBLISHED</span
  ></ng-template
>
