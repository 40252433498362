import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, filter } from 'rxjs';
import { CountryService } from 'src/app/shared/store/country/country.service';
import { Country } from 'src/app/shared/store/country/types';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { Entity, View } from 'src/app/shared/store/view/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-country-edit',
  templateUrl: './country-edit.component.html',
  styleUrls: ['./country-edit.component.scss'],
})
export class CountryEditComponent implements OnInit, OnDestroy {
  country$: Observable<Country>;
  countryForm: FormGroup;

  protected readonly Entity = Entity;
  view$: Observable<View>;

  constructor(
    private readonly countryService: CountryService,
    private readonly subHeaderService: SubHeaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
  ) {
    this.country$ = this.countryService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'countryUpdate') this.onSubmit();
        if (x == 'countryDetails') {
          this.router.navigate([
            'Configuration/Countries/Details/',
            this.route.snapshot.params['id'],
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.countryService.getById.call(this.route.snapshot.params['id']);

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@countryList', x.title);
      });
    this.country$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@countryEdit', x.name);
      });

    this.countryForm = new FormGroup({
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(80),
      ]),
      englishName: new FormControl('', [Validators.maxLength(80)]),
      alpha2Code: new FormControl('', [
        Validators.minLength(2),
        Validators.maxLength(2),
      ]),
      alpha3Code: new FormControl('', [
        Validators.minLength(3),
        Validators.maxLength(3),
      ]),
      currencyId: new FormControl(),
      nationality: new FormControl('', [Validators.maxLength(80)]),
      active: new FormControl(true),
    });

    this.country$.pipe(filter((x) => !!x)).subscribe((x) => {
      // console.log(x.currencyId)
      // this.currencyModelId = x.currencyId ?? undefined;
      this.countryForm.patchValue({
        name: x.name,
        englishName: x.englishName,
        alpha2Code: x.alpha2Code,
        alpha3Code: x.alpha3Code,
        nationality: x.nationality,
        active: x.active,
        currencyId: x.currencyId ?? undefined,
      });
    });
  }

  onSubmit(): void {
    if (this.countryForm.invalid) {
      this.countryForm.markAllAsTouched();
      this.formService.countErrors(this.countryForm, true);
      return;
    }

    this.formService.clear();

    let result$ = this.countryService.updateCountry.call({
      id: this.route.snapshot.params['id'],
      name: this.countryForm.value.name!,
      englishName: this.countryForm.value.englishName!,
      alpha2Code: this.countryForm.value.alpha2Code!.toUpperCase(),
      alpha3Code: this.countryForm.value.alpha3Code!.toUpperCase(),
      currencyId: this.countryForm.value.currencyId!,
      nationality: this.countryForm.value.nationality!,
      active: this.countryForm.value.active!,
    });
    //console.log(result$);
    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.router.navigate(['/Configuration/Countries/Details', result.id]); //result.id
      });
  }

  ngOnDestroy(): void {
    // this.countryService.getById.reset();
    this.countryService.updateCountry.reset();
    this.breadcrumbService.set('@countryEdit', ' ');
  }
}
