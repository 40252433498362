import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { filter, Observable } from 'rxjs';
import {
  ContractModel,
  CreateContractFormModel,
} from '../../../../../../shared/store/contract/types';
import { Entity } from 'src/app/shared/store/view/types';
import { ContractService } from '../../../../../../shared/store/contract/contract.service';
import { take } from 'rxjs/operators';
import { formatDate } from '../../../../../../shared/helper/datehelper';

@Component({
  selector: 'app-contract-form-marketing',
  templateUrl: './contract-form-marketing.component.html',
  styleUrls: ['./contract-form-marketing.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ContractFormMarketingComponent implements OnInit {
  createContractFormModel$: Observable<CreateContractFormModel>;
  contractDetail$: Observable<ContractModel>;
  form: FormGroup;
  protected readonly Entity = Entity;
  constructor(
    private readonly contractService: ContractService,
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
  ) {
    this.createContractFormModel$ =
      this.contractService.getContractModel.value$;
    this.contractDetail$ = this.contractService.getByUniqueId.value$;
  }

  ngOnInit(): void {
    this.form = this.parent.form;
    this.form.addControl(
      'marketingFormGroup',
      this.formBuilder.group({
        marketingCampaignId: [undefined, [Validators.required]],
        marketingMedium: [undefined],
        marketingSource: [undefined],
        marketingCreationDate: [''],
      }),
    );

    this.createContractFormModel$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.form.patchValue({
          marketingFormGroup: {
            marketingSource: x.contractFormModel.source,
          },
        });
      });

    this.contractDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.form.patchValue({
          marketingFormGroup: {
            marketingCampaignId: x.campaignId,
            marketingMedium: x.medium === '' ? undefined : x.medium,
            marketingSource: x.source === '' ? undefined : x.source,
            marketingCreationDate: formatDate(
              x.creationDate,
              'ToInverseSqlDate',
            ),
          },
        });
      });
  }
}
