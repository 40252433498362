import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, filter } from 'rxjs';
import { SubHeaderService } from '../../store/subheader/subheader.service';
import { SubHeaderOption } from '../../store/subheader/type';
import { ReportingService } from '../../store/reporting/reporting.service';
import { Period } from '../../store/reporting/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CampaignSimplified } from '../../store/campaign/types';
import { SalesTeamBase } from '../../store/sales-team/types';
import { CampaignService } from '../../store/campaign/campaign.service';
import { SalesTeamService } from '../../store/sales-team/sales-team.service';
import { SalesTeamMember } from '../../store/sales-team-member/types';

@Component({
  selector: 'app-dashboard-subheader',
  templateUrl: './dashboard-subheader.component.html',
  styleUrls: ['./dashboard-subheader.component.scss'],
})
@UntilDestroy()
export class DashboardSubheaderComponent {
  @Input() campaignId!: number;
  @Output() campaignIdChange = new EventEmitter<number>();

  @Input() insuranceType!: number;
  @Output() insuranceTypeChange = new EventEmitter<number>();

  @Input() startDate!: Date;
  @Output() startDateChange = new EventEmitter<Date>();

  @Input() endDate!: Date;
  @Output() endDateChange = new EventEmitter<Date>();

  @Input() salesTeamId!: number;
  @Output() salesTeamIdChange = new EventEmitter<number>();

  @Input() salesTeamMemberId!: number;
  @Output() salesTeamMemberIdChange = new EventEmitter<number>();

  subHeaderOption$: Observable<SubHeaderOption>;

  periods$: Observable<Period[]>;
  periods: Period[];

  periodLabel: string;

  campaigns$: Observable<CampaignSimplified[]>;
  salesTeams$: Observable<SalesTeamBase[]>;
  salesTeamMembers$: Observable<SalesTeamMember[]>;

  defaultPeriod: string;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly reportingService: ReportingService,
    private readonly campaignService: CampaignService,
    private readonly salesTeamService: SalesTeamService,
  ) {}

  ngOnInit(): void {
    this.periodLabel = formatPeriodLabel(this.startDate, this.endDate);

    this.defaultPeriod = 'Last30Days';
    this.subHeaderOption$ = this.subHeaderService.getSubHeaderOption.value$;

    this.periods$ = this.reportingService.getPeriods.value$;
    this.campaigns$ = this.campaignService.getList.value$;
    this.salesTeams$ = this.salesTeamService.getList.value$;
    this.salesTeamMembers$ =
      this.salesTeamService.getSalesTeamMemberList.value$;

    this.reportingService.getPeriods.call(new Date());
    this.campaignService.getList.call();
    this.salesTeamService.getList.call();
    this.salesTeamService.getSalesTeamMemberList.call(this.salesTeamId);

    this.periods$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((periods) => {
        this.periods = periods;
      });
  }

  triggerAction(actionName) {
    this.subHeaderService.setTriggerAction(actionName);
  }

  onSalesTeamIdChange(newValue) {
    this.salesTeamId = newValue;
    this.salesTeamIdChange.emit(this.salesTeamId);
  }
  onSalesTeamMemberIdChange(newValue) {
    this.salesTeamMemberId = newValue;
    this.salesTeamMemberIdChange.emit(this.salesTeamMemberId);
  }
  onInsuranceTypeChange(newValue) {
    this.insuranceType = newValue;
    this.insuranceTypeChange.emit(this.insuranceType);
  }
  onCampaignIdChange(newValue) {
    this.campaignId = newValue;
    this.campaignIdChange.emit(this.campaignId);
  }
  onPeriodChange(newValue) {
    this.periods.forEach((period) => {
      if (period.value == newValue.target.value) {
        this.startDate = period.startDate;
        this.startDateChange.emit(this.startDate);

        this.endDate = period.endDate;
        this.endDateChange.emit(this.endDate);
      }
    });

    this.periodLabel = formatPeriodLabel(
      new Date(this.startDate),
      new Date(this.endDate),
    );
  }
}

function formatPeriodLabel(startDate: Date, endDate: Date) {
  const fullOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  const monthOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    month: 'long',
    day: 'numeric',
  };
  const dayOptions: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    day: 'numeric',
  };
  let periodLabel: string = '';
  if (startDate.getFullYear() == endDate.getFullYear()) {
    periodLabel =
      'Du ' +
      startDate.toLocaleDateString('fr-FR', monthOptions) +
      ' au ' +
      endDate.toLocaleDateString('fr-FR', fullOptions);

    if (startDate.getMonth() == endDate.getMonth()) {
      periodLabel =
        'Du ' +
        startDate.toLocaleDateString('fr-FR', dayOptions) +
        ' au ' +
        endDate.toLocaleDateString('fr-FR', fullOptions);

      if (startDate.getDate() == endDate.getDate()) {
        periodLabel =
          'Le ' + startDate.toLocaleDateString('fr-FR', fullOptions);
      }
    }
  }

  if (startDate.getFullYear() != endDate.getFullYear()) {
    periodLabel =
      'Du ' +
      startDate.toLocaleDateString('fr-FR', fullOptions) +
      ' au ' +
      endDate.toLocaleDateString('fr-FR', fullOptions);
  }
  return periodLabel;
}
