import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractService } from 'src/app/shared/store/contract/contract.service';
import { HouseInsuranceContractModel } from 'src/app/shared/store/contract/types';

@Component({
  selector: 'app-contract-details-house',
  templateUrl: './contract-details-house.component.html',
  styleUrls: ['./contract-details-house.component.scss'],
})
export class ContractDetailsHouseComponent {
  houseDetail$: Observable<HouseInsuranceContractModel>;
  constructor(private readonly contractService: ContractService) {}
  ngOnInit(): void {
    this.houseDetail$ = this.contractService.getHouseDetail.value$;
  }
}
