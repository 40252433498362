import { NgModule } from '@angular/core';
import {NgSelectModule} from "@ng-select/ng-select";
import {NgOptionHighlightModule} from "@ng-select/ng-option-highlight";

@NgModule({
  declarations: [],
  imports: [NgSelectModule, NgOptionHighlightModule],
  exports: [NgSelectModule, NgOptionHighlightModule]
})
export class TypeaheadModule { }
