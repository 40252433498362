import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Job, JobPost, JobUpdate } from './types';
import {ErrorService} from "../../error";
import {catchError} from "rxjs/operators";
import {MessageService} from "../../service/message/message.service";
import {CrudType} from "../../service/message/types";

@Injectable({
  providedIn: 'root',
})
export class JobApiService {
  constructor(private readonly httpClient: HttpClient,
              private readonly errorService:ErrorService,
              private readonly messageService:MessageService
  ) {}

  getById = (id: number): Observable<Job> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<Job>(url)
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  postJob = (jobPost: JobPost): any => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);
    return this.httpClient.post<Job>(url, jobPost, {observe: 'response'})
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  updateJob = (jobUpdate: JobUpdate): any => {
    let parameters: string[];
    parameters = [jobUpdate.id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.put<Job>(url, jobUpdate, {observe: 'response'})
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.jobs.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
