<div
  class="tab-pane fade flex-even active show"
  id="contacts"
  role="tabpanel"
  aria-labelledby="contacts-tab"
  formGroupName="notesFormGroup"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">

        <div class="form-group row">
<!--          <label class="col-4 col-form-label-sm" translate-->
<!--          >CONTACT_LBL_NOTES</label-->
<!--          >-->
          <div class="col">
              <textarea
                formControlName="notes"
                [placeholder]="'CONTACT_PLACEHOLDER_NOTES' | translate"
                class="form-control form-control-sm"
                [rows]="5"
              >
              </textarea>
          </div>
        </div>

<!--        class="form-control form-control-sm"-->
      </div>
<!--      <div class="col"></div>-->
    </div>
  </div>
</div>
