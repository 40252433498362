import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {debounceTime, Observable} from 'rxjs';
import {GlossaryService} from 'src/app/shared/store/glossary/glossary.service';
import {Glossary} from 'src/app/shared/store/glossary/types';
import {SubHeaderService} from 'src/app/shared/store/subheader/subheader.service';
import {BreadcrumbService} from 'xng-breadcrumb';
import {filter, take, tap} from 'rxjs/operators';
import {View} from '../../../../shared/store/view/types';
import {ViewService} from '../../../../shared/store/view/views.service';

@UntilDestroy()
@Component({
  selector: 'app-glossary-detail',
  templateUrl: './glossary-detail.component.html',
  styleUrls: ['./glossary-detail.component.scss'],
})
export class GlossaryDetailComponent implements OnInit, OnDestroy {
  glossaryId: string;
  glossary$: Observable<Glossary>;
  view$: Observable<View>;

  constructor(
    private readonly glossaryService: GlossaryService,
    private readonly subHeaderService: SubHeaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService
  ) {
    this.glossary$ = this.glossaryService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.glossaryId = this.route.snapshot.params['id'];
    this.glossaryService.getById.call(this.glossaryId);

    this.glossary$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@glossaryDetails', x.name);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@glossaryList', x.title);
      });

    this.subHeaderService.getTriggerAction().pipe(
      filter(x => !!x),
      untilDestroyed(this)
    )
      .subscribe((x) => {
        if (x == 'glossaryPublish') this.publishContent();
        if (x == 'glossaryUnpublish') this.unpublishContent();
        if (x == 'glossaryEdit') {
          this.router.navigate(['Content/Glossaries/Edit/', this.glossaryId]);
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  publishContent() {
    this.glossaryService.publishGlossary
      .call(this.route.snapshot.params['id'])
      .pipe(
        debounceTime(200),
        filter((x) => !!x),
        take(1)
      )
      .subscribe((x) => {
        this.glossaryService.getById.call(x.id);
        this.glossaryService.publishGlossary.reset();
      });
  }

  unpublishContent() {

    this.glossaryService.unpublishGlossary
      .call(this.route.snapshot.params['id'])
      .pipe(
        // tap(x => console.log(x)),
        debounceTime(200),
        filter((x) => !!x),
        take(1)
      )
      .subscribe((x) => {
        this.glossaryService.getById.call(x.id);
        this.glossaryService.unpublishGlossary.reset();
      });
  }

  ngOnDestroy(): void {
    this.glossaryService.unpublishGlossary.reset();
    this.glossaryService.publishGlossary.reset();
    this.breadcrumbService.set('@glossaryDetails', ' ');
  }
}
