import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { PageResult } from '../../types';
import { AppState } from '../types';
import { RegisterModel, User } from './type';
import { userActions } from './user.actions';
import { userSelectors } from './user.selectors';

@Injectable()
export class UserService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  get: GenericApiCall<User[], void> = this.genericApiCall(
    userActions.get,
    userSelectors.get,
    true
  );

  getById: GenericApiCall<User, string> = this.genericApiCall(
    userActions.getById,
    userSelectors.getById,
    true
  );

  add: GenericApiCall<boolean, RegisterModel> = this.genericApiCall(
    userActions.add,
    userSelectors.add,
    true
  );

  getCurrentUser: GenericApiCall<User, void> = this.genericApiCall(
    userActions.getCurrentUser,
    userSelectors.getCurrentUser,
    true
  );
}
