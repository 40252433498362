import { ContractModel } from '../contract/types';

export const leadFeatureKey = 'leads';

export class InsuranceLead {
  id: number;
  leadId: number;
  contractStartDate: Date;
}

export type PushLeadModel = {
  id: string;
  oggo: boolean;
  smartbroker: boolean;
};

export enum InsuranceCategory {
  Undefined = 0,
  Health = 1,
  Car = 2,
  House = 3,
}

export type LeadModel = {
  id: number;
  uniqueId: string;
  category: InsuranceCategory;
  categoryLoc: string;
  categoryColor: string;
  contactFirstName: string;
  contactLastName: string;
  creationDate: Date;
  contactPhoneNumber: string;
  contactEmail: string;
  contactZipCode: string;
  contactCity: string;
  contactAddress: string;
  fullCity: string;
  salesPersonContactName: string;
  campaignName: string;
  contactName: string;
};

export type LeadDetailModel = {
  id: number;
  uniqueId: string;
  category: InsuranceCategory;
  categoryLoc: string;
  categoryColor: string;
  contactFirstName: string;
  firstName: string;
  contactLastName: string;
  lastName: string;
  creationDate: Date;
  contactPhoneNumber: string;
  phoneNumber: string;
  contactEmail: string;
  email: string;
  contactZipCode: string;
  contactCity: string;
  contactAddress: string;
  fullCity: string;
  medium: string;
  source: string;
  salesPersonId: number | null;
  salesPersonContactName: string;
  campaignId: number | null;
  campaignName: string;
  insuranceLeadContractStartDate: Date;
  salesTeamId: number | null;
  salesTeamName: string;
  contactName: string;
  contracts: ContractModel[];
  similarLeadsCount: number;
};

export type HealthInsuranceLeadModel = {
  routineCareCoverageLevel: number;
  hospitalizationCoverageLevel: number;
  dentalCoverageLevel: number;
  opticalCoverageLevel: number;
  insuredPersons: HealthInsuranceLeadDetailModel[];
};

export type HealthInsuranceLeadDetailModel = {
  id: number;
  insuredTypeLoc: string;
  insuredType: InsuredType;
  socialSecurityKey: RegimeEnum;
  socialSecurityKeyLoc: string;
  birthDate: Date;
  age: number;
  lastName: string;
  firstName: string;
};

export type CarInsuranceLeadModel = {
  driverFirstName: string;
  driverLastName: string;
  driverBirthdate: Date;
  driverZipCode: string;
  driverCity: string;
  driverAddress: string;
  licenseDate: Date;
  isDriverVehicleRegistrationHolder: boolean;
  vehiculeRegistrationHolder: string;
  vehiculeRegistrationHolderNameLastName: string;
  vehiculeRegistrationHolderNameFirstName: string;
  vehiculeRegistrationHolderCompanyName: string;
  vehiculeRegistrationHolderBirthdate: Date;
  yearsInsured: string;
  bonusMalus: string;
  monthInsuranceInterruption: string;
  insuranceTermination: string;
  licenseAnnulation: string;
  noLicenseConviction: string;
  licenseCancel: string;
  licenseCancelMotive: string;
  licenseCancelDate: Date;
  licenseSuspension: string;
  licenseSuspensionMotive: string;
  licenseSuspensionDate: Date;
  longestSuspension: string;
  alcoholDrugControl: string;
  nbCarDisaster: string;
  licensePlateNumber: string;
  brand: string;
  model: string;
  exactModel: string;
  carBody: string;
  carEnergie: string;
  carPower: string;
  vehiculeUsage: string;
  vehiculeUsageProSupp: string;
  parkingType: string;
  dateOfCirculation: Date;
  contractStartDate: Date;
  insuranceCoverage: string;
  currentInsuranceCompany: string;
  driverBodyCoverage: boolean;
  replacementVehicleCoverage: boolean;
  driverPropertyCoverage: boolean;
  brandNewCoverage: boolean;
  moneyLossCoverage: boolean;
  carDisasters: CarInsuranceLeadDisasterDetailModel[];
};

export type CarInsuranceLeadDisasterDetailModel = {
  carDisasterDate: Date;
  carDisasterReason: string;
  carDisasterResponsiblePerson: string;
};

export type HouseInsuranceLeadModel = {
  address: string;
  zipCode: string;
  city: string;
  maritalStatus: string;
  profession: string;
  housingType: string;
  occupancyRole: string;
  nbOfRooms: number;
  area: number;
  constructionType: string;
  roofType: string;
  amountToInsure: string;
  flatLevel: string;
  residencyType: string;
  fireCoverage: boolean;
  waterCoverage: boolean;
  theftCoverage: boolean;
  glassBreakCoverage: boolean;
  publicLiabilityCoverage: boolean;
  feeCancellationCoverage: boolean;
  electricalDamageCoverage: boolean;
  poolCoverage: boolean;
  dogCoverage: boolean;
  currentInsuranceCompany: string;
};

export enum InsuredType {
  Holder = 'holder',
  Partner = 'partner',
  Child = 'child',
}

export enum RegimeEnum {
  SECURITE_SOCIALE = 1,
  TNS = 2,
  EXPLOITANT_AGRICOLE = 3,
  SALARIE_AGRICOLE = 4,
  ALSACE_MOSELLE = 5,
  FONCTIONNAIRE_TERRITORIAL = 6,
}

export type BaseLeadFormModel = {
  leadFormModel: LeadFormModel;
  contactFormModel: ContactFormModel;
  insuranceLeadFormModel: InsuranceLeadFormModel;
};

export type HealthLeadFormModel = {
  baseLeadFormModel: BaseLeadFormModel;
  healthInsuranceLeadFormModel: HealthInsuranceLeadFormModel;
  insureds: HealthInsuranceLeadDetailFormModel[];
};

export type CarLeadFormModel = {
  baseLeadFormModel: BaseLeadFormModel;
  carInsuranceLeadFormModel: CarInsuranceLeadFormModel;
  disasters: CarInsuranceLeadDisasterDetailFormModel[];
};

export type HouseLeadFormModel = {
  baseLeadFormModel: BaseLeadFormModel;
  houseInsuranceLeadFormModel: HouseInsuranceLeadFormModel;
};

export type LeadFormModel = {
  id?: number;
  category: InsuranceCategory;
  categoryLoc: string;
  categoryColor?: string;
  title: string;
  firstName: string;
  lastName: string;
  profession: string;
  phoneNumber: string;
  email: string;
  zipCode: string;
  city: string;
  address: string;
  address2: string;
  salesTeamId: number;
  salesPersonId: number;
  campaignId: number;
  medium: string;
  source: string;
  creationDate: Date;
};

export type ContactFormModel = {
  id?: number;
  firstName: string;
  lastName: string;
  address: string;
  address2: string;
  zipCode: string;
  city: string;
  phoneNumber: string;
  email: string;
};

export type InsuranceLeadFormModel = {
  contractStartDate: Date;
};

export type HealthInsuranceLeadFormModel = {
  routineCareCoverageLevel: number;
  hospitalizationCoverageLevel: number;
  dentalCoverageLevel: number;
  opticalCoverageLevel: number;
  county: string;
};

export type HealthInsuranceLeadDetailFormModel = {
  insuredType: InsuredType;
  socialSecurityKey: RegimeEnum;
  firstName: string;
  lastName: string;
  birthDate: Date;
};

export type CarInsuranceLeadFormModel = {
  driverFirstName: string;
  driverLastName: string;
  driverBirthdate: Date;
  driverZipCode: string;
  driverCity: string;
  driverAddress: string;
  licenseDate: Date;
  isDriverVehiculeRegistrationHolder: boolean;
  vehiculeRegistrationHolder: string;
  vehiculeRegistrationHolderNameLastName: string;
  vehiculeRegistrationHolderNameFirstName: string;
  vehiculeRegistrationHolderCompanyName: string;
  vehiculeRegistrationHolderBirthdate: Date;
  yearsInsured: string;
  bonusMalus: string;
  monthInsuranceInterruption: string;
  insuranceTermination: string;
  licenseAnnulation: string;
  noLicenseConviction: string;
  licenseCancel: string;
  licenseCancelMotive: string;
  licenseCancelDate: Date;
  licenseSuspension: string;
  licenseSuspensionMotive: string;
  licenseSuspensionDate: Date;
  longestSuspension: string;
  alcoholDrugControl: string;
  nbCarDisaster: string;
  licensePlateNumber: string;
  brand: string;
  model: string;
  exactModel: string;
  carBody: string;
  carEnergie: string;
  carPower: string;
  vehiculeUsage: string;
  vehiculeUsageProSupp: string;
  parkingType: string;
  dateOfCirculation: Date;
  contractStartDate: Date;
  insuranceCoverage: string;
  currentInsuranceCompany: string;
  driverBodyCoverage: boolean;
  replacementVehicleCoverage: boolean;
  driverPropertyCoverage: boolean;
  brandNewCoverage: boolean;
  moneyLossCoverage: boolean;
};

export type CarInsuranceLeadDisasterDetailFormModel = {
  carDisasterDate: Date;
  carDisasterReason: string;
  carDisasterResponsiblePerson: string;
};

export type HouseInsuranceLeadFormModel = {
  address: string;
  zipCode: string;
  city: string;
  maritalStatus: string;
  profession: string;
  housingType: string;
  occupancyRole: string;
  nbOfRooms: number;
  area: number;
  constructionType: string;
  roofType: string;
  amountToInsure: string;
  flatLevel: string;
  residencyType: string;
  fireCoverage: boolean;
  waterCoverage: boolean;
  theftCoverage: boolean;
  glassBreakCoverage: boolean;
  publicLiabilityCoverage: boolean;
  feeCancellationCoverage: boolean;
  electricalDamageCoverage: boolean;
  poolCoverage: boolean;
  dogCoverage: boolean;
  currentInsuranceCompany: string;
};

export type LeadStatusFlow = {
  label: string;
  status: LeadStatus;
  statusId: number;
};

export type LeadStatus = 'New' | 'Open' | 'Lost' | 'Won';
