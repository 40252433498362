import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  IAuthenticationState,
  authenticationInitialState,
  authenticationReducer,
} from './authentication.reducer';
import { AuthenticationService } from './authentication.service';
import { authenticationFeatureKey } from './types';
import {EffectsModule} from "@ngrx/effects";
import {AuthenticationEffects} from "./authentication.effects";
import {AuthenticationApiService} from "./authentication.api.service";


@NgModule({
  imports: [
    StoreModule.forFeature<IAuthenticationState>(
      authenticationFeatureKey,
      authenticationReducer,
      {
        initialState: authenticationInitialState,
      }),
      EffectsModule.forFeature([AuthenticationEffects])
  ],
  providers: [AuthenticationApiService,AuthenticationService],
})
export class StoreAuthenticationModule {}
