import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { PaymentSplittingService } from '../../../../../../shared/store/payment-splitting/payment-splitting.service';
import { filter, Observable } from 'rxjs';
import { PaymentSplitting } from '../../../../../../shared/store/payment-splitting/type';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ListItem } from '../../../../../../shared/store/typeahead/types';
import {
  removeValidators,
  setValidators,
} from '../../../../../../shared/helper/formhelper';
import { ContractModel } from '../../../../../../shared/store/contract/types';
import { ContractService } from '../../../../../../shared/store/contract/contract.service';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-contract-form-payment-splitting',
  templateUrl: './contract-form-payment-splitting.component.html',
  styleUrls: ['./contract-form-payment-splitting.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ContractFormPaymentSplittingComponent implements OnInit {
  form: FormGroup;
  paymentSplittingOptions$: Observable<PaymentSplitting[]>;
  paymentSplittingOptions: PaymentSplitting[];
  paymentSplittingDebit: ListItem[];
  paymentSplittingDebitDayOfMonth: ListItem[];
  paymentSplittingNonDebit: ListItem[];
  debit: boolean = true;
  readonly: boolean = false;

  contractDetail$: Observable<ContractModel>;
  paymentSplittingDetail$: Observable<PaymentSplitting>;

  customPatterns = {
    0: {
      pattern: new RegExp(/[0-9]/),
    },
    C: {
      pattern: new RegExp('[a-zA-Z0-9]'),
    },
    S: {
      pattern: new RegExp('[a-zA-Z]'),
    },
  };

  constructor(
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
    private readonly paymentSplittingService: PaymentSplittingService,
    private readonly contractService: ContractService,
  ) {
    this.paymentSplittingOptions$ =
      this.paymentSplittingService.getByProductLine.value$;
    this.contractDetail$ = this.contractService.getByUniqueId.value$;
    this.paymentSplittingDetail$ = this.paymentSplittingService.getById.value$;
  }

  ngOnInit(): void {
    this.debit = true;

    this.form = this.parent.form;
    this.form.addControl(
      'paymentSplittingFormGroup',
      this.formBuilder.group({
        paymentMode: [undefined, [Validators.required]],
        paymentDebitDay: [undefined, [Validators.required]],
        paymentSplittingId: [-1],
        paymentHolderName: ['', [Validators.required]],
        paymentIBAN: ['', [Validators.required]],
        paymentBIC: ['', [Validators.required]],
        refundingHolderName: [''],
        refundingIBAN: [''],
        refundingBIC: [''],
        sameBankAccount: [true],
        paymentDay: [''],
        paymentModeLoc: [''],
      }),
    );

    this.contractDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.paymentSplittingService.getById.call(
          x.paymentSplittingId.toString(),
        );
        this.paymentSplittingService.getByProductLine.call(x.productLineKey);
        this.readonly = true;
        this.form.patchValue({
          paymentSplittingFormGroup: {
            // paymentMode: x.paymentSplittingMode,
            // paymentDebitDay: x.paymentSplittingId,
            paymentSplittingId: x.paymentSplittingId,
            paymentHolderName: x.paymentBankAccountHolderName,
            paymentIBAN: x.paymentBankAccountIBAN,
            paymentBIC: x.paymentBankAccountBIC,
            refundingHolderName: x.refundingBankAccountHolderName,
            refundingIBAN: x.refundingBankAccountIBAN,
            refundingBIC: x.refundingBankAccountBIC,
            sameBankAccount:
              x.refundingBankAccountId === x.paymentBankAccountId,
          },
        });
      });

    this.paymentSplittingDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        // this.onChangePaymentMode(x.splittingModeId.toString());
        this.form.patchValue({
          paymentSplittingFormGroup: {
            paymentMode: x.splittingModeId,
            paymentDebitDay: x.id,
            paymentModeLoc: x.splittingModeLoc,
            paymentDay: x.dayOfMonth,
          },
        });
      });

    this.form.controls['productLineKey'].valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.paymentSplittingService.getByProductLine.reset();
        this.onChangePaymentType(this.debit);
        this.paymentSplittingService.getByProductLine.call(
          this.form.controls['productLineKey'].value,
        );
      });

    this.paymentSplittingOptions$.pipe(untilDestroyed(this)).subscribe((x) => {
      if (x) {
        this.paymentSplittingNonDebit = x
          .filter((paymentSplitting) => !paymentSplitting.debit)
          .map(
            (result) =>
              <ListItem>{
                id: result.splittingModeId,
                name: result.splittingModeLoc,
              },
          );

        this.paymentSplittingDebit = x
          .filter((paymentSplitting) => paymentSplitting.debit)
          .map(
            (result) =>
              <ListItem>{
                id: result.splittingModeId,
                name: result.splittingModeLoc,
              },
          );
        this.paymentSplittingOptions = x;
      } else {
        this.paymentSplittingNonDebit = [];
        this.paymentSplittingDebit = [];
        this.paymentSplittingOptions = [];
        this.paymentSplittingDebitDayOfMonth = [];
      }
    });
  }

  onChangePaymentMode(value: string) {
    // if (!this.paymentSplittingOptions) return;
    this.paymentSplittingDebitDayOfMonth = this.paymentSplittingOptions
      .filter((x) => x.debit === this.debit && x.splittingModeId === +value)
      .map(
        (result) =>
          <ListItem>{ id: result.id, name: result.dayOfMonth.toString() },
      );
    if (this.paymentSplittingDebitDayOfMonth.length === 1)
      this.form.controls['paymentSplittingFormGroup']
        .get('paymentDebitDay')
        ?.setValue(this.paymentSplittingDebitDayOfMonth[0].id);

    if (!this.debit) {
      this.form.controls['paymentSplittingFormGroup']
        .get('paymentSplittingId')
        ?.setValue(this.paymentSplittingDebitDayOfMonth[0].id);
    }
  }

  onChangePaymentType(checked: boolean) {
    this.debit = checked;

    //reinit
    this.form.controls['paymentSplittingFormGroup'].get('paymentMode')?.reset();
    this.form.controls['paymentSplittingFormGroup']
      .get('paymentDebitDay')
      ?.reset();
    this.form.controls['paymentSplittingFormGroup']
      .get('paymentSplittingId')
      ?.reset();
    this.paymentSplittingDebitDayOfMonth = [];

    if (checked) {
      setValidators(
        this.form.controls['paymentSplittingFormGroup'].get('paymentDebitDay'),
        Validators.required,
      );
      return;
    }
    removeValidators(
      this.form.controls['paymentSplittingFormGroup'].get('paymentDebitDay'),
      Validators.required,
    );
  }

  onChangeSetRefundingValidators(checked: boolean) {
    if (checked) {
      removeValidators(
        this.form.controls['paymentSplittingFormGroup'].get(
          'refundingHolderName',
        ),
        Validators.required,
      );
      removeValidators(
        this.form.controls['paymentSplittingFormGroup'].get('refundingIBAN'),
        Validators.required,
      );
      removeValidators(
        this.form.controls['paymentSplittingFormGroup'].get('refundingBIC'),
        Validators.required,
      );
      return;
    }

    setValidators(
      this.form.controls['paymentSplittingFormGroup'].get(
        'refundingHolderName',
      ),
      Validators.required,
    );
    setValidators(
      this.form.controls['paymentSplittingFormGroup'].get('refundingIBAN'),
      Validators.required,
    );
    setValidators(
      this.form.controls['paymentSplittingFormGroup'].get('refundingBIC'),
      Validators.required,
    );
  }

  onChangeDebitDay(value: string) {
    this.form.controls['paymentSplittingFormGroup']
      .get('paymentSplittingId')
      ?.setValue(value);
  }
}
