import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { CountryApiService } from "./country.api.service";
import { CountryEffects } from "./country.effects";
import { ICountryState, countryInitialState, countryReducer } from "./country.reducer";
import { CountryService } from "./country.service";
import { countryFeatureKey } from "./types";

@NgModule({
    imports:[
        StoreModule.forFeature<ICountryState>(countryFeatureKey, countryReducer,{
            initialState: countryInitialState,
        }),
        EffectsModule.forFeature([CountryEffects])
    ],
    providers:[CountryApiService, CountryService]
})
export class StoreCountryModule{}