<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  *ngIf="this.carDetail$ | async as carDetail"
>
  <div class="d-flex flex-column h-100">
    <div class="form-group-title my-1" translate>CONTRACT_FIELDSET_DRIVER</div>
    <div class="row mt-2">
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_LASTNAME</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.driverLastName }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_FIRSTNAME</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.driverFirstName }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_BIRTHDATE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.driverBirthdate | date : "dd/MM/yyyy" }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_ADDRESS</label
          >
          <div class="col">
            <div class="form-control-plaintext form-control-sm mb-2 h-100">
              <app-address
                [address]="carDetail.driverAddress"
                [zipCode]="carDetail.driverZipCode"
                [city]="carDetail.driverCity"
              ></app-address>
            </div>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_LICENSE_DATE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.licenseDate | date : "dd/MM/yyyy" }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group-title my-1" translate>
      CONTRACT_FIELDSET_INSURANCE_HISTORY
    </div>
    <div class="row mt-2">
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_YEARS_INSURED</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.yearsInsured }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_BONUS_MALUS</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.bonusMalus }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_MONTH_INSURANCE_INTERRUPTION</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.monthInsuranceInterruption }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_INSURANCE_TERMINATION</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.insuranceTermination }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group-title my-1" translate>
      CONTRACT_FIELDSET_LICENSE_HISTORY
    </div>
    <div class="row mt-2">
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_LICENSE_ANNULATION</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              <ng-container
                *ngIf="
                  ['true', 'false'].includes(carDetail.licenseAnnulation);
                  else elseBlock
                "
              >
                <input
                  type="checkbox"
                  name="active"
                  [ngModel]="carDetail.licenseAnnulation == 'true'"
                  onclick="return false;"
                  [disabled]="true"
                />
              </ng-container>
              <ng-template #elseBlock>
                {{ carDetail.licenseAnnulation }}
              </ng-template>
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_NO_LICENSE_CONVICTION</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.noLicenseConviction }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_LICENSE_CANCEL</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.licenseCancel }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_LICENSE_CANCEL_MOTIVE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.licenseCancelMotive }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_LICENSE_CANCEL_DATE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.licenseCancelDate | date : "dd/MM/yyyy" }}
            </span>
          </div>
        </div>
      </div>
      <div class="col-12 col-xl-6">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_LICENSE_SUSPENSION</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.licenseSuspension }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_LICENSE_SUSPENSION_MOTIVE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.licenseSuspensionMotive }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_LICENSE_SUSPENSION_DATE</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.licenseSuspensionDate | date : "dd/MM/yyyy" }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_LONGEST_SUSPENSION</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.longestSuspension }}
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
            >CONTRACT_LBL_DRIVER_ALCOHOL_DRUG_CONTROL</label
          >
          <div class="col-auto">
            <span class="form-control-plaintext form-control-sm">
              {{ carDetail.alcoholDrugControl }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <ng-container
      *ngIf="carDetail.disasters != null && carDetail.disasters.length != 0"
    >
      <div class="form-group-title my-1" translate="">
        CONTRACT_FIELDSET_DISASTERS
      </div>
      <div class="row my-2 disaster-row">
        <div
          class="col-md-6 col-12"
          *ngFor="let disaster of carDetail.disasters"
        >
          <div class="card">
            <div class="card-header">
              <h5 class="form-group-title my-1" translate>
                CONTRACT_FIELDSET_DISASTER
              </h5>
            </div>
            <div class="card-body">
              <div class="row mt-2">
                <div class="col-12 col-xl-6">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_LBL_DISASTER_DATE</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ disaster.carDisasterDate | date : "dd/MM/yyyy" }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_FIELDSET_REASON</label
                    >
                    <div class="col">
                      {{ disaster.carDisasterReason }}
                    </div>
                  </div>
                </div>
                <div class="col-12 col-xl-6">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                      >CONTRACT_FIELDSET_RESPONSIBLE</label
                    >
                    <div class="col">
                      {{ disaster.carDisasterResponsiblePerson }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
