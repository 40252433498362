import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { PageResult } from '../../types';
import { Glossary } from './types';
export const glossaryActions = {
  getPaged: createApiCallActions<PageResult<Glossary>>('glossary', 'getPaged'),
  getById: createApiCallActions<Glossary>('glossary', 'getById'),
  postGlossary: createApiCallActions<any>('glossary', 'postGlossary'),
  publishGlossary: createApiCallActions<any>('glossary', 'publishGlossary'),
  unpublishGlossary: createApiCallActions<any>('glossary', 'unpublishGlossary'),
  updateGlossary: createApiCallActions<any>('glossary', 'updateGlossary'),
};

export type GlossaryAction = typeof glossaryActions;
