<ngb-pagination
  class="pagination col-6"
  *ngIf="this.paginationConfig$ | async as paginationConfig"
  [(page)]="paginationConfig.pageIndex"
  [pageSize]="paginationConfig.pageSize"
  [collectionSize]="paginationConfig.totalCount"
  [boundaryLinks]="true"
  (pageChange)="onPageChange(paginationConfig.pageIndex)"
>
  <ng-template ngbPaginationFirst>
    <i class="mdi mdi-chevron-double-left"></i>
  </ng-template>
  <ng-template ngbPaginationPrevious>
    <i class="mdi mdi-chevron-left"></i>
  </ng-template>
  <ng-template ngbPaginationPages let-page let-pages="pages">
    <li>
      <label>{{ paginationConfig | paginationLabel }}</label>
    </li>
  </ng-template>
  <ng-template ngbPaginationNext>
    <i class="mdi mdi-chevron-right"></i>
  </ng-template>
  <ng-template ngbPaginationLast>
    <i class="mdi mdi-chevron-double-right"></i>
  </ng-template>
</ngb-pagination>
