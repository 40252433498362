import { NgModule } from "@angular/core";
import { StoreModule } from "@ngrx/store";
import { INotificationState, notificationInitialState, notificationReducer } from "./notification.reducer";
import { notificationFeatureKey } from "./types";
import { NotificationService } from "./notification.service";

@NgModule({
imports: [
    StoreModule.forFeature<INotificationState>(
        notificationFeatureKey,
        notificationReducer,
        {
            initialState: notificationInitialState
        }
    )
    ],
    providers: [NotificationService]
})
export class StoreNotificationModule {}