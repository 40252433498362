import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { formFeatureKey } from './types';
import { formInitialState, formReducer, IFormState } from './form.reducer';
import { FormService } from './form.service';

@NgModule({
  imports: [
    StoreModule.forFeature<IFormState>(formFeatureKey, formReducer, {
      initialState: formInitialState,
    }),
  ],
  providers: [FormService],
})
export class StoreFormModule {}
