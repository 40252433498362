import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { GenericState } from 'src/app/common/store/types';
import { PageResult } from '../../types';
import { articleActions } from './article.actions';
import { Article } from './types';

export interface IArticleState {
  getPaged: GenericState<any>;
  getById: GenericState<Article>;
  postArticle: GenericState<Article>;
  publishArticle: GenericState<any>;
  unpublishArticle: GenericState<any>;
  updateArticle: GenericState<any>;
}
export const articleInitialState: IArticleState = {
  getPaged: new GenericState<any>(),
  getById: new GenericState<Article>(),
  postArticle: new GenericState<Article>(),
  publishArticle: new GenericState<any>(),
  unpublishArticle: new GenericState<any>(),
  updateArticle: new GenericState<any>(),
};

const articleReducers = [
  ...onApiCall<PageResult<Article>>(articleActions.getPaged, 'getPaged'),
  ...onApiCall<Article>(articleActions.getById, 'getById'),
  ...onApiCall<any>(articleActions.postArticle, 'postArticle'),
  ...onApiCall<any>(articleActions.publishArticle, 'publishArticle'),
  ...onApiCall<any>(articleActions.unpublishArticle, 'unpublishArticle'),
  ...onApiCall<any>(articleActions.updateArticle, 'updateArticle'),
];

export const articleReducer = createReducer(
  articleInitialState,
  ...articleReducers
) as ActionReducer<IArticleState, Action>;

export function userReducerFunc(state: any | undefined, action: Action) {
  return articleReducer(state, action);
}
