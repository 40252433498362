import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { CoreAction, coreActions } from './core.actions';
import { CoreApiService } from './core.api.services';
import { ICoreState } from './core.reducer';

@Injectable()
export class CoreEffects {
  constructor(
    private readonly store: Store<ICoreState>,
    private readonly coreApiService: CoreApiService,
    private readonly actions$: Actions<CoreAction>
  ) {}

  getFieldsByEntity = createApiCallEffects(
    this,
    this.actions$,
    coreActions.getFieldsByEntity,
    this.coreApiService.getFieldsByEntity
  );
  getSearchViewByEntity = createApiCallEffects(
    this,
    this.actions$,
    coreActions.getSearchViewByEntity,
    this.coreApiService.getSearchViewByEntity
  );
  getFavoriteByEntity = createApiCallEffects(
    this,
    this.actions$,
    coreActions.getFavoriteByEntity,
    this.coreApiService.getFavoriteByEntity
  );
  getOperatorsByType = createApiCallEffects(
    this,
    this.actions$,
    coreActions.getOperatorsByType,
    this.coreApiService.getOperatorsByType
  );

  getStringOperators = createApiCallEffects(
    this,
    this.actions$,
    coreActions.getStringOperators,
    this.coreApiService.getStringOperators
  );

  getIntOperators = createApiCallEffects(
    this,
    this.actions$,
    coreActions.getIntOperators,
    this.coreApiService.getIntOperators
  );
}
