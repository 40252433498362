import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { User } from 'src/app/shared/store/user/type';
import { UserService } from 'src/app/shared/store/user/user.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbService } from 'xng-breadcrumb';
import { filter } from 'rxjs/operators';
import { View } from '../../../../../shared/store/view/types';
import { ViewService } from '../../../../../shared/store/view/views.service';

@UntilDestroy()
@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
})
export class UserDetailComponent implements OnInit, OnDestroy {
  userId: string;
  user$: Observable<User>;
  view$: Observable<View>;

  constructor(
    private userService: UserService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService
  ) {
    this.user$ = this.userService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.userId = this.route.snapshot.params['id'];
    this.userService.getById.call(this.userId);

    this.user$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@userDetails', x.contactName);
      });
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@userList', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'userEdit') {
          this.router.navigate(['Configuration/Users/Edit/', this.userId]);
          this.subHeaderService.setTriggerAction('');
        }
      });
  }

  ngOnDestroy() {
    this.userService.getById.reset();
    this.breadcrumbService.set('@userDetails', ' ');
  }
}
