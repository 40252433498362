import {NgModule} from "@angular/core";
import {StoreModule} from "@ngrx/store";
import {EffectsModule} from "@ngrx/effects";
import {SalesTeamApiService} from "./sales-team.api.service";
import {SalesTeamService} from "./sales-team.service";
import {SalesTeamEffects} from "./sales-team.effects";
import {salesTeamFeatureKey} from "./types";
import {ISalesTeamState, salesTeamInitialState, salesTeamReducer} from "./sales-team.reducer";

@NgModule({
    imports:[
        StoreModule.forFeature<ISalesTeamState>(salesTeamFeatureKey, salesTeamReducer, {
            initialState: salesTeamInitialState,
            }),
        EffectsModule.forFeature([SalesTeamEffects])
    ],
    providers:[SalesTeamApiService, SalesTeamService]
})
export class StoreSalesTeamModule {}
