import { Component, OnInit } from '@angular/core';
import {  Observable } from 'rxjs';
import { LeadService } from 'src/app/shared/store/lead/lead.service';
import { CarInsuranceLeadModel } from 'src/app/shared/store/lead/types';

@Component({
  selector: 'app-lead-details-vehicle',
  templateUrl: './lead-details-vehicle.component.html',
  styleUrls: ['./lead-details-vehicle.component.scss'],
})
export class LeadDetailsVehicleComponent implements OnInit {
  constructor(private readonly leadService: LeadService) {}

  carDetail$: Observable<CarInsuranceLeadModel>;

  ngOnInit(): void {
    this.carDetail$ = this.leadService.getCarDetail.value$;
  }
}
