<!--<xng-breadcrumb></xng-breadcrumb>-->
<nav id="breadcrumb" class="flex-even" aria-label="breadcrumb">
  <ol class="breadcrumb">
    <ng-container *ngIf="breadcrumbBase$ | async as breadcrumbs">
      <ng-container *ngFor="let breadcrumb of breadcrumbs; let last = last; let index = index;">
        <ng-container *ngIf="!last">
          <li class="breadcrumb-item" aria-current="page">
            <a routerLink="{{breadcrumb.routeLink}}">{{ breadcrumb.label }} </a>
          </li>
        </ng-container>
        <ng-container *ngIf="last">
          <li class="breadcrumb-item active" aria-current="page">
            {{ breadcrumb.label }}
          </li>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="breadcrumbs?.length === 0">
        <li></li>
      </ng-container>
    </ng-container>
  </ol>
</nav>
