import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { InternalServerErrorComponent } from "./internal-server-error/internal-server-error.component";
import { OfflineErrorComponent } from './offline-error/offline-error.component';
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { UnauthorizedComponent } from "./unauthorized/unauthorized.component";
import { WorkInProgressComponent } from "./work-in-progress/work-in-progress.component";

const routes: Routes = [
  {
    path: "page-not-found",
    component: PageNotFoundComponent,
  },
  {
    path: "offline-error",
    component: OfflineErrorComponent,
  },
  {
    path: "internal-server-error/:title/:message",
    component: InternalServerErrorComponent,
  },
  {
    path: "internal-server-error",
    component: InternalServerErrorComponent,
  },
  {
    path: "unauthorized/:title",
    component: UnauthorizedComponent,
  },
  {
    path: "unauthorized",
    component: UnauthorizedComponent,
  },
  {
    path: "work-in-progress",
    component: WorkInProgressComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ErrorRoutingModule { }
