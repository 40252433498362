import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { PageResult } from '../../types';
import { ZipCode } from './types';

export const zipCodeActions = {
  get: createApiCallActions<ZipCode[]>('zipcode', 'get'),
  getById: createApiCallActions<ZipCode>('zipcode', 'getById'),
  getPaged: createApiCallActions<PageResult<ZipCode>>('zipcode', 'getPaged'),
};

export type ZipCodeAction = typeof zipCodeActions;
