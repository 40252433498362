import { Pipe, PipeTransform } from '@angular/core';
import {environment} from "../../../environments/environment";

@Pipe({
  name: 'fullPathImage'
})
export class FullPathImagePipe implements PipeTransform {

  transform(value: string, args: "ToAbsolute" | "ToRelative"): string {

    let relative:string = 'src="/';
    let absolute:string = 'src=' + environment.comparanoourl +'/';

    switch (args) {
      case "ToAbsolute":
        return value.replace(new RegExp(relative, 'g'), absolute);
      case "ToRelative":
        return value.replace(new RegExp(absolute, 'g'), relative);
      default:
        return value;
    }
  }

}
