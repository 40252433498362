<div class="row align-items-center">
  <div class="col-auto">
    <div *ngIf="this.address">{{ this.address }}</div>
    <div *ngIf="this.address2">{{ this.address2 }}</div>
    <div *ngIf="this.zipCode || this.city">
      <span *ngIf="this.zipCode">{{ this.zipCode }} </span>
      <span *ngIf="this.city">{{ this.city }}</span>
    </div>
    <div *ngIf="this.country">{{ this.country }}</div>
  </div>
  <a
    class="mdi mdi-map-marker-outline col h5"
    href="https://www.google.com/maps/search/?api=1&query={{ this.address }}+{{
      this.city
    }}+{{ this.zipCode }}+{{ this.country }}"
    target="_blank"
  ></a>
</div>
