import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Comment, InputComment} from "../../../store/comment/types";
import {Observable} from "rxjs";
import {ActivatedRoute, Router} from "@angular/router";
import {CommentService} from "../../../store/comment/comment.service";

@Component({
  selector: 'app-comment-panel',
  templateUrl: './comment-panel.component.html',
  styleUrls: ['./comment-panel.component.scss'],
})
export class CommentPanelComponent  implements OnInit {

  inputComment: InputComment;
  @Input() entityId: string;
  @Input() entityType: string;
  commentBase$: Observable<Comment[]>;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly commentService: CommentService,
  ) {
    this.commentBase$ = this.commentService.getByEntityId.value$;
  }

  ngOnInit(): void {

    this.inputComment = {
      entityId: +this.entityId,
      entityType: this.entityType
    };

    this.commentService.getByEntityId.call(this.inputComment);
  }

  trackByComment(index: any, comment: Comment) {
    return comment.id;
  }

  refresh(newComment: boolean) {
    this.commentService.getByEntityId.call(this.inputComment);
  }
}
