import { DateTime } from "luxon";

export const glossaryFeatureKey = 'glossary';
export type Glossary = {
  id: number;
  name: string;
  url: string;
  pageContent: string;
  creationDate: Date;
  publicationDate: Date;
};

export type GlossaryPost = {
  name: string;
  url: string;
  pageContent: string;
  meta: string;
};

export type GlossaryUpdate = {
  id: string;
  name: string;
  url: string;
  pageContent: string
  meta: string
}

export type GlossaryPublish = {
  publicationDate: DateTime;
};
