import { Component, inject, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { ViewService } from '../../store/view/views.service';
import { Observable } from 'rxjs';
import { EnumModel } from '../../store/view/types';
import { SalesType } from '../../enum/sales-type';

@Component({
  selector: 'app-category-choice',
  templateUrl: './category-choice.component.html',
  styleUrls: ['./category-choice.component.scss'],
})
export class CategoryChoiceComponent implements OnInit {
  activeModal = inject(NgbActiveModal);
  category: string;
  enumInsuranceCategory$: Observable<EnumModel[]>;
  @Input() salesType: SalesType = SalesType.LEAD;
  private route: string = '';

  constructor(
    private readonly router: Router,
    private readonly viewService: ViewService,
  ) {
    this.enumInsuranceCategory$ = this.viewService.getEnumValues.value$;
  }

  ngOnInit(): void {
    this.viewService.getEnumValues.call(
      'Comparanoo.Core.Enums.InsuranceCategory',
    );

    this.category = 'Health'; //default checked

    switch (this.salesType) {
      case SalesType.LEAD:
        this.route = 'Leads';
        break;
      case SalesType.CONTRACT:
        this.route = 'ContractRequests';
        break;
    }
  }

  navigateForm() {
    this.activeModal.dismiss();
    this.router.navigate(['Sales/' + this.route + '/Create/', this.category]);
  }

  trackByCategory(index: any, item: EnumModel) {
    return item.id;
  }

  select(name: string) {
    this.category = name;
  }
}
