import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractService } from 'src/app/shared/store/contract/contract.service';
import { CarInsuranceContractModel } from 'src/app/shared/store/contract/types';

@Component({
  selector: 'app-contract-details-car-insurance',
  templateUrl: './contract-details-car-insurance.component.html',
  styleUrls: ['./contract-details-car-insurance.component.scss'],
})
export class ContractDetailsCarInsuranceComponent {
  constructor(private readonly contractService: ContractService) {}

  carDetail$: Observable<CarInsuranceContractModel>;

  ngOnInit(): void {
    this.carDetail$ = this.contractService.getCarDetail.value$;
  }
}
