import { NgModule } from '@angular/core';
import {BreadcrumbModule} from "xng-breadcrumb";

@NgModule({
  declarations: [],
  imports: [
    BreadcrumbModule
  ],
  exports: [
    BreadcrumbModule
  ]
})
export class XngBreadcrumbModule { }
