import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { GlossaryRoutingModule } from "./glossary-routing.module";
import { GlossaryListComponent } from './glossary-list/glossary-list.component';
import { GlossaryCreateComponent } from './glossary-create/glossary-create.component';
import { GlossaryDetailComponent } from './glossary-detail/glossary-detail.component';
import { GlossaryEditComponent } from './glossary-edit/glossary-edit.component';

@NgModule({
    declarations:[
        GlossaryListComponent,
        GlossaryCreateComponent,
        GlossaryDetailComponent,
        GlossaryEditComponent
  ],
    imports:[
        CommonModule,
        SharedModule,
        GlossaryRoutingModule,
    ]
})
export class GlossaryModule{}