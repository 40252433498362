import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { SidebarAction, sidebarActions } from './sidebar.actions';
import { SidebarApiService } from './sidebar.api.services';
import { ISidebarState } from './sidebar.reducer';

@Injectable()
export class SidebarEffects {
  constructor(
    private readonly store: Store<ISidebarState>,
    private readonly sidebarApiService: SidebarApiService,
    private readonly actions$: Actions<SidebarAction>
  ) {}

  getSidebarModule = createApiCallEffects(
    this,
    this.actions$,
    sidebarActions.getSidebarModule,
    this.sidebarApiService.getSidebarModule
  );
}
