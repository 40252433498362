import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { leadInitialState } from '../lead/lead.reducer';
import { SearchApiService } from './search.api.services';
import { SearchEffects } from './search.effects';
import {
  ISearchState,
  searchInitialState,
  searchReducer,
} from './search.reducer';
import { searchFeatureKey } from './types';
import { SearchService } from './search.service';

@NgModule({
  imports: [
    StoreModule.forFeature<ISearchState>(searchFeatureKey, searchReducer, {
      initialState: searchInitialState,
    }),
    EffectsModule.forFeature([SearchEffects]),
  ],
  providers: [SearchApiService, SearchService],
})
export class StoreSearchModule {}
