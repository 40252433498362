import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'phoneNumber'
})
export class PhoneNumberPipe implements PipeTransform {

  transform(value?: string): any {

    if (typeof value !== 'string') {
      return value;
    }

    const phoneNumber = value.split(' ').join('');

    let containsPlus = value.indexOf('+') != -1;
    if (containsPlus) {
      //+33 6 93 48 70 09
      return phoneNumber.replace(/(.{3})(.{1})(.{2})(.{2})(.{2})(.{2})/, `$1 $2 $3 $4 $5 $6`);
    }

    //06 93 48 70 09
    return phoneNumber.replace(/(.{2})/g, `$1 `);

  }
}
