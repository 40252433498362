import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {
  ChangePasswordModel,
  ForgotPasswordModel,
  ResetPasswordModel,
} from './types';
import { environment } from '../../../../environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../../error';
import { MessageService } from '../../service/message/message.service';
import { CrudType } from '../../service/message/types';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  forgotPassword = (forgotPassword: ForgotPasswordModel): any => {
    let params: HttpParams = new HttpParams().append(
      'email',
      forgotPassword.email,
    );

    const urlArray: string[] = [
      environment.comparanooapiurl,
      '/',
      environment.features.password.url,
      environment.features.password.forgot,
    ];

    return this.httpClient
      .post<any>(urlArray.join(''), null, {
        params: params,
        observe: 'response',
      })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };
  resetPassword = (resetPassword: ResetPasswordModel): any => {
    const urlArray: string[] = [
      environment.comparanooapiurl,
      '/',
      environment.features.password.url,
      environment.features.password.reset,
    ];

    return this.httpClient
      .post<any>(urlArray.join(''), resetPassword, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };
  changePassword = (changePassword: ChangePasswordModel): any => {
    const urlArray: string[] = [
      environment.comparanooapiurl,
      '/',
      environment.features.password.url,
      environment.features.password.change,
    ];

    return this.httpClient
      .post<any>(urlArray.join(''), changePassword, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };
}
