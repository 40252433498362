import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { HeaderApiService } from './header.api.services';
import { HeaderEffects } from './header.effects';
import {
  headerInitialState,
  headerReducer,
  IHeaderState,
} from './header.reducer';
import { HeaderService } from './header.service';
import { headerFeatureKey } from './type';

@NgModule({
  imports: [
    StoreModule.forFeature<IHeaderState>(headerFeatureKey, headerReducer, {
      initialState: headerInitialState,
    }),
    EffectsModule.forFeature([HeaderEffects]),
  ],
  providers: [HeaderApiService, HeaderService],
})
export class StoreHeaderModule {}
