import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { GenericState, IState } from '../../../common/store/types';
import { Contact, ContactPost, CreateContactFormModel } from './types';
import { onApiCall } from '../../../common/store/generic.reducer';
import { contactActions } from './contact.actions';

export interface IContactState extends IState<Contact> {
  getById: GenericState<Contact>;
  postContact: GenericState<Contact>;
  updateContact: GenericState<Contact>;
  getContactFormModel: GenericState<CreateContactFormModel>;
}

export const contactInitialState: IContactState = {
  getById: new GenericState<Contact>(),
  postContact: new GenericState<Contact>(),
  updateContact: new GenericState<Contact>(),
  getContactFormModel: new GenericState<CreateContactFormModel>(),
};

const contactReducers = [
  ...onApiCall<Contact>(contactActions.getById, 'getById'),
  ...onApiCall<Contact>(contactActions.postContact, 'postContact'),
  ...onApiCall<Contact>(contactActions.updateContact, 'updateContact'),
  ...onApiCall<CreateContactFormModel>(
    contactActions.getContactFormModel,
    'getContactFormModel',
  ),
];

export const contactReducer = createReducer(
  contactInitialState,
  ...contactReducers,
) as ActionReducer<IContactState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return contactReducer(state, action);
}
