import { createApiCallActions } from 'src/app/common/store/generic.actions';
import {
  HealthInsuranceLeadModel,
  CarInsuranceLeadModel,
  HouseInsuranceLeadModel,
  LeadDetailModel,
  HealthLeadFormModel,
  CarLeadFormModel,
  HouseLeadFormModel,
  BaseLeadFormModel,
} from './types';

export const leadActions = {
  getByUniqueId: createApiCallActions<LeadDetailModel>('lead', 'getByUniqueId'),
  getHealthDetail: createApiCallActions<HealthInsuranceLeadModel>(
    'lead',
    'getHealthDetail'
  ),
  getCarDetail: createApiCallActions<CarInsuranceLeadModel>(
    'lead',
    'getCarDetail'
  ),
  getHouseDetail: createApiCallActions<HouseInsuranceLeadModel>(
    'lead',
    'getHouseDetail'
  ),
  sendLead: createApiCallActions<any>('lead', 'sendLead'),
  updateHealthDetail: createApiCallActions<any>('lead', 'updateHealthDetail'),
  updateCarDetail: createApiCallActions<any>('lead', 'updateCarDetail'),
  updateHouseDetail: createApiCallActions<any>('lead', 'updateHouseDetail'),
  createHealthLead: createApiCallActions<any>('lead', 'createHealthLead'),
  createCarLead: createApiCallActions<any>('lead', 'createCarLead'),
  createHouseLead: createApiCallActions<any>('lead', 'createHouseLead'),
  getBaseLeadModel: createApiCallActions<BaseLeadFormModel>(
    'lead',
    'getBaseLeadModel'
  ),
  getHealthLeadModel: createApiCallActions<HealthLeadFormModel>(
    'lead',
    'getHealthLeadModel'
  ),
  getCarLeadModel: createApiCallActions<CarLeadFormModel>(
    'lead',
    'getCarLeadModel'
  ),
  getHouseLeadModel: createApiCallActions<HouseLeadFormModel>(
    'lead',
    'getHouseLeadModel'
  ),
};
export type LeadAction = typeof leadActions;
