import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade } from 'src/app/common/store/types';
import { AppState } from '../types';
import { paginationActions } from './pagination.actions';
import { paginationSelectors } from './pagination.selectors';

@Injectable()
export class PaginationService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  setPageIndex = (pageIndex: number) => {
    this.store.dispatch(
      paginationActions.setPageIndex({ pageIndex: pageIndex })
    );
  };

  setTotalCount = (totalCount: number) => {
    this.store.dispatch(
      paginationActions.setTotalCount({ totalCount: totalCount })
    );
  };

  setPageSize = (pageSize: number) => {
    this.store.dispatch(paginationActions.setPageSize({ pageSize: pageSize }));
  };

  setTotalPages = (totalPages: number) => {
    this.store.dispatch(
      paginationActions.setTotalPages({ totalPages: totalPages })
    );
  };

  setFilter = (filter: string[]) => {
    this.store.dispatch(
      paginationActions.setFilter({
        filter,
      })
    );
  };

  setSortBy = (sortBy: string) => {
    this.store.dispatch(paginationActions.setSortBy({ sortBy }));
  };

  setOrderBy = (orderBy: string) => {
    this.store.dispatch(paginationActions.setOrderBy({ orderBy }));
  };

  getPageIndex = () => this.store.select(paginationSelectors.getPageIndex);

  getTotalCount = () => this.store.select(paginationSelectors.getTotalCount);

  getPageSize = () => this.store.select(paginationSelectors.getPageSize);

  getTotalPages = () => this.store.select(paginationSelectors.getTotalPages);

  getFilter = () => this.store.select(paginationSelectors.getFilter);

  getSortBy = () => this.store.select(paginationSelectors.getSortBy);

  getOrderBy = () => this.store.select(paginationSelectors.getOrderBy);
}
