import { createAction, createActionGroup, props } from '@ngrx/store';

export const paginationActions = {
  setPageIndex: createAction(
    '[Pagination] Set page',
    props<{ pageIndex: number }>()
  ),
  setTotalCount: createAction(
    '[Pagination] Set Total Count',
    props<{ totalCount: number }>()
  ),
  setPageSize: createAction(
    '[Pagination] Set PageSize',
    props<{ pageSize: number }>()
  ),
  setTotalPages: createAction(
    '[Pagination] Set TotalPages',
    props<{ totalPages: number }>()
  ),
  setFilter: createAction(
    '[Pagination] Set CurrentFilter',
    props<{ filter: string[] }>()
  ),
  setSortBy: createAction(
    '[Pagination] Set SortBy',
    props<{ sortBy: string }>()
  ),
  setOrderBy: createAction(
    '[Pagination] Set OrderBy',
    props<{ orderBy: string }>()
  ),
};
export type PaginationAction = typeof paginationActions;
