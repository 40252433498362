<div class="modal-header">
  <h5 class="modal-title" translate>MODAL_LBL_UNSAVED_DATA</h5>
</div>
<div class="modal-body">
  <p translate>
    MODAL_MSG_UNSAVED_DATA_CONTENT_DESCRIPTION
    <br />
    MODAL_MSG_UNSAVED_DATA_CONTENT_QUESTION
  </p>
</div>
<div class="modal-footer">
  <button
    [class]="ButtonType.Light | buttontypepipe"
    class="btn-sm btn"
    (click)="modal.close(false)"
    translate
  >
    GLOBAL_BTN_NO
  </button>
  <button
    [class]="ButtonType.Primary | buttontypepipe"
    class="btn-sm btn"
    translate
    (click)="modal.close(true)"
    ngbAutofocus
  >
    GLOBAL_BTN_YES
  </button>

</div>

