export const currencyFeatureKey = 'currency';
export type Currency = {
    id: number;
    isoCode: string;
    name: string;
    symbol: string;
    accuracy: number;
    rounding: number;
    symbolPosition: SymbolPosition;
    currentRate: number;
    symbolPositionLoc: string;
}

export class CurrencyPost {
    constructor(
        isoCode: string,
        name: string,
        symbol: string,
        accuracy: number,
        rounding: number,
        symbolPosition: number,
        currentRate: number | null,
    ) {
        this.isoCode = isoCode;
        this.name = name;    
        this.symbol = symbol;
        this.accuracy = accuracy;
        this.rounding = rounding;
        this.symbolPosition = symbolPosition;
        this.currentRate = currentRate;
    }
    isoCode: string;
    name: string;
    symbol: string;
    accuracy: number;
    rounding: number;
    symbolPosition: SymbolPosition;
    currentRate: number | null;
}

export class CurrencyUpdate {
    id: number;
    isoCode: string;
    name: string;
    symbol: string;
    accuracy: number;
    rounding: number;
    symbolPosition: SymbolPosition;
    currentRate: number | null;
}

export enum SymbolPosition {
    AfterAmount = 1,
    BeforeAmount,
    
}