import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ButtonType } from '../../store/subheader/type';
@Component({
  selector: 'confirmation',
  standalone: false,
  templateUrl: './confirmation.component.html',
})
export class ConfirmationComponent {
  protected readonly ButtonType = ButtonType;
  constructor(public modal: NgbActiveModal) {}
}
