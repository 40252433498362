import { DateTime } from "luxon";

export const articleFeatureKey = 'article';
export class Article {
  id: number;
  name: string;
  url: string;
  description: string;
  pageContent: string;
  creationDate: Date;
  publicationDate: Date;
}

export class ArticlePost {
  constructor(
    name: string,
    url: string,
    description: string,
    pageContent: string,
    thumbnail: File,
    background: File
  ) {
    this.name = name;
    this.url = url;
    this.description = description;
    this.pageContent = pageContent;
    this.thumbnail = thumbnail;
    this.background = background;
  }
  name: string;
  url: string;
  description: string;
  pageContent: string;
  thumbnail: File;
  background: File;
}

export type ArticleUpdate = {
  id: string;
  name: string;
  url: string;
  description: string;
  pageContent: string;
}

export type ArticlePublish = {
  publicationDate: DateTime;
};
