import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SidebarModule } from './types';
import {ErrorService} from "../../error";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root',
})
export class SidebarApiService {
  constructor(private readonly httpClient: HttpClient,
              private readonly errorService:ErrorService
  ) {}

  getSidebarModule = (): Observable<SidebarModule[]> => {
    let parameters: string[];
    parameters = [];
    const headers = new HttpHeaders();
    headers.set('Accept-Language', 'fr');
    let url = this.formatUrl(parameters);
    return this.httpClient
      .get<SidebarModule[]>(url, { headers: headers })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  formatUrl = (parameters?: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.layout.navigation.url;
    if (typeof parameters !== 'undefined') {
      parameters.forEach((parameters) => {
        url += '/';
        url += parameters;
      });
    }
    return url;
  };
}
