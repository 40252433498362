import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Contact } from '../../../../shared/store/contact/types';
import { SmartAction, View } from '../../../../shared/store/view/types';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { ContactService } from '../../../../shared/store/contact/contact.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-contact-details',
  templateUrl: './contact-details.component.html',
  styleUrls: ['./contact-details.component.scss'],
})
export class ContactDetailsComponent implements OnInit {
  contactId: number;
  view$: Observable<View>;
  smartActions: SmartAction[];
  contactDetail$: Observable<Contact>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly viewService: ViewService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly contactService: ContactService
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.contactId = this.route.snapshot.params['id'];
    this.contactDetail$ = this.contactService.getById.value$;

    this.smartActions = [
      {
        id: 'leads',
        label: 'Prospects',
        url: '/Sales/Leads/List',
        count: 0,
        filter: {
          domains: [
            {
              field: 'ContactId',
              operator: {
                id: 'EqualTo',
                label: '',
                numberOfValues: 1,
              },
              connector: 'Or',
              fieldLabel: '',
              text: '',
              isActive: true,
              text2: '',
              value: '',
              value2: null,
            },
          ],
          name: '',
          tag: 'SmartActionFilter',
          text: '',
        },
        icon: 'file-check-outline',
      },
      {
        id: 'leads',
        label: 'Contracts',
        url: '/Sales/ContractRequests/List',
        count: 0,
        filter: {
          domains: [
            {
              field: 'ContactId',
              operator: {
                id: 'EqualTo',
                label: '',
                numberOfValues: 1,
              },
              connector: 'Or',
              fieldLabel: '',
              text: '',
              isActive: true,
              text2: '',
              value: '',
              value2: null,
            },
          ],
          name: '',
          tag: 'SmartActionFilter',
          text: '',
        },
        icon: 'file-check-outline',
      },
    ];

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'contactEdit') {
          this.router.navigate(['Contacts/Contacts/Edit/', this.contactId]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.contactDetail$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this)
      )
      .subscribe((x) => {
        this.smartActions[0].count = x.leads.length;
        this.smartActions[0].filter.domains[0].value = x.id;
        this.smartActions[0].filter.name = x.name;
        this.smartActions[0].filter.text = x.name;

        this.smartActions[1].count = x.contracts.length;
        this.smartActions[1].filter.domains[0].value = x.id;
        this.smartActions[1].filter.name = x.name;
        this.smartActions[1].filter.text = x.name;
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@contactList', x.title);
      });
  }
}
