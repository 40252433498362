import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BankAccountApiService } from './bank-account.api.services';
import { BankAccountEffects } from './bank-account.effects';
import {
  IBankAccountState,
  bankAccountInitialState,
  bankAccountReducer,
} from './bank-account.reducer';
import { BankAccountService } from './bank-account.service';
import { bankAccountFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IBankAccountState>(
      bankAccountFeatureKey,
      bankAccountReducer,
      {
        initialState: bankAccountInitialState,
      }
    ),
    EffectsModule.forFeature([BankAccountEffects]),
  ],
  providers: [BankAccountApiService, BankAccountService],
})
export class StoreBankAccountModule {}
