import { BehaviorSubject } from 'rxjs';
import { combineLatest, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';
import { TranslateConfig } from './types';
import { TranslateConfigService } from './translate.config.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class CommonTranslateService {
  private config: TranslateConfig;

  all$: BehaviorSubject<string[]>;
  current$: BehaviorSubject<string> = new BehaviorSubject('');
  notCurrent$: Observable<string[]>;

  constructor(
    private readonly translateConfigService: TranslateConfigService,
    private readonly translateService: TranslateService
  ) {
    this.config = this.translateConfigService.get();
    const locales = this.config.locales;
    const defaultLocale = this.getDefaultUserLanguage();

    this.current$.next(defaultLocale);
    this.all$ = new BehaviorSubject(locales);
    this.notCurrent$ = combineLatest([this.all$, this.current$]).pipe(
      map(([keys, current]) => keys.filter((key) => key != current))
    );

    this.translateService.setDefaultLang(defaultLocale);

    this.translateService.addLangs(locales);

    this.setLanguage(defaultLocale);
  }

  /**
   * Initialize the common translate service.
   * @param langKey The application language key.
   */
  init = (langKey?: string): void => {
    if (langKey) {
      this.setLanguage(langKey);
    }
  };

  /**
   * Set application to specified language key.
   * @param langKey The language key.
   */
  setLanguage = (langKey: string): void => {
    if (!langKey) {
      langKey = this.config.locales[0];
    }
    this.translateService
      .use(langKey)
      .pipe(take(1))
      .subscribe(
        () => {
          this.current$.next(langKey);
          console.info(`Successfully initialized '${langKey}' language.`);
        },
        (_) => {
          console.error(`Problem with '${langKey}' language initialization.`);
        }
      );
  };

  /**
   * Get the current application language key.
   * @returns The language key.
   */
  getLanguage = (): string => this.translateService.currentLang;

  /**
   * Gets the translated value of a key (or an array of keys)
   * @returns the translated key, or an object of translated keys
   */
  get = (key: string, replacements?: object) => this.translateService.get(key, replacements);

  /**
   * Returns a translation instantly from the internal state of loaded translation.
   * All rules regarding the current language, the preferred language of even fallback languages will be used except any promise handling.
   */
  instant = (key: string, replacements?: object) => this.translateService.instant(key, replacements);

  private getDefaultUserLanguage = (): string => {
    let result = this.config.locales[0];
    this.config.locales.forEach((locale) => {
      if (window.navigator.language.includes(locale)) {
        result = locale;
      }
    });
    return result;
  };
}
