import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ErrorsNestedFormGroup } from '../../../store/form/types';
import { FormService } from '../../../store/form/form.service';
import { ViewService } from '../../../store/view/views.service';
import { View } from '../../../store/view/types';

@Component({
  selector: 'app-toast-validation-content',
  templateUrl: './toast-validation-content.component.html',
  styleUrls: ['./toast-validation-content.component.scss'],
})
export class ToastValidationContentComponent implements OnInit {
  errorsDescription$: Observable<ErrorsNestedFormGroup>;
  view$: Observable<View>;

  constructor(
    private readonly formService: FormService,
    private readonly viewService: ViewService,
  ) {}

  ngOnInit(): void {
    this.errorsDescription$ = this.formService.getErrors();
    this.view$ = this.viewService.getViews.value$;
  }
}
