import { Injectable } from '@angular/core';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { Store } from '@ngrx/store';
import { AppState } from '../types';
import { Country, CountryPost, CountryUpdate } from './types';
import { PageResult } from '../../types';
import { countrySelectors } from './country.selector';
import { countryActions } from './country.actions';

@Injectable()
export class CountryService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<Country, string> = this.genericApiCall(
    countryActions.getById,
    countrySelectors.getById,
    true
  );

  postCountry: GenericApiCall<any, CountryPost> = this.genericApiCall(
    countryActions.postCountry,
    countrySelectors.postCountry,
    true
  );

  updateCountry: GenericApiCall<Country, CountryUpdate> = this.genericApiCall(
    countryActions.updateCountry,
    countrySelectors.updateCountry,
    true
  );
}
