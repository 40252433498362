import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { filter, Observable, of } from 'rxjs';
import { View } from '../../../../../shared/store/view/types';
import { CurrencyService } from '../../../../../shared/store/currency/currency.service';
import { Router } from '@angular/router';
import { SubHeaderService } from '../../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SymbolPosition } from '../../../../../shared/store/currency/types';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SourceEnum } from '../../../../../shared/store/typeahead/types';
import { ModalService } from '../../../../../shared/service/modal.service';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-currency-create-content',
  templateUrl: './currency-create-content.component.html',
  styleUrls: ['./currency-create-content.component.scss'],
})
export class CurrencyCreateContentComponent implements OnInit, OnDestroy {
  symbolPositionsArray: any[];
  view$: Observable<View>;

  @Input() modalName: string;
  @Input() source: SourceEnum = SourceEnum.Component;
  @Output() modalEvent = new EventEmitter<number>();

  constructor(
    private readonly currencyService: CurrencyService,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly dynamicService: ModalService,
    private readonly formService: FormService,
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    if (this.modalName) {
      this.currencyForm.get('name')?.setValue(this.modalName);
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'currencyCreate') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@currencyList', x.title);
        this.breadcrumbService.set('@currencyCreate', x.breadcrumbCreate);
      });

    this.symbolPositionsArray = [
      { label: 'Après le montant', value: SymbolPosition.AfterAmount },
      { label: 'Avant le montant', value: SymbolPosition.BeforeAmount },
    ];
  }

  currencyForm = new FormGroup({
    isoCode: new FormControl('', [
      Validators.required,
      Validators.maxLength(5),
    ]),
    name: new FormControl('', [Validators.required, Validators.maxLength(80)]),
    accuracy: new FormControl('', [Validators.required]),
    currentRate: new FormControl(''),
    rounding: new FormControl('', [Validators.required]),
    symbol: new FormControl('', [Validators.maxLength(5)]),
    symbolPosition: new FormControl(SymbolPosition.AfterAmount, [
      Validators.required,
    ]),
  });

  onSubmit() {
    if (this.currencyForm.invalid) {
      this.currencyForm.markAllAsTouched();
      this.formService.countErrors(this.currencyForm, true);
      return;
    }

    this.formService.clear();

    let isCurrentRateWrong: boolean =
      this.currencyForm.value.currentRate! === '' ||
      this.currencyForm.value.currentRate! === null;

    let result$ = this.currencyService.postCurrency.call({
      isoCode: this.currencyForm.value.isoCode!,
      name: this.currencyForm.value.name!,
      accuracy: +this.currencyForm.value.accuracy!,
      currentRate: isCurrentRateWrong
        ? null
        : +this.currencyForm.value.currentRate!,
      rounding: +this.currencyForm.value.rounding!,
      symbol: this.currencyForm.value.symbol!,
      symbolPosition: this.currencyForm.value.symbolPosition!,
    });

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        if (result != undefined) {
          if (this.source === SourceEnum.Component) {
            this.router.navigate([
              '/Configuration/Currencies/Details',
              result.id,
            ]);
          }
          if (this.source === SourceEnum.Modal) {
            this.dynamicService.outputFromDynamicComponent(result.id);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.currencyService.postCurrency.reset();
  }
}
