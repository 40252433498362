import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { GlossaryAction, glossaryActions } from './glossary.actions';
import { GlossaryApiService } from './glossary.api.services';

@Injectable()
export class GlossaryEffects {
  constructor(
    private readonly glossaryApiService: GlossaryApiService,
    private readonly actions$: Actions<GlossaryAction>
  ) {}
  getPaged = createApiCallEffects(
    this,
    this.actions$,
    glossaryActions.getPaged,
    this.glossaryApiService.getPaged
  );
  getById = createApiCallEffects(
    this,
    this.actions$,
    glossaryActions.getById,
    this.glossaryApiService.getById
  );
  postGlossary = createApiCallEffects(
    this,
    this.actions$,
    glossaryActions.postGlossary,
    this.glossaryApiService.postGlossary
  );
  publishGlossary = createApiCallEffects(
    this,
    this.actions$,
    glossaryActions.publishGlossary,
    this.glossaryApiService.publishGlossary
  );
  unpublishGlossary = createApiCallEffects(
    this,
    this.actions$,
    glossaryActions.unpublishGlossary,
    this.glossaryApiService.unpublishGlossary
  );
  updateGlossary = createApiCallEffects(
    this,
    this.actions$,
    glossaryActions.updateGlossary,
    this.glossaryApiService.updateGlossary
  )
}
