import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { PaymentSplittingEffects } from './payment-splitting.effects';
import {
  IPaymentSplittingState,
  paymentSplittingInitialState,
  paymentSplittingReducer,
} from './payment-splitting.reducer';
import { PaymentSplittingService } from './payment-splitting.service';
import { paymentSplittingFeatureKey } from './type';
import { PaymentSplittingApiService } from './payment-splitting.api.services';

@NgModule({
  imports: [
    StoreModule.forFeature<IPaymentSplittingState>(
      paymentSplittingFeatureKey,
      paymentSplittingReducer,
      {
        initialState: paymentSplittingInitialState,
      }
    ),
    EffectsModule.forFeature([PaymentSplittingEffects]),
  ],
  providers: [PaymentSplittingApiService, PaymentSplittingService],
})
export class StorePaymentSplittingModule {}
