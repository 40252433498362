import { createFeatureSelector } from "@ngrx/store";
import { createApiCallSelectors } from "src/app/common/store/generic.selectors";
import { ICurrencyState } from "./currency.reducer";
import { currencyFeatureKey } from "./types";

const currencyFeatureState = 
    createFeatureSelector<ICurrencyState>(currencyFeatureKey);

export const currencySelectors = {
    getById: createApiCallSelectors<number, ICurrencyState>(
        currencyFeatureState,
        'getById'
    ),
    getList: createApiCallSelectors<number, ICurrencyState>(
        currencyFeatureState,
        'getList'
    ),
    postCurrency: createApiCallSelectors<any, ICurrencyState>(
        currencyFeatureState,
        'postCurrency'
    ),
    updateCurrency: createApiCallSelectors<any, ICurrencyState>(
        currencyFeatureState,
        'updateCurrency'
    )
};