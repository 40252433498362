import { Component } from '@angular/core';

@Component({
  selector: 'page-not-found',
  templateUrl: './page-not-found.html',
  styleUrls: ['page-not-found.scss']
})
export class PageNotFoundComponent {

  constructor() { };
}
