export const headerFeatureKey = 'header';

export class HeaderMenu {
  id: any;
  title: string;
  route?: string;
  icon?: string;
  items?: HeaderMenu[];
}

export class HeaderSubMenu {
  title: string;
  route: string;
}

export enum HeaderMenuType {
  Home = 1,
  Users = 2,
  Content = 3,
  Configuration = 4,
  Sales = 5,
  HumanResources,
  Account,
  Contacts,
}
