    <h2 class="mb-4" translate>LOGIN_LBL_TITLE</h2>
    <input type="hidden" name="returnUrl" value="@ViewBag.ReturnUrl" />
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div
        *ngIf="this.showErrorLogin"
        class="alert alert-danger"
        id="validationSummary"
      >
        <ul>
          <li translate>LOGIN_MSG_WRONG_LOGIN</li>
        </ul>
      </div>
      <div class="form-group"
      >
        <label for="email" class="form-label" translate>LOGIN_LBL_USERNAME</label>
        <input
          id="email"
          type="text"
          formControlName="email"
          class="form-control form-control-lg"
          required
          autocomplete="off"
          tabindex="1"
          checkFormFieldValidity
      />
      </div>
      <div class="form-group"
      >
        <div class="d-flex align-items-baseline">
          <div class="flex-fill">
            <label for="password" class="form-label" translate
              >LOGIN_LBL_PASSWORD</label
            >
          </div>
          <a class="fs-6"  routerLink="/ForgotPassword" translate>LOGIN_LBL_FORGET_PASSWORD</a>
        </div>
        <input
          id="password"
          type="password"
          formControlName="password"
          class="form-control form-control-lg"
          tabindex="2"
          required
          checkFormFieldValidity
        />
      </div>
      <div class="form-group d-flex justify-content-center">
        <div class="form-check" [hidden]="true">
          <input class="form-check-input" type="checkbox" id="rememberMe" />
          <label class="form-check-label" for="rememberMe" translate
            >LOGIN_LBL_REMEMBER_ME</label
          >
        </div>
      </div>
      <div class="text-center">
        <button type="submit" class="btn btn-primary btn-lg" tabindex="3" translate>
          LOGIN_BTN_LOGIN
        </button>
      </div>
    </form>
