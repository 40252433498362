import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { AppState } from '../types';
import { Store } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { InputReporting, LabelWithValue, Period } from './types';
import { reportingActions } from './reporting.actions';
import { reportingSelectors } from './reporting.selectors';

@Injectable()
export class ReportingService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getReportByFilter: GenericApiCall<any[], InputReporting> =
    this.genericApiCall(
      reportingActions.getReportByFilter,
      reportingSelectors.getReportByFilter,
      true
    );

  getReportLeadsMonthlyRange: GenericApiCall<any[], InputReporting> =
    this.genericApiCall(
      reportingActions.getReportLeadsMonthlyRange,
      reportingSelectors.getReportLeadsMonthlyRange,
      true
    );

  getReportPeopleByRegime: GenericApiCall<any[], InputReporting> =
    this.genericApiCall(
      reportingActions.getReportPeopleByRegime,
      reportingSelectors.getReportPeopleByRegime,
      true
    );

  getReportFemalePeopleByAge: GenericApiCall<any[], InputReporting> =
    this.genericApiCall(
      reportingActions.getReportFemalePeopleByAge,
      reportingSelectors.getReportFemalePeopleByAge,
      true
    );

  getReportMalePeopleByAge: GenericApiCall<any[], InputReporting> =
    this.genericApiCall(
      reportingActions.getReportMalePeopleByAge,
      reportingSelectors.getReportMalePeopleByAge,
      true
    );

  getLeadsByDayAndHoursRange: GenericApiCall<any[], InputReporting> =
    this.genericApiCall(
      reportingActions.getLeadsByDayAndHoursRange,
      reportingSelectors.getLeadsByDayAndHoursRange,
      true
    );

  getReportLeadsByType: GenericApiCall<any[], InputReporting> =
    this.genericApiCall(
      reportingActions.getReportLeadsByType,
      reportingSelectors.getReportLeadsByType,
      true
    );

  getReportLeadsByCity: GenericApiCall<any[], InputReporting> =
    this.genericApiCall(
      reportingActions.getReportLeadsByCity,
      reportingSelectors.getReportLeadsByCity,
      true
    );

  getPeriods: GenericApiCall<Period[], Date> = this.genericApiCall(
    reportingActions.getPeriods,
    reportingSelectors.getPeriods,
    true
  );

  getReportLeadsSum: GenericApiCall<number, InputReporting> =
      this.genericApiCall(
          reportingActions.getReportLeadsSum,
          reportingSelectors.getReportLeadsSum,
          true
      );

  getReportContractsSum: GenericApiCall<number, InputReporting> =
      this.genericApiCall(
          reportingActions.getReportContractsSum,
          reportingSelectors.getReportContractsSum,
          true
      );
  getReportAnnualFeesSum: GenericApiCall<number, InputReporting> =
      this.genericApiCall(
          reportingActions.getReportAnnualFeesSum,
          reportingSelectors.getReportAnnualFeesSum,
          true
      );
  getReportMonthlyFeesAverage: GenericApiCall<number, InputReporting> =
      this.genericApiCall(
          reportingActions.getReportMonthlyFeesAverage,
          reportingSelectors.getReportMonthlyFeesAverage,
          true
      );

  setBarGraphStartDate = (barGraphStartDate: string) => {
    this.store.dispatch(
      reportingActions.setBarGraphStartDate({
        barGraphStartDate: barGraphStartDate,
      })
    );
  };

  setBarGraphEndDate = (barGraphEndDate: string) => {
    this.store.dispatch(
      reportingActions.setBarGraphEndDate({
        barGraphEndDate: barGraphEndDate,
      })
    );
  };

  getBarGraphStartDate = () =>
    this.store.select(reportingSelectors.getBarGraphStartDate);

  getBarGraphEndDate = () =>
    this.store.select(reportingSelectors.getBarGraphEndDate);
}
