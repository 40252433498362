import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { PageResult } from '../../types';
import { User } from './type';

export const userActions = {
  get: createApiCallActions<User[]>('user', 'get'),
  getById: createApiCallActions<User>('user', 'getById'),
  add: createApiCallActions<boolean>('user', 'add'),
  getPaged: createApiCallActions<PageResult<User>>('user', 'getPaged'),
  getCurrentUser: createApiCallActions<User>('currentUser', 'getCurrentUser'),
};
export type UserAction = typeof userActions;
