<ng-container *ngIf="!this.referenceEndpoint">

  <ng-select [items]="dataSource$ | async"
             #select
             [bindValue]="this.valueMember"
             [bindLabel]="this.displayMember"
             [searchable]="this.dropdownStyle === DropdownStyle.DropDownList"
             [editableSearchTerm]="this.allowEdit"
             [multiple]="false"
             [typeahead]="searchInput$"
             [addTagText]="'TYPEAHEAD_LBL_ADD_TAG_TEXT' | translate"
             [clearAllText]="'TYPEAHEAD_LBL_CLEAR_ALL_TEXT' | translate"
             [addTag]="quickAdd"
             [closeOnSelect]="true"
             [clearSearchOnAdd]="false"
             (clear)="cleanSearchTerm()"
             (change)="onValueMemberChange($event)"
             (focusout)="onFocusOut($event)"
             (focusin)="onFocusIn($event)"
             [formControl]="this.controlForm"
             [readonly]="this.readonly"
             class="form-control form-control-sm"
             checkFormFieldValidity
  >
    <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
      <div [ngOptionHighlight]="search">{{ item.name }}</div>
    </ng-template>
    <ng-template ng-footer-tmp
                 *ngIf="allowCustomActions && (this.controlForm.value || this.termSearchString.length > 0)">
      <div class="row">
        <div class="col btn-sm btn btn-outline-primary m-2" *ngIf="this.controlForm.value" (click)="openModalDetails()" translate>
          TYPEAHEAD_LBL_DETAIL
        </div>
        <div class="col btn-sm btn btn-outline-primary m-2" *ngIf="this.termSearchString.length > 0"
             (click)="openModalCreate()" translate>TYPEAHEAD_LBL_CREATE
        </div>
      </div>
    </ng-template>
  </ng-select>
</ng-container>

<ng-container *ngIf="this.referenceEndpoint">
  <ng-select #myselectRef
             [items]="dataSource$ | async"
             [bindValue]="this.valueMember"
             [bindLabel]="this.displayMember"
             [searchable]="this.dropdownStyle === DropdownStyle.DropDownList"
             [addTag]="quickAdd"
             [addTagText]="'TYPEAHEAD_LBL_ADD_TAG_TEXT' | translate"
             [clearAllText]="'TYPEAHEAD_LBL_CLEAR_ALL_TEXT' | translate"
             [searchFn]="customSearchFn"
             (change)="onValueMemberRefChange($event)"
             (clear)="cleanRefTerm()"
             [formControl]="this.controlForm"
             [readonly]="this.readonly"
             class="form-control form-control-sm"
             checkFormFieldValidity
  >

    <ng-template ng-label-tmp let-item="item">
      {{ item.name }}
    </ng-template>
    <ng-template ng-option-tmp let-item="item" let-index="index" let-search="searchTerm">
      <div [ngOptionHighlight]="search">{{ item.name }}</div>
    </ng-template>
  </ng-select>
</ng-container>
