<div class="modal-content">
  <div class="modal-header">
    <div class="modal-title h4" translate>CATEGORY_CHOICE_LBL_{{this.salesType}}</div>
  </div>
  <div class="modal-body">
    <div class="panel-body">
      <div class="clearfix">
        <ng-container *ngIf="(this.enumInsuranceCategory$ | async) as enumValues">
          <ng-container *ngFor="let enumValue of enumValues; index as index; trackBy:trackByCategory">
            <div class="form-check" *ngIf="enumValue.id > 0">
              <input
                class="form-check-input"
                type="radio"
                name="flexRadioDefault"
                [(ngModel)]="category"
                [value]="enumValue.name"
                [id]="enumValue.id"
              />
              <label class="form-check-label" [for]="enumValue.name" (click)="select(enumValue.name)"> {{ enumValue.nameLoc }} </label>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
    <div class="modal-footer">
      <button
        type="reset"
        class="btn btn-outline-light"
        (click)="activeModal.dismiss('Cancel click')"
        translate
      >
        GLOBAL_BTN_CANCEL
      </button>
      <button type="submit" class="btn btn-primary" (click)="navigateForm()" translate>
        GLOBAL_BTN_VALIDATION
      </button>
    </div>
  </div>
</div>
