import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactsRoutingModule } from './contacts-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { ContactModule } from './contact/contact.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, SharedModule, ContactsRoutingModule, ContactModule],
  exports: [],
})
export class ContactsModule {}
