import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent {

  @Input() address!:string;
  @Input() address2!:string;
  @Input() zipCode!:string;
  @Input() city!:string;
  @Input() country!:string;


}
