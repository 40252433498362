import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { PageResult } from '../../types';
import { ICountyState } from './county.reducer';
import { County, countyFeatureKey } from './types';

const countyFeatureState =
  createFeatureSelector<ICountyState>(countyFeatureKey);

export const countySelectors = {
  get: createApiCallSelectors<County[], ICountyState>(
    countyFeatureState,
    'get'
  ),
  getById: createApiCallSelectors<County, ICountyState>(
    countyFeatureState,
    'getById'
  ),
  getPaged: createApiCallSelectors<PageResult<County>, ICountyState>(
    countyFeatureState,
    'getPaged'
  ),
};
