import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import {BaseServiceFacade, GenericApiCall} from 'src/app/common/store/types';
import { AppState } from '../types';
import { authenticationActions } from './authentication.actions';
import { authenticationSelectors } from './authentication.selectors';
import {ChangePasswordModel, ForgotPasswordModel, ResetPasswordModel, UserConnected} from './types';


@Injectable()
export class AuthenticationService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  setUserConnected = (userConnected: UserConnected) => {
    this.store.dispatch(
      authenticationActions.setUserConnected({ userConnected: userConnected })
    );
  };

  getUserConnected = () =>
    this.store.select(authenticationSelectors.getUserConnected);

  forgotPassword: GenericApiCall<any, ForgotPasswordModel> = this.genericApiCall(
      authenticationActions.forgotPassword,
      authenticationSelectors.forgotPassword,
      true
  );

  resetPassword: GenericApiCall<any, ResetPasswordModel> = this.genericApiCall(
      authenticationActions.resetPassword,
      authenticationSelectors.resetPassword,
      true
  );
  changePassword: GenericApiCall<any, ChangePasswordModel> = this.genericApiCall(
      authenticationActions.changePassword,
      authenticationSelectors.changePassword,
      true
  );
}
