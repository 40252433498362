import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthentificationGuard } from 'src/app/shared/guard/authentification-guard';
import { HeaderMenuType } from 'src/app/shared/store/header/type';
import { SubHeaderType } from 'src/app/shared/store/subheader/type';
import { HomeLayoutComponent } from './home-layout/home-layout.component';
import { Entity } from 'src/app/shared/store/view/types';
import { HasUnsavedChangesGuard } from '../../shared/guard/has-form-changed.guard';

const routes: Routes = [
  {
    path: 'Home',
    component: HomeLayoutComponent,
    canActivate: [AuthentificationGuard],
    canDeactivate: [HasUnsavedChangesGuard],
    canActivateChild: [],
    data: {
      title: 'APP_TITLE_HOME',
      header: 'HEADER_SUBTITLE_HOME',
      headerMenu: HeaderMenuType.Home,
      entity: Entity.Home,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class HomeRoutingModule {}
