import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LeadService } from 'src/app/shared/store/lead/lead.service';
import { CarInsuranceLeadModel } from 'src/app/shared/store/lead/types';

@Component({
  selector: 'app-lead-details-car-insurance',
  templateUrl: './lead-details-car-insurance.component.html',
  styleUrls: ['./lead-details-car-insurance.component.scss'],
})
export class LeadDetailsCarInsuranceComponent implements OnInit {
  constructor(private readonly leadService: LeadService) {}

  carDetail$: Observable<CarInsuranceLeadModel>;

  ngOnInit(): void {
    this.carDetail$ = this.leadService.getCarDetail.value$;
  }
}
