import {Injectable} from "@angular/core";
import {ITypeaheadState} from "./typeahead.reducer";
import {Store} from "@ngrx/store";
import {TypeaheadApiService} from "./typeahead.api.service";
import {TypeaheadAction, typeaheadActions} from "./typeahead.actions";
import {Actions} from "@ngrx/effects";
import {createApiCallEffects} from "../../../common/store/generic.effects";

@Injectable()
export class TypeaheadEffects {
  constructor(private readonly store:Store<ITypeaheadState>,
              private readonly typeaheadApiService:TypeaheadApiService,
              private readonly action$: Actions<TypeaheadAction>) {
  }

  get = createApiCallEffects(
    this,
    this.action$,
    typeaheadActions.get,
    this.typeaheadApiService.get
  )
  getReferencesItems = createApiCallEffects(
    this,
    this.action$,
    typeaheadActions.getReferencesItems,
    this.typeaheadApiService.getReferencesItems
  )
  quickAdd = createApiCallEffects(
    this,
    this.action$,
    typeaheadActions.quickAdd,
    this.typeaheadApiService.quickAdd
  )

}
