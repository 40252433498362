import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { ISidebarState } from './sidebar.reducer';
import { sidebarFeatureKey, SidebarModule } from './types';

const sidebarFeatureState =
  createFeatureSelector<ISidebarState>(sidebarFeatureKey);

export const sidebarSelectors = {
  getSidebarModule: createApiCallSelectors<SidebarModule[], ISidebarState>(
    sidebarFeatureState,
    'getSidebarModule'
  ),
};
