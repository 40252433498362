import { GenericState } from 'src/app/common/store/types';
import { coreActions } from './core.actions';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { Action, ActionReducer, createReducer } from '@ngrx/store';
import {
  FavoriteSearchView,
  Field,
  Operation,
  SearchView,
} from '../search/types';

export interface ICoreState {
  getFieldsByEntity: GenericState<Field[]>;
  getSearchViewByEntity: GenericState<SearchView>;
  getFavoriteByEntity: GenericState<FavoriteSearchView[]>;
  getOperatorsByType: GenericState<Operation[]>;
  getStringOperators: GenericState<Operation[]>;
  getIntOperators: GenericState<Operation[]>;
}

export const coreInitialState: ICoreState = {
  getFieldsByEntity: new GenericState<Field[]>(),
  getSearchViewByEntity: new GenericState<SearchView>(),
  getFavoriteByEntity: new GenericState<FavoriteSearchView[]>(),
  getOperatorsByType: new GenericState<Operation[]>(),
  getStringOperators: new GenericState<Operation[]>(),
  getIntOperators: new GenericState<Operation[]>(),
};
const coreReducers = [
  ...onApiCall<Field[]>(coreActions.getFieldsByEntity, 'getFieldsByEntity'),
  ...onApiCall<SearchView>(
    coreActions.getSearchViewByEntity,
    'getSearchViewByEntity'
  ),
  ...onApiCall<FavoriteSearchView[]>(
    coreActions.getFavoriteByEntity,
    'getFavoriteByEntity'
  ),
  ...onApiCall<Operation[]>(
    coreActions.getOperatorsByType,
    'getOperatorsByType'
  ),
  ...onApiCall<Operation[]>(
    coreActions.getStringOperators,
    'getStringOperators'
  ),
  ...onApiCall<Operation[]>(coreActions.getIntOperators, 'getIntOperators'),
];

export const coreReducer = createReducer(
  coreInitialState,
  ...coreReducers
) as ActionReducer<ICoreState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return coreReducer(state, action);
}
