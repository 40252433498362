import { Action, ActionReducer, createReducer, on } from '@ngrx/store';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { GenericState } from 'src/app/common/store/types';
import { PageResult, SearchResult } from '../../types';
import { searchActions } from './search.actions';
import { Domain, Filter, FilterType, OperationModel } from './types';

export interface ISearchState {
  search: GenericState<SearchResult<any>>;
  operations: GenericState<OperationModel[]>;
  activeFilters: Filter[];
  customFilters: Filter[];
  contextFilters: Filter[];
  isContextFilterFlag: boolean;
}

export const searchInitialState: ISearchState = {
  search: new GenericState<SearchResult<any>>(),
  operations: new GenericState<OperationModel[]>(),
  activeFilters: [],
  customFilters: [],
  contextFilters: [],
  isContextFilterFlag: false,
};
const searchReducers = [
  ...onApiCall<SearchResult<any>>(searchActions.search, 'search'),
  ...onApiCall<OperationModel[]>(searchActions.operations, 'operations'),
  on(
    searchActions.setActiveFilters,
    (state: ISearchState, { activeFilters }) => ({
      ...state,
      activeFilters: activeFilters,
    })
  ),
  on(
    searchActions.setCustomFilters,
    (state: ISearchState, { customFilters }) => ({
      ...state,
      customFilters: customFilters,
    })
  ),
  on(
    searchActions.setContextFilters,
    (state: ISearchState, { contextFilters, isContextFilterFlag }) => ({
      ...state,
      contextFilters: contextFilters,
      isContextFilterFlag: isContextFilterFlag,
    })
  ),
  on(searchActions.setContextFilterFlag, (state: ISearchState, { flag }) => ({
    ...state,
    isContextFilterFlag: flag,
  })),
  on(
    searchActions.removeActiveFilterByIndex,
    (state: ISearchState, { index }) => {
      let activeFilter = [...state.activeFilters];
      activeFilter.splice(index, 1);

      state = {
        ...state,
        activeFilters: activeFilter,
      };
      return state;
    }
  ),
  on(searchActions.addTextFilter, (state: ISearchState, { domain }) => {
    let activeFilter = [...state.activeFilters];

    let textFilterExists = false;
    for (let filter of activeFilter) {
      if (
        filter.tag == 'TextFilter' &&
        filter.domains[0].field == domain.field
      ) {
        //if there's already a filter with the same field, add to the same domain
        let domainToAdd: Domain = {
          field: domain.field,
          fieldLabel: domain.fieldLabel,
          operator: domain.operator,
          connector: domain.connector,
          value: domain.value,
          value2: domain.value2,
          text: domain.text,
          text2: domain.text2,
          isActive: domain.isActive,
        };
        filter.domains.push(domainToAdd);
        textFilterExists = true;
        break;
      }
    }
    //if there's isn't any text filter of the same type create a new one
    if (!textFilterExists) {
      activeFilter.push({
        name: domain.fieldLabel,
        tag: 'TextFilter',
        domains: [
          {
            field: domain.field,
            fieldLabel: domain.fieldLabel,
            operator: domain.operator,
            connector: domain.connector,
            value: domain.value,
            value2: domain.value2,
            text: domain.text,
            text2: domain.text2,
            isActive: domain.isActive,
          },
        ],
        text: '',
      });
    }

    state = {
      ...state,
      activeFilters: activeFilter,
    };
    return state;
  }),
  on(
    searchActions.toggleBuiltInFilter,
    (state: ISearchState, { builtInFilter: builtInFilter }) => {
      let activeFilters = [...state.activeFilters];
      //check if the chosen filter exists in the active filters
      let builtInFilterindex = activeFilters.findIndex(
        (activeFilter: Filter) => activeFilter.name == builtInFilter.name
      );
      if (builtInFilterindex == -1) {
        //if the chosen filter doesn't exists, set it to active and add it to the active filter
        let chosenFilter = JSON.parse(JSON.stringify(builtInFilter));
        chosenFilter.domains.forEach((domain: Domain) => {
          domain.isActive = true;
        });
        activeFilters.push(chosenFilter);
      } else {
        //if the chosen filter exists in the active filters, remove it
        activeFilters.splice(builtInFilterindex, 1);
      }
      state = {
        ...state,
        activeFilters: activeFilters,
      };
      return state;
    }
  ),
  on(
    searchActions.addActiveFilter,
    (state: ISearchState, { filter, isContextFilterFlag }) => {
      let activeFilters = [...state.activeFilters];
      activeFilters.push(filter);
      state = {
        ...state,
        activeFilters: activeFilters,
        isContextFilterFlag: isContextFilterFlag,
      };
      return state;
    }
  ),
  on(
    searchActions.changeDomainState,
    (state: ISearchState, { filterIndex, domainIndex }) => {
      let activeFilters = JSON.parse(JSON.stringify(state.activeFilters));
      let customFilters = JSON.parse(JSON.stringify(state.customFilters));
      let customFilter: Filter = customFilters[filterIndex];

      let activeFilterIndex = activeFilters.findIndex(
        (x) => x.name == customFilter.name
      );
      if (activeFilterIndex == -1) {
        customFilter.domains[domainIndex].isActive =
          !customFilter.domains[domainIndex].isActive;
        activeFilters.push(customFilter);
      } else {
        activeFilters[activeFilterIndex].domains[domainIndex].isActive =
          !activeFilters[activeFilterIndex].domains[domainIndex].isActive;

        let result = false;
        activeFilters[activeFilterIndex].domains.forEach((domain) => {
          if (domain.isActive) {
            result = true;
          }
        });
        if (!result) {
          activeFilters.splice(activeFilterIndex, 1);
        }
      }
      state = {
        ...state,
        activeFilters: activeFilters,
      };
      return state;
    }
  ),
  on(searchActions.applyCustomFilter, (state: ISearchState, { domains }) => {
    let activeFilters = [...state.activeFilters];
    let customFilters = [...state.customFilters];
    let name = '';
    domains.forEach((x) => {
      name = name + x.field + x.value;
    });
    //create the filter from the domain array
    let customFilter: Filter = {
      name: name,
      tag: 'CustomFilter',
      domains: domains,
      text: '',
    };
    customFilters.push(customFilter);
    let custom: Filter = JSON.parse(JSON.stringify(customFilter));
    custom.domains.forEach((x) => (x.isActive = true));
    activeFilters.push(custom);
    state = {
      ...state,
      activeFilters: activeFilters,
      customFilters: customFilters,
    };
    return state;
  }),
  on(searchActions.resetActiveFilter, (state: ISearchState) => {
    const isContextFilterFlag = state.isContextFilterFlag;
    let activeFilter = [...state.activeFilters];

    if (isContextFilterFlag) {
      activeFilter = activeFilter.filter((x) => {
        return x.tag == 'SmartActionFilter';
      });
      state = {
        ...state,
        activeFilters: activeFilter,
        isContextFilterFlag: false,
      };
    } else {
      state = {
        ...state,
        activeFilters: [],
      };
    }

    return state;
  }),
];

export const searchReducer = createReducer(
  searchInitialState,
  ...searchReducers
) as ActionReducer<ISearchState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return searchReducer(state, action);
}
