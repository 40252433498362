import { HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { IGenericApiCallActions } from './generic.actions';
import {
  DefaultProjectorFn,
  MemoizedSelector,
  select,
  Store,
} from '@ngrx/store';

export interface IModel {
  id: any;
}

export interface IGenericState<TResult> {
  value: TResult | null;
  isLoaded: boolean;
  isLoading: boolean;
  error: HttpErrorResponse | null;
  params: any;
  lastUpdateDate: string | null;
}

export interface IState<TResult> {}

export interface IGenericSelectors {
  valueSelector: any;
  isLoadedSelector: any;
  isLoadingSelector: any;
  errorSelector: any;
  paramsSelector: any;
  lastUpdateDateSelector: any;
}

export type HubDefinition = {
  hubName: string;
  url: string;
};

export class GenericState<TResult> {
  value$: Observable<TResult>;
  isLoaded$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  error$: Observable<HttpErrorResponse | null>;
  params$: Observable<any>;
  lastUpdateDate$: Observable<string>;
}

export class GenericApiCall<TResult, TParams> {
  pipe(arg0: <U>(source: Observable<U>) => Observable<U>) {
    throw new Error('Method not implemented.');
  }
  private _called: boolean;

  constructor(
    store: Store<any>,
    actions: IGenericApiCallActions<TResult>,
    selectors: IGenericSelectors,
    multiple: boolean
  ) {
    this._called = false;
    this.call = (params: TParams) => {
      if (!this._called || multiple) {
        this._called = true; //TODO: subscribe on this.isLoaded$
        store.dispatch(actions.calling({ params }));
      }
      return this.value$;
    };
    this.value$ = store.pipe(select(selectors.valueSelector));
    this.isLoaded$ = store.pipe(select(selectors.isLoadedSelector));
    this.isLoading$ = store.pipe(select(selectors.isLoadingSelector));
    this.error$ = store.pipe(select(selectors.errorSelector));
    this.params$ = store.pipe(select(selectors.paramsSelector));
    this.lastUpdateDate$ = store.pipe(select(selectors.lastUpdateDateSelector));
    this.reset = () => {
      this._called = false;
      store
        .pipe(select(selectors.valueSelector), take(1))
        .subscribe((value) => {
          store.dispatch(actions.reset({ value: value }));
        });
    };
  }

  call: (params: TParams) => Observable<TResult>;
  value$: Observable<TResult>;
  isLoaded$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  error$: Observable<HttpErrorResponse | null>;
  params$: Observable<any>;
  lastUpdateDate$: Observable<string>;
  reset: () => void;
}

export class GenericApiCallWithCondition<TResult, TParams> {
  private _called: boolean;

  constructor(
    store: Store<any>,
    actions: IGenericApiCallActions<TResult>,
    selectors: IGenericSelectors,
    canbeCalled$: Observable<boolean>,
    multiple: boolean
  ) {
    this._called = false;
    this.call = (params: TParams) => {
      if (!this._called || multiple) {
        canbeCalled$.pipe(take(1)).subscribe((canbeCalled) => {
          if (canbeCalled) {
            this._called = true;
            store.dispatch(actions.calling({ params }));
          }
        });
      }
      return this.value$;
    };
    this.value$ = store.pipe(select(selectors.valueSelector));
    this.isLoaded$ = store.pipe(select(selectors.isLoadedSelector));
    this.isLoading$ = store.pipe(select(selectors.isLoadingSelector));
    this.error$ = store.pipe(select(selectors.errorSelector));
    this.params$ = store.pipe(select(selectors.paramsSelector));
    this.lastUpdateDate$ = store.pipe(select(selectors.lastUpdateDateSelector));
    this.reset = () => {
      this._called = false;
      store
        .pipe(select(selectors.valueSelector), take(1))
        .subscribe((value) => {
          store.dispatch(actions.reset({ value: value }));
        });
    };
  }

  call: (params: TParams) => Observable<TResult>;
  value$: Observable<TResult>;
  isLoaded$: Observable<boolean>;
  isLoading$: Observable<boolean>;
  error$: Observable<HttpErrorResponse | null>;
  params$: Observable<any>;
  lastUpdateDate$: Observable<string>;
  reset: () => void;
}

export class BaseServiceFacade {
  constructor(protected readonly store: Store<any>) {}

  protected fromStore = <T>(
    selector: MemoizedSelector<object, T, DefaultProjectorFn<T>>
  ) => this.store.pipe(select(selector));

  protected genericApiCall = <T, TParams>(
    actions: IGenericApiCallActions<T>,
    selectors: IGenericSelectors,
    multiple: boolean = false
  ): GenericApiCall<T, TParams> =>
    new GenericApiCall<T, TParams>(this.store, actions, selectors, multiple);

  protected genericApiCallWithCondition = <T, TParams>(
    actions: IGenericApiCallActions<T>,
    selectors: IGenericSelectors,
    canbeCalled$: Observable<boolean>,
    multiple: boolean = false
  ): GenericApiCallWithCondition<T, TParams> =>
    new GenericApiCallWithCondition<T, TParams>(
      this.store,
      actions,
      selectors,
      canbeCalled$,
      multiple
    );
}
