import { Injectable } from '@angular/core';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { Store } from '@ngrx/store';
import { AppState } from '../types';
import {
  Campaign,
  CampaignPost,
  CampaignSimplified,
  CampaignUpdate,
  CreateCampaignFormModel,
} from './types';
import { campaignActions } from './campaign.action';
import { campaignSelectors } from './campaign.selector';

@Injectable()
export class CampaignService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<Campaign, string> = this.genericApiCall(
    campaignActions.getById,
    campaignSelectors.getById,
    true,
  );

  getList: GenericApiCall<CampaignSimplified[], void> = this.genericApiCall(
    campaignActions.getList,
    campaignSelectors.getList,
    true,
  );

  getCampaignModel: GenericApiCall<CreateCampaignFormModel, void> =
    this.genericApiCall(
      campaignActions.getCampaignModel,
      campaignSelectors.getCampaignModel,
      true,
    );
  postCampaign: GenericApiCall<Campaign, CampaignPost> = this.genericApiCall(
    campaignActions.postCampaign,
    campaignSelectors.postCampaign,
    true,
  );

  updateCampaign: GenericApiCall<Campaign, CampaignUpdate> =
    this.genericApiCall(
      campaignActions.updateCampaign,
      campaignSelectors.updateCampaign,
      true,
    );
}
