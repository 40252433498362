import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { CategoryChoiceComponent } from 'src/app/shared/components/category-choice/category-choice.component';
import { SalesType } from '../../../../shared/enum/sales-type';

@UntilDestroy()
@Component({
  selector: 'app-lead-list',
  templateUrl: './lead-list.component.html',
  styleUrls: ['./lead-list.component.scss'],
})
export class LeadListComponent implements OnInit {
  view$: Observable<View>;
  viewType: string;
  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly modalService: NgbModal,
    private readonly subHeaderService: SubHeaderService,
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@leadList', x.title);
        this.viewType = x.views;
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'leadCreate') {
          this.createLead();
        }
        if (x == 'TreeView') {
          this.viewType = x;
        }
        if (x == 'KanbanView') {
          this.viewType = x;
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  createLead() {
    const modalRef = this.modalService.open(CategoryChoiceComponent);
    modalRef.componentInstance.salesType = SalesType.LEAD;
  }
}
