  <div class="comment-content pt-0 h-100">
    <ng-container *ngIf="(this.commentBase$ | async) as comments" >

      <app-comment-add [entityId]="entityId" [entityType]="entityType" (newComment)="refresh($event)"></app-comment-add>

      <ng-container *ngIf="comments.length >= 1" >
      <ul class="list-group list-group-flush">
        <li class="list-group-item align-items-start mt-2 px-1 py-2" *ngFor="let comment of comments; trackBy: trackByComment">
            <app-comment [comment]="comment" class="d-flex"></app-comment>
        </li>
      </ul>
      </ng-container>

      <ng-container *ngIf="comments.length == 0">
        <app-comment-none></app-comment-none>
      </ng-container>

    </ng-container>
  </div>
