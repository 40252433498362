import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  ContractModel,
  ContractHealthFormModel,
  ContractStatus,
  ContractStatusFlow,
  CreateContractFormModel,
  HealthInsuranceContractDetailModel,
  ContractCarFormModel,
  ContractHouseFormModel,
  CarInsuranceContractModel,
  HouseInsuranceContractModel,
  ContractHealthUpdateModel,
  ContractCarUpdateModel,
  ContractHouseUpdateModel,
} from './types';
import { ErrorService } from '../../error';
import { catchError } from 'rxjs/operators';
import { CrudType } from '../../service/message/types';
import { MessageService } from '../../service/message/message.service';
import { InsuranceCategory } from '../lead/types';

@Injectable({
  providedIn: 'root',
})
export class ContractApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService
  ) {}

  getById = (id: string): Observable<ContractModel> => {
    let parameters: string[];
    parameters = [id];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getByUniqueId = (id: string): Observable<ContractModel> => {
    let parameters: string[];
    parameters = ['uniqueid', id];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getByLeadId = (id: string): Observable<ContractModel> => {
    let parameters: string[];
    parameters = ['lead', id];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getHealthDetail = (
    id: number
  ): Observable<HealthInsuranceContractDetailModel[]> => {
    let parameters: string[];
    parameters = ['HealthDetail', id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<HealthInsuranceContractDetailModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getCarDetail = (id: number): Observable<CarInsuranceContractModel> => {
    let parameters: string[];
    parameters = ['CarDetail', id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CarInsuranceContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getHouseDetail = (id: number): Observable<HouseInsuranceContractModel> => {
    let parameters: string[];
    parameters = ['HouseDetail', id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<HouseInsuranceContractModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getStatusFlow = (
    statusFlow: ContractStatus
  ): Observable<ContractStatusFlow[]> => {
    let parameters: string[];
    parameters = ['statusflow', statusFlow.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<ContractStatusFlow[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  getContractModel = (
    category: InsuranceCategory
  ): Observable<CreateContractFormModel> => {
    let parameters: string[];
    parameters = ['ContractModel'];
    let url = this.formatUrl(parameters);

    let queryParams = new HttpParams();
    queryParams = queryParams.append('insuranceCategory', category);

    return this.httpClient
      .get<CreateContractFormModel>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  createHealthContract = (
    contractHealthFormModel: ContractHealthFormModel
  ): any => {
    let parameters: string[];
    parameters = ['HealthContract'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .post<ContractModel>(url, contractHealthFormModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };
  createCarContract = (contractCarFormModel: ContractCarFormModel): any => {
    let parameters: string[];
    parameters = ['CarContract'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .post<ContractModel>(url, contractCarFormModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };
  createHouseContract = (
    contractHouseFormModel: ContractHouseFormModel
  ): any => {
    let parameters: string[];
    parameters = ['HouseContract'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .post<ContractModel>(url, contractHouseFormModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  updateHealthContract = (
    contractHealthUpdateModel: ContractHealthUpdateModel
  ): any => {
    let parameters: string[];
    parameters = ['HealthContract'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .put<ContractModel>(url, contractHealthUpdateModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };
  updateCarContract = (contractCarUpdateModel: ContractCarUpdateModel): any => {
    let parameters: string[];
    parameters = ['CarContract'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .put<ContractModel>(url, contractCarUpdateModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };
  updateHouseContract = (
    contractHouseUpdateModel: ContractHouseUpdateModel
  ): any => {
    let parameters: string[];
    parameters = ['HouseContract'];
    let url = this.formatUrl(parameters);

    return this.httpClient
      .put<ContractModel>(url, contractHouseUpdateModel, {
        observe: 'response',
      })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.contracts.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
