import { createFeatureSelector, createSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { ISubHeaderState } from './subheader.reducer';
import { subHeaderFeatureKey, SubHeaderOption } from './type';

const subHeaderFeatureState =
  createFeatureSelector<ISubHeaderState>(subHeaderFeatureKey);

export const subHeaderSelectors = {
  getSubHeaderOption: createApiCallSelectors<SubHeaderOption, ISubHeaderState>(
    subHeaderFeatureState,
    'getSubHeaderOption'
  ),
  getTriggerAction: createSelector(
    subHeaderFeatureState,
    (state: ISubHeaderState) => state.triggerAction
  ),
};
