export const paymentSplittingFeatureKey = 'paymentSplitting';
export type PaymentSplitting = {
  id: number;
  companyKey: string;
  productLineKey: string;
  splittingMode: number;
  splittingModeLoc: string;
  splittingModeId: number;
  debit: boolean;
  paymentType: string;
  dayOfMonth: number;
};
