import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PasswordManageComponent } from './password-manage/password-manage.component';
import { AuthentificationGuard } from '../../shared/guard/authentification-guard';
import { HeaderMenuType } from '../../shared/store/header/type';
import { Entity } from '../../shared/store/view/types';
import { SubHeaderType } from '../../shared/store/subheader/type';
import { HasUnsavedChangesGuard } from '../../shared/guard/has-form-changed.guard';

const routes: Routes = [
  {
    path: 'Account',
    redirectTo: 'Account/PasswordManage',
    pathMatch: 'full',
  },
  {
    path: 'Account',
    data: {
      breadcrumb: { label: ' ', alias: 'accountRoot' },
    },
    children: [
      {
        path: 'PasswordManage',
        component: PasswordManageComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Account,
          entity: Entity.Account,
          breadcrumb: { label: ' ', alias: 'accountPasswordManage' },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
