import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { LeadService } from 'src/app/shared/store/lead/lead.service';
import { LeadDetailModel } from 'src/app/shared/store/lead/types';

@Component({
  selector: 'app-lead-details-marketing',
  templateUrl: './lead-details-marketing.component.html',
  styleUrls: ['./lead-details-marketing.component.scss'],
})
export class LeadDetailsMarketingComponent implements OnInit {
  leadDetail$: Observable<LeadDetailModel>;

  constructor(private readonly leadService: LeadService) {}

  ngOnInit(): void {
    this.leadDetail$ = this.leadService.getByUniqueId.value$;
  }
}
