import { Component, OnDestroy, OnInit } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { View } from '../../../shared/store/view/types';
import { ViewService } from '../../../shared/store/view/views.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BreadcrumbService } from 'xng-breadcrumb';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { customValidator } from '../../../shared/validators/custom-validator.validators';
import { matchPasswordValidator } from '../../../shared/validators/match-password.validator';
import { AuthenticationService } from '../../../shared/store/authentication/authentication.service';
import { UserConnected } from '../../../shared/store/authentication/types';
import { SubHeaderService } from '../../../shared/store/subheader/subheader.service';
import { FormService } from '../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-password-manage',
  templateUrl: './password-manage.component.html',
  styleUrls: ['./password-manage.component.scss'],
})
export class PasswordManageComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  userConnected$: Observable<UserConnected>;
  userEmail: string;

  constructor(
    private readonly viewService: ViewService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly authenticationService: AuthenticationService,
    private readonly subHeaderService: SubHeaderService,
    private readonly formService: FormService,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.userConnected$ = this.authenticationService.getUserConnected();
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'changePassword') this.onSubmit();
      });

    this.userConnected$.pipe(filter((x) => !!x)).subscribe((user) => {
      this.userEmail = user.email;
    });

    this.changePasswordForm.setValidators(matchPasswordValidator);
    this.changePasswordForm.updateValueAndValidity();

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@accountRoot', x.title);
      });

    this.breadcrumbService.set(
      '@accountPasswordManage',
      'Changement du mot de passe',
    );
  }

  changePasswordForm = new FormGroup({
    current: new FormControl('', [Validators.required]),
    password: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(6),
      customValidator(/\d/),
      customValidator(/[A-Z]/),
      customValidator(/[a-z]/),
    ]),
    passwordConfirm: new FormControl<string>('', [
      Validators.required,
      Validators.minLength(6),
      customValidator(/\d/),
      customValidator(/[A-Z]/),
      customValidator(/[a-z]/),
    ]),
  });

  onSubmit(): void {
    if (this.changePasswordForm.invalid) {
      this.changePasswordForm.markAllAsTouched();
      this.formService.countErrors(this.changePasswordForm, true);
      return;
    }

    this.formService.clear();

    let result$ = this.authenticationService.changePassword.call({
      currentPassword: this.changePasswordForm.value.current!,
      newPassword: this.changePasswordForm.value.password!,
      email: this.userEmail,
    });
    result$.pipe(untilDestroyed(this)).subscribe((result) => {
      if (result !== undefined) {
        this.changePasswordForm.reset();
      }
    });
  }

  ngOnDestroy(): void {}
}
