import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { PageResult } from '../../types';
import { AppState } from '../types';
import { glossaryActions } from './glossary.actions';
import { glossarySelectors } from './glossary.selectors';
import { Glossary, GlossaryPost, GlossaryUpdate } from './types';

@Injectable()
export class GlossaryService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<Glossary, string> = this.genericApiCall(
    glossaryActions.getById,
    glossarySelectors.getById,
    true
  );
  postGlossary: GenericApiCall<any, GlossaryPost> = this.genericApiCall(
    glossaryActions.postGlossary,
    glossarySelectors.postGlossary,
    true
  );
  publishGlossary: GenericApiCall<any, string> = this.genericApiCall(
    glossaryActions.publishGlossary,
    glossarySelectors.publishGlossary,
    true
  );
  unpublishGlossary: GenericApiCall<any, string> = this.genericApiCall(
    glossaryActions.unpublishGlossary,
    glossarySelectors.unpublishGlossary,
    true
  );
  updateGlossary: GenericApiCall<any, GlossaryUpdate> = this.genericApiCall(
    glossaryActions.updateGlossary,
    glossarySelectors.updateGlossary,
    true
  );
}
