import { Directive, Self, HostBinding, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[checkFormFieldValidity]',
})
export class CheckFormFieldValidityDirective {
  @Input() public class: string;

  constructor(@Self() private ngControl: NgControl) {}

  // @HostBinding('class.is-valid')
  // public get isValid(): boolean {
  //   return this.valid;
  // }

  @HostBinding('class.is-invalid')
  public get isInvalid(): boolean {
    return this.invalid;
  }

  public get valid(): boolean {
    if (!this.ngControl.errors?.['required']) return false;

    let isBoo =
      this.ngControl.valid && (this.ngControl.dirty || this.ngControl.touched);
    return isBoo ?? false;
  }

  public get invalid(): boolean {
    let isBoo =
      this.ngControl.invalid &&
      (this.ngControl.touched || this.ngControl.dirty);
    return isBoo ?? false;
  }

  private checkErrors(): boolean {
    if (this.ngControl.errors) return true;

    return false;
  }
}
