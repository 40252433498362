<app-document-subheader></app-document-subheader>
<div class="page-container">
    <div class="card card-light w-100">
        <form [formGroup]="countryForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="countryForm">
          <button type="submit" hidden="hidden"></button>
        <div class="card-body">
            <div class="container-fluid">

                <div class="row">
                    <div class="col-lg-10">
                        <div class="form-group">
                            <label class="form-title-label" for="name" translate>COUNTRY_LBL_NAME</label>
                            <input
                            class="form-control form-title"
                            id="name"
                            name="name"
                            [placeholder]="'COUNTRY_PLACEHOLDER_NAME' | translate"
                            type="text"
                            value=""
                            formControlName="name"
                            checkFormFieldValidity
                            />
                        </div>
                    </div>
                </div>

                <div class="row">

                    <div class="col-6 col-lg-6">

                        <div class="form-group row">
                            <label class="col-4 col-form-label-sm" for="englishName" translate>COUNTRY_LBL_ENGLISH_NAME</label>
                            <div class="col">
                                <input
                                class="form-control form-control-sm"
                                id="englishName"
                                name="englishName"
                                type="text"
                                value=""
                                formControlName="englishName"
                                checkFormFieldValidity
                                />
                            </div>
                        </div>


                        <div class="form-group row">
                            <label class="col-4 col-form-label-sm" for="currencyId" translate>COUNTRY_LBL_CURRENCY</label>
                            <div class="col">
                              <app-typeahead
                                id="currencyId"
                                [entity]="Entity.Currency"
                                [routeEndpoint]="'Currencies'"
                                [allowCustomActions]="true"
                                [limitToList]="true"
                                [controlForm]="this.countryForm.controls['currencyId']"
                              ></app-typeahead>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-4 col-form-label-sm" for="nationality" translate>COUNTRY_LBL_NATIONALITY</label>
                            <div class="col">
                                <input
                                class="form-control form-control-sm"
                                id="nationality"
                                name="nationality"
                                type="text"
                                value=""
                                formControlName="nationality"
                                checkFormFieldValidity
                                />
                            </div>
                        </div>

                    </div>


                    <div class="col">

                        <div class="form-group row">
                            <label class="col-4 col-form-label-sm" for="alpha2Code" translate>COUNTRY_LBL_ALPHA2CODE</label>
                            <div class="col">
                                <input
                                class="form-control form-control-sm"
                                id="alpha2Code"
                                name="alpha2Code"
                                type="text"
                                value=""
                                formControlName="alpha2Code"
                                oninput="this.value = this.value.toUpperCase()"
                                checkFormFieldValidity
                                />
                            </div>
                         </div>

                        <div class="form-group row">
                            <label class="col-4 col-form-label-sm" for="alpha3Code" translate>COUNTRY_LBL_ALPHA3CODE</label>
                            <div class="col">
                                <input
                                class="form-control form-control-sm"
                                id="alpha3Code"
                                name="alpha3Code"
                                type="text"
                                value=""
                                formControlName="alpha3Code"
                                oninput="this.value = this.value.toUpperCase()"
                                checkFormFieldValidity
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label class="col-4 col-form-label-sm" for="active" translate>COUNTRY_LBL_ACTIVE</label>
                            <div class="col">
                                <input
                                class="form-control-sm"
                                id="active"
                                name="active"
                                type="checkbox"
                                value=""
                                formControlName="active"
                                checkFormFieldValidity
                                />
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        </form>
    </div>
</div>
