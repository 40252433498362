import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Entity, View } from '../../../../shared/store/view/types';
import { SalesTeam } from '../../../../shared/store/sales-team/types';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { FormService } from '../../../../shared/store/form/form.service';
import { SalesTeamService } from '../../../../shared/store/sales-team/sales-team.service';
import { take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-sales-team-edit',
  templateUrl: './sales-team-edit.component.html',
  styleUrls: ['./sales-team-edit.component.scss'],
})
export class SalesTeamEditComponent implements OnInit, OnDestroy {
  salesTeam$: Observable<SalesTeam>;
  salesTeamForm: FormGroup;
  view$: Observable<View>;

  protected readonly Entity = Entity;

  constructor(
    private readonly subHeaderService: SubHeaderService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
    private readonly salesTeamService: SalesTeamService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.salesTeam$ = this.salesTeamService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'salesTeamUpdate') this.onSubmit();
        if (x == 'salesTeamDetails') {
          this.router.navigate([
            'Sales/SalesTeams/Details/',
            this.route.snapshot.params['id'],
          ]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.salesTeamService.getById.call(this.route.snapshot.params['id']);

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@salesTeamList', x.title);
      });

    this.salesTeamForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      code: [''],
      teamLeaderId: [undefined],
      notes: [''],
      active: [true],
    });

    this.salesTeam$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@salesTeamEdit', x.name);
        this.salesTeamForm.patchValue({
          name: x.name,
          code: x.code,
          teamLeaderId: x.teamLeaderId,
          notes: x.notes,
          active: x.active,
        });
      });
  }

  onSubmit() {
    if (this.salesTeamForm.invalid) {
      this.salesTeamForm.markAllAsTouched();
      this.formService.countErrors(this.salesTeamForm, true);
      return;
    }
    console.log(this.salesTeamForm);

    this.formService.clear();

    let result$ = this.salesTeamService.updateSalesTeam.call({
      id: this.route.snapshot.params['id'],
      active: this.salesTeamForm.value.active!,
      code: this.salesTeamForm.value.code!,
      name: this.salesTeamForm.value.name!,
      notes: this.salesTeamForm.value.notes!,
      teamLeaderId: this.salesTeamForm.value.teamLeaderId!,
    });
    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.router.navigate(['/Sales/SalesTeams/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.salesTeamService.updateSalesTeam.reset();
    this.breadcrumbService.set('@salesTeamEdit', ' ');
  }
}
