import { HomeModule } from './home/home.module';
import { NgModule } from '@angular/core';
import { LeadModule } from './sale/lead/lead.module';
import { AuthentificationModule } from './authentification/authentification.module';
import { WebContentModule } from './web-content/web-content.module';
import { ConfigurationModule } from './configuration/configuration.module';
import { SaleModule } from './sale/sale.module';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { RouterLink } from '@angular/router';
import { HumanResourcesModule } from './human-resources/human-resources.module';
import { AccountModule } from './account/account.module';
import { ContactsModule } from './contacts/contacts.module';

@NgModule({
  imports: [
    HomeModule,
    LeadModule,
    AuthentificationModule,
    WebContentModule,
    ConfigurationModule,
    SaleModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterLink,
    HumanResourcesModule,
    AccountModule,
    ContactsModule,
  ],
  exports: [],
  declarations: [],
})
export class FeaturesModule {}
