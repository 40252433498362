import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthentificationService } from 'src/app/shared/authentification/authentification.service';
import { SidebarService } from 'src/app/shared/store/sidebar/sidebar.service';
import { SidebarModule } from 'src/app/shared/store/sidebar/types';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  sidebarModules$: Observable<SidebarModule[]>;

  constructor(
    private readonly sidebarService: SidebarService,
    private readonly authService: AuthentificationService
  ) {
    this.sidebarModules$ = this.sidebarService.getSidebarModule.value$;
  }

  ngOnInit(): void {
    if (this.authService.isAuthenticated()) {
      this.sidebarService.getSidebarModule.call();
    }
  }
}
