import { Component, OnInit } from '@angular/core';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, filter } from 'rxjs';
import { LeadService } from 'src/app/shared/store/lead/lead.service';
import {
  HealthInsuranceLeadDetailModel,
  HealthInsuranceLeadModel,
  InsuredType,
} from 'src/app/shared/store/lead/types';

@UntilDestroy()
@Component({
  selector: 'app-lead-details-health',
  templateUrl: './lead-details-health.component.html',
  styleUrls: ['./lead-details-health.component.scss'],
})
export class LeadDetailsHealthComponent implements OnInit {
  healthDetail$: Observable<HealthInsuranceLeadModel>;
  subscriber: HealthInsuranceLeadDetailModel;
  spouse: HealthInsuranceLeadDetailModel | undefined;
  children: HealthInsuranceLeadDetailModel[];

  constructor(
    private readonly leadService: LeadService,
    config: NgbRatingConfig
  ) {
    config.max = 4;
    config.readonly = true;
  }

  ngOnInit(): void {
    this.healthDetail$ = this.leadService.getHealthDetail.value$;

    this.healthDetail$
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this)
      )
      .subscribe((x: HealthInsuranceLeadModel) => {
        this.subscriber = x.insuredPersons.find(
          (x) => x.insuredType == InsuredType.Holder
        )!;

        this.spouse = x.insuredPersons.find(
          (x) => x.insuredType == InsuredType.Partner
        );

        this.children = x.insuredPersons.filter(
          (x) => x.insuredType == InsuredType.Child
        );
      });
  }
}
