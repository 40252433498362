import { AppService } from './app.service';
import { AppState } from './types';
import { EffectsModule } from '@ngrx/effects';
import { environment } from 'src/environments/environment';
import { NgModule } from '@angular/core';
import { routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { StoreRouterModule } from './router/store-router.module';
import { StoreLeadModule } from './lead/store-lead.modules';
import { StoreHeaderModule } from './header/store-header.module';
import { StoreSubHeaderModule } from './subheader/store-subheader.module';
import { StoreUserModule } from './user/store-user.modules';
import { StoreZipCodeModule } from './zipcode/store-zipcode.module';
import { StoreSidebarModule } from './sidebar/store-sidebar.module';
import { StoreCountyModule } from './county/store-county.module';
import { StoreContractModule } from './contract/store-contract.module';
import { StorePaginationdModule } from './pagination/store-pagination.module';
import { StoreArticleModule } from './article/store-article.module';
import { StoreGlossaryModule } from './glossary/store-glossary.module';
import { StoreCoreModule } from './core/store-core.module';
import { StoreSearchModule } from './search/store-search-module';
import { StoreAuthenticationModule } from './authentication/store-authentication.module';
import { StoreReportingModule } from './reporting/store-reporting-modules';
import { StoreCurrencyModule } from './currency/store-currency.module';
import { StoreCountryModule } from './country/store-country.module';
import { StoreNotificationModule } from './notification/store-notification.module';
import { StoreJobModule } from './job/store-job.module';
import { StoreBankAccountModule } from './bank-account/store-bank-account.module';
import { StorePaymentSplittingModule } from './payment-splitting/store-payment-splitting.module';
import { StoreViewModule } from './view/store-view.module';
import { StoreCommentModule } from './comment/store-comment.module';
import { StoreSalesTeamModule } from './sales-team/store-sales-team.module';
import { StoreCampaignModule } from './campaign/store-campaign.module';
import { StoreSalesTeamMemberModule } from './sales-team-member/store-sales-team-member.module';
import { StoreEmployeeModule } from './employee/store-employee.module';
import { StoreTypeaheadModule } from './typeahead/store-typeahead.module';
import { StoreFormModule } from './form/store-form.module';
import { StoreContactModule } from './contact/store-contact.module';

@NgModule({
  imports: [
    StoreModule.forRoot<AppState>({
      router: routerReducer,
    }),
    EffectsModule.forRoot([]),
    StoreRouterConnectingModule.forRoot(),
    StoreDevtoolsModule.instrument({
      name: 'STORE - @ngrx DevTools',
      logOnly: environment.production,
    }),
    StoreRouterModule,

    StoreLeadModule,

    StoreContractModule,

    StoreHeaderModule,

    StoreSubHeaderModule,

    StoreZipCodeModule,

    StoreCountyModule,

    StoreUserModule,

    StoreSidebarModule,

    StorePaginationdModule,

    StoreArticleModule,

    StoreGlossaryModule,

    StoreCoreModule,

    StoreSearchModule,

    StoreAuthenticationModule,

    StoreReportingModule,

    StoreCurrencyModule,

    StoreCountryModule,

    StoreNotificationModule,

    StoreJobModule,

    StoreBankAccountModule,

    StorePaymentSplittingModule,

    StoreCommentModule,

    StoreViewModule,

    StoreSalesTeamModule,

    StoreSalesTeamMemberModule,

    StoreCampaignModule,

    StoreEmployeeModule,

    StoreTypeaheadModule,

    StoreFormModule,

    StoreContactModule,
  ],
  providers: [AppService],
})
export class AppStoreModule {}
