import {Component, OnDestroy} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, Subscription} from 'rxjs';
import {AuthentificationService} from 'src/app/shared/authentification/authentification.service';
import {SidebarService} from "../../../shared/store/sidebar/sidebar.service";
import {filter} from "rxjs/operators";
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";

@UntilDestroy()
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  host: {class: 'connexion-form p-4 col-12 col-sm-8'},
})
export class LoginComponent implements OnDestroy {
  isAuthenticated = false;
  checkSessionChanged$: Observable<boolean>;
  checkSessionChanged: any;
  result: Promise<Object>;
  returnUrl: string;
  showErrorLogin: boolean;

  private firstPath: string = '/page-not-found';
  private sidebar: Subscription;

  constructor(
    private readonly authService: AuthentificationService,
    private readonly router: Router,
    private route: ActivatedRoute,
    private readonly sidebarService: SidebarService
  ) {
  }

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];
    this.sidebarService.getSidebarModule.reset()
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      this.loginForm.markAllAsTouched();
      return;
    }

    this.authService
      .loginwithcredentials({
        mail: this.loginForm.value.email!,
        password: this.loginForm.value.password!,
      })
      .then((x) => {

        //Ajouter le path vers le currentUser
        this.sidebar = this.sidebarService.getSidebarModule.call().pipe(
          untilDestroyed(this),
          filter(x => !!x)
        ).subscribe((sidebar) => {
            this.firstPath = sidebar[0]?.route
            this.router.navigateByUrl(this.returnUrl ?? this.firstPath);
          }
        )
      })
      .catch((x) => {
        this.showErrorLogin = true;
        // console.log(x);
      });


  }

  get email() {
    return this.loginForm.get('email')!;
  }

  get password() {
    return this.loginForm.get('password')!;
  }

  ngOnDestroy(): void {
    // this.sidebar.unsubscribe();
  }
}
