import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { CommonLoaderDirective } from './loader.directive';
import { CommonLoaderService } from './loader.service';
import {
  NgxUiLoaderHttpModule,
  NgxUiLoaderModule,
  NgxUiLoaderRouterModule,
  POSITION,
  SPINNER,
} from 'ngx-ui-loader';

const loaderDelay = environment.production ? 200 : 1;
const loaderMinTime = 200;

@NgModule({
  declarations: [CommonLoaderDirective],
  imports: [
    NgxUiLoaderModule.forRoot({
      //background loader
      bgsColor: '#00BCD4',
      bgsOpacity: 0.75,
      bgsPosition: POSITION.centerCenter,
      bgsSize: 70,
      bgsType: SPINNER.rectangleBounce,

      //foreground loader
      blur: 0,
      fgsColor: 'rgba(255,255,255,.4)',
      fgsPosition: POSITION.centerCenter,
      fgsType: SPINNER.rectangleBounce,
      gap: 10,
      logoPosition: POSITION.centerCenter,
      logoSize: 150,
      //logoUrl: "assets/images/logo.svg",
      overlayColor: 'rgba(2, 90, 139,1)',

      //progress bar
      pbColor: '#00BCD4',
      pbThickness: 10,

      delay: loaderDelay,
      minTime: loaderMinTime,
    }),
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule.forRoot({
      delay: loaderDelay,
      minTime: loaderMinTime,
    }),
  ],
  exports: [
    NgxUiLoaderModule,
    NgxUiLoaderRouterModule,
    NgxUiLoaderHttpModule,
    CommonLoaderDirective,
  ],
  providers: [CommonLoaderService],
})
export class CommonLoaderModule {}
