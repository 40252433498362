import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ViewService } from '../../../../shared/store/view/views.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FormService } from '../../../../shared/store/form/form.service';
import { ContactService } from '../../../../shared/store/contact/contact.service';
import { filter, Observable, of } from 'rxjs';
import { Entity, View } from '../../../../shared/store/view/types';
import { Contact } from '../../../../shared/store/contact/types';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { DataType, DropdownStyle } from 'src/app/shared/store/typeahead/types';
import { ErrorsNestedFormGroup } from '../../../../shared/store/form/types';
import { formatDate } from '../../../../shared/helper/datehelper';

@UntilDestroy()
@Component({
  selector: 'app-contact-edit',
  templateUrl: './contact-edit.component.html',
  styleUrls: ['./contact-edit.component.scss'],
})
export class ContactEditComponent implements OnInit, OnDestroy {
  active = 1;
  view$: Observable<View>;
  contact$: Observable<Contact>;
  contactId: string;
  contactForm: FormGroup;
  errorsDescription$: Observable<ErrorsNestedFormGroup> = of();

  protected readonly DropdownStyle = DropdownStyle;
  protected readonly Entity = Entity;
  protected readonly DataType = DataType;

  constructor(
    private readonly viewService: ViewService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly subHeaderService: SubHeaderService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly formService: FormService,
    private readonly contactService: ContactService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.contact$ = this.contactService.getById.value$;
  }

  ngOnInit(): void {
    this.contactId = this.route.snapshot.params['id'];
    this.contactService.getById.call(this.contactId);

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'contactUpdate') this.onSubmit();
        if (x == 'contactDetails') {
          this.router.navigate(['/Contacts/Contacts/Details', this.contactId]);
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@contactList', x.title);
      });

    this.contact$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@contactEdit', x.name);
        this.contactForm.patchValue({
          isCompany: x.isCompany,
          firstname: x.firstName,
          lastname: x.lastName,
          addressType: x.addressTypeInt,
          address: x.address,
          address2: x.address2,
          zipCode: x.zipCode,
          city: x.city,
          countryId: x.countryId,
          phoneNumber: x.phoneNumber,
          email: x.email,
          job: x.profession,
          salesTeamId: x.salesTeamId,
          salesPersonId: x.salesPersonId,
          marketingFormGroup: {
            marketingCampaignId: x.campaignId,
            marketingMedium: x.medium,
            marketingSource: x.source,
            marketingCreationDate: formatDate(
              x.creationTime,
              'ToInverseSqlDate',
            ),
          },
          notesFormGroup: {
            notes: x.notes,
          },
        });
      });

    this.contactForm = this.formBuilder.group({
      isCompany: [false],
      company: [undefined],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      addressType: [0, [Validators.required]],
      address: ['', [Validators.required]],
      address2: [''],
      zipCode: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(5)],
      ],
      city: [undefined, [Validators.required]],
      countryId: [undefined, [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      job: [''],
      salesTeamId: [undefined, [Validators.required]],
      salesPersonId: [undefined, [Validators.required]],
      empty: [''],
    });
  }

  onSubmit(): void {
    console.log(this.contactForm);

    if (this.contactForm.invalid) {
      this.contactForm.markAllAsTouched();
      this.formService.countErrors(this.contactForm, true);
      return;
    }

    this.formService.clear();

    let result$ = this.contactService.updateContact.call({
      id: +this.contactId,
      firstName: this.contactForm.value.firstname,
      lastName: this.contactForm.value.lastname,
      address: this.contactForm.value.address,
      zipCode: this.contactForm.value.zipCode,
      city: this.contactForm.value.city,
      email: this.contactForm.value.email,
      phoneNumber: this.contactForm.value.phoneNumber,
      addressType: this.contactForm.value.addressType,
      campaignId: this.contactForm.value.marketingFormGroup.marketingCampaignId,
      medium: this.contactForm.value.marketingFormGroup.marketingMedium,
      source: this.contactForm.value.marketingFormGroup.marketingSource,
      countryId: this.contactForm.value.countryId,
      isCompany: this.contactForm.value.isCompany,
      notes: this.contactForm.value.notesFormGroup.notes,
      profession: this.contactForm.value.job,
      salesPersonId: this.contactForm.value.salesPersonId,
      salesTeamId: this.contactForm.value.salesTeamId,
      address2: this.contactForm.value.address2,
    });
    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.router.navigate(['/Contacts/Contacts/Details', result.id]);
      });
  }

  ngOnDestroy(): void {
    this.breadcrumbService.set('@leadDetails', ' ');
    this.contactService.updateContact.reset();
  }
}
