import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import {
  PaymentSplittingAction,
  paymentSplittingActions,
} from './payment-splitting.actions';
import { PaymentSplittingApiService } from './payment-splitting.api.services';
import { IPaymentSplittingState } from './payment-splitting.reducer';

@Injectable()
export class PaymentSplittingEffects {
  constructor(
    private readonly store: Store<IPaymentSplittingState>,
    private readonly paymentSplittingApiService: PaymentSplittingApiService,
    private readonly actions$: Actions<PaymentSplittingAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.actions$,
    paymentSplittingActions.getById,
    this.paymentSplittingApiService.getById,
  );

  getByProductLine = createApiCallEffects(
    this,
    this.actions$,
    paymentSplittingActions.getByProductLine,
    this.paymentSplittingApiService.getByProductLine,
  );
}
