import { Component, Input, OnInit } from '@angular/core';
import { Comment } from '../../../store/comment/types';
import { environment } from '../../../../../environments/environment';
import { Observable, of } from 'rxjs';
import { Avatar } from '../../../store/user/type';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
})
export class CommentComponent implements OnInit {
  @Input() comment!: Comment;

  currentAvatar$: Observable<Avatar>;

  constructor() {}

  ngOnInit(): void {
    let avatar = new Avatar(
      this.comment.userContactName,
      [
        environment.comparanooapiurl,
        environment.features.thumbnail.url,
        environment.features.thumbnail.avatar,
      ].join(''),
      window
        .getComputedStyle(document.body)
        .getPropertyValue('--comment-avatar-bg')
        .replace('#', ''),
    );

    this.currentAvatar$ = of(avatar);
  }
}
