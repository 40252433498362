import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HumanResourcesDashboardRoutingModule } from './human-resources-dashboard-routing.module';
import {HumanResourcesDashboardComponent} from "./human-resources-dashboard/human-resources-dashboard.component";
import {SharedModule} from "../../../shared/shared.module";

@NgModule({
  declarations: [
    HumanResourcesDashboardComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    HumanResourcesDashboardRoutingModule,
  ]
})
export class HumanResourcesDashboardModule { }
