import { on } from "@ngrx/store";
import { IGenericApiCallActions } from "./generic.actions";
import { IGenericState, IModel } from "./types";

export function getInitialState<T>(initialValue: T | null): IGenericState<T> {
  return {
    value: initialValue,
    isLoaded: false,
    isLoading: false,
    error: null,
    params: null,
    lastUpdateDate: null,
  };
}

export function onApiCall<T>(
  genericApiCallActions: IGenericApiCallActions<T>,
  genericApiCallName: string
) {
  return [
    on(genericApiCallActions.calling, (state: any, _action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        isLoaded: false,
        isLoading: true,
        params: _action.params,
      },
    })),
    on(genericApiCallActions.callingSuccess, (state: any, _action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        isLoading: false,
        error: null,
      },
    })),
    on(genericApiCallActions.callingFailed, (state: any, action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        isLoading: false,
        error: action,
      },
    })),
    on(genericApiCallActions.callingCancelled, (state: any, _action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        isLoading: false,
      },
    })),
    on(genericApiCallActions.done, (state: any, action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        value: action.value,
        error: null,
        isLoaded: true,
        isLoading: false,
      },
    })),
    on(genericApiCallActions.reset, (state: any, _action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        value: null,
        error: null,
        isLoaded: false,
        isLoading: false,
        params: null,
      },
    })),
    on(genericApiCallActions.addedOrUpdated, (state: any, action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        value: action.value,
      },
    })),
    on(genericApiCallActions.added, (state: any, action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        value: action.value,
      },
    })),
    on(genericApiCallActions.updated, (state: any, action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        value: action.value,
      },
    })),
    on(genericApiCallActions.deleted, (state: any, _action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        value: null,
      },
    })),
    on(genericApiCallActions.setLastUpdateDate, (state: any, action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        lastUpdateDate: action.value,
      },
    })),
  ];
}

export function onArrayApiCall<T extends IModel>(
  genericApiCallActions: IGenericApiCallActions<T[]>,
  genericApiCallName: string
) {
  return [
    on(genericApiCallActions.calling, (state: any, _action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        isLoaded: false,
        isLoading: true,
      },
    })),
    on(genericApiCallActions.callingSuccess, (state: any, _action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        isLoading: false,
        error: null,
      },
    })),
    on(genericApiCallActions.callingFailed, (state: any, action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        isLoading: false,
        error: action.error,
      },
    })),
    on(genericApiCallActions.callingCancelled, (state: any, _action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        isLoading: false,
      },
    })),
    on(genericApiCallActions.done, (state: any, action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        value: action.value,
        error: null,
        isLoaded: true,
        isLoading: false,
      },
    })),
    on(genericApiCallActions.reset, (state: any, _action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        value: null,
        error: null,
        isLoaded: false,
        isLoading: false,
      },
    })),
    on(genericApiCallActions.addedOrUpdated, (state: any, action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        value: (state[genericApiCallName].value || []).find(
          (x: T) => x.id == action.value.id
        )
          ? [...(state[genericApiCallName].value || [])].map((stateValue) => {
              if (stateValue.id === action.value.id) {
                stateValue = action.value;
              }
              return stateValue;
            })
          : [
              ...(state[genericApiCallName].value || []).concat(
                action.value as T
              ),
            ],
      },
    })),
    on(genericApiCallActions.added, (state: any, action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        value: [
          ...(state[genericApiCallName].value || []).concat(action.value as T),
        ],
      },
    })),
    on(genericApiCallActions.updated, (state: any, action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        value: (state[genericApiCallName].value || []).find(
          (x: T) => x.id == action.value.id
        )
          ? [...(state[genericApiCallName].value || [])].map((stateValue) => {
              if (stateValue.id === action.value.id) {
                stateValue = action.value;
              }
              return stateValue;
            })
          : [...(state[genericApiCallName].value || [])],
      },
    })),
    on(genericApiCallActions.deleted, (state: any, action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        value: [
          ...(state[genericApiCallName].value || []).filter(
            (stateValue: T) => stateValue.id !== action.value.id
          ),
        ],
      },
    })),
    on(genericApiCallActions.setLastUpdateDate, (state: any, action) => ({
      ...state,
      [genericApiCallName]: {
        ...state[genericApiCallName],
        lastUpdateDate: action.value,
      },
    })),
  ];
}
