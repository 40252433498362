import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { SubHeaderService } from 'src/app/shared/store/subheader/subheader.service';
import { RegisterModel } from 'src/app/shared/store/user/type';
import { UserService } from 'src/app/shared/store/user/user.service';
import { customValidator } from '../../../../../shared/validators/custom-validator.validators';
import { View } from '../../../../../shared/store/view/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { take } from 'rxjs/operators';
import { FormService } from '../../../../../shared/store/form/form.service';

@UntilDestroy()
@Component({
  selector: 'app-user-create',
  templateUrl: './user-create.component.html',
  styleUrls: ['./user-create.component.scss'],
})
export class UserCreateComponent implements OnInit, OnDestroy {
  result$: Observable<boolean>;
  registerInfo: RegisterModel;
  view$: Observable<View>;

  constructor(
    private readonly userService: UserService,
    private readonly subHeaderService: SubHeaderService,
    private readonly router: Router,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly formService: FormService,
  ) {
    this.result$ = this.userService.add.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  userForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(6),
      customValidator(/\d/),
      customValidator(/[A-Z]/),
      customValidator(/[a-z]/),
    ]),
    username: new FormControl('', [Validators.required]),
  });

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'userCreate') this.onSubmit();
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@userList', x.title);
        this.breadcrumbService.set('@userCreate', x.breadcrumbCreate);
      });
  }

  onSubmit(): void {
    if (this.userForm.invalid) {
      this.userForm.markAllAsTouched();
      this.formService.countErrors(this.userForm, true);
      return;
    }

    this.formService.clear();

    let result$ = this.userService.add.call(
      new RegisterModel(
        this.userForm.value.email!,
        this.userForm.value.password!,
        this.userForm.value.username!,
      ),
    );

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        this.router.navigate(['/configuration/user/list']);
      });
  }

  get email() {
    return this.userForm.get('email')!;
  }

  get password() {
    return this.userForm.get('password')!;
  }

  ngOnDestroy(): void {
    this.userService.add.reset();
  }
}
