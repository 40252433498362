import { createApiCallActions } from 'src/app/common/store/generic.actions';
import {
  Field,
  SearchView,
  FavoriteSearchView,
  Operation,
} from '../search/types';

export const coreActions = {
  getFieldsByEntity: createApiCallActions<Field[]>('core', 'getFieldsByEntity'),
  getSearchViewByEntity: createApiCallActions<SearchView>(
    'core',
    'getSearchViewByEntity'
  ),
  getFavoriteByEntity: createApiCallActions<FavoriteSearchView[]>(
    'core',
    'getFavoriteByEntity'
  ),
  getOperatorsByType: createApiCallActions<Operation[]>(
    'core',
    'getOperatorsByType'
  ),
  getStringOperators: createApiCallActions<Operation[]>(
    'core',
    'getStringOperators'
  ),
  getIntOperators: createApiCallActions<Operation[]>('core', 'getIntOperators'),
};
export type CoreAction = typeof coreActions;
