import {Injectable} from "@angular/core";
import {BaseServiceFacade, GenericApiCall} from "../../../common/store/types";
import {AppState} from "../types";
import {Store} from "@ngrx/store";
import {InputTypeahead, InputTypeaheadQuickAdd, ListItem, ReferencesItem} from "./types";
import {typeaheadActions} from "./typeahead.actions";
import {typeaheadSelectors} from "./typeahead.selectors";

@Injectable()
export class TypeaheadService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  get: GenericApiCall<ListItem[], InputTypeahead> = this.genericApiCall(
    typeaheadActions.get,
    typeaheadSelectors.get,
    true
  )
  getReferencesItems: GenericApiCall<ReferencesItem[], InputTypeahead> = this.genericApiCall(
    typeaheadActions.getReferencesItems,
    typeaheadSelectors.getReferencesItems,
    true
  )
  quickAdd: GenericApiCall<ListItem, InputTypeaheadQuickAdd> = this.genericApiCall(
    typeaheadActions.quickAdd,
    typeaheadSelectors.quickAdd,
    true
  )
}
