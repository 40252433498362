import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { AppState } from '../types';
import { leadActions } from './lead.actions';
import { leadSelectors } from './lead.selectors';
import {
  BaseLeadFormModel,
  CarInsuranceLeadModel,
  CarLeadFormModel,
  HealthInsuranceLeadModel,
  HealthLeadFormModel,
  HouseInsuranceLeadModel,
  HouseLeadFormModel,
  InsuranceCategory,
  LeadDetailModel,
  PushLeadModel,
} from './types';

@Injectable()
export class LeadService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getByUniqueId: GenericApiCall<LeadDetailModel, string> = this.genericApiCall(
    leadActions.getByUniqueId,
    leadSelectors.getByUniqueId,
    true
  );
  getHealthDetail: GenericApiCall<HealthInsuranceLeadModel, string> =
    this.genericApiCall(
      leadActions.getHealthDetail,
      leadSelectors.getHealthDetail,
      true
    );
  getCarDetail: GenericApiCall<CarInsuranceLeadModel, string> =
    this.genericApiCall(
      leadActions.getCarDetail,
      leadSelectors.getCarDetail,
      true
    );
  getHouseDetail: GenericApiCall<HouseInsuranceLeadModel, string> =
    this.genericApiCall(
      leadActions.getHouseDetail,
      leadSelectors.getHouseDetail,
      true
    );
  sendLead: GenericApiCall<any, PushLeadModel> = this.genericApiCall(
    leadActions.sendLead,
    leadSelectors.sendLead,
    true
  );

  updateHealthDetail: GenericApiCall<any, HealthLeadFormModel> =
    this.genericApiCall(
      leadActions.updateHealthDetail,
      leadSelectors.updateHealthDetail,
      true
    );
  updateCarDetail: GenericApiCall<any, CarLeadFormModel> = this.genericApiCall(
    leadActions.updateCarDetail,
    leadSelectors.updateCarDetail,
    true
  );
  updateHouseDetail: GenericApiCall<any, HouseLeadFormModel> =
    this.genericApiCall(
      leadActions.updateHouseDetail,
      leadSelectors.updateHouseDetail,
      true
    );
  createHealthLead: GenericApiCall<any, HealthLeadFormModel> =
    this.genericApiCall(
      leadActions.createHealthLead,
      leadSelectors.createHealthLead,
      true
    );
  createCarLead: GenericApiCall<any, CarLeadFormModel> = this.genericApiCall(
    leadActions.createCarLead,
    leadSelectors.createCarLead,
    true
  );
  createHouseLead: GenericApiCall<any, HouseLeadFormModel> =
    this.genericApiCall(
      leadActions.createHouseLead,
      leadSelectors.createHouseLead,
      true
    );
  getBaseLeadModel: GenericApiCall<BaseLeadFormModel, InsuranceCategory> =
    this.genericApiCall(
      leadActions.getBaseLeadModel,
      leadSelectors.getBaseLeadModel,
      true
    );
  getHealthLeadModel: GenericApiCall<HealthLeadFormModel, void> =
    this.genericApiCall(
      leadActions.getHealthLeadModel,
      leadSelectors.getHealthLeadModel,
      true
    );
  getCarLeadModel: GenericApiCall<CarLeadFormModel, void> = this.genericApiCall(
    leadActions.getCarLeadModel,
    leadSelectors.getCarLeadModel,
    true
  );
  getHouseLeadModel: GenericApiCall<HouseLeadFormModel, void> =
    this.genericApiCall(
      leadActions.getHouseLeadModel,
      leadSelectors.getHouseLeadModel,
      true
    );
}
