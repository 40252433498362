import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IPaginationState } from './pagination.reducer';
import { paginationFeatureKey } from './types';

const paginationFeatureState =
  createFeatureSelector<IPaginationState>(paginationFeatureKey);

export const paginationSelectors = {
  getPageIndex: createSelector(
    paginationFeatureState,
    (state: IPaginationState) => state.pageIndex
  ),
  getTotalCount: createSelector(
    paginationFeatureState,
    (state: IPaginationState) => state.totalCount
  ),
  getPageSize: createSelector(
    paginationFeatureState,
    (state: IPaginationState) => state.pageSize
  ),
  getTotalPages: createSelector(
    paginationFeatureState,
    (state: IPaginationState) => state.totalPages
  ),
  getFilter: createSelector(
    paginationFeatureState,
    (state: IPaginationState) => state.filter
  ),
  getSortBy: createSelector(
    paginationFeatureState,
    (state: IPaginationState) => state.sortBy
  ),
  getOrderBy: createSelector(
    paginationFeatureState,
    (state: IPaginationState) => state.orderBy
  ),
};
