import { GenericState, IState } from 'src/app/common/store/types';
import { Country } from './types';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { Action, ActionReducer, createReducer } from '@ngrx/store';
import { countryActions } from './country.actions';

export interface ICountryState extends IState<Country> {
  getById: GenericState<Country>;
  postCountry: GenericState<any>;
  updateCountry: GenericState<any>;
}

export const countryInitialState: ICountryState = {
  getById: new GenericState<Country>(),
  postCountry: new GenericState<any>(),
  updateCountry: new GenericState<any>(),
};

const countryReducers = [
  ...onApiCall<Country>(countryActions.getById, 'getById'),
  ...onApiCall<any>(countryActions.postCountry, 'postCountry'),
  ...onApiCall<any>(countryActions.updateCountry, 'updateCountry'),
];

export const countryReducer = createReducer(
  countryInitialState,
  ...countryReducers
) as ActionReducer<ICountryState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return countryReducer(state, action);
}
