import { Component, OnInit } from '@angular/core';
import { filter, Observable } from 'rxjs';
import { HouseInsuranceLeadModel } from '../../../../../../shared/store/lead/types';
import { LeadService } from '../../../../../../shared/store/lead/lead.service';
import {
  ControlContainer,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { take } from 'rxjs/operators';
import { Entity } from '../../../../../../shared/store/view/types';
import { DropdownStyle } from '../../../../../../shared/store/typeahead/types';

@Component({
  selector: 'app-lead-form-house',
  templateUrl: './lead-form-house.component.html',
  styleUrls: ['./lead-form-house.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class LeadFormHouseComponent implements OnInit {
  houseDetail$: Observable<HouseInsuranceLeadModel>;
  leadForm: FormGroup<any>;

  protected readonly Entity = Entity;
  protected readonly DropdownStyle = DropdownStyle;

  constructor(
    private readonly leadService: LeadService,
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
  ) {
    this.houseDetail$ = this.leadService.getHouseDetail.value$;
  }

  ngOnInit(): void {
    this.leadForm = this.parent.form;

    this.leadForm.addControl(
      'houseFormGroup',
      this.formBuilder.group({
        houseHousingType: [undefined, [Validators.required]],
        houseNbOfRooms: [+''],
        houseAmountToInsure: [undefined, [Validators.required]],
        houseAddress: ['', [Validators.required]],
        houseZipCode: [
          '',
          [
            Validators.required,
            Validators.minLength(5),
            Validators.maxLength(5),
          ],
        ],
        houseCity: [undefined, [Validators.required]],
        houseFlatLevel: [''],
        houseResidencyType: [undefined, [Validators.required]],
        houseArea: [+'', [Validators.required]],
        houseConstructionType: [undefined, [Validators.required]],
        houseRoofType: [undefined, [Validators.required]],
        houseOccupancyRole: [undefined],
        houseCurrentInsuranceCompany: [undefined],
        houseFireCoverage: [false],
        houseWaterCoverage: [false],
        houseTheftCoverage: [false],
        houseGlassBreakCoverage: [false],
        housePublicLiabilityCoverage: [false],
        houseFeeCancellationCoverage: [false],
        housePoolCoverage: [false],
        houseDogCoverage: [false],
        houseElectricalDamageCoverage: [false],
      }),
    );

    this.houseDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.leadForm.patchValue({
          houseFormGroup: {
            houseHousingType: x.housingType,
            houseNbOfRooms: x.nbOfRooms,
            houseAmountToInsure: x.amountToInsure,
            houseAddress: x.address,
            houseZipCode: x.zipCode,
            houseCity: x.city,
            houseFlatLevel: x.flatLevel,
            houseResidencyType: x.residencyType,
            houseArea: x.area,
            houseConstructionType: x.constructionType,
            houseRoofType: x.roofType,
            houseOccupancyRole: x.occupancyRole,
            houseCurrentInsuranceCompany: x.currentInsuranceCompany,
            houseFireCoverage: x.fireCoverage,
            houseWaterCoverage: x.waterCoverage,
            houseTheftCoverage: x.theftCoverage,
            houseGlassBreakCoverage: x.glassBreakCoverage,
            housePublicLiabilityCoverage: x.publicLiabilityCoverage,
            houseFeeCancellationCoverage: x.feeCancellationCoverage,
            housePoolCoverage: x.poolCoverage,
            houseDogCoverage: x.dogCoverage,
            houseElectricalDamageCoverage: x.electricalDamageCoverage,
          },
        });
      });
  }
}
