import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SidebarApiService } from './sidebar.api.services';
import { SidebarEffects } from './sidebar.effects';
import {
  ISidebarState,
  sidebarInitialState,
  sidebarReducer,
} from './sidebar.reducer';
import { SidebarService } from './sidebar.service';
import { sidebarFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<ISidebarState>(sidebarFeatureKey, sidebarReducer, {
      initialState: sidebarInitialState,
    }),
    EffectsModule.forFeature([SidebarEffects]),
  ],
  providers: [SidebarApiService, SidebarService],
})
export class StoreSidebarModule {}
