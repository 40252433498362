import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IReportingState } from './reporting.reducer';
import { LabelWithValue, Period, reportingFeatureKey } from './types';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';

const reportingFeatureState =
  createFeatureSelector<IReportingState>(reportingFeatureKey);

export const reportingSelectors = {
  getReportByFilter: createApiCallSelectors<any[], IReportingState>(
    reportingFeatureState,
    'getReportByFilter'
  ),
  getReportMalePeopleByAge: createApiCallSelectors<any[], IReportingState>(
    reportingFeatureState,
    'getReportMalePeopleByAge'
  ),
  getReportFemalePeopleByAge: createApiCallSelectors<any[], IReportingState>(
    reportingFeatureState,
    'getReportFemalePeopleByAge'
  ),
  getReportLeadsMonthlyRange: createApiCallSelectors<any[], IReportingState>(
    reportingFeatureState,
    'getReportLeadsMonthlyRange'
  ),
  getLeadsByDayAndHoursRange: createApiCallSelectors<any[], IReportingState>(
    reportingFeatureState,
    'getLeadsByDayAndHoursRange'
  ),
  getReportPeopleByRegime: createApiCallSelectors<any[], IReportingState>(
    reportingFeatureState,
    'getReportPeopleByRegime'
  ),
  getReportLeadsByType: createApiCallSelectors<any[], IReportingState>(
    reportingFeatureState,
    'getReportLeadsByType'
  ),
  getReportLeadsByCity: createApiCallSelectors<any[], IReportingState>(
    reportingFeatureState,
    'getReportLeadsByCity'
  ),
  getPeriods: createApiCallSelectors<Period[], IReportingState>(
    reportingFeatureState,
    'getPeriods'
  ),
  getReportLeadsSum: createApiCallSelectors<number, IReportingState>(
    reportingFeatureState,
    'getReportLeadsSum'
  ),
  getReportContractsSum: createApiCallSelectors<number, IReportingState>(
    reportingFeatureState,
    'getReportContractsSum'
  ),
  getReportAnnualFeesSum: createApiCallSelectors<number, IReportingState>(
    reportingFeatureState,
    'getReportAnnualFeesSum'
  ),
  getReportMonthlyFeesAverage: createApiCallSelectors<number, IReportingState>(
    reportingFeatureState,
    'getReportMonthlyFeesAverage'
  ),

  getBarGraphStartDate: createSelector(
    reportingFeatureState,
    (state: IReportingState) => state.barGraphStartDate
  ),
  getBarGraphEndDate: createSelector(
    reportingFeatureState,
    (state: IReportingState) => state.barGraphEndDate
  ),
};
