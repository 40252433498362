import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { PageResult } from '../../types';
import { AppState } from '../types';
import { contractActions } from './contract.actions';
import { contractSelectors } from './contract.selectors';
import {
  ContractModel,
  ContractHealthFormModel,
  ContractFormModel,
  ContractStatus,
  ContractStatusFlow,
  CreateContractFormModel,
  HealthInsuranceContractDetailModel,
  ContractCarFormModel,
  ContractHouseFormModel,
  CarInsuranceContractModel,
  HouseInsuranceContractModel,
  ContractHealthUpdateModel,
  ContractHouseUpdateModel,
  ContractCarUpdateModel,
} from './types';
import { InsuranceCategory } from '../lead/types';

@Injectable()
export class ContractService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getById: GenericApiCall<ContractModel, string> = this.genericApiCall(
    contractActions.getById,
    contractSelectors.getById,
    true
  );
  getByUniqueId: GenericApiCall<ContractModel, string> = this.genericApiCall(
    contractActions.getByUniqueId,
    contractSelectors.getByUniqueId,
    true
  );

  getByLeadId: GenericApiCall<ContractModel, string> = this.genericApiCall(
    contractActions.getByLeadId,
    contractSelectors.getByLeadId,
    true
  );

  getHealthDetail: GenericApiCall<
    HealthInsuranceContractDetailModel[],
    number
  > = this.genericApiCall(
    contractActions.getHealthDetail,
    contractSelectors.getHealthDetail,
    true
  );

  getCarDetail: GenericApiCall<CarInsuranceContractModel, number> =
    this.genericApiCall(
      contractActions.getCarDetail,
      contractSelectors.getCarDetail,
      true
    );

  getHouseDetail: GenericApiCall<HouseInsuranceContractModel, number> =
    this.genericApiCall(
      contractActions.getHouseDetail,
      contractSelectors.getHouseDetail,
      true
    );

  getStatusFlow: GenericApiCall<ContractStatusFlow[], ContractStatus> =
    this.genericApiCall(
      contractActions.getStatusFlow,
      contractSelectors.getStatusFlow,
      true
    );

  getContractModel: GenericApiCall<CreateContractFormModel, InsuranceCategory> =
    this.genericApiCall(
      contractActions.getContractFormModel,
      contractSelectors.getContractModel,
      true
    );

  createHealthContract: GenericApiCall<ContractModel, ContractHealthFormModel> =
    this.genericApiCall(
      contractActions.createHealthContract,
      contractSelectors.createHealthContract,
      true
    );

  createCarContract: GenericApiCall<ContractModel, ContractCarFormModel> =
    this.genericApiCall(
      contractActions.createCarContract,
      contractSelectors.createCarContract,
      true
    );

  createHouseContract: GenericApiCall<ContractModel, ContractHouseFormModel> =
    this.genericApiCall(
      contractActions.createHouseContract,
      contractSelectors.createHouseContract,
      true
    );

  updateHealthContract: GenericApiCall<
    ContractModel,
    ContractHealthUpdateModel
  > = this.genericApiCall(
    contractActions.updateHealthContract,
    contractSelectors.updateHealthContract,
    true
  );

  updateCarContract: GenericApiCall<ContractModel, ContractCarUpdateModel> =
    this.genericApiCall(
      contractActions.updateCarContract,
      contractSelectors.updateCarContract,
      true
    );

  updateHouseContract: GenericApiCall<ContractModel, ContractHouseUpdateModel> =
    this.genericApiCall(
      contractActions.updateHouseContract,
      contractSelectors.updateHouseContract,
      true
    );
}
