<ng-container *ngIf="tabsContent$ | async as tabsContent">
  <ul
    ngbNav
    #nav="ngbNav"
    [(activeId)]="active"
    class="nav-tabs"
    [destroyOnHide]="false"
  >
    <li [ngbNavItem]="tabContent.index"
        *ngFor="let tabContent of tabsContent; count as count; last as isLast; trackBy: trackByTabsContent">
      <button ngbNavLink class="nav-link" translate>
        {{ tabContent.title }}
        <ng-container
          *ngIf="tabContent.formGroupName && this.form.controls[tabContent.formGroupName]?.touched && this.form.controls[tabContent.formGroupName]?.invalid">
          <span class="ps-1">
            <ng-container *ngIf="errorsDescription$ | async as errors; else noError">
              <ng-container *ngFor="let error of errors.children">
                <span class="badge text-bg-danger"
                      *ngIf="error.groupName === tabContent.formGroupName">{{ error.count }}</span>
              </ng-container>
            </ng-container>
            <ng-template #noError>
              <span class="dot"></span>
            </ng-template>
          </span>
        </ng-container>

      </button>
      <ng-template ngbNavContent>
        <ng-container *ngComponentOutlet="tabContent.component"/>
      </ng-template>
    </li>

    <li [ngbNavItem]="tabsContent.length + 1">
      <button ngbNavLink class="nav-link" translate>
        CONTACT_TABS_MARKETING
        <ng-container
          *ngIf="this.form.controls['marketingFormGroup']?.touched && this.form.controls['marketingFormGroup']?.invalid">
          <span class="ps-1">
            <ng-container *ngIf="errorsDescription$ | async as errors; else noError">
              <ng-container *ngFor="let error of errors.children">
                <span class="badge text-bg-danger"
                      *ngIf="error.groupName === 'marketingFormGroup'">{{ error.count }}</span>
              </ng-container>
            </ng-container>
            <ng-template #noError>
              <span class="dot"></span>
            </ng-template>
          </span>
        </ng-container>
      </button>
      <ng-template ngbNavContent>
        <app-contact-form-marketing/>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</ng-container>
