import { isNumeric } from 'src/app/shared/helper/stringhelper';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import {
  OperatorFunction,
  Observable,
  debounceTime,
  distinctUntilChanged,
  map,
  filter,
} from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { View } from '../../store/view/types';
import { ViewService } from '../../store/view/views.service';
import { Domain } from '../../store/search/types';
import { SearchService } from '../../store/search/search.service';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss'],
})
@UntilDestroy()
export class SearchBarComponent implements OnInit {
  //the input element of the search
  @ViewChild('Input') input: ElementRef<HTMLInputElement>;

  view$: Observable<View>;
  constructor(
    private readonly searchService: SearchService,
    private readonly viewService: ViewService
  ) {}

  ngOnInit(): void {
    this.view$ = this.viewService.getViews.value$;
  }

  //fetch the suggestion list
  fetchItems: OperatorFunction<string, readonly Domain[]> = (
    text$: Observable<string>
  ) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term) =>
        term.length < 1
          ? []
          : this.formatResponse(this.input.nativeElement.value)
      )
    );

  //return list of suggestions to the end user based on type
  formatResponse(inputString: string): any {
    var suggestionList: Domain[] = [];
    var isNum = isNumeric(inputString);

    //combine searchview and fieldmetadata observable
    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((view) => {
        view.searchView.autoCompleteFields.forEach((field) => {
          const containsOperator = {
            id: 'Contains',
            label: 'contient',
            numberOfValues: 1,
          };
          const equalsOperator = {
            id: 'EqualTo',
            label: 'egal à',
            numberOfValues: 1,
          };

          switch (field.type) {
            case 'Text':
              const domain: Domain = {
                field: field.name,
                fieldLabel: field.label,
                operator: containsOperator,
                value: inputString,
                value2: '',
                connector: 'Or',
                text: inputString,
                text2: '',
                isActive: true,
              };
              if (!suggestionList.includes(domain)) {
                suggestionList.push(domain);
              }
              break;
            case 'Number': {
              if (isNum) {
                const equalsDomain: Domain = {
                  field: field.name,
                  fieldLabel: field.label,
                  operator: equalsOperator,
                  value: inputString,
                  value2: '',
                  connector: 'Or',
                  text: inputString,
                  text2: '',
                  isActive: true,
                };
                if (!suggestionList.includes(equalsDomain)) {
                  suggestionList.push(equalsDomain);
                }
              }
            }
          }
        });
      });
    return suggestionList;
  }

  //format the result in the input field
  inputFormatter = (result: Domain) =>
    `${result.fieldLabel} ${result.operator.label} ${result.value}`;

  //add the filter to the model
  selectedItem(event) {
    //push text filters in store
    this.searchService.addTextFilter(event.item);

    //set a slight delay to empty the input
    setTimeout(() => {
      this.input.nativeElement.value = '';
    }, 20);
  }
}
