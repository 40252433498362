import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-contact-form-notes',
  templateUrl: './contact-form-notes.component.html',
  styleUrls: ['./contact-form-notes.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ContactFormNotesComponent implements OnInit {
  form: FormGroup;

  constructor(
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.parent.form;
    this.form.addControl(
      'notesFormGroup',
      this.formBuilder.group({
        notes: [''],
      }),
    );
  }
}
