import {
  createSelector,
  DefaultProjectorFn,
  MemoizedSelector,
} from "@ngrx/store";
import { GenericState, IGenericSelectors, IState } from "./types";

export function createApiCallSelectors<K, T extends IState<K | K[]>>(
  featureState: MemoizedSelector<object, T, DefaultProjectorFn<T>>,
  callName: string
): IGenericSelectors {
  const valueSelector = createSelector(featureState, (state: T) => {
    if (!state[callName]) init<K>(state, callName);
    return state[callName].value;
  });

  const isLoadedSelector = createSelector(featureState, (state: T) => {
    if (!state[callName]) init<K>(state, callName);
    return state[callName].isLoaded;
  });

  const isLoadingSelector = createSelector(featureState, (state: T) => {
    if (!state[callName]) init<K>(state, callName);
    return state[callName].isLoading;
  });

  const errorSelector = createSelector(featureState, (state: T) => {
    if (!state[callName]) init<K>(state, callName);
    return state[callName].error;
  });

  const paramsSelector = createSelector(featureState, (state: T) => {
    if (!state[callName]) init<K>(state, callName);
    return state[callName].params;
  });

  const lastUpdateDateSelector = createSelector(featureState, (state: T) => {
    if (!state[callName]) init<K>(state, callName);
    return state[callName].lastUpdateDate;
  });

  return {
    valueSelector,
    isLoadedSelector,
    isLoadingSelector,
    errorSelector,
    paramsSelector,
    lastUpdateDateSelector,
  };
}

function init<K>(state, callName) {
  if (!state[callName]) {
    state[callName] = new GenericState<K>();
  }
  return true;
}
