import { Component, OnInit } from '@angular/core';
import { ContractService } from '../../store/contract/contract.service';
import { ContractModel, ContractStatusFlow } from '../../store/contract/types';
import { Observable, filter, map } from 'rxjs';
import { Action, View } from '../../store/view/types';
import { ViewService } from '../../store/view/views.service';

@Component({
  selector: 'app-custom-actions-flow-contract',
  templateUrl: './custom-actions-flow-contract.component.html',
  styleUrls: ['./custom-actions-flow-contract.component.scss'],
})
export class CustomActionsFlowContractComponent implements OnInit {
  view$: Observable<View>;
  contractBase$: Observable<ContractModel>;
  contractStatusFlows$: Observable<ContractStatusFlow[]>;
  customActions$: Observable<Action[]>;

  constructor(
    private readonly contractService: ContractService,
    private readonly viewService: ViewService
  ) {}

  ngOnInit(): void {
    this.view$ = this.viewService.getViews.value$;
    this.contractBase$ = this.contractService.getByUniqueId.value$;
    this.contractStatusFlows$ = this.contractService.getStatusFlow.value$;

    this.contractBase$.pipe(filter((x) => !!x)).subscribe((contractBase) => {
      this.contractService.getStatusFlow.call(contractBase.statusId);
    });
  }
}
