import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { SalesDashboardRoutingModule } from './sale-dashboard-routing.module';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
@NgModule({
  declarations: [SalesDashboardComponent],
  imports: [CommonModule, SharedModule, SalesDashboardRoutingModule],
})
export class SalesDashboardModule {}
