import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { catchError } from 'rxjs/operators';
import { ErrorService } from '../../error';
import { OperationModel, SearchRequest } from './types';
import { PageResult, SearchResult } from '../../types';
import { format } from 'path';

@Injectable({ providedIn: 'root' })
export class SearchApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService
  ) {}

  search = (searchRequest: SearchRequest): Observable<SearchResult<any>> => {
    let parameters: string[];
    parameters = ['api', searchRequest.url, 'search'];
    const parameter = new HttpParams()
      .set('pageIndex', searchRequest.pageIndex)
      .set('pageSize', searchRequest.pageSize)
      .set('orderBy', `${searchRequest.sortBy} ${searchRequest.orderBy}`);
    let url = environment.comparanooapiurl;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return this.httpClient
      .post<SearchResult<any>>(url, searchRequest.filters, {
        params: parameter,
      })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  operations = (): Observable<OperationModel[]> => {
    let parameters: string[];
    parameters = ['operations'];
    let url = this.formatUrl(parameters);

    return this.httpClient.get<OperationModel[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      })
    );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.search.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
