import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { AppState } from '../types';
import { searchActions } from './search.actions';
import { searchSelectors } from './search.selectors';
import { Domain, Filter, OperationModel, SearchRequest } from './types';
import { SearchResult } from '../../types';
import { Observable, combineLatest, filter, map } from 'rxjs';

@Injectable()
export class SearchService extends BaseServiceFacade {
  filters$: Observable<Filter[]>;
  constructor(store: Store<AppState>) {
    super(store);
    this.filters$ = combineLatest([
      this.getActiveFilters(),
      this.getContextFilters(),
      this.getContextFilterFlag(),
    ]).pipe(
      filter(
        ([activeFilters, contextFilters, contextFiltersFlag]) =>
          !!activeFilters && !!contextFilters && !!contextFiltersFlag
      ),
      map(([activeFilters, contextFilters, contextFiltersFlag]) => {
        if (contextFiltersFlag) {
          return contextFilters;
        } else {
          return activeFilters;
        }
      })
    );
  }

  search: GenericApiCall<SearchResult<any>, SearchRequest> =
    this.genericApiCall(searchActions.search, searchSelectors.search, true);

  operations: GenericApiCall<OperationModel[], void> = this.genericApiCall(
    searchActions.operations,
    searchSelectors.operations,
    false
  );

  setActiveFilters = (activeFilters: Filter[]) => {
    this.store.dispatch(
      searchActions.setActiveFilters({ activeFilters: activeFilters })
    );
  };
  getActiveFilters = () => this.store.select(searchSelectors.getActiveFilters);

  setCustomFilters = (customFilters: Filter[]) => {
    this.store.dispatch(
      searchActions.setCustomFilters({ customFilters: customFilters })
    );
  };
  getCustomFilters = () => this.store.select(searchSelectors.getCustomFilters);

  removeActiveFilterByIndex = (index: number) => {
    this.store.dispatch(
      searchActions.removeActiveFilterByIndex({ index: index })
    );
  };

  addActiveFilter = (filter: Filter, isContextFilterFlag?: boolean) => {
    this.store.dispatch(
      searchActions.addActiveFilter({
        filter,
        isContextFilterFlag: !!isContextFilterFlag,
      })
    );
  };

  resetActiveFilter = () => {
    this.store.dispatch(searchActions.resetActiveFilter());
  };

  setContextFilters = (
    contextFilters: Filter[],
    isContextFilterFlag?: boolean
  ) => {
    this.store.dispatch(
      searchActions.setContextFilters({
        contextFilters: contextFilters,
        isContextFilterFlag: !!isContextFilterFlag,
      })
    );
  };
  getContextFilters = () =>
    this.store.select(searchSelectors.getContextFilters);

  setContextFilterFlag = (flag: boolean) => {
    this.store.dispatch(searchActions.setContextFilterFlag({ flag: flag }));
  };
  getContextFilterFlag = () =>
    this.store.select(searchSelectors.getContextFilterFlag);

  addTextFilter = (domain: Domain) => {
    this.store.dispatch(searchActions.addTextFilter({ domain: domain }));
  };
  toggleBuiltInFilter = (builtInFilter: Filter) => {
    this.store.dispatch(
      searchActions.toggleBuiltInFilter({ builtInFilter: builtInFilter })
    );
  };
  applyCustomFilter = (domains: Domain[]) => {
    this.store.dispatch(searchActions.applyCustomFilter({ domains: domains }));
  };
  changeDomainState = (filterIndex: number, domainIndex: number) => {
    this.store.dispatch(
      searchActions.changeDomainState({
        filterIndex: filterIndex,
        domainIndex: domainIndex,
      })
    );
  };
}
