import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BankAccount } from './types';
import { environment } from 'src/environments/environment';
import {catchError} from "rxjs/operators";
import {ErrorService} from "../../error";

@Injectable({
  providedIn: 'root',
})
export class BankAccountApiService {
  constructor(private readonly httpClient: HttpClient,
              private readonly errorService:ErrorService
) {}

  getById = (id: string): Observable<BankAccount> => {
    let parameters: string[];
    parameters = [id];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<BankAccount>(url)
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.bankaccounts.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
