import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { SearchAction, searchActions } from './search.actions';
import { SearchApiService } from './search.api.services';
import { ISearchState } from './search.reducer';

@Injectable()
export class SearchEffects {
  constructor(
    private readonly store: Store<ISearchState>,
    private readonly searchApiService: SearchApiService,
    private readonly actions$: Actions<SearchAction>
  ) {}

  search = createApiCallEffects(
    this,
    this.actions$,
    searchActions.search,
    this.searchApiService.search
  );

  operations = createApiCallEffects(
    this,
    this.actions$,
    searchActions.operations,
    this.searchApiService.operations
  );
}
