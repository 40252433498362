import { Injectable } from '@angular/core';
import { CommonTranslateService } from '../common/translate/translate.service';

@Injectable()
export class CoreService {
  constructor(private readonly commonTranslateService : CommonTranslateService
  ) {}

  configure = (): void => {
    this.commonTranslateService.init();
  };
}
