import { createApiCallActions } from 'src/app/common/store/generic.actions';
import { PageResult } from '../../types';
import { County } from './types';

export const countyActions = {
  get: createApiCallActions<County[]>('county', 'get'),
  getById: createApiCallActions<County>('county', 'getById'),
  getPaged: createApiCallActions<PageResult<County>>('county', 'getPaged'),
};

export type CountyAction = typeof countyActions;
