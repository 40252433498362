import {createFeatureSelector} from "@ngrx/store";
import {ITypeaheadState} from "./typeahead.reducer";
import {ListItem, ReferencesItem, typeaheadFeatureKey} from "./types";
import {createApiCallSelectors} from "../../../common/store/generic.selectors";

const typeaheadFeatureState =
  createFeatureSelector<ITypeaheadState>(typeaheadFeatureKey);

export const typeaheadSelectors = {
  get: createApiCallSelectors<ListItem[], ITypeaheadState>(
    typeaheadFeatureState,
    'get'
  ),
  getReferencesItems: createApiCallSelectors<ReferencesItem[], ITypeaheadState>(
    typeaheadFeatureState,
    'getReferencesItems'
  ),
  quickAdd: createApiCallSelectors<ListItem, ITypeaheadState>(
    typeaheadFeatureState,
    'quickAdd'
  ),
}
