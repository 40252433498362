export const countryFeatureKey = 'country';
export type Country =  {
    id: number;
    name: string;
    englishName: string;
    alpha2Code: string;
    alpha3Code: string;
    nationality: string;
    active: boolean;
    currencyName: string;
    currencyId: number | undefined | null;
}

export class CountryPost {
    constructor(
        name: string,
        englishName: string,
        alpha2Code: string,
        alpha3Code: string,
        currencyId: number,
        nationality: string,
        active: boolean,
    ) {
        this.name = name;
        this.englishName = englishName;
        this.alpha2Code = alpha2Code;
        this.alpha3Code = alpha3Code;
        this.currencyId = currencyId;
        this.nationality = nationality;
        this.active = active;
    }

    name:string;
    englishName: string;
    alpha2Code: string;
    alpha3Code: string;
    currencyId: number | string | undefined | null;
    nationality: string;
    active: boolean;
}

export class CountryUpdate {
    id:number;
    name:string;
    englishName: string;
    alpha2Code: string;
    alpha3Code: string;
    currencyId: number | string | undefined | null;
    nationality: string;
    active: boolean;
}
