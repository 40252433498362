<div class="modal-header">
  <h5 class="modal-title">{{this.title}}</h5>
</div>
<div class="modal-body">
  <ng-container *ngComponentOutlet="
        component;
        inputs: this.inputs;
      "/>
</div>
<div class="modal-footer" *ngIf="this.isCreate">
  <button
    [class]="ButtonType.Light | buttontypepipe"
    class="btn-sm btn"
    (click)="activeModalService.dismiss(-1)"
    translate
  >
    GLOBAL_BTN_CANCEL
  </button>
  <button
    [class]="ButtonType.Primary | buttontypepipe"
    class="btn-sm btn"
    translate
    (click)="triggerAction(this.saveActionName)"
  >
    GLOBAL_BTN_SUBMIT
  </button>

</div>

