import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable, of } from 'rxjs';
import { InsuranceCategory } from '../../../../../shared/store/lead/types';
import { ContractFormHealthComponent } from '../contract-form-tabs/contract-form-health/contract-form-health.component';
import { ContractFormPaymentSplittingComponent } from '../contract-form-tabs/contract-form-payment-splitting/contract-form-payment-splitting.component';
import { ContractFormGeneralInformationsComponent } from '../contract-form-tabs/contract-form-general-informations/contract-form-general-informations.component';
import { ContractFormCommissionComponent } from '../contract-form-tabs/contract-form-commission/contract-form-commission.component';
import { TabsContent } from '../../../../../shared/models/tabs-content';
import { ErrorsNestedFormGroup } from '../../../../../shared/store/form/types';
import { ContractFormDriverComponent } from '../contract-form-tabs/contract-form-driver/contract-form-driver.component';
import { ContractFormVehicleComponent } from '../contract-form-tabs/contract-form-vehicle/contract-form-vehicle.component';
import { ContractFormCarInsuranceComponent } from '../contract-form-tabs/contract-form-car-insurance/contract-form-car-insurance.component';
import { ContractFormHouseComponent } from '../contract-form-tabs/contract-form-house/contract-form-house.component';
import { FormService } from '../../../../../shared/store/form/form.service';

@Component({
  selector: 'app-contract-tabs-form',
  templateUrl: './contract-tabs-form.component.html',
  styleUrls: ['./contract-tabs-form.component.scss'],
})
export class ContractTabsFormComponent implements OnInit, OnChanges {
  @Input() form: FormGroup;
  @Input() category: string;
  errorsDescription$: Observable<ErrorsNestedFormGroup>;

  active = 1;

  tabsContent$: Observable<TabsContent[]> = of([]);

  tabsInit: TabsContent[] = [
    {
      title: 'CONTRACT_TABS_GENERAL_INFORMATIONS',
      index: 1,
      name: 'general-information',
      categoryString: 'Health',
      category: InsuranceCategory.Health,
      component: ContractFormGeneralInformationsComponent,
      formGroupName: 'generalInformationFormGroup',
    },
    {
      title: 'CONTRACT_TABS_HEALTH',
      index: 2,
      name: 'health',
      categoryString: 'Health',
      category: InsuranceCategory.Health,
      component: ContractFormHealthComponent,
      formGroupName: 'healthFormGroup',
    },
    {
      title: 'CONTRACT_TABS_PAYMENT_SPLITTING',
      index: 3,
      name: 'payment-splitting',
      categoryString: 'Health',
      category: InsuranceCategory.Health,
      component: ContractFormPaymentSplittingComponent,
      formGroupName: 'paymentSplittingFormGroup',
    },
    {
      title: 'CONTRACT_TABS_COMMISSIONS',
      index: 4,
      name: 'commission',
      categoryString: 'Health',
      category: InsuranceCategory.Health,
      component: ContractFormCommissionComponent,
      formGroupName: 'commissionFormGroup',
    },
    {
      title: 'CONTRACT_TABS_GENERAL_INFORMATIONS',
      index: 1,
      name: 'general-information',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractFormGeneralInformationsComponent,
      formGroupName: 'generalInformationFormGroup',
    },
    {
      title: 'CONTRACT_TABS_DRIVER',
      index: 2,
      name: 'driver',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractFormDriverComponent,
      formGroupName: 'driverFormGroup',
    },
    {
      title: 'CONTRACT_TABS_VEHICLE',
      index: 3,
      name: 'vehicle',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractFormVehicleComponent,
      formGroupName: 'vehicleFormGroup',
    },
    {
      title: 'CONTRACT_TABS_INFORMATIONS',
      index: 4,
      name: 'car-insurance',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractFormCarInsuranceComponent,
      formGroupName: 'carInsuranceFormGroup',
    },
    {
      title: 'CONTRACT_TABS_PAYMENT_SPLITTING',
      index: 5,
      name: 'payment-splitting',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractFormPaymentSplittingComponent,
      formGroupName: 'paymentSplittingFormGroup',
    },

    {
      title: 'CONTRACT_TABS_COMMISSIONS',
      index: 6,
      name: 'commission',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractFormCommissionComponent,
      formGroupName: 'commissionFormGroup',
    },
    {
      title: 'CONTRACT_TABS_GENERAL_INFORMATIONS',
      index: 1,
      name: 'general-information',
      categoryString: 'House',
      category: InsuranceCategory.House,
      component: ContractFormGeneralInformationsComponent,
      formGroupName: 'generalInformationFormGroup',
    },
    {
      title: 'CONTRACT_TABS_HOUSE',
      index: 2,
      name: 'house',
      categoryString: 'House',
      category: InsuranceCategory.House,
      component: ContractFormHouseComponent,
      formGroupName: 'houseFormGroup',
    },
    {
      title: 'CONTRACT_TABS_PAYMENT_SPLITTING',
      index: 3,
      name: 'payment-splitting',
      categoryString: 'House',
      category: InsuranceCategory.House,
      component: ContractFormPaymentSplittingComponent,
      formGroupName: 'paymentSplittingFormGroup',
    },

    {
      title: 'CONTRACT_TABS_COMMISSIONS',
      index: 4,
      name: 'commission',
      categoryString: 'House',
      category: InsuranceCategory.House,
      component: ContractFormCommissionComponent,
      formGroupName: 'commissionFormGroup',
    },
  ];
  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private readonly formService: FormService,
  ) {}

  ngOnInit(): void {
    this.errorsDescription$ = this.formService.getErrors();
    this.tabsContent$ = of(
      this.tabsInit.filter((x) => x.categoryString === this.category),
    );
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tabsContent$ = of(
      this.tabsInit.filter(
        (x) => x.categoryString === this.category?.toString(),
      ),
    );
  }

  trackByTabsContent(index: any, item: TabsContent) {
    return item.index;
  }
}
