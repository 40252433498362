<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light">
    <form [formGroup]="changePasswordForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="changePasswordForm">
      <button type="submit" hidden="hidden"></button>
        <div class="card-body">
          <div class="container-fluid">

            <div class="row">
              <div class="col-lg-12 col-12">

                <div class="form-group row">
                  <label class="col col-form-label-sm" for="current" translate>
                    ACCOUNT_LBL_CURRENT_PASSWORD</label>
                  <input
                    class="form-control form-control-sm"
                    id="current"
                    name="current"
                    type="password"
                    value=""
                    formControlName="current"
                    checkFormFieldValidity
                  />

                </div>

                <div class="form-group row">
                  <label class="col col-form-label-sm" for="password" translate>
                    ACCOUNT_LBL_NEW_PASSWORD</label>
                  <input
                    class="form-control form-control-sm"
                    id="password"
                    name="password"
                    type="password"
                    value=""
                    formControlName="password"
                    checkFormFieldValidity
                  />
                </div>

                <div class="form-group row">
                  <label class="col col-form-label-sm" for="passwordConfirm" translate>
                    ACCOUNT_LBL_CONFIRM_PASSWORD</label>
                  <input
                    class="form-control form-control-sm"
                    id="passwordConfirm"
                    name="passwordConfirm"
                    type="password"
                    value=""
                    formControlName="passwordConfirm"
                    checkFormFieldValidity
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
    </form>
  </div>
</div>
