import { Injectable } from "@angular/core";
import { Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { createApiCallEffects } from "src/app/common/store/generic.effects";
import { CountryAction, countryActions } from "./country.actions";
import { CountryApiService } from "./country.api.service";
import { ICountryState } from "./country.reducer";


@Injectable()
export class CountryEffects {
  constructor(
    private readonly store: Store<ICountryState>,
    private readonly countryApiService: CountryApiService,
    private readonly action$: Actions<CountryAction>    
  ) {}
  
  getById = createApiCallEffects(
    this,
    this.action$,
    countryActions.getById,
    this.countryApiService.getById
  );

  postCountry = createApiCallEffects(
    this,
    this.action$,
    countryActions.postCountry,
    this.countryApiService.postCountry
  );

  updateCountry = createApiCallEffects(
    this,
    this.action$,
    countryActions.updateCountry,
    this.countryApiService.updateCountry
  );
}