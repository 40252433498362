import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ZipCodeApiService } from "./zipcode.api.services";
import { ZipCodeEffects } from "./zipcode.effects";
import { IZipCodeState, zipCodeInitialState, zipCodeReducer } from "./zipcode.reducer";
import { ZipCodeService } from "./zipcode.service";
import { zipCodeFeatureKey } from "./types";

@NgModule({
    imports:[
        StoreModule.forFeature<IZipCodeState>(zipCodeFeatureKey, zipCodeReducer,{
            initialState: zipCodeInitialState,
        }),
        EffectsModule.forFeature([ZipCodeEffects])
    ],
    providers:[ZipCodeApiService, ZipCodeService]
})
export class StoreZipCodeModule{}