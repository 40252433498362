import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Config, Environment } from './types';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class ConfigService {
  private configuration: Config = new Config();

  constructor(private readonly deviceDetectorService: DeviceDetectorService) {
    this.init();
  }

  private init = (): void => {
    
    /**
     * Configure environment
     */
    this.configuration.env.name = environment.name.toLowerCase() as Environment;
    this.configuration.env.version = environment.release.number as unknown as string;

    /**
     * Configure device
     */
    this.configuration.deviceInfo = this.deviceDetectorService.getDeviceInfo();

    /**
     * Configure the app features
     */
    this.configuration.features = environment.features;
  };

  public get = (): Config => this.configuration;
}
