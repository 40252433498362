<div class="sub-header">
  <!--  *ngIf="this.subHeaderOption$ | async as subHeaderOption"-->
  <div class="sub-header-row px-1 py-2">
    <div class="row">
      <div class="col">
        <app-breadcrumb></app-breadcrumb>
      </div>

      <div class="col-5 text-right">
        <app-search-bar></app-search-bar>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <app-actions></app-actions>
      </div>

      <ng-container>
        <div class="col text-center">
          <!-- <div class="dropdown d-inline">
            <button
              class="btn btn-sm btn-outline-light dropdown-toggle"
              type="button"
              id="ActionLink"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Actions<span class="caret"></span>
            </button>
            <div class="dropdown-menu" aria-labelledby="ActionLink">
              <button class="dropdown-item" type="button">
                <i class="mdi mdi-plus"></i>
                <span>Ajouter un devis</span>
              </button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item" type="button">
                <i class="mdi mdi-plus"></i>
                <span>Nouvelle demande de prix</span>
              </button>
              <div class="dropdown-divider"></div>
              <button class="dropdown-item text-danger" type="button">
                <i class="mdi mdi-trash-can-outline"></i>
                <span>Supprimer</span>
              </button>
            </div>
          </div> -->
        </div>

        <div class="col-5 d-flex justify-content-between flex-wrap">
          <app-search-filters-dropdown />
          <app-pagination></app-pagination>
          <div>
            <a
              class="btn btn-outline-light btn-xs"
              (click)="triggerAction('TreeView')"
            >
              <i class="mdi mdi-format-list-bulleted-square"></i>
            </a>
            <a
              class="btn btn-outline-light btn-xs"
              (click)="triggerAction('KanbanView')"
            >
              <i class="mdi mdi-view-grid"></i>
            </a>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
