import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HealthInsuranceContractDetailModel } from '../../../../../../shared/store/contract/types';
import { ContractService } from '../../../../../../shared/store/contract/contract.service';

@Component({
  selector: 'app-contract-details-insured-persons',
  templateUrl: './contract-details-insured-persons.component.html',
  styleUrls: ['./contract-details-insured-persons.component.scss'],
})
export class ContractDetailsInsuredPersonsComponent implements OnInit {
  contractDetails$: Observable<HealthInsuranceContractDetailModel[]>;

  constructor(private readonly contractService: ContractService) {}

  ngOnInit(): void {
    this.contractDetails$ = this.contractService.getHealthDetail.value$;
  }
}
