<app-document-subheader></app-document-subheader>
<div class="details-content container-fluid h-100">
  <div class="row h-100">
    <div class="col-content d-flex flex-column h-100">
      <div class="page-container" *ngIf="this.campaignBase$ | async as campaign">
        <div class="card card-light w-100">
          <div class="card-body">
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <div class="form-group">
                    <label class="form-title-label" translate
                    >CAMPAIGN_LBL_NAME</label
                    >
                    <div class="form-title">{{ campaign.name }}</div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-lg-6">
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >CAMPAIGN_LBL_START_DATE</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ campaign.startDate | date : "dd/MM/yyyy" }}
                      </span>
                    </div>
                  </div>
                  <div class="form-group row">
                    <label class="col-4 col-form-label-sm" translate
                    >CAMPAIGN_LBL_END_DATE</label
                    >
                    <div class="col">
                      <span class="form-control-plaintext form-control-sm">
                        {{ campaign.endDate | date : "dd/MM/yyyy"}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-comment" *ngIf="this.campaignBase$ | async as campaign">
      <app-comment-panel
        [entityType]="'Campaign'"
        [entityId]="campaign.id.toString()"
      ></app-comment-panel>
    </div>
  </div>
</div>
