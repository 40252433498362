import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { emailRegExp } from '../../../shared/validators/pattern.validators';
import { AuthenticationService } from '../../../shared/store/authentication/authentication.service';
import { filter, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  host: { class: 'connexion-form p-4 col-12 col-sm-8' },
})
export class ForgotPasswordComponent implements OnInit {
  showErrorLogin: any;
  returnUrl: string;
  css: string = 'alert-danger';
  msgError: string;

  constructor(private readonly authenticationService: AuthenticationService) {}

  forgotPasswordForm: FormGroup = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.pattern(emailRegExp),
    ]),
  });

  ngOnInit(): void {
    this.returnUrl = '/';
  }

  onSubmit() {
    if (this.forgotPasswordForm.invalid) {
      this.forgotPasswordForm.markAllAsTouched();
      return;
    }

    let result$ = this.authenticationService.forgotPassword.call({
      email: this.forgotPasswordForm.value.email!,
    });

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe({
        next: (result) => {
          console.log(result);
          if (result !== undefined) {
            this.css = 'alert-success';
            this.msgError =
              'Consultez votre boite email pour obtenir un lien qui vous permettra de réinitialiser votre mot de passe';
            this.showErrorLogin = true;
            // this.forgotPasswordForm.reset();
          }
        },
        error: () => {
          console.log('n');
          this.css = 'alert-danger';
          this.msgError = 'Un problème est survenu, veuillez réessayer.';
          this.showErrorLogin = true;
        },
      });

    console.log('mail sent');
  }
}
