<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  *ngIf="this.contractDetails$ | async as contractDetails"
>
  <div class="d-flex flex-column h-100">

    <table class="table table-striped border-bottom">
      <thead>
      <tr>
        <th>
          <a translate>CONTRACT_LBL_INSURED_PERSONS_INSURED_TYPE</a>
        </th>
        <th>
          <a translate>CONTRACT_LBL_INSURED_PERSONS_FIRSTNAME</a>
        </th>
        <th>
          <a translate>CONTRACT_LBL_INSURED_PERSONS_LASTNAME</a>
        </th>
        <th>
          <a translate>CONTRACT_LBL_INSURED_PERSONS_BIRTHDATE</a>
        </th>
        <th>
          <a translate>CONTRACT_LBL_INSURED_PERSONS_SEX</a>
        </th>
        <th>
          <a translate>CONTRACT_LBL_INSURED_PERSONS_MARITAL_STATUS</a>
        </th>
        <th>
          <a translate>CONTRACT_LBL_INSURED_PERSONS_SOCIAL_SECURITY_NUMBER</a>
        </th>
        <th>
          <a translate>CONTRACT_LBL_INSURED_PERSONS_REGIME</a>
        </th>
      </tr>
      </thead>

      <tbody>
      <tr
        *ngFor="let person of contractDetails"
        class="row-action"
      >
        <td>{{ person.insuredTypeLoc }}</td>
        <td>{{ person.personFirstName }}</td>
        <td>
          {{ person.personLastName }}
        </td>
        <td>
          {{ person.personBirthDate | date : "dd/MM/yyyy" }}
        </td>
        <td>
          {{ person.personGenderLoc }}
        </td>
        <td>
          {{ person.personMaritalStatusLoc }}
        </td>
        <td>
          {{ person.personSocialSecurityNumber | mask:'0 00 00 00 000 000 00' }}
        </td>
        <td>
          {{ person.personSocialSecurityKeyLog }}
        </td>
      </tr>
      </tbody>

    </table>
  </div>
</div>
