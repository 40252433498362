import { Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';

@UntilDestroy()
@Component({
  selector: 'app-sales-dashboard',
  templateUrl: './sales-dashboard.component.html',
  styleUrls: ['./sales-dashboard.component.scss'],
})
export class SalesDashboardComponent implements OnInit {
  startDate: Date;
  endDate: Date;
  campaignId: number;
  insuranceType: number;
  salesTeamId: number;
  salesTeamMemberId: number;
  userId: number;
  view$: Observable<View>;

  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.startDate = new Date();
    this.startDate.setDate(this.startDate.getDate() - 30);
    this.endDate = new Date();
    this.endDate.setDate(this.endDate.getDate());
    this.campaignId = -1;
    this.insuranceType = -1;
    this.salesTeamId = -1;
    this.salesTeamMemberId = -1;

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x)
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@salesDashboard', x.title);
      });
  }
}
