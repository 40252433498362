import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { CurrencyApiService } from "./currency.api.service";
import { CurrencyEffects } from "./currency.effects";
import { ICurrencyState, currencyInitialState, currencyReducer } from "./currency.reducer";
import { CurrencyService } from "./currency.service";
import { currencyFeatureKey } from "./types";

@NgModule({
    imports:[
        StoreModule.forFeature<ICurrencyState>(currencyFeatureKey, currencyReducer,{
            initialState: currencyInitialState,
        }),
        EffectsModule.forFeature([CurrencyEffects])
    ],
    providers:[CurrencyApiService, CurrencyService]
})
export class StoreCurrencyModule{}