import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, Observable, of } from 'rxjs';
import { Entity, View } from 'src/app/shared/store/view/types';
import {
  DataType,
  DropdownStyle,
  SourceEnum,
} from '../../../../../shared/store/typeahead/types';
import { ContactService } from '../../../../../shared/store/contact/contact.service';
import { Router } from '@angular/router';
import { SubHeaderService } from '../../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../../shared/store/view/views.service';
import { ModalService } from '../../../../../shared/service/modal.service';
import { FormService } from '../../../../../shared/store/form/form.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { take } from 'rxjs/operators';
import { ErrorsNestedFormGroup } from '../../../../../shared/store/form/types';
import { CreateContactFormModel } from '../../../../../shared/store/contact/types';

@UntilDestroy()
@Component({
  selector: 'app-contact-create-content',
  templateUrl: './contact-create-content.component.html',
  styleUrls: ['./contact-create-content.component.scss'],
})
export class ContactCreateContentComponent implements OnInit, OnDestroy {
  view$: Observable<View>;
  contactForm: FormGroup;
  errorsDescription$: Observable<ErrorsNestedFormGroup> = of();
  createContactFormModel$: Observable<CreateContactFormModel>;

  @Input() modalName: string;
  @Input() source: SourceEnum = SourceEnum.Component;
  @Output() modalEvent = new EventEmitter<number>();

  protected readonly DropdownStyle = DropdownStyle;
  protected readonly Entity = Entity;
  protected readonly DataType = DataType;
  protected readonly SourceEnum = SourceEnum;

  constructor(
    private readonly contactService: ContactService,
    private readonly router: Router,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly dynamicService: ModalService,
    private readonly formService: FormService,
    private readonly formBuilder: FormBuilder,
  ) {
    this.view$ = this.viewService.getViews.value$;
    this.createContactFormModel$ =
      this.contactService.getContactFormModel.value$;
  }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      isCompany: [false],
      company: [undefined],
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      addressType: [0, [Validators.required]],
      address: ['', [Validators.required]],
      address2: [''],
      zipCode: [
        '',
        [Validators.required, Validators.minLength(5), Validators.maxLength(5)],
      ],
      city: [undefined, [Validators.required]],
      countryId: [undefined, [Validators.required]],
      phoneNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      job: [''],
      salesTeamId: [undefined, [Validators.required]],
      salesPersonId: [undefined, [Validators.required]],
      empty: [''],
    });

    if (this.modalName) {
      let name = this.modalName.split(' ');
      this.contactForm.get('lastname')?.setValue(name[0]);
      this.contactForm.get('firstname')?.setValue(name.slice(1).join(' '));
    }

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'contactCreate') this.onSubmit();
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@contactList', x.title);
        this.breadcrumbService.set('@contactCreate', x.breadcrumbCreate);
      });

    this.contactService.getContactFormModel.call();
    this.createContactFormModel$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        this.contactForm?.patchValue({
          addressType: x.addressType,
          countryId: x.countryId,
          salesPersonId: x.salesPersonId,
          marketingFormGroup: {
            marketingSource: x.source,
          },
        });
      });
  }

  onSubmit() {
    if (this.contactForm.invalid) {
      this.contactForm.markAllAsTouched();
      this.formService.countErrors(this.contactForm, true);
      return;
    }

    console.log(this.contactForm);

    this.formService.clear();

    let result$ = this.contactService.postContact.call({
      firstName: this.contactForm.value.firstname,
      lastName: this.contactForm.value.lastname,
      address: this.contactForm.value.address,
      zipCode: this.contactForm.value.zipCode,
      city: this.contactForm.value.city,
      email: this.contactForm.value.email,
      phoneNumber: this.contactForm.value.phoneNumber,
      addressType: this.contactForm.value.addressType,
      campaignId: this.contactForm.value.marketingFormGroup.marketingCampaignId,
      medium: this.contactForm.value.marketingFormGroup.marketingMedium,
      source: this.contactForm.value.marketingFormGroup.marketingSource,
      countryId: this.contactForm.value.countryId,
      isCompany: this.contactForm.value.isCompany,
      notes: this.contactForm.value.notesFormGroup.notes,
      profession: this.contactForm.value.job,
      salesPersonId: this.contactForm.value.salesPersonId,
      salesTeamId: this.contactForm.value.salesTeamId,
      name: '',
      title: '',
      gender: undefined,
      parentId: undefined,
      address2: '',
    });

    result$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((result) => {
        switch (this.source) {
          case SourceEnum.Component:
            this.router.navigate(['/Contacts/Contacts/Details', result.id]);
            break;
          case SourceEnum.Modal:
            this.dynamicService.outputFromDynamicComponent(
              result.id.toString(),
            );
            break;
          default:
            this.router.navigate(['/Contacts/Contacts/Details', result.id]);
            break;
        }
      });
  }

  ngOnDestroy(): void {
    this.contactService.postContact.reset();
  }
}
