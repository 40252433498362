import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { GlossaryApiService } from './glossary.api.services';
import { GlossaryEffects } from './glossary.effects';
import {
  IGlossaryState,
  glossaryReducer,
  glossaryInitialState,
} from './glossary.reducer';
import { GlossaryService } from './glossary.service';
import { glossaryFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IGlossaryState>(
      glossaryFeatureKey,
      glossaryReducer,
      {
        initialState: glossaryInitialState,
      }
    ),
    EffectsModule.forFeature([GlossaryEffects]),
  ],
  providers: [GlossaryApiService, GlossaryService],
})
export class StoreGlossaryModule {}
