export const commentFeatureKey = 'comment';
export type Comment = {
    id: number;
    parentId: number | null;
    message: string;
    creationDate: Date | null;
    localeCreationDate: Date | null;
    userId: number | null;
    userContactName: string;
    entityType: string;
    entityId: number;
}

export class CommentPost {
    constructor(
        parentId: number | null,
        message: string,
        userId: number | null,
        entityType: string,
        entityId: number,
    ) {
        this.parentId = parentId;
        this.entityId = entityId;
        this.entityType = entityType;
        this.message = message;
        this.userId = userId;
    }

    parentId: number | null;
    message: string;
    userId: number | null;
    entityType: string;
    entityId: number;
}

export type InputComment = {
    entityType: string;
    entityId: number;
}
