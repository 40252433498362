import { Pipe, type PipeTransform } from '@angular/core';
import { Filter } from '../../store/search/types';

@Pipe({
  name: 'isBuiltInActive',
})
export class IsBuiltInActivePipe implements PipeTransform {
  transform(activeFilters: Filter[], builtInFilter: Filter): boolean {
    let isActive: boolean = false;
    isActive =
      activeFilters.find((f) => f.name === builtInFilter.name) != undefined;
    return isActive;
  }
}
