import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';

@Component({
  selector: 'app-contract-form-commission',
  templateUrl: './contract-form-commission.component.html',
  styleUrls: ['./contract-form-commission.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ContractFormCommissionComponent implements OnInit {
  form: FormGroup<any>;
  constructor(
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this.parent.form;
    this.form.addControl('commissionFormGroup', this.formBuilder.group({}));
  }
}
