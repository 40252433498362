import { createApiCallActions } from '../../../common/store/generic.actions';
import { Campaign, CampaignSimplified, CreateCampaignFormModel } from './types';

export const campaignActions = {
  getById: createApiCallActions<Campaign>('campaign', 'getById'),
  getList: createApiCallActions<CampaignSimplified[]>('campaign', 'getList'),
  getCampaignModel: createApiCallActions<CreateCampaignFormModel>(
    'campaign',
    'getCampaignModel',
  ),
  postCampaign: createApiCallActions<Campaign>('campaign', 'postCampaign'),
  updateCampaign: createApiCallActions<Campaign>('campaign', 'updateCampaign'),
};

export type CampaignAction = typeof campaignActions;
