import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ContactRoutingModule } from './contact-routing.module';
import { ContactListComponent } from './contact-list/contact-list.component';
import { SharedModule } from '../../../shared/shared.module';
import { ContactDetailsComponent } from './contact-details/contact-details.component';
import { ContactDetailsContentComponent } from './contact-details/contact-details-content/contact-details-content.component';
import { ContactCreateComponent } from './contact-create/contact-create.component';
import { ContactCreateContentComponent } from './contact-create/contact-create-content/contact-create-content.component';
import { ContactTabsFormComponent } from './contact-form/contact-tabs-form/contact-tabs-form.component';
import { ContactFormNotesComponent } from './contact-form/contact-form-tabs/contact-form-notes/contact-form-notes.component';
import { ContactFormMarketingComponent } from './contact-form/contact-form-tabs/contact-form-marketing/contact-form-marketing.component';
import { ContactTabsDetailsComponent } from './contact-details/contact-tabs-details/contact-tabs-details.component';
import { ContactDetailsMarketingComponent } from './contact-details/contact-details-tab/contact-details-marketing/contact-details-marketing.component';
import { ContactDetailsNotesComponent } from './contact-details/contact-details-tab/contact-details-notes/contact-details-notes.component';
import { ContactEditComponent } from './contact-edit/contact-edit.component';

@NgModule({
  declarations: [
    ContactListComponent,
    ContactDetailsComponent,
    ContactDetailsContentComponent,
    ContactCreateComponent,
    ContactCreateContentComponent,
    ContactTabsFormComponent,
    ContactFormNotesComponent,
    ContactFormMarketingComponent,
    ContactTabsDetailsComponent,
    ContactDetailsMarketingComponent,
    ContactDetailsNotesComponent,
    ContactEditComponent,
  ],
  imports: [CommonModule, ContactRoutingModule, SharedModule],
})
export class ContactModule {}
