import { Component, OnInit } from '@angular/core';
import { combineLatest, debounceTime, filter, Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PaginationService } from '../../../../shared/store/pagination/pagination.service';
import { Domain, Filter } from '../../../../shared/store/search/types';
import { PageResult } from '../../../../shared/types';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { SearchService } from 'src/app/shared/store/search/search.service';

@UntilDestroy()
@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.scss'],
})
export class ContactListComponent implements OnInit {
  view$: Observable<View>;

  viewType: string;

  constructor(
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
    private readonly subHeaderService: SubHeaderService,
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.subHeaderService
      .getTriggerAction()
      .pipe(
        filter((x) => !!x),
        untilDestroyed(this),
      )
      .subscribe((x) => {
        if (x == 'TreeView') {
          this.viewType = x;
        }
        if (x == 'KanbanView') {
          this.viewType = x;
        }
        this.subHeaderService.setTriggerAction('');
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@contactList', x.title);
        this.viewType = x.views;
      });
  }
}
