import { Component, OnInit } from '@angular/core';
import {UntilDestroy, untilDestroyed} from "@ngneat/until-destroy";
import {filter, Observable} from "rxjs";
import {BreadcrumbService} from "xng-breadcrumb";
import {ViewService} from "../../../../shared/store/view/views.service";
import {View} from "../../../../shared/store/view/types";

@UntilDestroy()
@Component({
  selector: 'app-currency-list',
  templateUrl: './currency-list.component.html',
  styleUrls: ['./currency-list.component.scss'],
})
export class CurrencyListComponent implements OnInit {
  view$: Observable<View>;
  constructor(private readonly breadcrumbService:BreadcrumbService,
              private readonly viewService:ViewService
  ) {
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {

    this.view$.pipe(
      untilDestroyed(this),
      filter((x) => !!x)
    ).subscribe((x) => this.breadcrumbService.set('@currencyList', x.title));

  }
}
