import { Component, OnInit } from "@angular/core";

@Component({
  selector: "offline-error",
  templateUrl: "./offline-error.html",
  styleUrls: ["./offline-error.scss"],
})
export class OfflineErrorComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {}
}
