import { createFeatureSelector } from '@ngrx/store';
import { ISalesTeamState } from './sales-team.reducer';
import { salesTeamFeatureKey, SalesTeamPost, SalesTeamUpdate } from './types';
import { createApiCallSelectors } from '../../../common/store/generic.selectors';
import { IJobState } from '../job/job.reducer';

const salesTeamFeatureState =
  createFeatureSelector<ISalesTeamState>(salesTeamFeatureKey);

export const salesTeamSelectors = {
  getById: createApiCallSelectors<number, ISalesTeamState>(
    salesTeamFeatureState,
    'getById',
  ),
  getList: createApiCallSelectors<void, ISalesTeamState>(
    salesTeamFeatureState,
    'getList',
  ),
  getSalesTeamMemberList: createApiCallSelectors<number, ISalesTeamState>(
    salesTeamFeatureState,
    'getSalesTeamMemberList',
  ),
  getSalesTeamTypeAheadList: createApiCallSelectors<void, ISalesTeamState>(
    salesTeamFeatureState,
    'getSalesTeamTypeAheadList',
  ),
  getSalesTeamMemberTypeAheadList: createApiCallSelectors<
    number,
    ISalesTeamState
  >(salesTeamFeatureState, 'getSalesTeamMemberTypeAheadList'),
  getSalesTeamModel: createApiCallSelectors<void, ISalesTeamState>(
    salesTeamFeatureState,
    'getSalesTeamModel',
  ),
  postSalesTeam: createApiCallSelectors<SalesTeamPost, ISalesTeamState>(
    salesTeamFeatureState,
    'postSalesTeam',
  ),
  updateSalesTeam: createApiCallSelectors<SalesTeamUpdate, ISalesTeamState>(
    salesTeamFeatureState,
    'updateSalesTeam',
  ),
};
