import { NgModule } from "@angular/core";
import { EffectsModule } from "@ngrx/effects";
import { StoreModule } from "@ngrx/store";
import { ContractApiService } from "./contract.api.services";
import { ContractEffects } from "./contract.effects";
import { IContractState, contractInitialState, contractReducer } from "./contract.reducer";
import { ContractService } from "./contract.service";
import { contractFeatureKey } from "./types";

@NgModule({
    imports:[
        StoreModule.forFeature<IContractState>(contractFeatureKey, contractReducer,{
            initialState: contractInitialState,
        }),
        EffectsModule.forFeature([ContractEffects])
    ],
    providers:[ContractApiService, ContractService]
})
export class StoreContractModule{}