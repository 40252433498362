import {Action, ActionReducer, createReducer} from "@ngrx/store";
import {Employee} from "./types";
import {GenericState, IState} from "../../../common/store/types";
import {onApiCall} from "../../../common/store/generic.reducer";
import {employeeActions} from "./employee.action";

export interface IEmployeeState extends IState<Employee> {
  getById: GenericState<Employee>;
}

export const employeeInitialState: IEmployeeState= {
  getById: new GenericState<Employee>()
};

const employeeReducers = [
  ...onApiCall<Employee>(employeeActions.getById, 'getById')
]

export const employeeReducer = createReducer(
  employeeInitialState,
  ...employeeReducers
)as ActionReducer<IEmployeeState, Action>;

export function useReducerFunc(state:any | undefined, action:Action ) {
  return employeeReducer(state, action);
}
