import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { ContractAction, contractActions } from './contract.actions';
import { ContractApiService } from './contract.api.services';
import { IContractState } from './contract.reducer';

@Injectable()
export class ContractEffects {
  constructor(
    private readonly store: Store<IContractState>,
    private readonly contractApiService: ContractApiService,
    private readonly actions$: Actions<ContractAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getById,
    this.contractApiService.getById,
  );

  getByUniqueId = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getByUniqueId,
    this.contractApiService.getByUniqueId,
  );

  getByLeadId = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getByLeadId,
    this.contractApiService.getByLeadId,
  );

  getHealthDetail = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getHealthDetail,
    this.contractApiService.getHealthDetail,
  );

  getCarDetail = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getCarDetail,
    this.contractApiService.getCarDetail,
  );

  getHouseDetail = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getHouseDetail,
    this.contractApiService.getHouseDetail,
  );

  getStatusFlow = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getStatusFlow,
    this.contractApiService.getStatusFlow,
  );

  getContractModel = createApiCallEffects(
    this,
    this.actions$,
    contractActions.getContractFormModel,
    this.contractApiService.getContractModel,
  );

  createHealthContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.createHealthContract,
    this.contractApiService.createHealthContract,
  );
  createCarContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.createCarContract,
    this.contractApiService.createCarContract,
  );
  createHouseContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.createHouseContract,
    this.contractApiService.createHouseContract,
  );

  updateHealthContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.updateHealthContract,
    this.contractApiService.updateHealthContract,
  );
  updateCarContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.updateCarContract,
    this.contractApiService.updateCarContract,
  );
  updateHouseContract = createApiCallEffects(
    this,
    this.actions$,
    contractActions.updateHouseContract,
    this.contractApiService.updateHouseContract,
  );
}
