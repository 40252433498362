import {DatePipe, registerLocaleData} from "@angular/common";
import localeFr from '@angular/common/locales/fr';


  export function formatDate(date: Date, type:'ToShortTimeString' | 'ToLongDay' | 'ToSqlDate' | 'ToInverseSqlDate' | 'Autre'): string {

    registerLocaleData(localeFr, 'fr-FR');
    const locale:string = 'fr-FR';

    let result: string | null;

    switch (type) {
      case 'ToShortTimeString':
        result = new DatePipe(locale).transform(date, 'HH:mm');
        break;
      case 'ToSqlDate':
        result = new DatePipe(locale).transform(date, 'dd-MM-yyyy');
        break;
      case 'ToInverseSqlDate':
        result = new DatePipe(locale).transform(date, 'yyyy-MM-dd');
        break;
      case 'ToLongDay':
        result = new DatePipe(locale).transform(date, 'EEEE');
        break;
      default:
        result = '';
        break;
    }

    return result === null ? '' : result;
  }

//https://stackoverflow.com/questions/542938/how-to-calculate-number-of-days-between-two-dates
export function dateDiff( str1, str2 ) {
  var diff = Date.parse( str2 ) - Date.parse( str1 );
  return isNaN( diff ) ? {
    diff : 0,
    millisec : 0,
    sec  : 0,
    minute  : 0,
    hour  : 0,
    day  : 0
  } : {
    diff : diff,
    millisec : Math.round( diff            % 1000 ),
    sec  : Math.round( diff /     1000 %   60 ),
    minute  : Math.round( diff /    60000 %   60 ),
    hour  : Math.round( diff /  3600000 %   24 ),
    day  : Math.round( diff / 86400000        )
  };
}
