import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { ILeadState } from './lead.reducer';
import {
  BaseLeadFormModel,
  CarInsuranceLeadModel,
  CarLeadFormModel,
  HealthInsuranceLeadModel,
  HealthLeadFormModel,
  HouseInsuranceLeadModel,
  HouseLeadFormModel,
  LeadDetailModel,
  LeadModel,
  leadFeatureKey,
} from './types';

const leadFeatureState = createFeatureSelector<ILeadState>(leadFeatureKey);

export const leadSelectors = {
  getByUniqueId: createApiCallSelectors<LeadDetailModel, ILeadState>(
    leadFeatureState,
    'getByUniqueId'
  ),
  getHealthDetail: createApiCallSelectors<HealthInsuranceLeadModel, ILeadState>(
    leadFeatureState,
    'getHealthDetail'
  ),
  getCarDetail: createApiCallSelectors<CarInsuranceLeadModel, ILeadState>(
    leadFeatureState,
    'getCarDetail'
  ),
  getHouseDetail: createApiCallSelectors<HouseInsuranceLeadModel, ILeadState>(
    leadFeatureState,
    'getHouseDetail'
  ),
  sendLead: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'sendLead'
  ),
  updateHealthDetail: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'updateHealthDetail'
  ),
  updateCarDetail: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'updateCarDetail'
  ),
  updateHouseDetail: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'updateHouseDetail'
  ),
  createHealthLead: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'createHealthLead'
  ),
  createCarLead: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'createCarLead'
  ),
  createHouseLead: createApiCallSelectors<any, ILeadState>(
    leadFeatureState,
    'createHouseLead'
  ),
  getBaseLeadModel: createApiCallSelectors<BaseLeadFormModel, ILeadState>(
    leadFeatureState,
    'getBaseLeadModel'
  ),
  getHealthLeadModel: createApiCallSelectors<HealthLeadFormModel, ILeadState>(
    leadFeatureState,
    'getHealthLeadModel'
  ),
  getCarLeadModel: createApiCallSelectors<HouseLeadFormModel, ILeadState>(
    leadFeatureState,
    'getCarLeadModel'
  ),
  getHouseLeadModel: createApiCallSelectors<CarLeadFormModel, ILeadState>(
    leadFeatureState,
    'getHouseLeadModel'
  ),
};
