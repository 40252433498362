<h2 class="mb-4">Changer mot de passe</h2>
<input type="hidden" name="returnUrl" value="@ViewBag.ReturnUrl"/>
<form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
  <div
    *ngIf="this.showErrorLogin"
    class="alert {{this.css}}"
    id="validationSummary"
  >
    <ul>
      <li>{{this.msgError}}</li>
    </ul>
  </div>
  <div *ngIf="resetPasswordForm.errors?.['PasswordNoMatch']"  class="alert {{this.css}}">
    <ul>
    <li translate>LOGIN_LBL_RESET_PASSWORD_ERRORS_MATCH</li>
    </ul>
  </div>
  <div class="form-group">
    <div class="d-flex align-items-baseline">
      <div class="flex-fill">
        <label for="password" class="form-label" translate
        >LOGIN_LBL_RESET_PASSWORD_PASSWORD</label
        >
      </div>
    </div>
    <input
      id="password"
      type="password"
      formControlName="password"
      class="form-control form-control-lg"
      required
      checkFormFieldValidity
    />
  </div>
  <div class="form-group">
    <div class="d-flex align-items-baseline">
      <div class="flex-fill">
        <label for="password" class="form-label" translate
        >LOGIN_LBL_RESET_PASSWORD_PASSWORD_CONFIRM</label
        >
      </div>
    </div>
    <input
      id="passwordConfirm"
      type="password"
      formControlName="passwordConfirm"
      class="form-control form-control-lg"
      required
      checkFormFieldValidity
    />
  </div>
  <div class="text-right">
    <button
      [routerLink]="'/'"
      class="btn-outline-primary btn"
      translate
    >
      LOGIN_BTN_RESET_PASSWORD_HOME
    </button>
<!--    [disabled]="resetPasswordForm.invalid"-->
    <button type="submit" class="btn btn-primary btn-lg" translate >
      LOGIN_BTN_RESET_PASSWORD_REINIT
    </button>
  </div>
</form>
