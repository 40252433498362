<div class="input-group search-bar" *ngIf="this.view$ | async as view">
  <app-search-bar-badge />
  <div class="search-bar-input">
    <input
      #Input
      class="form-control form-control-sm search-bar-form-control"
      placeholder="{{ view.searchView.inputPlaceholder }}"
      [ngbTypeahead]="fetchItems"
      [resultTemplate]="resultFormatter"
      (selectItem)="selectedItem($event)"
    />
    <span class="input-group-btn search-input">
      <button
        type="submit"
        class="btn btn-outline-light btn-xs"
        title="Rechercher"
      >
        <i class="mdi mdi-magnify"></i>
      </button>
    </span>
  </div>
</div>

<ng-template #resultFormatter let-r="result">
  <div>{{ r.fieldLabel }} {{ r.operator.label }} {{ r.text }}</div>
</ng-template>
