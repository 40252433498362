import { Injectable } from '@angular/core';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthenticationService } from '../store/authentication/authentication.service';
import { LoginModel, UserConnected } from '../store/authentication/types';
import {ErrorService} from "../error";

@Injectable()
export class AuthentificationService {
  constructor(
    private readonly oauthService: OAuthService,
    private readonly authenticationService: AuthenticationService,
    private readonly errorService:ErrorService
  ) {}

  ngOnInit(): void {}

  public loginwithcredentials(loginModel: LoginModel): any {
    this.oauthService.oidc = false;
    return this.oauthService
      .fetchTokenUsingPasswordFlow(loginModel.mail, loginModel.password)
      .then((resp) => {
        // Loading data about the user
        let claims = this.oauthService.getIdentityClaims();
        return this.oauthService.loadUserProfile();
      })
      .catch((error) => {
        throw this.errorService.serverErrorRedirect(error);
      });
  }

  public getUserProfile(): any {
    this.oauthService.oidc = false;
    return this.oauthService.loadUserProfile();
  }

  public logout(): any {
    return this.oauthService.logOut();
  }

  public isAuthenticated = (): boolean => {
    var isAuthenticated = this.oauthService.hasValidAccessToken();
    if (isAuthenticated) {
      let claims = this.oauthService.getIdentityClaims();
      var user: UserConnected = {
        username: claims['username'],
        email: claims['email'],
      };
      this.authenticationService.setUserConnected(user);
    }
    return isAuthenticated;
  };
}
