import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'groupBy',
})
export class GroupByPipe implements PipeTransform {
  transform(value: Array<any>, field: string): Array<any> {
    if (!value) {
      return [];
    }

    const groupedObj = value.reduce((previousVal, currentVal) => {
      if (!previousVal[currentVal[field]]) {
        previousVal[currentVal[field]] = [currentVal];
      } else {
        previousVal[currentVal[field]].push(currentVal);
      }
      return previousVal;
    }, {});

    // Convert the grouped object into an array of objects
    return Object.keys(groupedObj).map((key) => ({
      key,
      value: groupedObj[key],
    }));
  }
}
