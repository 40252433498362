import { GenericState } from 'src/app/common/store/types';
import { LabelWithValue, Period } from './types';
import { onApiCall } from 'src/app/common/store/generic.reducer';
import { reportingActions } from './reporting.actions';
import { createReducer, ActionReducer, Action, on } from '@ngrx/store';

export interface IReportingState {
  getReportByFilter: GenericState<any[]>;
  getReportLeadsMonthlyRange: GenericState<any[]>;
  getReportFemalePeopleByAge: GenericState<any[]>;
  getReportMalePeopleByAge: GenericState<any[]>;
  getReportPeopleByRegime: GenericState<any[]>;
  getLeadsByDayAndHoursRange: GenericState<any[]>;
  getReportLeadsByType: GenericState<any[]>;
  getReportLeadsTopCities: GenericState<any[]>;
  getReportLeadsSum: GenericState<number>;
  getReportContractsSum: GenericState<number>;
  getReportAnnualFeesSum: GenericState<number>;
  getReportMonthlyFeesAverage: GenericState<number>;
  getPeriods: GenericState<Period[]>;
  barGraphStartDate: string;
  barGraphEndDate: string;
}

export const reportingInitialState: IReportingState = {
  getReportByFilter: new GenericState<any[]>(),
  getReportLeadsMonthlyRange: new GenericState<any[]>(),
  getReportFemalePeopleByAge: new GenericState<any[]>(),
  getReportMalePeopleByAge: new GenericState<any[]>(),
  getReportPeopleByRegime: new GenericState<any[]>(),
  getLeadsByDayAndHoursRange: new GenericState<any[]>(),
  getReportLeadsByType: new GenericState<any[]>(),
  getReportLeadsTopCities: new GenericState<any[]>(),
  getReportLeadsSum: new GenericState<number>(),
  getReportContractsSum: new GenericState<number>(),
  getReportAnnualFeesSum: new GenericState<number>(),
  getReportMonthlyFeesAverage: new GenericState<number>(),
  getPeriods: new GenericState<Period[]>(),
  barGraphStartDate: '',
  barGraphEndDate: '',
};

const reportingReducers = [
  ...onApiCall<any[]>(reportingActions.getReportByFilter, 'getReportByFilter'),
  ...onApiCall<any[]>(
    reportingActions.getReportLeadsMonthlyRange,
    'getReportLeadsMonthlyRange'
  ),
  ...onApiCall<any[]>(
    reportingActions.getReportFemalePeopleByAge,
    'getReportFemalePeopleByAge'
  ),
  ...onApiCall<any[]>(
    reportingActions.getReportMalePeopleByAge,
    'getReportMalePeopleByAge'
  ),
  ...onApiCall<any[]>(
    reportingActions.getReportPeopleByRegime,
    'getReportPeopleByRegime'
  ),
  ...onApiCall<any[]>(
    reportingActions.getLeadsByDayAndHoursRange,
    'getLeadsByDayAndHoursRange'
  ),
  ...onApiCall<any[]>(
    reportingActions.getReportLeadsByType,
    'getReportLeadsByType'
  ),
  ...onApiCall<any[]>(
    reportingActions.getReportLeadsByCity,
    'getReportLeadsByCity'
  ),
  ...onApiCall<number>(reportingActions.getReportLeadsSum, 'getReportLeadsSum'),
  ...onApiCall<number>(
    reportingActions.getReportContractsSum,
    'getReportContractsSum'
  ),
  ...onApiCall<number>(
    reportingActions.getReportMonthlyFeesAverage,
    'getReportMonthlyFeesAverage'
  ),
  ...onApiCall<number>(
    reportingActions.getReportAnnualFeesSum,
    'getReportAnnualFeesSum'
  ),
  ...onApiCall<Period[]>(reportingActions.getPeriods, 'getPeriods'),
  on(
    reportingActions.setBarGraphStartDate,
    (state: IReportingState, { barGraphStartDate }) => ({
      ...state,
      barGraphStartDate: barGraphStartDate,
    })
  ),
  on(
    reportingActions.setBarGraphEndDate,
    (state: IReportingState, { barGraphEndDate }) => ({
      ...state,
      barGraphEndDate: barGraphEndDate,
    })
  ),
];

export const reportingReducer = createReducer(
  reportingInitialState,
  ...reportingReducers
) as ActionReducer<IReportingState, Action>;

export function useReducerFunc(state: any | undefined, action: Action) {
  return reportingReducer(state, action);
}
