import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CampaignRoutingModule } from './campaign-routing.module';
import { CampaignListComponent } from './campaign-list/campaign-list.component';
import { SharedModule } from '../../../shared/shared.module';
import { CampaignEditComponent } from './campaign-edit/campaign-edit.component';
import { CampaignCreateComponent } from './campaign-create/campaign-create.component';
import { CampaignDetailsComponent } from './campaign-details/campaign-details.component';

@NgModule({
  declarations: [
    CampaignListComponent,
    CampaignDetailsComponent,
    CampaignEditComponent,
    CampaignCreateComponent,
  ],
  imports: [CommonModule, SharedModule, CampaignRoutingModule],
})
export class CampaignModule {}
