import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SalesTeamMemberApiService } from './sales-team-member.api.service';
import { SalesTeamMemberEffects } from './sales-team-member.effects';
import {
  ISalesTeamMemberState,
  salesTeamMemberReducer,
  salesTeamMemberInitialState,
} from './sales-team-member.reducer';
import { SalesTeamMemberService } from './sales-team-member.service';
import { salesTeamMemberFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<ISalesTeamMemberState>(
      salesTeamMemberFeatureKey,
      salesTeamMemberReducer,
      {
        initialState: salesTeamMemberInitialState,
      }
    ),
    EffectsModule.forFeature([SalesTeamMemberEffects]),
  ],
  providers: [SalesTeamMemberApiService, SalesTeamMemberService],
})
export class StoreSalesTeamMemberModule {}
