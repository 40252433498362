export const authenticationFeatureKey = 'authentication';

//new object model
export type UserConnected = {
  username: string;
  email: string;
};

export class LoginModel {
  mail: string;
  password: string;
}

export class ForgotPasswordModel {
  email: string;
}

export class ResetPasswordModel {
  email: string;
  password: string;
  token: string;
}
export class ChangePasswordModel {
  email: string;
  currentPassword: string;
  newPassword: string;
}
