import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { AppState } from '../types';
import { BankAccount } from './types';
import { bankAccountActions } from './bank-account.actions';
import { bankAccountSelectors } from './bank-account.selectors';

@Injectable()
export class BankAccountService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<BankAccount, string> = this.genericApiCall(
    bankAccountActions.getById,
    bankAccountSelectors.getById,
    true
  );
}
