<div class="d-flex align-items-center justify-content-center h-100">
  <div class="error-message text-center">
    <div class="i-circle text-danger"><i class="mdi mdi-account-cancel"></i></div>
    <h2 class="text-danger" translate>ERROR_UNAUTHORIZED_MESSAGE</h2>
    <div>
      <button class="btn btn-outline-dark mr-3" onClick="history.go(-1)">
        <i class="mdi mdi-arrow-left mr-1"></i>
        <span translate>ERROR_UNAUTHORIZED_RELOAD</span>
      </button>
    </div>
  </div>
</div>