import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, filter } from 'rxjs';
import { FavoriteSaveComponent } from '../favorite-save/favorite-save.component';
import { SearchCustomFilterComponent } from '../search-custom-filter/search-custom-filter.component';
import { View } from '../../store/view/types';
import { ViewService } from '../../store/view/views.service';
import { Domain, Filter } from '../../store/search/types';
import { SearchService } from '../../store/search/search.service';

@Component({
  selector: 'app-search-filters-dropdown',
  templateUrl: './search-filters-dropdown.component.html',
  styleUrls: ['./search-filters-dropdown.component.scss'],
})
export class SearchFiltersDropdownComponent implements OnInit {
  //the view from the webapi
  view$: Observable<View>;
  //the active filters from store
  activeFilters$: Observable<Filter[]>;
  //the custom filters from store
  customFilters$: Observable<Filter[]>;
  //the domains we get from the child component for custom filter
  domains: Domain[];
  //The list of every custom filter component created
  @ViewChildren(SearchCustomFilterComponent)
  customComponentChildren: QueryList<SearchCustomFilterComponent>;

  constructor(
    private readonly searchService: SearchService,
    private readonly viewService: ViewService,
    private readonly modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.view$ = this.viewService.getViews.value$;
    this.customFilters$ = this.searchService.getCustomFilters();
    this.activeFilters$ = this.searchService.getActiveFilters();
    //Instantiate domain list
    this.domains = [];
  }

  //add a custom filter in the list
  addCustomFilter() {
    this.domains.push({
      field: '',
      fieldLabel: '',
      operator: { label: '', id: '', numberOfValues: 1 },
      value: '',
      value2: '',
      connector: 'Or',
      text: '',
      text2: '',
      isActive: false,
    });
  }

  //remove the custom filter at index
  removeCustomFilter(index: number) {
    //remove the selected domain from the array
    this.domains.splice(index, 1);
  }

  //apply the custom filters
  applyCustomFilter() {
    //fetch the domains from the child custom filter component
    this.customComponentChildren.toArray().forEach((item) => {
      this.domains[this.customComponentChildren.toArray().indexOf(item)] =
        item.domain;
    });
    this.searchService.applyCustomFilter(this.domains);
    //empty the domain array
    this.domains = [];
  }

  //change the domain state from custom filter
  changeDomainState(filterIndex: number, domainIndex: number) {
    this.searchService.changeDomainState(filterIndex, domainIndex);
  }

  //toggle the built in filter
  toggleBuiltInFilter(filter: Filter) {
    this.searchService.toggleBuiltInFilter(filter);
  }

  //save favorite view
  saveFavorite() {
    this.modalService.open(FavoriteSaveComponent);
  }
}
