import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import {
  paginationReducer,
  paginationInitialState,
  IPaginationState,
} from './pagination.reducer';
import { PaginationService } from './pagination.service';
import { paginationFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IPaginationState>(
      paginationFeatureKey,
      paginationReducer,
      {
        initialState: paginationInitialState,
      }
    ),
  ],
  providers: [PaginationService],
})
export class StorePaginationdModule {}
