import { Injectable } from '@angular/core';
import { SalesTeamApiService } from './sales-team.api.service';
import { SalesTeamAction, salesTeamActions } from './sales-team.action';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';

@Injectable()
export class SalesTeamEffects {
  constructor(
    private readonly salesTeamApiService: SalesTeamApiService,
    private readonly action$: Actions<SalesTeamAction>,
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.getById,
    this.salesTeamApiService.getById,
  );

  getList = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.getList,
    this.salesTeamApiService.getList,
  );

  getSalesTeamMemberList = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.getSalesTeamMemberList,
    this.salesTeamApiService.getSalesTeamMemberList,
  );

  getSalesTeamTypeAheadList = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.getSalesTeamTypeAheadList,
    this.salesTeamApiService.getSalesTeamTypeAheadList,
  );

  getSalesTeamMemberTypeAheadList = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.getSalesTeamMemberTypeAheadList,
    this.salesTeamApiService.getSalesTeamMemberTypeAheadList,
  );

  getSalesTeamModel = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.getSalesTeamModel,
    this.salesTeamApiService.getSalesTeamModel,
  );

  postSalesTeam = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.postSalesTeam,
    this.salesTeamApiService.postSalesTeam,
  );

  updateSalesTeam = createApiCallEffects(
    this,
    this.action$,
    salesTeamActions.updateSalesTeam,
    this.salesTeamApiService.updateSalesTeam,
  );
}
