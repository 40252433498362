import { createAction, props } from "@ngrx/store";
import { UserConnected } from "./types";
import {createApiCallActions} from "../../../common/store/generic.actions";

export const authenticationActions = {
    setAuthenticatedUser: createAction('setAuthenticatedUser'),
    setUserConnected: createAction(
        '[Authentification] Set user connected',
        props<{ userConnected: UserConnected }>()
      ),
    forgotPassword: createApiCallActions<any>('password',
        'forgotPassword'),
    resetPassword: createApiCallActions<any>('password',
        'resetPassword'),
    changePassword: createApiCallActions<any>('password',
        'changePassword'),
};

export type AuthenticationAction = typeof authenticationActions;
