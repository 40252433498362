import { Component, OnInit } from '@angular/core';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { LeadService } from '../../../../../../shared/store/lead/lead.service';
import { filter, Observable, take } from 'rxjs';
import {
  HealthInsuranceLeadModel,
  InsuredType,
  RegimeEnum,
} from '../../../../../../shared/store/lead/types';
import {
  ControlContainer,
  FormArray,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import { DataType, DropdownStyle } from 'src/app/shared/store/typeahead/types';
import { formatDate } from '../../../../../../shared/helper/datehelper';

@Component({
  selector: 'app-lead-form-health',
  templateUrl: './lead-form-health.component.html',
  styleUrls: ['./lead-form-health.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class LeadFormHealthComponent implements OnInit {
  leadForm: FormGroup;
  healthDetail$: Observable<HealthInsuranceLeadModel>;
  hasPartner: boolean;
  dropdownStyle = DropdownStyle.DropDown;

  constructor(
    private readonly leadService: LeadService,
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
    config: NgbRatingConfig,
  ) {
    config.max = 4;
    config.readonly = false;
    this.healthDetail$ = this.leadService.getHealthDetail.value$;
  }

  ngOnInit(): void {
    this.hasPartner = false;

    this.leadForm = this.parent.form;

    this.leadForm.addControl(
      'healthFormGroup',
      this.formBuilder.group({
        routineCare: [1],
        hospi: [1],
        dental: [1],
        optical: [1],
        subscriberId: [+''],
        subscriberLastName: [''],
        subscriberFirstName: [''],
        subscriberBirthDate: ['', [Validators.required]],
        subscriberRegime: [undefined, [Validators.required]],
        partners: this.formBuilder.array([]),
        children: this.formBuilder.array([]),
      }),
    );

    this.healthDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        //patch lead
        this.leadForm.patchValue({
          healthFormGroup: {
            routineCare: x.routineCareCoverageLevel,
            hospi: x.hospitalizationCoverageLevel,
            dental: x.dentalCoverageLevel,
            optical: x.opticalCoverageLevel,
            subscriberId: x.insuredPersons.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].id,
            subscriberLastName: x.insuredPersons.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].lastName,
            subscriberFirstName: x.insuredPersons.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].firstName,
            subscriberBirthDate: formatDate(
              x.insuredPersons.filter(
                (x) => x.insuredType === InsuredType.Holder,
              )[0].birthDate,
              'ToInverseSqlDate',
            ),
            subscriberRegime:
              RegimeEnum[
                x.insuredPersons.filter(
                  (x) => x.insuredType === InsuredType.Holder,
                )[0].socialSecurityKey
              ],
          },
        });

        //patch partner
        const partner = x.insuredPersons.filter(
          (x) => x.insuredType === InsuredType.Partner,
        );
        var dataPartner: any[] = [];
        for (let i = 0; i < partner.length; i++) {
          this.addPartner();
          dataPartner.push({
            id: partner[i].id,
            partnerLastName: partner[i].lastName,
            partnerFirstName: partner[i].firstName,
            partnerBirthDate: formatDate(
              partner[i].birthDate,
              'ToInverseSqlDate',
            ),
            partnerRegime: RegimeEnum[partner[i].socialSecurityKey],
          });
        }
        this.partners.patchValue(dataPartner);

        //patch child
        const children = x.insuredPersons.filter(
          (x) => x.insuredType === InsuredType.Child,
        );
        var dataChild: any[] = [];
        for (let i = 0; i < children.length; i++) {
          this.addChild();
          dataChild.push({
            id: children[i].id,
            childLastName: children[i].lastName,
            childFirstName: children[i].firstName,
            childBirthDate: formatDate(
              children[i].birthDate,
              'ToInverseSqlDate',
            ),
            childRegime: RegimeEnum[children[i].socialSecurityKey],
          });
        }
        this.children.patchValue(dataChild);
      });
  }

  get children() {
    let healthFormGroup = this.leadForm.controls[
      'healthFormGroup'
    ] as FormGroup;

    return healthFormGroup.controls['children'] as FormArray;
  }

  get partners() {
    let healthFormGroup = this.leadForm.controls[
      'healthFormGroup'
    ] as FormGroup;

    return healthFormGroup.controls['partners'] as FormArray;
  }

  addPartner() {
    const partnerForm = this.formBuilder.group({
      partnerId: [+''],
      partnerLastName: [''],
      partnerFirstName: [''],
      partnerBirthDate: ['', [Validators.required]],
      partnerRegime: [undefined, [Validators.required]],
    });
    this.partners.push(partnerForm);
    this.hasPartner = true;
  }

  addChild() {
    const childForm = this.formBuilder.group({
      childId: [+''],
      childLastName: [''],
      childFirstName: [''],
      childBirthDate: ['', [Validators.required]],
      childRegime: [undefined, [Validators.required]],
    });

    this.children.push(childForm);
  }

  removeChild(index: number) {
    this.children.removeAt(index);
  }

  removePartner() {
    this.partners.removeAt(0);
    this.hasPartner = false;
  }

  protected readonly DataType = DataType;
}
