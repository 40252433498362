import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { View } from '../../../../shared/store/view/types';
import { SalesTeam } from '../../../../shared/store/sales-team/types';
import { ActivatedRoute, Router } from '@angular/router';
import { SubHeaderService } from '../../../../shared/store/subheader/subheader.service';
import { BreadcrumbService } from 'xng-breadcrumb';
import { ViewService } from '../../../../shared/store/view/views.service';
import { SalesTeamService } from '../../../../shared/store/sales-team/sales-team.service';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-sales-team-details',
  templateUrl: './sales-team-details.component.html',
  styleUrls: ['./sales-team-details.component.scss'],
})
export class SalesTeamDetailsComponent implements OnInit, OnDestroy {
  salesTeamId: number;
  salesTeamBase$: Observable<SalesTeam>;
  view$: Observable<View>;

  @Input() modalId: number;

  constructor(
    private route: ActivatedRoute,
    private readonly router: Router,
    private readonly salesTeamService: SalesTeamService,
    private readonly subHeaderService: SubHeaderService,
    private readonly breadcrumbService: BreadcrumbService,
    private readonly viewService: ViewService,
  ) {
    this.salesTeamBase$ = this.salesTeamService.getById.value$;
    this.view$ = this.viewService.getViews.value$;
  }

  ngOnInit(): void {
    this.salesTeamId = this.route.snapshot.params['id'];
    if (this.modalId) this.salesTeamId = this.modalId;

    this.salesTeamService.getById.call(this.salesTeamId.toString());

    this.salesTeamBase$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@salesTeamDetails', x.name);
      });

    this.view$
      .pipe(
        untilDestroyed(this),
        filter((x) => !!x),
      )
      .subscribe((x) => {
        this.breadcrumbService.set('@salesTeamList', x.title);
      });

    this.subHeaderService
      .getTriggerAction()
      .pipe(untilDestroyed(this))
      .subscribe((x) => {
        if (x == 'salesTeamEdit') {
          this.router.navigate(['Sales/SalesTeams/Edit/', this.salesTeamId]);
        }
        this.subHeaderService.setTriggerAction('');
      });
  }

  ngOnDestroy(): void {
    this.salesTeamService.getById.reset();
    this.breadcrumbService.set('@salesTeamDetails', ' ');
  }
}
