import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ArticleApiService } from './article.api.services';
import { ArticleEffects } from './article.effects';
import {
  IArticleState,
  articleReducer,
  articleInitialState,
} from './article.reducer';
import { ArticleService } from './article.service';
import { articleFeatureKey } from './types';

@NgModule({
  imports: [
    StoreModule.forFeature<IArticleState>(articleFeatureKey, articleReducer, {
      initialState: articleInitialState,
    }),
    EffectsModule.forFeature([ArticleEffects]),
  ],
  providers: [ArticleApiService, ArticleService],
})
export class StoreArticleModule {}
