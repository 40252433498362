import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { IContractState } from './contract.reducer';
import {
  ContractStatus,
  contractFeatureKey,
  CreateContractFormModel,
  ContractHealthFormModel,
  ContractCarFormModel,
  ContractHouseFormModel,
  ContractHealthUpdateModel,
  ContractHouseUpdateModel,
  ContractCarUpdateModel,
} from './types';

const contractFeatureState =
  createFeatureSelector<IContractState>(contractFeatureKey);

export const contractSelectors = {
  getById: createApiCallSelectors<string, IContractState>(
    contractFeatureState,
    'getById',
  ),

  getByUniqueId: createApiCallSelectors<string, IContractState>(
    contractFeatureState,
    'getByUniqueId',
  ),

  getByLeadId: createApiCallSelectors<string, IContractState>(
    contractFeatureState,
    'getByLeadId',
  ),

  getHealthDetail: createApiCallSelectors<number, IContractState>(
    contractFeatureState,
    'getHealthDetail',
  ),
  getCarDetail: createApiCallSelectors<number, IContractState>(
    contractFeatureState,
    'getCarDetail',
  ),
  getHouseDetail: createApiCallSelectors<number, IContractState>(
    contractFeatureState,
    'getHouseDetail',
  ),

  getStatusFlow: createApiCallSelectors<ContractStatus, IContractState>(
    contractFeatureState,
    'getStatusFlow',
  ),

  getContractModel: createApiCallSelectors<
    CreateContractFormModel,
    IContractState
  >(contractFeatureState, 'getContractModel'),

  createHealthContract: createApiCallSelectors<
    ContractHealthFormModel,
    IContractState
  >(contractFeatureState, 'createHealthContract'),

  createCarContract: createApiCallSelectors<
    ContractCarFormModel,
    IContractState
  >(contractFeatureState, 'createCarContract'),

  createHouseContract: createApiCallSelectors<
    ContractHouseFormModel,
    IContractState
  >(contractFeatureState, 'createHouseContract'),

  updateHealthContract: createApiCallSelectors<
    ContractHealthUpdateModel,
    IContractState
  >(contractFeatureState, 'updateHealthContract'),

  updateCarContract: createApiCallSelectors<
    ContractCarUpdateModel,
    IContractState
  >(contractFeatureState, 'updateCarContract'),

  updateHouseContract: createApiCallSelectors<
    ContractHouseUpdateModel,
    IContractState
  >(contractFeatureState, 'updateHouseContract'),
};
