import { NgModule } from '@angular/core';
import { OAuthModule} from 'angular-oauth2-oidc';
import { environment } from 'src/environments/environment';


@NgModule({
    imports: [OAuthModule.forRoot({
        resourceServer:{
            sendAccessToken:true,
            allowedUrls:[environment.comparanooapiurl+"/api"]
        }
    })],
    exports: [OAuthModule],
    providers:[]
})
export class AuthConfigModule {}
