import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { HeaderService } from 'src/app/shared/store/header/header.service';
import { HeaderMenu } from '../../store/header/type';

@Component({
  selector: 'app-menu-dropdown',
  templateUrl: './menu-dropdown.component.html',
  styleUrls: ['./menu-dropdown.component.scss'],
})
export class MenuDropdownComponent {
  @Input('items') items: HeaderMenu[] | undefined;
  @Input('baseRoute') baseRoute: string | undefined;
  headerMenu$: Observable<HeaderMenu[]>;

  constructor(
    private readonly router: Router,
    private headerService: HeaderService
  ) {}

  ngOnInit(): void {
    this.headerMenu$ = this.headerService.getHeaderMenu.value$;
  }

  navigateTo(url?: string | undefined) {
    this.router.navigateByUrl(url ? url : '');
    this.closeDropdown();
  }

  closeDropdown(): void {
    Array.from(document.getElementsByClassName('dropdown-menu')).forEach(
      (element) => {
        element.classList.add('display-none');
        setTimeout(() => {
          element.classList.remove('display-none');
        }, 50);
      }
    );
  }
}
