import {environment} from "../../../../environments/environment";
import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ErrorService} from "../../error";
import {Employee} from "./types";
import {Observable} from "rxjs";
import {catchError} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class EmployeeApiService {
  constructor(private readonly httpClient: HttpClient,
              private readonly errorService:ErrorService
  ) {}

  getById = (id: number): Observable<Employee> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<Employee>(url)
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        })
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.employee.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
