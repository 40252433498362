<app-document-subheader></app-document-subheader>
<div class="page-container">
  <div class="card card-light w-100">
    <div class="card-body">
      <div class="container-fluid">
        <form [formGroup]="campaignForm" (ngSubmit)="onSubmit()" [hasUnsavedData]="campaignForm">
          <button type="submit" hidden="hidden"></button>

          <div class="row">
            <div class="col-6 col-lg-6">
              <div class="form-group">
                <label class="form-title-label" for="name" translate>CAMPAIGN_LBL_NAME</label>
                <input
                  id="name"
                  class="form-control form-title"
                  type="text"
                  formControlName="name"
                  checkFormFieldValidity
                  [maxLength]="80"
                />
              </div>
            </div>
            <div class="col"></div>
          </div>

          <div class="row">

            <div class="col-6 col-lg-6">

              <div class="form-group row">
                <label class="col-4 col-form-label-sm" for="startDate" translate>CAMPAIGN_LBL_START_DATE</label>
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    id="startDate"
                    type="date"
                    formControlName="startDate"
                    checkFormFieldValidity
                  />
                </div>
              </div>
              <div class="form-group row">
                <label class="col-4 col-form-label-sm" for="endDate" translate>CAMPAIGN_LBL_END_DATE</label>
                <div class="col">
                  <input
                    class="form-control form-control-sm"
                    id="endDate"
                    type="date"
                    formControlName="endDate"
                    checkFormFieldValidity
                  />
                </div>
              </div>
            </div>

            <div class="col">
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
