import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { LeadRoutingModule } from './lead-routing.module';
import { LeadListComponent } from './lead-list/lead-list.component';
import { LeadDetailsComponent } from './lead-details/lead-details.component';
import { LeadDetailsCarInsuranceComponent } from './lead-details/lead-details-tab/lead-details-car-insurance/lead-details-car-insurance.component';
import { LeadDetailsDriverComponent } from './lead-details/lead-details-tab/lead-details-driver/lead-details-driver.component';
import { LeadDetailsHouseComponent } from './lead-details/lead-details-tab/lead-details-house/lead-details-house.component';
import { LeadDetailsVehicleComponent } from './lead-details/lead-details-tab/lead-details-vehicle/lead-details-vehicle.component';
import { LeadDetailsHealthComponent } from './lead-details/lead-details-tab/lead-details-health/lead-details-health.component';
import { LeadDetailsMarketingComponent } from './lead-details/lead-details-tab/lead-details-marketing/lead-details-marketing.component';
import { LeadEditComponent } from './lead-edit/lead-edit.component';
import { LeadCreateComponent } from './lead-create/lead-create.component';
import { LeadDetailsInsuredComponent } from './lead-details/lead-details-tab/lead-details-insured/lead-details-insured.component';
import { LeadTabsDetailsComponent } from './lead-details/lead-tabs-details/lead-tabs-details.component';
import { LeadTabsFormComponent } from './lead-form/lead-tabs-form/lead-tabs-form.component';
import { LeadFormCarInsuranceComponent } from './lead-form/lead-form-tab/lead-form-car-insurance/lead-form-car-insurance.component';
import { LeadFormHealthComponent } from './lead-form/lead-form-tab/lead-form-health/lead-form-health.component';
import { LeadFormMarketingComponent } from './lead-form/lead-form-tab/lead-form-marketing/lead-form-marketing.component';
import { LeadFormDriverComponent } from './lead-form/lead-form-tab/lead-form-driver/lead-form-driver.component';
import { LeadFormHouseComponent } from './lead-form/lead-form-tab/lead-form-house/lead-form-house.component';
import { LeadFormVehicleComponent } from './lead-form/lead-form-tab/lead-form-vehicle/lead-form-vehicle.component';
@NgModule({
  declarations: [
    LeadListComponent,
    LeadDetailsComponent,
    LeadDetailsCarInsuranceComponent,
    LeadDetailsDriverComponent,
    LeadDetailsHouseComponent,
    LeadDetailsVehicleComponent,
    LeadDetailsHealthComponent,
    LeadDetailsMarketingComponent,
    LeadEditComponent,
    LeadFormCarInsuranceComponent,
    LeadFormHealthComponent,
    LeadFormMarketingComponent,
    LeadFormDriverComponent,
    LeadFormHouseComponent,
    LeadFormVehicleComponent,
    LeadCreateComponent,
    LeadDetailsInsuredComponent,
    LeadTabsDetailsComponent,
    LeadTabsFormComponent,
  ],
  imports: [CommonModule, SharedModule, LeadRoutingModule],
  exports: [],
})
export class LeadModule {}
