import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountRoutingModule } from './account-routing.module';
import {SharedModule} from "../../shared/shared.module";
import {PasswordManageComponent} from "./password-manage/password-manage.component";

@NgModule({
  declarations: [
    PasswordManageComponent
  ],
  imports: [
    CommonModule,
    AccountRoutingModule,
    SharedModule
  ]
})
export class AccountModule { }
