import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { LayoutRoutingModule } from './layout-routing.module';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { SubHeaderComponent } from './subheader/subheader.component';

@NgModule({
  declarations: [HeaderComponent, SidebarComponent, SubHeaderComponent],
  imports: [CommonModule, SharedModule, LayoutRoutingModule],
  exports: [HeaderComponent, SidebarComponent, SubHeaderComponent],
  providers: [],
})
export class LayoutModule {}
