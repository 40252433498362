import { Component } from '@angular/core';
import { Observable } from 'rxjs';
import { ContractService } from 'src/app/shared/store/contract/contract.service';
import { ContractModel } from 'src/app/shared/store/contract/types';

@Component({
  selector: 'app-contract-details-general-informations',
  templateUrl: './contract-details-general-informations.component.html',
  styleUrls: ['./contract-details-general-informations.component.scss'],
})
export class ContractDetailsGeneralInformationsComponent {
  constructor(private readonly contractService: ContractService) {}
  contractBase$: Observable<ContractModel>;
  ngOnInit(): void {
    this.contractBase$ = this.contractService.getByUniqueId.value$;
  }
}
