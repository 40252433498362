import {
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { TabsContentEmployee } from '../../../../../shared/models/tabs-content-employee';
import { TabsContentContact } from '../../../../../shared/models/tabs-content-contact';
import { EmployeeDetailsPublicInformationsComponent } from '../employee-details-tab/employee-details-public-informations/employee-details-public-informations.component';
import { EmployeeDetailsPrivateInformationsComponent } from '../employee-details-tab/employee-details-private-informations/employee-details-private-informations.component';
import { EmployeeDetailsParametersComponent } from '../employee-details-tab/employee-details-parameters/employee-details-parameters.component';
import { EmployeeDetailsNotesComponent } from '../employee-details-tab/employee-details-notes/employee-details-notes.component';

@Component({
  selector: 'app-employee-tabs-details',
  templateUrl: './employee-tabs-details.component.html',
  styleUrls: ['./employee-tabs-details.component.scss'],
})
export class EmployeeTabsDetailsComponent implements OnInit, OnChanges {
  active = 1;

  tabsContent$: Observable<TabsContentEmployee[]> = of([]);
  tabsInit: TabsContentEmployee[] = [
    {
      title: 'EMPLOYEE_TABS_PUBLIC_INFORMATIONS',
      index: 1,
      name: 'public-informations',
      component: EmployeeDetailsPublicInformationsComponent,
    },
    {
      title: 'EMPLOYEE_TABS_PRIVATE_INFORMATIONS',
      index: 2,
      name: 'private-informations',
      component: EmployeeDetailsPrivateInformationsComponent,
    },
    {
      title: 'EMPLOYEE_TABS_PARAMETERS',
      index: 3,
      name: 'parameters',
      component: EmployeeDetailsParametersComponent,
    },
    {
      title: 'EMPLOYEE_TABS_NOTES',
      index: 4,
      name: 'notes',
      component: EmployeeDetailsNotesComponent,
    },
  ];

  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.tabsContent$ = of(this.tabsInit);
    this.changeDetectorRef.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tabsContent$ = of(this.tabsInit);
  }

  trackByTabsContent(index: any, item: TabsContentContact) {
    return item.index;
  }
}
