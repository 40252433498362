<div
  class="tab-pane fade flex-even active show"
  id="sales"
  role="tabpanel"
  aria-labelledby="sales-tab"
  formGroupName="carInsuranceFormGroup"
>
  <div class="d-flex flex-column h-100">
    <div class="row mt-2">
      <div class="col-12 col-md">
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_CAR_INSURANCE_COMPANY_NAME</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/Car/ReferenceData'"
              [referenceField]="'CurrentInsuranceCompany'"
              [controlForm]="this.leadForm.controls['carInsuranceFormGroup'].get('carInsuranceCurrentInsuranceCompany')"
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_CAR_INSURANCE_COVERAGE</label
          >
          <div class="col">
            <app-typeahead
              [limitToList]="false"
              [referenceEndpoint]="'Leads/Car/ReferenceData'"
              [referenceField]="'InsuranceCoverage'"
              [controlForm]="this.leadForm.controls['carInsuranceFormGroup'].get('carInsuranceInsuranceCoverage')"
            ></app-typeahead>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_CAR_INSURANCE_DRIVER_BODY_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
             <input type="checkbox" name="active" formControlName="carInsuranceDriverBodyCoverage"/>
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_CAR_INSURANCE_REPLACEMENT_VEHICLE_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
             <input type="checkbox" name="active" formControlName="carInsuranceReplacementVehicleCoverage"/>
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_CAR_INSURANCE_DRIVER_PROPERTY_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
             <input type="checkbox" name="active" formControlName="carInsuranceDriverPropertyCoverage"/>
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_CAR_INSURANCE_DRIVER_BRAND_NEW_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
             <input type="checkbox" name="active" formControlName="carInsuranceBrandNewCoverage"/>
            </span>
          </div>
        </div>
        <div class="form-group row">
          <label class="col-4 col-form-label-sm" translate
          >LEAD_LBL_CAR_INSURANCE_DRIVER_MONEY_LOSS_COVERAGE</label
          >
          <div class="col">
            <span class="form-control-plaintext form-control-sm">
             <input type="checkbox" name="active" formControlName="carInsuranceMoneyLossCoverage"/>
            </span>
          </div>
        </div>
      </div>

      <div class="col"></div>
    </div>
  </div>
</div>
