import { ActionCreator, createAction, props } from "@ngrx/store";
import { TypedAction } from "@ngrx/store/src/models";

export type ArrayElement<A> = A extends readonly (infer T)[] ? T : A;

export interface IGenericApiCallActions<T> {
  calling: ActionCreator<
    string,
    (props: { params: any }) => { params: any } & TypedAction<string>
  >;
  callingCancelled: ActionCreator<string, () => TypedAction<string>>;
  callingSuccess: ActionCreator<
    string,
    (props: { value: T }) => { value: T } & TypedAction<string>
  >;
  callingFailed: ActionCreator<
    string,
    (props: { error: any }) => {
      error: any;
    } & TypedAction<string>
  >;
  done: ActionCreator<
    string,
    (props: { value: T }) => { value: T } & TypedAction<string>
  >;
  reset: ActionCreator<
    string,
    (props: { value: T }) => { value: T } & TypedAction<string>
  >;
  added: ActionCreator<
    string,
    (props: { value: ArrayElement<T> }) => {
      value: ArrayElement<T>;
    } & TypedAction<string>
  >;
  updated: ActionCreator<
    string,
    (props: { value: ArrayElement<T> }) => {
      value: ArrayElement<T>;
    } & TypedAction<string>
  >;
  addedOrUpdated: ActionCreator<
    string,
    (props: { value: ArrayElement<T> }) => {
      value: ArrayElement<T>;
    } & TypedAction<string>
  >;
  deleted: ActionCreator<
    string,
    (props: { value: ArrayElement<T> }) => {
      value: ArrayElement<T>;
    } & TypedAction<string>
  >;
  setLastUpdateDate: ActionCreator<
    string,
    (props: { value: string }) => { value: string } & TypedAction<string>
  >;
}

export const createApiCallActions = <T>(
  storeName: string,
  apiCallName: string
): IGenericApiCallActions<T> => {
  const actionTypes = {
    Calling: `[${storeName}] ${apiCallName} In Progress`,
    CallingCancelled: `[${storeName}] ${apiCallName} Cancelled`,
    CallingFailed: `[${storeName}] ${apiCallName} Failed`,
    CallingSuccess: `[${storeName}] ${apiCallName} Success`,
    Done: `[${storeName}] ${apiCallName} Done`,
    Reset: `[${storeName}] ${apiCallName} Reset`,
    Added: `[${storeName}] Added to ${apiCallName} value`,
    Updated: `[${storeName}] Updated to ${apiCallName} value`,
    AddedOrUpdated: `[${storeName}] Added or Updated to ${apiCallName} value`,
    Deleted: `[${storeName}] Deleted from ${apiCallName} value`,
    SetLastUpdateDate: `[${storeName}] Set the last update date for ${apiCallName}`,
  };

  const calling = createAction(actionTypes.Calling, props<{ params: any }>());
  const callingCancelled = createAction(actionTypes.CallingCancelled);
  const callingSuccess = createAction(
    actionTypes.CallingSuccess,
    props<{ value: T }>()
  );
  const callingFailed = createAction(
    actionTypes.CallingFailed,
    props<{ error: any }>()
  );
  const done = createAction(actionTypes.Done, props<{ value: T }>());
  const reset = createAction(actionTypes.Reset, props<{ value: T }>());

  const added = createAction(
    actionTypes.Added,
    props<{ value: ArrayElement<T> }>()
  );
  const updated = createAction(
    actionTypes.Updated,
    props<{ value: ArrayElement<T> }>()
  );
  const addedOrUpdated = createAction(
    actionTypes.AddedOrUpdated,
    props<{ value: ArrayElement<T> }>()
  );
  const deleted = createAction(
    actionTypes.Deleted,
    props<{ value: ArrayElement<T> }>()
  );
  const setLastUpdateDate = createAction(
    actionTypes.SetLastUpdateDate,
    props<{ value: string }>()
  );

  return {
    calling,
    callingCancelled,
    callingSuccess,
    callingFailed,
    done,
    reset,
    added,
    updated,
    addedOrUpdated,
    deleted,
    setLastUpdateDate,
  };
};
