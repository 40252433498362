import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { Observable, of } from 'rxjs';
import { TabsContent } from 'src/app/shared/models/tabs-content';
import { InsuranceCategory } from 'src/app/shared/store/lead/types';
import { ContractDetailsInsuredPersonsComponent } from '../contract-details-tab/contract-details-insured-persons/contract-details-insured-persons.component';
import { ContractDetailsDriverComponent } from 'src/app/features/sale/contract/contract-details/contract-details-tab/contract-details-driver/contract-details-driver.component';
import { ContractDetailsVehicleComponent } from 'src/app/features/sale/contract/contract-details/contract-details-tab/contract-details-vehicle/contract-details-vehicle.component';
import { ContractDetailsCarInsuranceComponent } from 'src/app/features/sale/contract/contract-details/contract-details-tab/contract-details-car-insurance/contract-details-car-insurance.component';
import { ContractDetailsHouseComponent } from 'src/app/features/sale/contract/contract-details/contract-details-tab/contract-details-house/contract-details-house.component';

@Component({
  selector: 'app-contract-tabs-details',
  templateUrl: './contract-tabs-details.component.html',
  styleUrls: ['./contract-tabs-details.component.scss'],
})
export class ContractTabsDetailsComponent implements OnInit, OnChanges {
  @Input() category: InsuranceCategory;
  active = 1;

  tabsContent$: Observable<TabsContent[]> = of([]);

  tabsInit: TabsContent[] = [
    {
      title: 'CONTRACT_TABS_HEALTH',
      index: 2,
      name: 'health',
      categoryString: 'Health',
      category: InsuranceCategory.Health,
      component: ContractDetailsInsuredPersonsComponent,
      formGroupName: 'healthFormGroup',
    },
    {
      title: 'CONTRACT_TABS_DRIVER',
      index: 2,
      name: 'driver',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractDetailsDriverComponent,
    },
    {
      title: 'CONTRACT_TABS_VEHICLE',
      index: 3,
      name: 'vehicle',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractDetailsVehicleComponent,
    },
    {
      title: 'CONTRACT_TABS_INFORMATIONS',
      index: 4,
      name: 'car-insurance',
      categoryString: 'Car',
      category: InsuranceCategory.Car,
      component: ContractDetailsCarInsuranceComponent,
    },
    {
      title: 'CONTRACT_TABS_HOUSE',
      index: 2,
      name: 'house',
      categoryString: 'House',
      category: InsuranceCategory.House,
      component: ContractDetailsHouseComponent,
    },
  ];

  ngOnInit(): void {
    this.tabsContent$ = of(
      this.tabsInit.filter((x) => x.categoryString === this.category.toString())
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.tabsContent$ = of(
      this.tabsInit.filter(
        (x) => x.categoryString === this.category?.toString()
      )
    );
  }

  trackByTabsContent(index: any, item: TabsContent) {
    return item.index;
  }
}
