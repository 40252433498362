import {EmployeeService} from "./employee.service";
import {EmployeeApiService} from "./employee.api.service";
import {EmployeeEffects} from "./employee.effects";
import {NgModule} from "@angular/core";
import {StoreModule} from "@ngrx/store";
import {employeeInitialState, employeeReducer, IEmployeeState} from "./employee.reducer";
import {employeeFeatureKey} from "./types";
import {EffectsModule} from "@ngrx/effects";

@NgModule({
  imports:[
    StoreModule.forFeature<IEmployeeState>(employeeFeatureKey, employeeReducer, {
      initialState: employeeInitialState,
    }),
    EffectsModule.forFeature([EmployeeEffects])
  ],
  providers:[EmployeeApiService, EmployeeService]
})
export class StoreEmployeeModule {}
