import { createFeatureSelector } from '@ngrx/store';
import { createApiCallSelectors } from 'src/app/common/store/generic.selectors';
import { PageResult } from '../../types';
import { IGlossaryState } from './glossary.reducer';
import { Glossary, glossaryFeatureKey } from './types';

const glossaryFeatureState =
  createFeatureSelector<IGlossaryState>(glossaryFeatureKey);

export const glossarySelectors = {
  getPaged: createApiCallSelectors<PageResult<Glossary>, IGlossaryState>(
    glossaryFeatureState,
    'getPaged'
  ),
  getById: createApiCallSelectors<Glossary, IGlossaryState>(
    glossaryFeatureState,
    'getById'
  ),
  postGlossary: createApiCallSelectors<any, IGlossaryState>(
    glossaryFeatureState,
    'postGlossary'
  ),
  publishGlossary: createApiCallSelectors<any, IGlossaryState>(
    glossaryFeatureState,
    'publishGlossary'
  ),
  unpublishGlossary: createApiCallSelectors<any, IGlossaryState>(
    glossaryFeatureState,
    'unpublishGlossary'
  ),
  updateGlossary: createApiCallSelectors<any, IGlossaryState>(
    glossaryFeatureState,
    'updateGlossary'
  ),
};
