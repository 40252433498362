import {
  Contact,
  ContactPost,
  ContactUpdate,
  CreateContactFormModel,
} from './types';
import { Injectable } from '@angular/core';
import { BaseServiceFacade, GenericApiCall } from '../../../common/store/types';
import { Store } from '@ngrx/store';
import { AppState } from '../types';
import { contactActions } from './contact.actions';
import { contactSelectors } from './contact.selector';

@Injectable()
export class ContactService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }

  getById: GenericApiCall<Contact, string> = this.genericApiCall(
    contactActions.getById,
    contactSelectors.getById,
    true,
  );

  postContact: GenericApiCall<Contact, ContactPost> = this.genericApiCall(
    contactActions.postContact,
    contactSelectors.postContact,
    true,
  );

  updateContact: GenericApiCall<Contact, ContactUpdate> = this.genericApiCall(
    contactActions.updateContact,
    contactSelectors.updateContact,
    true,
  );

  getContactFormModel: GenericApiCall<CreateContactFormModel, void> =
    this.genericApiCall(
      contactActions.getContactFormModel,
      contactSelectors.getContactFormModel,
      true,
    );
}
