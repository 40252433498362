import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { createApiCallEffects } from '../../../common/store/generic.effects';
import { SalesTeamMemberApiService } from './sales-team-member.api.service';
import {
  SalesTeamMemberAction,
  salesTeamMemberActions,
} from './sales-team-member.action';

@Injectable()
export class SalesTeamMemberEffects {
  constructor(
    private readonly salesTeamMemberApiService: SalesTeamMemberApiService,
    private readonly action$: Actions<SalesTeamMemberAction>
  ) {}

  getById = createApiCallEffects(
    this,
    this.action$,
    salesTeamMemberActions.getById,
    this.salesTeamMemberApiService.getById
  );
}
