import { createApiCallActions } from 'src/app/common/store/generic.actions';
import {
  CarInsuranceContractModel,
  ContractModel,
  ContractStatusFlow,
  CreateContractFormModel,
  HealthInsuranceContractDetailModel,
  HouseInsuranceContractModel,
} from './types';

export const contractActions = {
  getById: createApiCallActions<ContractModel>('contract', 'getById'),
  getByUniqueId: createApiCallActions<ContractModel>(
    'contract',
    'getByUniqueId',
  ),
  getByLeadId: createApiCallActions<ContractModel>('contract', 'getByLeadId'),
  getHealthDetail: createApiCallActions<HealthInsuranceContractDetailModel[]>(
    'contract',
    'getHealthDetail',
  ),
  getCarDetail: createApiCallActions<CarInsuranceContractModel>(
    'contract',
    'getCarDetail',
  ),
  getHouseDetail: createApiCallActions<HouseInsuranceContractModel>(
    'contract',
    'getHouseDetail',
  ),
  getStatusFlow: createApiCallActions<ContractStatusFlow[]>(
    'contract',
    'getStatusFlow',
  ),
  getContractFormModel: createApiCallActions<CreateContractFormModel>(
    'contract',
    'getContractFormModel',
  ),
  createHealthContract: createApiCallActions<ContractModel>(
    'contract',
    'createHealthContract',
  ),
  createCarContract: createApiCallActions<ContractModel>(
    'contract',
    'createCarContract',
  ),
  createHouseContract: createApiCallActions<ContractModel>(
    'contract',
    'createHouseContract',
  ),
  updateHealthContract: createApiCallActions<ContractModel>(
    'contract',
    'updateHealthContract',
  ),
  updateCarContract: createApiCallActions<ContractModel>(
    'contract',
    'updateCarContract',
  ),
  updateHouseContract: createApiCallActions<ContractModel>(
    'contract',
    'updateHouseContract',
  ),
};

export type ContractAction = typeof contractActions;
