import {Injectable} from "@angular/core";
import {Actions} from "@ngrx/effects";
import {AuthenticationAction, authenticationActions} from "./authentication.actions";
import {createApiCallEffects} from "../../../common/store/generic.effects";
import {IAuthenticationState} from "./authentication.reducer";
import {Store} from "@ngrx/store";
import {AuthenticationApiService} from "./authentication.api.service";

@Injectable()
export class AuthenticationEffects {
    constructor(
        private readonly store: Store<IAuthenticationState>,
        private readonly authenticationApiService: AuthenticationApiService,
        private readonly actions$: Actions<AuthenticationAction>
    ) { }

    forgotPassword = createApiCallEffects(
        this,
        this.actions$,
        authenticationActions.forgotPassword,
        this.authenticationApiService.forgotPassword
    );

    resetPassword = createApiCallEffects(
        this,
        this.actions$,
        authenticationActions.resetPassword,
        this.authenticationApiService.resetPassword
    );
    changePassword = createApiCallEffects(
        this,
        this.actions$,
        authenticationActions.changePassword,
        this.authenticationApiService.changePassword
    );
}
