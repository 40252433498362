import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseServiceFacade, GenericApiCall } from 'src/app/common/store/types';
import { AppState } from '../types';
import { sidebarActions } from './sidebar.actions';
import { sidebarSelectors } from './sidebar.selectors';
import { SidebarModule } from './types';

@Injectable()
export class SidebarService extends BaseServiceFacade {
  constructor(store: Store<AppState>) {
    super(store);
  }
  getSidebarModule: GenericApiCall<SidebarModule[], void> = this.genericApiCall(
    sidebarActions.getSidebarModule,
    sidebarSelectors.getSidebarModule,
    true
  );
}
