import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ErrorService } from '../../error';
import { environment } from '../../../../environments/environment';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  CreateSalesTeamFormModel,
  SalesTeam,
  SalesTeamBase,
  SalesTeamPost,
  SalesTeamUpdate,
} from './types';
import { SalesTeamMember } from '../sales-team-member/types';
import { Job, JobPost, JobUpdate } from '../job/types';
import { CrudType } from '../../service/message/types';
import { MessageService } from '../../service/message/message.service';
import { SalesTeamCreateComponent } from '../../../features/sale/sales-team/sales-team-create/sales-team-create.component';
import { CreateContractFormModel } from '../contract/types';

@Injectable({
  providedIn: 'root',
})
export class SalesTeamApiService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly errorService: ErrorService,
    private readonly messageService: MessageService,
  ) {}

  getById = (id: number): Observable<SalesTeam> => {
    let parameters: string[];
    parameters = [id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<SalesTeam>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };
  getList = (id: number): Observable<SalesTeamBase[]> => {
    let parameters: string[];
    parameters = ['list'];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<SalesTeamBase[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  getSalesTeamMemberList = (id: number): Observable<SalesTeamMember[]> => {
    let parameters: string[];
    parameters = ['userlist'];
    let queryParams = new HttpParams();
    queryParams = queryParams.append('salesTeamId', id);
    let url = this.formatUrl(parameters);
    return this.httpClient
      .get<SalesTeamMember[]>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  getSalesTeamTypeAheadList = (id: number): Observable<SalesTeamBase[]> => {
    let parameters: string[];
    parameters = ['typeahead', 'list'];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<SalesTeamBase[]>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };
  getSalesTeamMemberTypeAheadList = (
    id: number,
  ): Observable<SalesTeamMember[]> => {
    let parameters: string[];
    parameters = ['typeahead', 'userslist'];
    let queryParams = new HttpParams();
    queryParams = queryParams.append('salesTeamId', id);
    let url = this.formatUrl(parameters);
    return this.httpClient
      .get<SalesTeamMember[]>(url, { params: queryParams })
      .pipe(
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  getSalesTeamModel = (): Observable<CreateSalesTeamFormModel> => {
    let parameters: string[];
    parameters = ['SalesTeamModel'];
    let url = this.formatUrl(parameters);
    return this.httpClient.get<CreateSalesTeamFormModel>(url).pipe(
      catchError((error) => {
        throw this.errorService.serverErrorRedirect(error);
      }),
    );
  };

  postSalesTeam = (salesTeamPost: SalesTeamPost): any => {
    let parameters: string[];
    parameters = [];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .post<SalesTeam>(url, salesTeamPost, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.create),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  updateSalesTeam = (salesTeamUpdate: SalesTeamUpdate): any => {
    let parameters: string[];
    parameters = [salesTeamUpdate.id.toString()];
    let url = this.formatUrl(parameters);
    return this.httpClient
      .put<SalesTeam>(url, salesTeamUpdate, { observe: 'response' })
      .pipe(
        this.messageService.checkStatusCustomOperator(CrudType.update),
        catchError((error) => {
          throw this.errorService.serverErrorRedirect(error);
        }),
      );
  };

  formatUrl = (parameters: string[]): string => {
    var url = environment.comparanooapiurl;
    url += environment.features.salesteam.url;
    parameters.forEach((parameters) => {
      url += '/';
      url += parameters;
    });
    return url;
  };
}
