import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthentificationGuard } from 'src/app/shared/guard/authentification-guard';
import { HeaderMenuType } from 'src/app/shared/store/header/type';
import { Entity } from 'src/app/shared/store/view/types';
import { SubHeaderType } from 'src/app/shared/store/subheader/type';
import { SalesDashboardComponent } from './sales-dashboard/sales-dashboard.component';
import { HasUnsavedChangesGuard } from '../../../shared/guard/has-form-changed.guard';

const routes: Routes = [
  {
    path: 'Sales',
    data: {
      breadcrumb: { label: ' ', alias: 'salesDashboard' },
    },
    children: [
      {
        path: '',
        redirectTo: 'Home',
        pathMatch: 'full',
      },
      {
        path: 'Home',
        component: SalesDashboardComponent,
        canActivate: [AuthentificationGuard],
        canDeactivate: [HasUnsavedChangesGuard],
        data: {
          headerMenu: HeaderMenuType.Sales,
          entity: Entity.SalesDashboard,
          breadcrumb: { skip: true },
        },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SalesDashboardRoutingModule {}
