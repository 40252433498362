import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { createApiCallEffects } from 'src/app/common/store/generic.effects';
import { BankAccountAction, bankAccountActions } from './bank-account.actions';
import { BankAccountApiService } from './bank-account.api.services';
import { IBankAccountState } from './bank-account.reducer';

@Injectable()
export class BankAccountEffects {
  constructor(
    private readonly store: Store<IBankAccountState>,
    private readonly bankAccountApiService: BankAccountApiService,
    private readonly actions$: Actions<BankAccountAction>
  ) {}

  getById = createApiCallEffects(
    this,
    this.actions$,
    bankAccountActions.getById,
    this.bankAccountApiService.getById
  );
}
