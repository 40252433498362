import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SubHeaderApiService } from './subheader.api.services';
import { SubHeaderEffects } from './subheader.effects';
import {
  ISubHeaderState,
  subHeaderInitialState,
  subHeaderReducer,
} from './subheader.reducer';
import { SubHeaderService } from './subheader.service';
import { subHeaderFeatureKey } from './type';

@NgModule({
  imports: [
    StoreModule.forFeature<ISubHeaderState>(
      subHeaderFeatureKey,
      subHeaderReducer,
      {
        initialState: subHeaderInitialState,
      }
    ),
    EffectsModule.forFeature([SubHeaderEffects]),
  ],
  providers: [SubHeaderApiService, SubHeaderService],
})
export class StoreSubHeaderModule {}
