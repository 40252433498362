import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, of } from 'rxjs';
import { Config, Environment } from 'src/app/core/types';
import { AuthentificationService } from 'src/app/shared/authentification/authentification.service';
import { AuthenticationService } from 'src/app/shared/store/authentication/authentication.service';
import { UserConnected } from 'src/app/shared/store/authentication/types';
import { HeaderService } from 'src/app/shared/store/header/header.service';
import { HeaderMenu } from 'src/app/shared/store/header/type';
import { Avatar, User } from '../../shared/store/user/type';
import { UserService } from '../../shared/store/user/user.service';
import { environment } from '../../../environments/environment';
import { filter, map, take } from 'rxjs/operators';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  config!: Config;
  env!: { name: Environment; version: string };
  title$!: Observable<string | null>;
  header$!: Observable<string | null>;
  user$!: Observable<UserConnected>;
  currentLanguage$!: Observable<string>;
  notCurrentLanguage$!: Observable<string[]>;
  isIpad!: boolean;
  isAuthenticated$!: Observable<boolean>;
  headerMenu$: Observable<HeaderMenu[]>;

  currentAvatar$: Observable<Avatar>;

  constructor(
    private readonly headerService: HeaderService,
    private readonly authService: AuthentificationService,
    private readonly authenticationService: AuthenticationService,
    private readonly router: Router,
    private readonly userService: UserService,
  ) {
    this.user$ = this.authenticationService.getUserConnected();
    this.headerMenu$ = this.headerService.getHeaderMenu.value$;
  }

  ngOnInit(): void {
    //this.env = this.config.env;
    //this.isIpad = this.config.deviceInfo.device.toLowerCase().includes('ipad');
    this.user$.pipe(filter((x) => !!x)).subscribe((x) => {
      if (x.email !== '') {
        this.userService.getCurrentUser
          .call()
          .pipe(
            filter((x) => !!x),
            take(1),
          )
          .subscribe((user) => {
            if (user != undefined) {
              let avatar = new Avatar(
                user.contactName,
                [
                  environment.comparanooapiurl,
                  environment.features.thumbnail.url,
                  environment.features.thumbnail.avatar,
                ].join(''),
                window
                  .getComputedStyle(document.body)
                  .getPropertyValue('--comment-avatar-bg')
                  .replace('#', ''),
              );

              this.currentAvatar$ = of(avatar);
            }
          });
      }
    });
  }

  ngOnDestroy(): void {}

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/Login');
  }

  changePassword() {
    this.router.navigateByUrl('/Account/PasswordManage');
  }
}
