import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HumanResourcesRoutingModule } from './human-resources-routing.module';
import { SharedModule } from '../../shared/shared.module';
import { HumanResourcesDashboardModule } from './dashboard/human-resources-dashboard.module';
import { EmployeeModule } from './employee/employee.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    SharedModule,
    HumanResourcesRoutingModule,
    HumanResourcesDashboardModule,
    EmployeeModule,
  ],
  exports: [],
})
export class HumanResourcesModule {}
