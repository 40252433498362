import { Component, OnInit } from '@angular/core';
import {
  ControlContainer,
  FormArray,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import {
  DataType,
  DropdownStyle,
} from '../../../../../../shared/store/typeahead/types';
import { ContractService } from '../../../../../../shared/store/contract/contract.service';
import { filter, Observable, take } from 'rxjs';
import { HealthInsuranceContractDetailModel } from '../../../../../../shared/store/contract/types';
import {
  InsuredType,
  RegimeEnum,
} from '../../../../../../shared/store/lead/types';
import { formatDate } from '../../../../../../shared/helper/datehelper';

@Component({
  selector: 'app-contract-form-health',
  templateUrl: './contract-form-health.component.html',
  styleUrls: ['./contract-form-health.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class ContractFormHealthComponent implements OnInit {
  form: FormGroup;
  hasPartner: boolean;
  dropdownStyle = DropdownStyle.DropDown;
  protected readonly DataType = DataType;

  healthDetail$: Observable<HealthInsuranceContractDetailModel[]>;

  constructor(
    private readonly contractService: ContractService,
    private parent: FormGroupDirective,
    private formBuilder: FormBuilder,
  ) {
    this.healthDetail$ = this.contractService.getHealthDetail.value$;
  }

  ngOnInit(): void {
    this.hasPartner = false;

    this.form = this.parent.form;
    this.form.addControl(
      'healthFormGroup',
      this.formBuilder.group({
        subscriberId: [+''],
        subscriberLastName: [''],
        subscriberFirstName: [''],
        subscriberBirthDate: ['', [Validators.required]],
        subscriberRegime: [undefined, [Validators.required]],
        subscriberGender: [undefined, [Validators.required]],
        subscriberSocialSecurityNumber: [
          '',
          [
            Validators.required,
            Validators.minLength(15),
            Validators.maxLength(15),
          ],
        ],
        subcriberCodeCpam: [
          '',
          [Validators.minLength(9), Validators.maxLength(9)],
        ],
        partners: this.formBuilder.array([]),
        children: this.formBuilder.array([]),
      }),
    );

    this.healthDetail$
      .pipe(
        filter((x) => !!x),
        take(1),
      )
      .subscribe((x) => {
        //patch lead
        this.form.patchValue({
          healthFormGroup: {
            subscriberId: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].id,
            subscriberLastName: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].personLastName,
            subscriberFirstName: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].personFirstName,
            subscriberBirthDate: formatDate(
              x.filter((x) => x.insuredType === InsuredType.Holder)[0]
                .personBirthDate,
              'ToInverseSqlDate',
            ),
            subscriberRegime:
              RegimeEnum[
                x.filter((x) => x.insuredType === InsuredType.Holder)[0]
                  .personSocialSecurityKey
              ],
            subscriberGender: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].personGenderInt,
            subscriberSocialSecurityNumber: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].personSocialSecurityNumber,
            subcriberCodeCpam: x.filter(
              (x) => x.insuredType === InsuredType.Holder,
            )[0].personCodeCpam,
          },
        });

        //patch partner
        const partner = x.filter((x) => x.insuredType === InsuredType.Partner);
        var dataPartner: any[] = [];
        for (let i = 0; i < partner.length; i++) {
          this.addPartner();
          dataPartner.push({
            id: partner[i].id,
            partnerLastName: partner[i].personLastName,
            partnerFirstName: partner[i].personFirstName,
            partnerBirthDate: formatDate(
              partner[i].personBirthDate,
              'ToInverseSqlDate',
            ),
            partnerRegime: RegimeEnum[partner[i].personSocialSecurityKey],
            partnerGender: partner[i].personGenderInt,
            partnerSocialSecurityNumber: partner[i].personSocialSecurityNumber,
            partnerCodeCpam: partner[i].personCodeCpam,
          });
        }
        this.partners.patchValue(dataPartner);

        //patch child
        const children = x.filter((x) => x.insuredType === InsuredType.Child);
        var dataChild: any[] = [];
        for (let i = 0; i < children.length; i++) {
          this.addChild();
          dataChild.push({
            id: children[i].id,
            childLastName: children[i].personLastName,
            childFirstName: children[i].personFirstName,
            childBirthDate: formatDate(
              children[i].personBirthDate,
              'ToInverseSqlDate',
            ),
            childRegime: RegimeEnum[children[i].personSocialSecurityKey],
            partnerGender: children[i].personGenderInt,
            partnerSocialSecurityNumber: children[i].personSocialSecurityNumber,
            partnerCodeCpam: children[i].personCodeCpam,
          });
        }
        this.children.patchValue(dataChild);
      });
  }

  get children() {
    let healthFormGroup = this.form.controls['healthFormGroup'] as FormGroup;

    return healthFormGroup.controls['children'] as FormArray;
  }

  get partners() {
    let healthFormGroup = this.form.controls['healthFormGroup'] as FormGroup;

    return healthFormGroup.controls['partners'] as FormArray;
  }

  addPartner() {
    const partnerForm = this.formBuilder.group({
      partnerId: [+''],
      partnerLastName: [''],
      partnerFirstName: [''],
      partnerBirthDate: ['', [Validators.required]],
      partnerRegime: [undefined, [Validators.required]],
      partnerGender: [undefined, [Validators.required]],
      partnerSocialSecurityNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(15),
          Validators.maxLength(15),
        ],
      ],
      partnerCodeCpam: ['', [Validators.minLength(9), Validators.maxLength(9)]],
    });
    this.partners.push(partnerForm);
    this.hasPartner = true;
  }

  addChild() {
    const childForm = this.formBuilder.group({
      childId: [+''],
      childLastName: [''],
      childFirstName: [''],
      childBirthDate: ['', [Validators.required]],
      childRegime: [undefined, [Validators.required]],
      childGender: [undefined, [Validators.required]],
      childSocialSecurityNumber: [
        '',
        [
          Validators.required,
          Validators.minLength(15),
          Validators.maxLength(15),
        ],
      ],
      childCodeCpam: ['', [Validators.minLength(9), Validators.maxLength(9)]],
      childAffiliatedToSubscriber: [true],
    });

    this.children.push(childForm);
  }

  removeChild(index: number) {
    this.children.removeAt(index);
  }

  removePartner() {
    this.partners.removeAt(0);
    this.hasPartner = false;
  }
}
