import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { SharedModule } from "src/app/shared/shared.module";
import { JobCreateComponent } from "./job-create/job-create.component";
import { JobDetailsComponent } from "./job-details/job-details.component";
import { JobEditComponent } from "./job-edit/job-edit.component";
import { JobListComponent } from "./job-list/job-list.component";
import { JobRoutingModule } from "./job-routing.module";

@NgModule({
    declarations: [
        JobListComponent,
        JobDetailsComponent,
        JobCreateComponent,
        JobEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        JobRoutingModule
    ]
})
export class JobModule { }