import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { CurrencyRoutingModule } from './currency-routing.module';
import { CurrencyListComponent } from './currency-list/currency-list.component';
import { CurrencyDetailsComponent } from './currency-details/currency-details.component';
import { CurrencyCreateComponent } from './currency-create/currency-create.component';
import { CurrencyEditComponent } from './currency-edit/currency-edit.component';
import { CurrencyDetailsContentComponent } from './currency-details/currency-details-content/currency-details-content.component';
import { CurrencyCreateContentComponent } from './currency-create/currency-create-content/currency-create-content.component';


@NgModule({
  declarations: [
    CurrencyListComponent,
    CurrencyDetailsComponent,
    CurrencyCreateComponent,
    CurrencyEditComponent,
    CurrencyDetailsContentComponent,
    CurrencyCreateContentComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    CurrencyRoutingModule
  ]
})
export class CurrencyModule { }
