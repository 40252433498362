import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from "../../../shared/shared.module";
import { CountryRoutingModule } from './country-routing.module';
import { CountryListComponent } from './country-list/country-list.component';
import { CountryDetailsComponent } from './country-details/country-details.component';
import { CountryCreateComponent } from './country-create/country-create.component';
import { CountryEditComponent } from './country-edit/country-edit.component';


@NgModule({
    declarations: [
        CountryListComponent,
        CountryDetailsComponent,
        CountryCreateComponent,
        CountryEditComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        CountryRoutingModule
    ]
})
export class CountryModule { }
